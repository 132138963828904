import { getOrderRoute, OrderRoute } from '../routes';
import { IStep, IStepper } from './stepper.types';

export const STEPPER: IStepper = {
  FIRST: {
    index: 0,
    nextIndex: 1,
    route: OrderRoute.CUSTOMER,
    routeId: getOrderRoute(OrderRoute.CUSTOMER),
    name: 'client',
    title: 'Client',
  },
  SECOND: {
    prevIndex: 0,
    index: 1,
    nextIndex: 2,
    route: OrderRoute.PRODUCT,
    routeId: getOrderRoute(OrderRoute.PRODUCT),
    name: 'product',
    title: 'Catalogue',
  },
  THIRD: {
    prevIndex: 1,
    index: 2,
    nextIndex: 3,
    route: OrderRoute.DELIVERY,
    routeId: getOrderRoute(OrderRoute.DELIVERY),
    name: 'order',
    title: 'Livraisons',
  },
  FOURTH: {
    prevIndex: 2,
    index: 3,
    route: OrderRoute.VALIDATION,
    routeId: getOrderRoute(OrderRoute.VALIDATION),
    name: 'validate',
    title: 'Validation',
  },
};

export const getStepNumber = (step: IStep): number => {
  return step.index + 1;
};
