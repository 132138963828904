import { PDFVariables } from 'types/pdf';
import {
  SavedCartsResponse,
  SavedCartStatusCount,
  SavedCartRestoredState,
  SavedCartSavedState,
  SaveSavedCartResponse,
  ISavedCartFilters,
  SavedCartUpdateData,
  ISavedCartRequestPayload,
  SavedCartStatusEnum,
} from 'store/savedCart/types';
import { RestServiceApi } from 'services/api.service';
import moment from 'moment';
import { getToken } from 'cookies';
import { config } from 'config';
import { ApiResponse } from 'apisauce';

const savedCartApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

class SavedCartServiceApi {
  public async getPage(
    skip: number,
    limit: number,
    filters?: ISavedCartFilters,
    tcCode?: string,
  ): Promise<ApiResponse<SavedCartsResponse>> {
    return savedCartApi.get<ISavedCartRequestPayload, SavedCartsResponse>(
      `carts?${this.getFilterQuery(skip, limit, filters, tcCode)}`,
      {},
      savedCartApi.getAuthenticateHeader(getToken()),
    );
  }

  public async getStatusCount(
    skip: number,
    limit: number,
    filters?: ISavedCartFilters,
    tcCode?: string,
  ): Promise<ApiResponse<SavedCartStatusCount>> {
    return savedCartApi.get<ISavedCartRequestPayload, SavedCartStatusCount>(
      `carts/count?${this.getFilterQuery(skip, limit, filters, tcCode)}`,
      {},
      savedCartApi.getAuthenticateHeader(getToken()),
    );
  }

  public getFilterQuery(skip: number, limit: number, filters?: ISavedCartFilters, tcCode?: string): string {
    const query = new URLSearchParams();
    query.append('$skip', skip.toString());
    if (limit > 0) {
      query.append('$limit', limit.toString());
    }

    filters = filters || ({} as ISavedCartFilters);

    if (filters.search) {
      query.append('search', filters.search);
    }

    if (filters.createdStartDate && filters.createdEndDate) {
      query.append('createdAt', moment(filters.createdStartDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
      query.append('createdAt', moment(filters.createdEndDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    }

    if (filters.expiredStartDate && filters.expiredEndDate) {
      query.append(
        'updatedAt',
        moment(filters.expiredStartDate).utc(false).subtract(2, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      );
      query.append(
        'updatedAt',
        moment(filters.expiredEndDate).utc(false).subtract(2, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      );
    }
    if (filters.atcCartNumber) {
      query.append('atcCartNumber', filters.atcCartNumber);
    }
    if (filters.customerIdentifier) {
      query.append('customerIdentifier', filters.customerIdentifier);
    }

    if (filters.status) {
      query.append('status', filters.status);
    }

    query.append('archived', String(filters.archived ?? false));
    query.append('convertedPdf', String(filters.convertedPdf ?? false));

    if (filters.assignedTcCode) {
      query.append('assignedTcCode', filters.assignedTcCode);
    }

    if (filters.assignedToMe && tcCode) {
      query.append('assignedTcCode', tcCode);
    }

    return query.toString();
  }

  public async get(id: string): Promise<ApiResponse<SavedCartRestoredState>> {
    return savedCartApi.get<Record<string, never>, SavedCartRestoredState>(
      `carts/${id}`,
      {},
      savedCartApi.getAuthenticateHeader(getToken()),
    );
  }

  public async save(
    data: SavedCartSavedState & { pdfVariables?: PDFVariables; attachPayload?: boolean },
    id: Nullable<string>,
    isSavedCart: boolean,
  ): Promise<ApiResponse<SaveSavedCartResponse>> {
    if (id && isSavedCart) {
      return this.update(id, data);
    } else {
      return this.add(id, data);
    }
  }

  public async update(id: string, data: Partial<SavedCartUpdateData>): Promise<ApiResponse<SaveSavedCartResponse>> {
    return savedCartApi.patch<Partial<SavedCartUpdateData>, SaveSavedCartResponse>(
      `carts/${id}`,
      { ...data },
      savedCartApi.getAuthenticateHeader(getToken()),
    );
  }

  public async add(id: Nullable<string>, data: SavedCartSavedState): Promise<ApiResponse<SaveSavedCartResponse>> {
    return savedCartApi.post<SavedCartUpdateData, SaveSavedCartResponse>(
      `carts`,
      {
        ...data,
        // create new id from front-end
        ...(id && { _id: id }),
        status: SavedCartStatusEnum.IN_PROGRESS,
      },
      savedCartApi.getAuthenticateHeader(getToken()),
    );
  }

  public async delete(
    id: string,
    queryParams: { permanent?: boolean } = {},
  ): Promise<ApiResponse<SavedCartRestoredState>> {
    const { permanent } = queryParams;
    const query = new URLSearchParams({
      ...(permanent && { permanent: 'true' }),
    }).toString();

    return savedCartApi.delete<Record<string, never>, SavedCartRestoredState>(
      `carts/${id}${query && `?${query}`}`,
      {},
      savedCartApi.getAuthenticateHeader(getToken()),
    );
  }

  public async assignToCart(cartId: string, tcCode: string | null): Promise<ApiResponse<unknown>> {
    return savedCartApi.patch<unknown, string>(
      `/carts/${cartId}/assignee/${tcCode}`,
      {},
      {
        ...savedCartApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async cloneCart(cartId: string): Promise<ApiResponse<unknown>> {
    return savedCartApi.post<unknown, unknown>(
      `/carts/${cartId}/clone`,
      {},
      {
        ...savedCartApi.getAuthenticateHeader(getToken()),
      },
    );
  }
}

export const SavedCartService = new SavedCartServiceApi();
