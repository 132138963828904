import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo } from 'react';
import { Table } from 'antd';
import {
  ProductDeliveryColumn,
  ProductInfoColumn,
  ProductPriceColumn,
  ProductReferenceColumn,
  ProductStockColumn,
  ProductWarehouseStockColumn,
} from '../../ProductTable/ProductTableColumns';
import styles from '../../ProductTable/ProductTable.module.scss';
import { percentage } from '../../../../utils/styles.utils';
import { filterColumns } from '../../../../utils';
import { selectTcTags } from '../../../../store/user';
import { useAppSelector } from '../../../../store/store';
import { IParsedProductItem, IParsedProductItemRowKey } from '../../../../store/products/types';
import {
  fetchReplacements,
  selectParsedReplacementProducts,
  selectPostalCode,
  selectReplacementModalDisplayAllOffers,
  selectReplacementModalLoading,
  selectReplacementModalSku,
  setReplacementModalDisplayAllOffers,
  setWarehouseStockModalSku,
  setWarehouseStockModalVisibility,
} from '../../../../store/products';
import { selectTags } from '../../../../store/customers';
import { selectCartId, selectIsSavedCart, selectRestoredUserTags } from '../../../../store/cart';
import ReplacementProductAmountColumn from './ReplacementProductAmountColumn';

type ReplacementTableParams = {
  excludeNoStockOffers: boolean;
};

const ReplacementTable: FC<ReplacementTableParams> = ({ excludeNoStockOffers }: ReplacementTableParams) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sku = useSelector(selectReplacementModalSku);
  const isLoading = useSelector(selectReplacementModalLoading);
  const products = useSelector(selectParsedReplacementProducts());
  const town = useSelector(selectPostalCode);
  const tcTags = useSelector(selectTcTags);
  const displayAllOffers = useSelector(selectReplacementModalDisplayAllOffers);
  const customerTags = useAppSelector(selectTags);
  const restoredTcTags = useSelector(selectRestoredUserTags);
  const isSavedCart = useSelector(selectIsSavedCart);
  const cartId = useSelector(selectCartId);

  const tags = useMemo(() => {
    let bestOfferTcTags = tcTags;
    if (isSavedCart && restoredTcTags && cartId) {
      bestOfferTcTags = [...bestOfferTcTags, ...restoredTcTags];
    }
    return displayAllOffers ? [...(customerTags ?? []), ...bestOfferTcTags] : customerTags;
  }, [tcTags, isSavedCart, restoredTcTags, cartId, displayAllOffers, customerTags]);

  useEffect(() => {
    if (sku) {
      dispatch(
        fetchReplacements({
          sku,
          postcode: town?.postcode,
          tags,
          displayAllOffers,
          excludeNoStockOffers,
        }),
      );
    }
  }, [dispatch, excludeNoStockOffers, displayAllOffers, sku, tags, town?.postcode]);

  const showStockModal = (productSku: string) => {
    dispatch(setWarehouseStockModalSku(productSku));
    dispatch(setWarehouseStockModalVisibility(true));
    dispatch(setReplacementModalDisplayAllOffers(true));
  };

  const tableColumnsOffers = filterColumns<TableColumnDefinition<IParsedProductItem>>([
    ProductInfoColumn({ width: percentage(7.5, 20), title: t('common.productInfo') }),
    ProductReferenceColumn({ width: percentage(1, 20), title: t('common.code') }),
    ProductStockColumn({ width: percentage(1, 20), title: t('common.stock') }),
    ProductWarehouseStockColumn(showStockModal, { width: percentage(1, 20), title: t('common.stock') }),
    ProductDeliveryColumn({ width: percentage(2, 20), title: t('common.delivery') }),
    ProductPriceColumn({ width: percentage(1.5, 20), title: t('common.netPrice'), align: 'center' }),
    ReplacementProductAmountColumn({ width: percentage(2, 20), align: 'center' }),
  ]);

  return (
    <>
      <Table
        columns={tableColumnsOffers as Array<TableColumnDefinition<IParsedProductItemRowKey>>}
        size="small"
        className={styles.container__table}
        rowClassName={styles.row}
        rowKey={(product) => product.rowKey}
        loading={isLoading}
        pagination={false}
        dataSource={sku ? products : []}
      />
    </>
  );
};

export default ReplacementTable;
