import { RestServiceApi } from 'services';
import { getToken } from 'cookies';
import { config } from 'config';
import { ApiResponse } from 'apisauce';

const msAtcAuthApi = new RestServiceApi(config.msAuthBaseUrl || '');
export interface GetUsersParams {
  filter?: Record<string, string>;
  limit?: number;
  paginationToken?: string;
}
export interface GetUsersInGroupParams {
  group?: string;
  limit?: number;
  paginationToken?: string;
}
export interface UserResponse {
  Users: User[];
  PaginationToken?: string;
  $metadata: Record<string, unknown>;
}
export type GroupsResponse = UserGroups[];

export interface UserGroups {
  username: string;
  Groups: string[];
}
export interface UserDeleteResponse {
  username: string;
}
export interface User {
  firstname: string;
  lastname: string;
  username: string;
  Groups: string[];
  phoneNumber: string;
  Username: string;
}

export class UserServiceApi {
  public async getUsers(args: GetUsersParams): Promise<ApiResponse<UserResponse>> {
    return msAtcAuthApi.get(`/users`, args, {
      ...msAtcAuthApi.getAuthenticateHeader(getToken()),
    });
  }
  public async getUsersInGroup(args: GetUsersInGroupParams): Promise<ApiResponse<UserResponse>> {
    const { group: groupName, paginationToken, limit } = args;
    return msAtcAuthApi.get(
      `group-users/${groupName}`,
      { paginationToken, limit },
      {
        ...msAtcAuthApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async deleteUser(username: string): Promise<ApiResponse<UserResponse>> {
    return msAtcAuthApi.delete(
      `/users/${username}`,
      {},
      {
        ...msAtcAuthApi.getAuthenticateHeader(getToken()),
      },
    );
  }
  public async editUser(user: User): Promise<ApiResponse<UserResponse>> {
    return msAtcAuthApi.put(`/users/${user.username}`, user, {
      ...msAtcAuthApi.getAuthenticateHeader(getToken()),
    });
  }
  public async getGroups(userIds: string[]): Promise<ApiResponse<GroupsResponse>> {
    return msAtcAuthApi.post(
      `/user-groups`,
      { usernames: userIds },
      {
        ...msAtcAuthApi.getAuthenticateHeader(getToken()),
      },
    );
  }
}

export const UserService = new UserServiceApi();
