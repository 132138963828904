import { useIsTabletSizes, useIsMobile } from 'utils/styles.utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import { SavedCartStatusEnum } from 'store/savedCart/types';
import { saveSavedCart, selectRestoreErrors, selectShowModal, showRestoreModal } from 'store/savedCart';
import {
  selectProductsError,
  selectWiuzProductsByProductType,
  selectTableMinimizeState,
  fetchWiuzUploadedFiles,
  selectPostalCode,
} from 'store/products';
import { sendMessage } from 'store/messages';
import {
  selectIsAnyProductSearched,
  checkAndUpdateProductsType,
  setCartRequestId,
  selectAtcCartNumber,
  selectIsSavedCart,
  setCartAtcCartNumber,
} from 'store/cart';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, ReactNode, useEffect } from 'react';
import { useGetSettings } from 'hooks/useSettings';
import { useFetchSuppliers, useFetchDepartures } from 'hooks';
import env from 'config';
import CartLogs from 'components/shared/CartLogs';
import { Modal, Text, Button, CartComments } from 'components/shared';
import { CartHeader, CartTable, ProductHeader, ProductSummary, ProductTable, WiuzContainer } from 'components/product';
import Stepper from 'components/layout/Stepper';
import { Layout, Space } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { createAtcCartNumber } from '../../utils';
import { CreateProductItemMessage } from '../../types/savedCart';
import { selectUserTcCode } from '../../store/user';
import { patchCartRequests, selectCartRequestDetail } from '../../store/cartRequests';
import { usePageBlocker } from '../../hooks/usePageBlocker';
import { useFetchDeliveryShippingAddresses } from '../../hooks/useFetchDeliveryShippingAddresses';
import ReplacementModal from '../../components/product/ReplacementModal';
import WarehouseStockModal from '../../components/product/ProductTable/components/WarehouseStockModal';
import ProductTableMulti from '../../components/product/ProductTable/ProductTableMulti';
import ProductTableCustomerQuotation from '../../components/product/ProductTable/ProductTableCustomerQuotation';
import AddProductModal from '../../components/product/AddProductModal';
import styles from './Products.module.scss';

const Products: FC = () => {
  const { cartId } = useParams();
  const atcCartNumber = useAppSelector(selectAtcCartNumber);
  const tcCode = useAppSelector(selectUserTcCode);
  const cartRequestDetail = useSelector(selectCartRequestDetail);
  const postalCode = useSelector(selectPostalCode);
  const isSavedCart = useSelector(selectIsSavedCart);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isAnyProductSearched = useSelector(selectIsAnyProductSearched);
  const productsError = useSelector(selectProductsError);
  const currentView = useSelector(selectTableMinimizeState);
  const isTablet = useIsTabletSizes();
  const selectedShowModal = useAppSelector(selectShowModal);
  const { productErrors } = useAppSelector(selectRestoreErrors);
  const productPage = useGetSettings('PRODUCT_PAGE');
  const selectedWiuzProducts = useAppSelector(selectWiuzProductsByProductType(productPage));

  const { t } = useTranslation();
  const { blocker, confirmationModal } = usePageBlocker();

  useEffect(() => {
    if (!atcCartNumber) {
      const generatedAtcCartNumber = createAtcCartNumber(tcCode!);
      dispatch(setCartAtcCartNumber(generatedAtcCartNumber));
    }
  }, [atcCartNumber]);

  useFetchDeliveryShippingAddresses();
  useFetchSuppliers();
  useFetchDepartures();

  useEffect(() => {
    if (productPage === 'customer-quotation' && cartRequestDetail && atcCartNumber && cartId && postalCode) {
      dispatch(saveSavedCart({ stepNumber: 1, notifyOnSuccess: false }));
    }
  }, [postalCode, atcCartNumber, cartId]);

  useEffect(() => {
    if (
      productPage === 'customer-quotation' &&
      cartRequestDetail &&
      atcCartNumber &&
      cartId &&
      isSavedCart &&
      cartRequestDetail.status === SavedCartStatusEnum.PENDING
    ) {
      dispatch(
        patchCartRequests({
          id: cartRequestDetail.id,
          cartRequest: {
            assignedTcCode: tcCode,
            atcCheckoutCartId: cartId,
            atcCartNumber: atcCartNumber,
          },
        }),
      );
    }
  }, [isSavedCart, atcCartNumber, cartId]);

  useEffect(() => {
    // update the cart products with wiuzIds if it's the case
    dispatch(checkAndUpdateProductsType({ productPage, wiuzProducts: selectedWiuzProducts }));

    if (productPage !== 'customer-quotation') {
      dispatch(setCartRequestId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productPage]);

  useEffect(() => {
    if (productsError) {
      dispatch(sendMessage({ message: productsError.message, type: 'error' }));
    }
  }, [dispatch, productsError]);

  useEffect(() => {
    if (productPage === 'wiuz') {
      dispatch(fetchWiuzUploadedFiles());
    }
  }, [dispatch, productPage]);

  const container = () => {
    if (productPage === 'default') {
      return (currentView || isTablet) && isAnyProductSearched && <ProductTable />;
    }
    if (productPage === 'wiuz') {
      return <WiuzContainer />;
    }

    if (productPage === 'multi') {
      return <ProductTableMulti />;
    }

    if (productPage === 'customer-quotation') {
      return <ProductTableCustomerQuotation />;
    }
  };

  return (
    <>
      <ProductHeader />
      <Layout.Content className={styles.container}>
        <div className={`${styles.container__table}`}>{container()}</div>
        <div className={styles.container__cart}>
          <CartHeader />
          <div className={styles.content}>
            <div className={styles.content__table}>
              <CartTable />
            </div>
            <div className={styles.content__sum}>
              <div className={styles.comment}>
                {!!env('enableCartComments') && <CartComments />}
                <div className={styles.content__timeline}>
                  <CartLogs />
                </div>
              </div>
              <div>
                <ProductSummary />
              </div>
            </div>
          </div>
        </div>
      </Layout.Content>
      {!isMobile && <Stepper footerClassName={styles.footer} className={styles.footer__stepper} />}
      <Modal
        width={isMobile ? 600 : 800}
        className={styles.modal}
        open={selectedShowModal}
        onCancel={() => dispatch(showRestoreModal(false))}
      >
        <div className={styles.modal_container}>
          {productErrors.priceChanged.length > 0 && (
            <ProductErrorSection
              heading={
                productErrors.priceChanged.length > 1
                  ? t('saved_cart.restoreModalPriceChangePlural')
                  : t('saved_cart.restoreModalPriceChangeSingular')
              }
              items={productErrors.priceChanged.map((p) => ({
                ...p,
                product: {
                  ...p.product,
                  name: p.message,
                },
              }))}
            />
          )}
          {productErrors.partialStock.length > 0 && (
            <ProductErrorSection
              heading={
                productErrors.partialStock.length > 1
                  ? t('saved_cart.restoreModalPartialStockPlural')
                  : t('saved_cart.restoreModalPartialStockSingular')
              }
              items={productErrors.partialStock}
            />
          )}
          {productErrors.outOfStock.length > 0 && (
            <ProductErrorSection
              heading={
                productErrors.outOfStock.length > 1
                  ? t('saved_cart.restoreModalOutOfStockPlural')
                  : t('saved_cart.restoreModalOutOfStockSingular')
              }
              items={productErrors.outOfStock}
            />
          )}
          {productErrors.noProductFound.length > 0 && (
            <ProductErrorSection
              heading={
                productErrors.noProductFound.length > 1
                  ? t('saved_cart.restoreModalNoProductFoundPlural')
                  : t('saved_cart.restoreModalNoProductFoundSingular')
              }
              items={productErrors.noProductFound}
            />
          )}
          {productErrors.noOfferFound.length > 0 && (
            <ProductErrorSection
              heading={
                <div>
                  {productErrors.noOfferFound.length > 1
                    ? t('saved_cart.restoreModalNoOfferFoundPlural')
                    : t('saved_cart.restoreModalNoOfferFoundSingular')}{' '}
                  <SwapOutlined /> :
                </div>
              }
              items={productErrors.noOfferFound}
            />
          )}
          <Button type="primary" className={styles.btn} onClick={() => dispatch(showRestoreModal(false))}>
            {t('common.next')}
          </Button>
        </div>
      </Modal>
      <ReplacementModal />
      <AddProductModal />
      <WarehouseStockModal />
      {blocker ? confirmationModal : null}
    </>
  );
};

type ProductErrorSectionProps = {
  heading: ReactNode;
  items: CreateProductItemMessage[];
};
const ProductErrorSection = ({ heading, items }: ProductErrorSectionProps) => (
  <Space direction="vertical">
    <Text type="title-gray3" className={styles.text__title_gray3}>
      {heading}
    </Text>
    {items.map((error, key) => (
      <Text key={key} type="subtitle">
        {error.product.name}
      </Text>
    ))}
  </Space>
);

export default Products;
