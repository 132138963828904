import { useIsSmallScreen } from 'utils/styles.utils';
import { selectFirstName, selectLastName } from 'store/user';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { useMenu } from 'hooks';
import { Button } from 'components/shared';
import classNames from 'classnames';
import { ItemType } from 'antd/lib/menu/interface';
import Tooltip from 'antd/es/tooltip';
import { Menu, Spin } from 'antd';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import type { IMenuItem, INavBarItemsProps } from './types';
import LanguageSelector from '../../../../shared/LanguageSelector';
import env from '../../../../../config';
import { getUnderConstructionPath } from 'constants/routes';
import styles from './styles.module.scss';

const NavBarItems: FC<INavBarItemsProps> = (props: INavBarItemsProps) => {
  const { onLogout } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const { t } = useTranslation();
  const { menuItems, isLoadingNavMenu } = useMenu();

  const isSmallScreen = useIsSmallScreen();
  const [collapsed, setCollapsed] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);

  const updateCollapsed = useCallback((value: boolean) => {
    const root = document.querySelector(':root') as HTMLElement;
    const rootStyle = getComputedStyle(root);

    const navbarWidth = rootStyle.getPropertyValue('--navbar-width');
    const navbarCollapsedWidth = rootStyle.getPropertyValue('--navbar-collapsed-width');
    root.style.setProperty('--navbar-width-dynamic-width', value ? navbarCollapsedWidth : navbarWidth);

    setCollapsed(() => value);
  }, []);

  useEffect(() => {
    updateCollapsed(isSmallScreen);
  }, [isSmallScreen, updateCollapsed]);

  const onMenuItemClick = (item: IMenuItem) => {
    setSelectedMenu(item.key);
    navigate(item.route);
  };

  const toggleCollapsed = useCallback(() => {
    updateCollapsed(!collapsed);
  }, [collapsed, updateCollapsed]);

  const isMenuSelected = (item: IMenuItem): boolean => {
    if (selectedMenu && pathname.includes(getUnderConstructionPath())) {
      return item.key === selectedMenu;
    } else if (!selectedMenu && pathname.includes(getUnderConstructionPath())) {
      return menuItems.find((menuItem) => menuItem.route === getUnderConstructionPath())?.key === item.key;
    }
    return pathname.includes(item.route);
  };

  const items = menuItems.map((item) => {
    return {
      icon: item.icon,
      key: item.key || item.route,
      label: item.text,
      onClick: () => onMenuItemClick(item),
      className: `${styles.menu__item} ${isMenuSelected(item) ? styles.menu__item_selected : ''}`,
    } as ItemType;
  });

  const selectedRoute = menuItems.find((item) => pathname.includes(item.route))?.route || '';

  const renderLogoutButton = useCallback(() => {
    return (
      <Button className={classNames(styles.logout_button)} onClick={onLogout} icon={<LogoutOutlined />} danger block>
        <span className={styles.logout_button__text}>{t('login.logout')}</span>
      </Button>
    );
  }, [onLogout, t]);

  return (
    <div className={classNames({ [styles.wrapperCollapsed]: collapsed })}>
      <div className={styles.wrapper}>
        <div className={styles.collapseButtonWrapper}>
          <Button className={styles.collapseButton} type="text" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
        <div className={styles.title}>
          <div className={styles.userIcon}>
            {collapsed ? (
              <Tooltip placement="right" title={`${firstName} ${lastName}`}>
                <UserOutlined />
              </Tooltip>
            ) : (
              <UserOutlined />
            )}
          </div>
          <div className={styles.account}>
            <p className={styles.account__firstName}>{firstName}</p>
            <p className={styles.account__lastName}>{lastName}</p>
          </div>
        </div>
        <div className={styles.menuContainer}>
          <div className={styles.menuWrapper}>
            {isLoadingNavMenu && <Spin className={styles.navMenuLoading} size="default" />}
            {!isLoadingNavMenu && !!items.length && (
              <Menu
                selectedKeys={[selectedRoute]}
                className={styles.menu}
                mode="inline"
                items={items}
                inlineCollapsed={collapsed}
              />
            )}
          </div>
          <div className={styles.logoutWrapper}>
            <div className={styles.logout}>
              <LanguageSelector className={styles.languageSelector} />
            </div>
            <div className={styles.logout}>
              {collapsed ? (
                <Tooltip placement="right" title={t('login.logout')}>
                  {renderLogoutButton()}
                </Tooltip>
              ) : (
                renderLogoutButton()
              )}
            </div>
          </div>
          {env('customerServicePhone') && env('theme') !== 'perret' && (
            <div className={styles.footer}>
              <span className={styles.info}>
                {t('common.customerServicePhone')}: {env('customerServicePhone')}
              </span>
            </div>
          )}
          {env('theme') === 'perret' && env('customerServicePhone') && (
            <div className={styles.footer}>
              <span className={styles.info}>
                {t('common.contact')}:{' '}
                <a className={styles.info} href={`mailto:${env('customerServicePhone')}`}>
                  {env('customerServicePhone')}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBarItems;
