import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button } from 'components/shared';
import mapImage from 'assets/images/map.svg';
import { Image, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { IHomeAddressModalProps } from './types';
import styles from './styles.module.scss';

const HomeAddressModal: FC<IHomeAddressModalProps> = (props: IHomeAddressModalProps) => {
  const { isFormVisible, isModalVisible, closeModal, onOpenForm, formComponent, contentComponent, isGroup } = props;
  const { t } = useTranslation();

  return (
    <Modal
      bodyStyle={{
        borderRadius: '10px',
        background: '#F5F7FA',
        padding: 0,
      }}
      width={470}
      footer={null}
      open={isModalVisible}
      onCancel={closeModal}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content__logo}>
            <Image src={mapImage} preview={false} className={styles.img} />
          </div>
          <p className={styles.content__title}>{t('modals.shippingAddress')}</p>
          <div className={styles.content__picker}>{contentComponent}</div>
        </div>
        <div className={styles.validate}>
          {isFormVisible ? (
            formComponent
          ) : (
            <div className={styles.buttons}>
              {!isGroup && (
                <Button
                  className={styles.buttons__add}
                  type="primary"
                  onClick={onOpenForm}
                  icon={<PlusCircleOutlined />}
                >
                  {t('modals.addShippingAddress')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HomeAddressModal;
