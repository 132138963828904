export const logs_fr = {
  '{FROM_TC_NAME} assigns this cart to {TO_TC_NAME} ({TO_TC_CODE})':
    '{{FROM_TC_NAME}} assigne ce panier à {{TO_TC_NAME}} ({{TO_TC_CODE}})',
  'Status change from {FROM_STATUS} to {TO_STATUS}': 'Changement de statut de {{FROM_STATUS}} à {{TO_STATUS}}',
  '{FROM_TC_NAME} add item to cart : {PRODUCT_LABEL} (SKU :{SKU}, QTY : {QUANTITY})':
    '{{FROM_TC_NAME}} ajoute un nouvel article au panier : {{PRODUCT_LABEL}} (SKU : {{SKU}}, QTY : {{QUANTITY}})',
  '{FROM_TC_NAME} remove item to cart : {PRODUCT_LABEL} (SKU :{SKU})':
    '{{FROM_TC_NAME}} supprime un article du panier : {{PRODUCT_LABEL}} (SKU : {{SKU}})',
  '{FROM_TC_NAME} change quantity from {FROM_QTY} to {TO_QTY} for item {SKU}':
    '{{FROM_TC_NAME}} a changé la quantité de {{FROM_QTY}} à {{TO_QTY}} de l’article {{SKU}}',
  '{FROM_TC_NAME} change forced price from {FROM_PRICE} to {TO_PRICE} for item {SKU}':
    '{{FROM_TC_NAME}} a changé le prix forcé de {{FROM_PRICE}} à {{TO_PRICE}} de l’article {{SKU}}',
};
