import { selectIsGroup } from 'store/customers';
import { ICartProductItem } from 'store/cart/types';
import {
  changeTruckNumberValues,
  recalculateProductPrices,
  removeGroupTruck,
  removeProduct,
  selectAreCartItemsExpanded,
} from 'store/cart';
import { SortableElement } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { BILLING_TYPE } from 'components/product/CartTable/components/CartRowInfo/types';
import {
  CartGroupRowContent,
  CartRowContent,
  CartRowContentNoStock,
  CartRowHeader,
  CartRowHeaderNoStock,
  CartRowInfo,
  CartSelectedQuantity,
} from 'components/product/CartTable/components';
import { Button, Collapse } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import styles from './SortableProductItem.module.scss';
import SortableCartProductItemHandle from './SortableCartProductItemHandle';

type SortableCartProductItemProps = {
  index: number;
  product: ICartProductItem;
  productIndex: number;
  handleHistoricalPriceModal: (sku: string) => void;
};

const SortableCartProductItem = SortableElement((props: SortableCartProductItemProps) => {
  const { productIndex, product, handleHistoricalPriceModal } = props;
  const dispatch = useDispatch();
  const areCartItemsExpanded = useSelector(selectAreCartItemsExpanded);
  const isGroup = useSelector(selectIsGroup);

  const activeKey = product?.noStock ? product.variantId : product.offerId;
  const getActiveKeys = useCallback(() => (areCartItemsExpanded ? [activeKey] : []), [activeKey, areCartItemsExpanded]);

  const [activeKeys, setActiveKeys] = useState<Array<number | string>>([product.offerId]);

  useEffect(() => {
    setActiveKeys(getActiveKeys);
  }, [areCartItemsExpanded, getActiveKeys]);

  const handleCollapseChange = useCallback((incomingKey: string | Array<string>) => {
    setActiveKeys(Array.isArray(incomingKey) ? incomingKey : [incomingKey]);
  }, []);

  const removeTruckFromProduct = useCallback(
    (truckKey: string) => {
      if (product.quantity === 1) {
        dispatch(removeProduct(product.offerId));
      } else {
        if (product.conditioningQuantity) {
          dispatch(
            removeGroupTruck({
              productId: product.offerId,
              quantity: product.quantity - 1,
              truckId: Number(truckKey),
              availableSelectedQt:
                product.availableSelectedQuantity - product.quantityIncrement / product.conditioningQuantity,
            }),
          );
          dispatch(recalculateProductPrices(product.offerId));
          if (product.truckSplitInfo) {
            let truckNumber = 1;
            Object.keys(product.truckSplitInfo).forEach((key) => {
              if (key === truckKey) return;
              dispatch(
                changeTruckNumberValues({
                  productId: product.offerId,
                  truckId: Number(key),
                  truckNumber: truckNumber++,
                }),
              );
            });
          }
        }
      }
    },
    [
      dispatch,
      product.availableSelectedQuantity,
      product.conditioningQuantity,
      product.offerId,
      product.quantity,
      product.quantityIncrement,
      product.truckSplitInfo,
    ],
  );

  const displayGroupTruckProduct = useCallback(() => {
    return (
      <div>
        {!!product.truckSplitInfo &&
          Object.entries(product.truckSplitInfo).map(([truckKey, truckValue]) => {
            return (
              <div key={truckKey} className={styles.groupTruck}>
                <div className={styles.trucHeader}>
                  <div className={styles.truckNumber}>Camion : {truckValue.truckNumber}</div>
                  <Button
                    className={
                      truckValue.totalTruckAvailable !== product.quantityIncrement / product.conditioningQuantity!
                        ? styles.truckRemoveDisable
                        : styles.truckRemoveEnable
                    }
                    disabled={
                      truckValue.totalTruckAvailable !== product.quantityIncrement / product.conditioningQuantity!
                    }
                    onClick={() => removeTruckFromProduct(truckKey)}
                  >
                    Supprimer le camion
                  </Button>
                </div>

                {Object.entries(truckValue.customerProductInfo).map(([key, value]) => (
                  <CartGroupRowContent
                    key={key}
                    product={product}
                    customerIdentifier={value.customerIdentifier}
                    customerInfo={value}
                    truckNumber={truckKey}
                    truckAvailableQt={truckValue.totalTruckAvailable}
                  />
                ))}
                <div className={styles.quantityInfo}>
                  {!!product.conditioningQuantity && (
                    <>
                      Quantité :
                      <span className={styles.quantityValue}>
                        <CartSelectedQuantity
                          totalQuantity={product.quantityIncrement}
                          availableQuantity={truckValue.totalTruckAvailable * product.conditioningQuantity}
                        />
                      </span>
                    </>
                  )}
                  {truckValue.totalTruckAvailable < 2 ? product.unit.singular : product.unit.plural}
                </div>
              </div>
            );
          })}
        <CartRowInfo
          product={product}
          productIndex={productIndex}
          billingType={BILLING_TYPE.MULTIPLE}
          displayHistoricalPriceModal={handleHistoricalPriceModal}
        />
      </div>
    );
  }, [handleHistoricalPriceModal, product, productIndex, removeTruckFromProduct]);

  const displayGroupProduct = useCallback(() => {
    if (!product.isTruck && !product.truckSplitInfo && product.customerProductInfo)
      return (
        <div>
          {Object.entries(product.customerProductInfo).map(([key, value]) => (
            <CartGroupRowContent
              key={key}
              product={product}
              customerIdentifier={value.customerIdentifier}
              customerInfo={value}
            />
          ))}
          <div className={styles.quantityInfo}>
            Quantité :{' '}
            <CartSelectedQuantity
              availableQuantity={product.availableSelectedQuantity}
              totalQuantity={product.quantity * product.quantityIncrement}
            />{' '}
            {product.availableSelectedQuantity < 2 ? product.unit.singular : product.unit.plural}
          </div>

          <CartRowInfo
            product={product}
            productIndex={productIndex}
            billingType={BILLING_TYPE.MULTIPLE}
            displayHistoricalPriceModal={handleHistoricalPriceModal}
          />
        </div>
      );
    return displayGroupTruckProduct();
  }, [displayGroupTruckProduct, handleHistoricalPriceModal, product, productIndex]);

  const displayProduct = useCallback(() => {
    let content;
    if (!isGroup) {
      content = (
        <CartRowContent
          product={product}
          productIndex={productIndex}
          displayHistoricalPriceModal={handleHistoricalPriceModal}
        />
      );
    } else {
      content = displayGroupProduct();
    }

    return (
      <Collapse.Panel
        className={styles.panel}
        header={<CartRowHeader product={product} productIndex={productIndex} />}
        key={product.offerId}
      >
        {content}
      </Collapse.Panel>
    );
  }, [displayGroupProduct, handleHistoricalPriceModal, isGroup, product, productIndex]);

  const displayNoStockProduct = useCallback(() => {
    return (
      <Collapse.Panel
        className={styles.panel}
        header={<CartRowHeaderNoStock product={product} productIndex={productIndex} />}
        key={product.variantId}
      >
        <CartRowContentNoStock product={product} productIndex={productIndex} />
      </Collapse.Panel>
    );
  }, [product, productIndex]);

  const renderProduct = useCallback(
    () => (product.noStock ? displayNoStockProduct() : displayProduct()),
    [displayNoStockProduct, displayProduct, product],
  );

  return (
    <div>
      <Collapse
        activeKey={activeKeys}
        expandIcon={({ isActive }) => {
          return (
            <div className={styles.dragHandlerWrapper}>
              <CaretUpOutlined className={styles.icon} rotate={isActive ? 0 : 180} />
              <SortableCartProductItemHandle />
            </div>
          );
        }}
        onChange={handleCollapseChange}
        bordered={false}
      >
        {renderProduct()}
      </Collapse>
    </div>
  );
});

export default SortableCartProductItem;
