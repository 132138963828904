import { useNavigate } from 'react-router';
import { FC } from 'react';
import { Button, Result } from 'antd';
import { getOrderPath, OrderRoute } from '../../../constants/routes';
import { INotFoundProps } from './NotFound.types';

const NotFound: FC<INotFoundProps> = (props: INotFoundProps) => {
  const { children, showContent, showOnRule, redirectRoute } = props;
  const history = useNavigate();

  const onClickBackToHome = () => history(redirectRoute || getOrderPath(OrderRoute.CUSTOMER));

  if (showOnRule && showContent && children) {
    return <>{children}</>;
  }

  return (
    <>
      <Result
        className="result"
        status="404"
        title="404"
        subTitle="Désolé, la page que vous avez visitée n'existe pas."
        extra={
          <Button onClick={onClickBackToHome} type="primary">
            Retour à la maison
          </Button>
        }
      />
    </>
  );
};

export default NotFound;
