import { useAppDispatch, useAppSelector } from 'store/store';
import {
  clearWhitelist,
  fetchCustomersWhitelist,
  selectWhitelist,
  selectWhitelistCustomers,
  setWhitelistPage,
} from 'store/customers';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { useToken } from 'cookies';
import env from 'config';
import { Text } from 'components/shared';
import classNames from 'classnames';
import { Spin, Pagination } from 'antd';
import { CustomerRow } from './components';
import { CustomersListProps } from './CustomersList.types';
import styles from './ClientsList.module.scss';

const CustomersList: React.FC<CustomersListProps> = (props: CustomersListProps) => {
  const { pageSize: limit = 50 } = props;
  const dispatch = useAppDispatch();
  const { token } = useToken();
  const { t } = useTranslation();
  const paginationRef = useRef<HTMLDivElement>(null);

  const customers = useAppSelector(selectWhitelistCustomers);
  const { isLoading, filter, page, total } = useAppSelector(selectWhitelist);
  const pageChange = useRef<boolean>(false);

  useEffect(() => {
    if (filter && filter.length > 2) {
      const skip = (page - 1) * limit;
      dispatch(fetchCustomersWhitelist({ token, skip, limit, filter })).then(() => {
        if (pageChange.current === true) {
          pageChange.current = false;
          paginationRef.current?.scrollIntoView(true);
        }
      });
    } else if (!filter) {
      dispatch(clearWhitelist());
    }
  }, [dispatch, page, limit, token, filter]);

  const renderEmptyListMessage = (): string =>
    filter && filter.length > 2
      ? t('common.emptyCustomerListNoSearchResults', { context: env('theme') })
      : t('common.emptyCustomerList', { context: env('theme') });

  const handlePageChange = useCallback(
    (nextPage) => {
      dispatch(setWhitelistPage(nextPage));
      pageChange.current = true;
    },
    [dispatch],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        {isLoading && (
          <div className={styles.loading}>
            <Spin />
          </div>
        )}
        <div>
          {isLoading && customers.length === 0 && (
            <div className={classNames(styles.emptyList, styles.emptyListMinHeight)} />
          )}
          {!isLoading && customers.length === 0 && (
            <div className={styles.emptyList}>
              <Text type="subtitle">{renderEmptyListMessage()}</Text>
            </div>
          )}
          {customers.length > 0 && (
            <div>
              {customers.map((customer) => (
                <CustomerRow key={customer.id} customer={customer} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div ref={paginationRef} className={styles.pagination}>
        <Pagination
          defaultCurrent={page}
          current={page}
          total={total}
          pageSize={limit}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default CustomersList;
