import { useIsMobile } from 'utils/styles.utils';
import { SavedCartTable } from 'types/savedCart';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  selectTotalSavedCart,
  selectIsLoadingSavedCart,
  selectLimitSavedCart,
  setPagination,
  getSavedCarts,
  getSavedCartStatusCount,
  selectSavedCarts,
  selectCurrentPage,
  selectPageSavedCart,
  setPage,
  selectFilters,
  clearSavedCartState,
} from 'store/savedCart';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import SaveCartDetails from 'components/savedCart/SavedCartTable/components/SaveCartDetails';
import { ColumnsType } from 'antd/lib/table';
import { Pagination, Table, Space } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { CartRequestActionMenu, Text, Dropdown } from '../../shared';
import { nullStringToMinus } from '../../../utils';
import { ICartRequest } from '../../../types/cartRequest';
import { OrderDate, TotalPrice, Client, ActionsColumn, CartStatus } from './components/TableColumns';
import SavedCartFilters from './components/SavedCartFilters';
import styles from './SavedCartTable.module.scss';

type SavedCartAndRequestTableProps = SavedCartTable & ICartRequest;

const SavedCartListTable: FC = () => {
  const savedCarts = useAppSelector(selectSavedCarts);

  const totalCart = useSelector(selectTotalSavedCart);
  const limitCart = useSelector(selectLimitSavedCart);
  const pageCart = useSelector(selectPageSavedCart);
  const currentPage = useAppSelector(selectCurrentPage);
  const isLoadingSavedCart = useSelector(selectIsLoadingSavedCart);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const [cartDetailsId, setCartDetailsId] = useState<string | null>(null);
  const selectedFilters = useAppSelector(selectFilters);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setPage(1));
    return () => {
      dispatch(setPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSavedCarts({
        skip: currentPage,
        limit: limitCart,
        filters: selectedFilters,
      }),
    );
    dispatch(
      getSavedCartStatusCount({
        skip: currentPage,
        limit: limitCart,
        filters: selectedFilters,
      }),
    );
  }, [dispatch, currentPage, selectedFilters, limitCart]);

  useEffect(() => {
    return () => {
      dispatch(clearSavedCartState());
    };
  }, [dispatch]);

  const isRowExpanded = (cartId: string) => {
    return Boolean(cartId && cartId === cartDetailsId);
  };

  const handleClickDetails = (cartId: string) => {
    if (cartId && cartDetailsId === cartId) {
      setCartDetailsId(null);
    } else {
      setCartDetailsId(cartId);
    }
  };

  const TABLE_COLUMNS: Array<TableColumnDefinition<SavedCartAndRequestTableProps>> = [
    {
      title: <span className={styles.col}>{t('cartRequests.quoteNumber')}</span>,
      dataIndex: undefined,
      align: 'left',
      render: ({
        atcCartNumber,
        assignedTcCode,
        assignedTcName,
        isCartRequest,
        id,
        cartRequestId,
      }: SavedCartAndRequestTableProps) => (
        <Space direction="vertical" align="start">
          {!isCartRequest && <span className={styles.col}>{nullStringToMinus(atcCartNumber)}</span>}
          {assignedTcName && assignedTcCode && (
            <Text className={styles.assignedTo} type="subtitle-thin">
              {t('saved_cart.assignedTo', {
                assignedTcName,
                assignedTcCode,
              })}
            </Text>
          )}
          {cartRequestId && (
            <Text type="subtitle-thin" className={styles.alignLeft}>
              {t('cartRequests.cartRequestId')}: <b>{cartRequestId}</b>
            </Text>
          )}
          {isCartRequest && (
            <Text type="subtitle-thin" className={styles.alignLeft}>
              {t('cartRequests.cartRequestId')}: <b>{id}</b>
            </Text>
          )}
        </Space>
      ),
    },
    {
      title: <span className={styles.col}>{t('saved_cart.client')}</span>,
      dataIndex: undefined,
      align: 'left',
      render: (row) => (
        <Client
          customerName={row.customerName}
          customerIdentifier={row.customerIdentifier}
          company={row.company}
          email={row.email}
        />
      ),
    },
    {
      title: <span className={styles.col}>{t('saved_cart.status')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: (record) => {
        const { convertedAt, refusedAt, expiredAt, expired, sentToCustomerAt, status } = record;
        return (
          <CartStatus
            expired={expired}
            convertedAt={convertedAt}
            refusedAt={refusedAt}
            sentToCustomerAt={sentToCustomerAt}
            expiredAt={expiredAt}
            status={status}
          />
        );
      },
    },
    {
      title: <span className={styles.col}>{t('saved_cart.creationDate')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: (record) => {
        const { createdAt, updatedAt } = record;

        return (
          <div>
            <div>
              {t('saved_cart.tableColumnCreationDate')}: <OrderDate date={createdAt} />
            </div>
            <div>
              <Text type="subtitle-thin">
                {t('common.updatedAt')}: <OrderDate date={updatedAt} />
              </Text>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className={styles.col}>{t('saved_cart.netTotal')}</span>,
      dataIndex: 'totalAmount',
      align: 'center',
      render: (totalAmount) => <TotalPrice price={totalAmount} />,
    },
    {
      title: t('common.actions'),
      align: 'center',
      dataIndex: undefined,
      render: ({
        id: cartId,
        status,
        saveCartPdfId,
        archivedAt,
        customerName,
        createdAt,
        convertedAt,
        assignedTcCode,
        atcCheckoutCartId,
        isCartRequest,
        userId,
        addressId,
      }: SavedCartAndRequestTableProps) => {
        if (isCartRequest) {
          return (
            <Dropdown
              className={styles.dropdown}
              overlay={() => (
                <CartRequestActionMenu
                  id={Number(cartId)}
                  assignedTcCode={assignedTcCode}
                  status={status}
                  atcCheckoutCartId={atcCheckoutCartId}
                  userId={userId}
                  addressId={addressId}
                />
              )}
              onClick={() => handleClickDetails(cartId)}
              lableIcon={<UnorderedListOutlined />}
            />
          );
        }
        return (
          <ActionsColumn
            cartId={cartId}
            isArchived={archivedAt !== null}
            status={status}
            saveCartPdfId={saveCartPdfId}
            onClickDetails={handleClickDetails}
            customer={customerName}
            createdAt={createdAt}
            convertedAt={convertedAt}
            assignedTcCode={assignedTcCode}
          />
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('saved_cart.savedCarts')}</h3>
      <SavedCartFilters filters={selectedFilters} />
      <Table
        columns={TABLE_COLUMNS as ColumnsType<SavedCartTable>}
        size={isMobile ? 'small' : 'large'}
        className={styles.container__table}
        rowClassName={({ id: cartId, archivedAt }) => {
          const expandedClassName = isRowExpanded(cartId) ? styles.row__expaned : '';
          const archivedClassName = archivedAt ? styles.row__deleted : '';
          return `${styles.row} ${expandedClassName} ${archivedClassName}`;
        }}
        rowKey={(record) => record.id}
        loading={isLoadingSavedCart}
        dataSource={savedCarts}
        locale={{ emptyText: 'Aucun paniers sauvegardés trouvé' }}
        pagination={{ style: { display: 'none' }, pageSize: savedCarts.length }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 0,
          expandedRowClassName: () => styles.expandableRow,
          expandRowByClick: false,
          expandedRowRender: ({ id: cartId }: SavedCartTable) => <SaveCartDetails cartId={cartId} />,
          rowExpandable: ({ id: cartId }) => isRowExpanded(cartId),
          expandIcon: () => null,
          expandIconColumnIndex: -1,
          // this will solve the problem with expanding the first item after a new cart is saved
          expandedRowKeys: savedCarts.map((item) => item.id),
        }}
      />
      {savedCarts.length > 0 && (
        <Pagination
          className={styles.container__pagination}
          current={pageCart}
          defaultCurrent={pageCart}
          total={totalCart}
          disabled={false}
          defaultPageSize={limitCart}
          pageSizeOptions={['20', '30', '50', '100']}
          onChange={(current, pageSize) => {
            dispatch(
              setPagination({
                page: current,
                size: pageSize,
              }),
            );
          }}
        />
      )}
    </div>
  );
};

export default SavedCartListTable;
