import { getCustomerAddress } from 'utils/customer.utils';
import { editFloatNumber } from 'utils';
import { selectGroupCustomerByIdentifier } from 'store/customers';
import {
  changeCustomerProductAvailableQuantity,
  changeCustomerProductSelectedQuantity,
  changeCustomerTruckProductAvailableQuantity,
  changeCustomerTruckProductSelectedQuantity,
  changeProductAvailableQuantity,
  correctTruckAvailableQuantity,
} from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';
import { Text } from 'components/shared';
import { Button, Slider } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ICustomerProductInfo } from '../../../../../store/cart/types';
import { ICartGroupRowContentProps } from './types';
import styles from './styles.module.scss';

const CartGroupRowContent: FC<ICartGroupRowContentProps> = (props: ICartGroupRowContentProps) => {
  const { product, customerIdentifier, customerInfo, truckNumber, truckAvailableQt } = props;
  const dispatch = useDispatch();
  const groupCustomer = useSelector(selectGroupCustomerByIdentifier(customerIdentifier));

  const getMaxQuantity = () => {
    if (truckNumber) {
      return product.quantityIncrement;
    }
    return product.quantityIncrement * product.quantity;
  };

  const showTruckQuantity = () => {
    if (truckNumber && product.conditioningQuantity) {
      return editFloatNumber(customerInfo.selectedQuantity * product.conditioningQuantity);
    }
    return customerInfo.selectedQuantity;
  };

  const showUnitQuantity = () => {
    if (customerInfo.selectedQuantity < 2) return product.unit.singular;
    return product.unit.plural;
  };

  const getAvailableCustomerQuantity = (value: ICustomerProductInfo, quantity: number) => {
    if (customerInfo.selectedQuantity < quantity) {
      return value.availableCustomerQuantity - (quantity - customerInfo.selectedQuantity);
    }
    return value.availableCustomerQuantity + (customerInfo.selectedQuantity - quantity);
  };

  const getProductQuantity = (quantity: number) => {
    if (customerInfo.selectedQuantity < quantity)
      return customerInfo.selectedQuantity + (quantity - customerInfo.selectedQuantity);
    return customerInfo.selectedQuantity - (customerInfo.selectedQuantity - quantity);
  };

  const getTotalTruckQuantity = (quantity: number) => {
    if (customerInfo.selectedQuantity < quantity) return truckAvailableQt! - (quantity - customerInfo.selectedQuantity);
    return truckAvailableQt! + (customerInfo.selectedQuantity - quantity);
  };

  const getProductAvailableSelectedQt = (quantity: number) => {
    if (customerInfo.selectedQuantity < quantity)
      return product.availableSelectedQuantity - (quantity - customerInfo.selectedQuantity);
    return product.availableSelectedQuantity + (customerInfo.selectedQuantity - quantity);
  };

  const onChangeProductQuantity = (quantity: number) => {
    if (!truckNumber) {
      if (product.customerProductInfo) {
        Object.entries(product.customerProductInfo).forEach(([_key, value]) => {
          if (value.customerIdentifier !== customerIdentifier) {
            dispatch(
              changeCustomerProductAvailableQuantity({
                productId: product.offerId,
                customerAvailableQuantity: getAvailableCustomerQuantity(value, quantity),
                customerIdentifier: value.customerIdentifier,
              }),
            );
          }
        });
      }
      dispatch(
        changeProductAvailableQuantity({
          offerId: product.offerId,
          quantity: getProductAvailableSelectedQt(quantity),
        }),
      );
      dispatch(
        changeCustomerProductSelectedQuantity({
          customerIdentifier: customerIdentifier,
          productId: product.offerId,
          productQuantity: quantity,
        }),
      );
    } else {
      dispatch(
        changeCustomerTruckProductSelectedQuantity({
          productId: product.offerId,
          customerIdentifier: customerIdentifier,
          truckId: Number(truckNumber),
          productQuantity: getProductQuantity(quantity),
          totalTruckQuantity: getTotalTruckQuantity(quantity),
          productAvailableSelectedQt: getProductAvailableSelectedQt(quantity),
        }),
      );

      if (product.truckSplitInfo && Number(truckNumber) in product.truckSplitInfo) {
        Object.entries(product.truckSplitInfo[Number(truckNumber)].customerProductInfo).forEach(([key, value]) => {
          if (value.customerIdentifier !== customerIdentifier) {
            dispatch(
              changeCustomerTruckProductAvailableQuantity({
                productId: product.offerId,
                customerIdentifier: key,
                truckId: Number(truckNumber),
                quantity: getAvailableCustomerQuantity(value, quantity),
              }),
            );
          }
        });
      }
    }
  };

  const showQtyIncrement = () => {
    if (truckNumber && product.conditioningQuantity) return 1;
    return product.quantityIncrement;
  };

  const increaseQuantity = () => {
    const quantityIncrement = truckNumber ? 1 : product.quantityIncrement;
    onChangeProductQuantity(customerInfo.selectedQuantity + quantityIncrement);
  };

  const decreaseQuantity = () => {
    const quantityIncrement = truckNumber ? 1 : product.quantityIncrement;
    onChangeProductQuantity(customerInfo.selectedQuantity - quantityIncrement);
  };

  const getTotalTruckAvailable = () => {
    if (truckNumber) {
      return product.truckSplitInfo?.[Number(truckNumber)].totalTruckAvailable || 0;
    }

    return product.availableSelectedQuantity;
  };

  const onSliderChange = (onchangeValue: number) => {
    const totalTruckQuantity = getTotalTruckQuantity(onchangeValue);
    const isAboveLimit = totalTruckQuantity <= 0 && onchangeValue >= customerInfo.selectedQuantity;
    const limitedValue = isAboveLimit ? onchangeValue + totalTruckQuantity : onchangeValue;

    onChangeProductQuantity(limitedValue);
  };

  const handleSliderAfterChange = () => {
    dispatch(
      correctTruckAvailableQuantity({
        offerId: product.offerId,
        truckNumber: Number(truckNumber),
        customerIdentifier: customerIdentifier,
      }),
    );
  };

  return groupCustomer ? (
    <div className={styles.main}>
      <div className={styles.customerBlock}>
        {groupCustomer.firstName} {groupCustomer.lastName} ({groupCustomer.customerIdentifier}){' '}
        <div>{getCustomerAddress(groupCustomer)}</div>
      </div>
      {!!product.taxes.rpd && (
        <div className={styles.taxesBlock}>
          <div className={styles.rpd}>
            <Text type="subtitle-gray5">Taxe RPD</Text>
            <Text type="subtitle-gray3">
              {((product.taxes.rpd / 100) * customerInfo.selectedQuantity).toFixed(2)} €
            </Text>
          </div>
        </div>
      )}

      <div className={styles.quantityBlock}>
        {product.conditioning}
        <Slider
          defaultValue={0}
          value={customerInfo.selectedQuantity}
          className={styles.slider}
          onChange={onSliderChange}
          onAfterChange={handleSliderAfterChange}
          step={showQtyIncrement()}
          max={getMaxQuantity()}
          trackStyle={{
            backgroundColor: '#fff0d3',
          }}
          handleStyle={{
            border: '3px solid #ffc653',
          }}
          tooltip={{
            open: false
          }}
        />
        <div className={styles.quantityInfo}>
          <Button
            onClick={decreaseQuantity}
            disabled={customerInfo.selectedQuantity <= 0}
            shape="circle"
            icon={<MinusOutlined />}
          />
          <Text className={styles.subtitle} type="title">
            {showTruckQuantity()} {showUnitQuantity()}
          </Text>
          <Button
            onClick={increaseQuantity}
            disabled={getTotalTruckAvailable() <= 0}
            shape="circle"
            icon={<PlusOutlined />}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default CartGroupRowContent;
