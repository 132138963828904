import { SelectOptionAttrs } from 'types';

export const ALGOLIA_SEARCH_FILTER: Array<SelectOptionAttrs> = [
  { value: '', label: 'Toutes categories' },
  { value: 'Engrais', label: 'Engrais' },
  { value: 'Semences', label: 'Semences' },
  { value: 'Pièces', label: 'Pièces' },
  { value: 'Atelier', label: 'Atelier' },
  { value: 'Elevage', label: 'Elevage' },
  { value: 'Nutrition', label: 'Nutrition' },
  { value: 'Phytos', label: 'Phytos' },
  { value: 'Services', label: 'Services' },
];

export const PRODUCT_OFFER_TYPE = {
  TYPE_12: 12,
  TYPE_13: 13,
};
