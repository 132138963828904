import { createTheme } from 'utils/theme.utils';
import { feature, config } from 'theme';
import { config as globalConfig } from 'config';

export const THEME_NAME = globalConfig.theme;

export const THEME_CLASS_NAME = `theme-${THEME_NAME}`;

// eslint-disable-next-line no-shadow
export enum Themes {
  AGRICONOMIE = 'agriconomie',
  IZI = 'izi',
  PERRET = 'perret',
}

export const themeConfig = createTheme(config);
export const themeFeature = createTheme(feature);
