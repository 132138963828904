export const EXTENSION_TO_MIMETYPE: Record<string, string> = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
};

export const MIMETYPE_TO_EXTENSION: Record<string, Array<string>> = ((list: Record<string, string>) => {
  return Object.entries(list).reduce<Record<string, Array<string>>>((acc, [value, key]) => {
    if (!Object.prototype.hasOwnProperty.call(acc, key)) {
      acc[key] = [];
    }

    if (!acc[key].includes(value)) {
      acc[key].push(value);
    }
    return acc;
  }, {});
})(EXTENSION_TO_MIMETYPE);
