import { convertCustomerToSearchedCustomer } from 'utils/customer.utils';
import { isSimpleCustomer } from 'utils';
import { clearFetchedTowns, clearPostalCodes, clearProductsState } from 'store/products';
import { clearShippingAddresses, setSelectedCustomer, setSelectedSearchedCustomer } from 'store/customers';
import { clearCartState, setUsers } from 'store/cart';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import moment from 'moment/moment';
import { useSetSettings } from 'hooks/useSettings';
import { useStepper } from 'hooks';
import { Button, OtherInfo, TagsList, Text } from 'components/shared';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import { IUser } from '../../../../../types';
import { clearSavedCartState } from '../../../../../store/savedCart';
import { clearDeliveryState } from '../../../../../store/delivery';
import { clearCartRequestsState } from '../../../../../store/cartRequests';
import {
  CustomerRowProps,
  CustomerColumnProps,
  AddressColumnProps,
  UserInfoColumnProps,
  CustomerSelectButtonProps,
} from './CustomerRow.types';
import styles from './CustomerRow.module.scss';

const CustomerColumn = ({ firstName, lastName, company, customerIdentifier }: CustomerColumnProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.column, styles.column__customer)}>
      <Text className={styles.name}>{t('cartRequests.client')}</Text>
      <Text>
        {firstName} {lastName}
      </Text>
      {!!company && <Text className={styles.text}>{company}</Text>}
      <Text className={styles.text}>{customerIdentifier}</Text>
    </div>
  );
};

const CustomerAddress = ({ postcode, town, street, phone }: AddressColumnProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.column, styles.column__address)}>
      <Text className={styles.name}>{t('common.billingAddress')}</Text>
      {street && <Text>{street}</Text>}
      {(postcode || town) && <Text>{[postcode, town].filter((s) => s).join(' ')}</Text>}
      {phone && <Text>{phone}</Text>}
    </div>
  );
};

const UserInfo = ({ email, enabled, lastLogin, tags }: UserInfoColumnProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.column, styles.column__userInfo)}>
      <Text className={styles.name}>{t('common.infoUser')}</Text>
      {email && <Text>{email}</Text>}
      {typeof enabled === 'number' && (
        <Text>
          {t('common.account')} : {enabled ? t('common.active') : t('common.inactive')}
        </Text>
      )}
      <Text>
        {t('common.lastConnection')} : {lastLogin ? moment(lastLogin).toDate().toLocaleString() : 'N/C'}
      </Text>
      <span>
        <Text>{t('common.availableTags')}</Text>{' '}
        <Tooltip overlayClassName={styles.tagContainer} overlay={<TagsList tags={tags} />}>
          <InfoCircleFilled />
        </Tooltip>
      </span>
    </div>
  );
};

const CustomerSelectButton = ({ simpleCustomer }: CustomerSelectButtonProps) => (
  <div className={classNames(styles.column, styles.column__checkmark)}>
    <Button
      type="default"
      className={classNames(styles.button)}
      style={{ pointerEvents: 'none' }}
      icon={simpleCustomer ? <CloseOutlined className={styles.icon} /> : <CheckOutlined className={styles.icon} />}
    />
  </div>
);

const CustomerRow: React.FC<CustomerRowProps> = (props: CustomerRowProps) => {
  const { customer } = props;
  const { firstName, lastName, customerIdentifier, company, enabled = false } = customer;
  const setProductPage = useSetSettings('PRODUCT_PAGE');
  const dispatch = useDispatch();
  const { goNextStep } = useStepper();

  const id = customer.id;

  const setSelectedCustomers = useCallback(() => {
    dispatch(clearDeliveryState());
    dispatch(clearCartState());
    dispatch(clearCartRequestsState());
    dispatch(clearSavedCartState());
    dispatch(clearProductsState());
    dispatch(setProductPage('default'));
    dispatch(setSelectedSearchedCustomer(convertCustomerToSearchedCustomer(customer)));
    dispatch(setSelectedCustomer(id));
    dispatch(setUsers(customer.user as unknown as IUser));
    dispatch(clearShippingAddresses());
    dispatch(clearFetchedTowns());
    dispatch(clearPostalCodes());
  }, [customer, dispatch, id, setProductPage]);

  const handleSelectCustomer = useCallback(() => {
    if (isSimpleCustomer(customer)) {
      return;
    }

    setSelectedCustomers();
    goNextStep(true);
  }, [customer, goNextStep, setSelectedCustomers]);

  return (
    <div
      className={classNames(styles.row, {
        [styles.row__simpleCustomer]: isSimpleCustomer(customer),
        [styles.row__detailedCustomer]: !isSimpleCustomer(customer),
        [styles.row__disabled]: !enabled,
      })}
      onClick={enabled ? handleSelectCustomer : undefined}
    >
      <CustomerColumn
        firstName={firstName}
        lastName={lastName}
        company={company}
        customerIdentifier={customerIdentifier}
      />
      {!isSimpleCustomer(customer) && (
        <>
          <CustomerAddress
            postcode={customer?.postcodeLabel}
            town={customer?.townLabel}
            street={customer?.street}
            phone={customer.phone1 || customer.phone2}
          />
          <UserInfo
            email={customer?.user?.emailCanonical}
            enabled={customer?.user?.enabled}
            lastLogin={customer?.user?.lastLogin}
            tags={customer?.tags}
          />
          <OtherInfo
            restrictedPrecursor={customer?.user?.restrictedPrecursor}
            regulatedPrecursor={customer?.user?.regulatedPrecursor}
            certExpiryDate={customer?.user?.certiphytoExpiredDate}
          />
        </>
      )}
      <CustomerSelectButton simpleCustomer={isSimpleCustomer(customer)} />
    </div>
  );
};

export default CustomerRow;
