import { SortableHandle } from 'react-sortable-hoc';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styles from './SortableCartProductItemHandle.module.scss';

const SortableCartProductItemHandle = SortableHandle(() => (
  <Button
    icon={<MenuOutlined />}
    size="small"
    className={styles.dragHandler}
    onClick={(e) => e.stopPropagation()}
    type="text"
    shape="circle"
  />
));

export default SortableCartProductItemHandle;
