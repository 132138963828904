import { useAppSelector } from 'store/store';
import { selectExpiration, setExpirationDays, setExpirationHours, setExpiredAt } from 'store/savedCart';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDateFormat } from 'hooks/useDateFormat';
import { Text } from 'components/shared';
import { InputNumber, Space } from 'antd';
import { DEFAULT_DAYS, DEFAULT_HOURS, MAX_DAYS } from '../../../constants/routes';
import { CartRequestExpirationProps } from './CartRequestExpiration.types';
import styles from './CartRequestExpiration.module.scss';

const CartRequestExpiration: React.FC<CartRequestExpirationProps> = (_props: CartRequestExpirationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dateFormat = useDateFormat();

  const { expirationDays, expirationHours } = useAppSelector(selectExpiration);

  const getExpirationDays = () => (typeof expirationDays === 'number' ? expirationDays : DEFAULT_DAYS);
  const getExpirationHours = () => (typeof expirationHours === 'number' ? expirationHours : DEFAULT_HOURS);

  const [days, setDays] = useState<number>(getExpirationDays());
  const [hours, setHours] = useState<number>(getExpirationHours());

  const updateExpiredAt = useCallback(
    ({ days: d, hours: h }: { days: number; hours: number }) => {
      const date = moment().utc(false).add({ days: d, hours: h }).toISOString();
      dispatch(setExpiredAt(date));
    },
    [dispatch],
  );

  const expiredAtDate = moment().utc(true).add({ days, hours });
  const expirationDate = expiredAtDate.format(dateFormat.long);

  const handleChangeDays = useCallback(
    (value: number | string | null) => {
      if (value !== null) {
        const currentDays = Number(value);
        if (currentDays + hours / 24 > MAX_DAYS) {
          return;
        }
        dispatch(setExpirationDays(currentDays));
        setDays(currentDays);
        updateExpiredAt({ days: currentDays, hours });
      }
    },
    [dispatch, hours, updateExpiredAt],
  );

  const handleChangeHours = useCallback(
    (value: number | string | null) => {
      if (value !== null) {
        const currentHours = Number(value);
        if (days + currentHours / 24 > MAX_DAYS) {
          return;
        }
        dispatch(setExpirationHours(currentHours));
        setHours(currentHours);
        updateExpiredAt({ days, hours: currentHours });
      }
    },
    [days, dispatch, updateExpiredAt],
  );

  useEffect(() => {
    return () => {
      dispatch(setExpirationDays(DEFAULT_DAYS));
      dispatch(setExpirationHours(DEFAULT_HOURS));
    };
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <Text className={styles.title} type="title-gray8">
        {t('common.cartRequestExpirationQuote')}
      </Text>
      <div className={styles.panel}>
        <div className={styles.contentSplit}>
          <Space direction="horizontal">
            <InputNumber
              min={0}
              max={31}
              defaultValue={DEFAULT_DAYS}
              value={days}
              precision={0}
              addonBefore={<div>{t('common.cartRequestExpirationDays')}</div>}
              onChange={handleChangeDays}
            />
            <InputNumber
              min={0}
              max={24}
              defaultValue={DEFAULT_HOURS}
              value={hours}
              precision={0}
              addonBefore={<div>{t('common.cartRequestExpirationHours')}</div>}
              onChange={handleChangeHours}
            />
          </Space>
          <div>
            <Text type="secondary-gray1">
              {t('common.expiresOn')} : {expirationDate}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartRequestExpiration;
