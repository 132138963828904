import { IShippingFeeItem, IShippingFeesUserInfo, IStoreShippingItem } from 'types/shippingFees';
import { INormalizedList, IShippingAddresses, ITown } from 'types';
import moment from 'moment';
import type { ICategorizedShippingItems, ICategorizeShippingItemsResponse, IDeliveryState } from 'store/delivery/types';
import { ERRORS } from 'constants/errors';

type CategorizeShippingItemsNormalizer = Pick<
  IDeliveryState,
  'shippingItems' | 'shippingFees' | 'shippingCategories' | 'sortedShippingCategories'
> & {
  categoryPointList?: INormalizedList<string>;
};

export const categorizeShippingItemsNormalizer = (
  payload: ICategorizeShippingItemsResponse,
  initialState: IDeliveryState,
): CategorizeShippingItemsNormalizer => {
  const shippingFees = (payload.shippingFees || []).reduce<INormalizedList<IShippingFeeItem>>(
    (accumulator, shippingFee) => {
      if (Object.hasOwnProperty.call(accumulator, shippingFee.shippingMethodId)) {
        accumulator[shippingFee.shippingMethodId].itemList = [
          ...accumulator[shippingFee.shippingMethodId].itemList,
          ...shippingFee.itemList,
        ];
        return accumulator;
      }
      accumulator[shippingFee.shippingMethodId] = shippingFee;
      return accumulator;
    },
    {},
  );

  const shippingCategories = payload.shippingMethods?.reduce<INormalizedList<ICategorizedShippingItems>>(
    (accumulator, shippingMethod) => {
      const shippingFeeItem = shippingFees[shippingMethod.shippingMethodId];
      const response = {
        ...shippingMethod,
        ...(shippingFeeItem || {}),
        hasFees: !!shippingFeeItem?.shippingMethodId,
        isPointList: !!shippingMethod.pointList?.length,
        pointListId: undefined,
        products: [],
      } as ICategorizedShippingItems;

      if (response.hasFees && shippingFeeItem) {
        shippingFeeItem.itemList.forEach((item) => {
          const foundProduct = payload.products.find((product) => product.offerId === item.offerId && product.enabled);
          if (foundProduct) response.products.push(foundProduct);
        });
      }

      accumulator[shippingMethod.shippingMethodId] = response;

      return accumulator;
    },
    {},
  );

  const sortedShippingCategories = payload.shippingMethods?.reduce<{
    byAddress: Array<number>;
    byPointLists: Array<number>;
  }>(
    (accumulator, category) => {
      if (category.pointList?.length) {
        accumulator.byPointLists.push(category.shippingMethodId);
      } else {
        accumulator.byAddress.push(category.shippingMethodId);
      }
      return accumulator;
    },
    {
      byAddress: [],
      byPointLists: [],
    },
  );

  const updatedShippingItems = payload.shippingItems.reduce<Array<IStoreShippingItem>>((accumulator, shippingItem) => {
    if (!shippingItem.shippingMethodId && shippingCategories) {
      const shippingCategory = Object.values(shippingCategories).find((shippingCategoryEntry) =>
        shippingCategoryEntry.itemList?.some((item) => item.offerId === shippingItem.offerId),
      );

      if (shippingCategory) {
        accumulator.push({ ...shippingItem, shippingMethodId: shippingCategory.shippingMethodId });
      }
    } else {
      accumulator.push(shippingItem);
    }

    return accumulator;
  }, []);

  if (
    shippingFees &&
    shippingCategories &&
    sortedShippingCategories?.byAddress &&
    sortedShippingCategories?.byPointLists
  ) {
    const haveSomeCategoryWithPointList = Object.values(shippingCategories).some(
      (category) => category.itemList?.length && category.pointList?.length,
    );

    return {
      shippingItems: updatedShippingItems,
      shippingFees,
      shippingCategories,
      sortedShippingCategories,
      categoryPointList: !haveSomeCategoryWithPointList ? initialState.categoryPointList : undefined,
    };
  }

  return {
    shippingItems: payload.shippingItems,
    shippingFees: initialState.shippingFees,
    shippingCategories: initialState.shippingCategories,
    sortedShippingCategories: initialState.sortedShippingCategories,
  };
};

export const calculateShippingFeesNormalizer = ({
  shippingAddress,
  tags,
  town,
  customerId,
  isGroup,
  clubMemberExpDate,
}: {
  shippingAddress: IShippingAddresses | undefined;
  town: ITown | null;
  tags: Array<string> | undefined;
  customerId: number | undefined;
  isGroup: boolean;
  clubMemberExpDate: Nullable<Date>;
}): { userInfo: IShippingFeesUserInfo } => {
  if (!customerId && !isGroup) {
    throw ERRORS.delivery.noSelectedCustomer;
  }
  if (!shippingAddress) {
    throw ERRORS.delivery.noSelectedAddress;
  }

  if (!town) {
    throw ERRORS.delivery.noSelectedTown;
  }
  if (customerId) {
    if (town.postcode !== shippingAddress.coreTown?.postcode) {
      throw ERRORS.delivery.townsNotCoincide;
    }
  }

  if (isGroup) {
    if (town.postcode !== shippingAddress.postcodeLabel) {
      throw ERRORS.delivery.townsNotCoincide;
    }
  }

  const { coreTown, countryCode, street: address, townLabel: city, postcodeLabel: zipCode } = shippingAddress;

  const geoAdminId =
    coreTown?.geoAdminTown?.find((geoTown) => geoTown.geoAdminLevel === 2)?.geoAdminId ||
    coreTown?.geoAdminId ||
    town.geoAdminId;
  const latitude = coreTown?.latitude || town.latitude;
  const longitude = coreTown?.longitude || town.longitude;

  const userInfo = {
    countryCode,
    geoAdminId,
    address,
    city,
    zipCode,
    latitude,
    longitude,
    tags,
    clubMemberExpDate: clubMemberExpDate,
    isClub: clubMemberExpDate ? moment(clubMemberExpDate).isSameOrAfter(moment()) : false,
  } as IShippingFeesUserInfo;

  return { userInfo };
};
