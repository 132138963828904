import { setIsEco, selectIsEco } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import env from 'config';
import { Checkbox, Popconfirm } from 'antd';
import { IEcoCheckboxProps } from './EcoCheckbox.types';
import styles from './EcoCheckbox.module.scss';

const EcoCheckbox: FC<IEcoCheckboxProps> = (props: IEcoCheckboxProps) => {
  const { light = false } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const isEco = useSelector(selectIsEco);

  if (!env('enableGammeCge')) {
    return null;
  }

  const closePopconfirm = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      dispatch(setIsEco(true));
      return;
    }
    // Determining condition before show the popconfirm.
    if (isEco) {
      dispatch(setIsEco(false));
    } else {
      setOpen(newOpen);
    }
  };

  return (
    <div className={`${styles.container} ${light ? styles.container_light : ''}`}>
      <Popconfirm
        title={t('modals.gammeCGEAttention')}
        okText="OK"
        showCancel={false}
        onConfirm={closePopconfirm}
        placement="top"
        onOpenChange={handleOpenChange}
        open={open}
      >
        <Checkbox className={styles.checkbox} checked={isEco}>
          {t('common.cgeProductLine')}
        </Checkbox>
      </Popconfirm>
    </div>
  );
};

export default EcoCheckbox;
