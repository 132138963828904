import { ReactNode } from 'react';
import { Dayjs } from 'dayjs';
import styles from './DateRenderOverride.module.scss';

export const DateRenderOverride = (current: Dayjs): ReactNode => {
  if (current.day() === 1) {
    const weekNumber = current.week();
    return (
      <div className="ant-picker-cell-inner">
        <div className={styles['ant-picker-calendar-date']}>
          <div>{current.date()}</div>
          <div className={styles['ant-picker-calendar-week-number']}>{weekNumber}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="ant-picker-cell-inner">
      <div className={styles['ant-picker-calendar-date']}>{current.date()}</div>
    </div>
  );
};
