import { selectIsGroup } from 'store/customers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { usePermissions } from 'hooks';
import env from 'config';
import { Text } from 'components/shared';
import { PageHeader } from '@ant-design/pro-layout';
import { selectUserTcCode } from '../../../store/user';
import CustomersGroupSwitcher from './components/CustomersGroupSwitcher';
import CustomersFilter from './components/CustomersFilter';
import CreateCustomer from './components/CreateCustomer';
import styles from './CustomerHeader.module.scss';

const ALLOWED_PERMISSIONS = ['QUOTE_CREATE'];
const CustomerHeader: FC = () => {
  const isGroup = useSelector(selectIsGroup);
  const { t } = useTranslation();
  const { allowed } = usePermissions(ALLOWED_PERMISSIONS, false, false);
  const tcCode = useSelector(selectUserTcCode);

  const showCustomerSearch = useMemo(() => {
    if (!tcCode) {
      return (
        <div className={styles.customerHeader__utility}>
          <Text className={styles.customerHeader__infoText}>{t('errors.missingTcCode')}</Text>
        </div>
      );
    }
    return (
      <div className={styles.customerHeader__utility}>
        {env('enableMultiFacturation') && <CustomersGroupSwitcher />}
        <CustomersFilter isGroup={isGroup} />
        {!isGroup && env('enableAddFarmer') && <CreateCustomer />}
      </div>
    );
  }, [tcCode, isGroup, t]);

  return (
    <>
      <PageHeader className={styles.customerHeader} ghost={false}>
        <h3 className={styles.title}>{t('landing.pageTitle')}</h3>
        <Text type="secondary" className={styles.customerHeader__infoText}>
          {t('landing.createOrderTitle', { count: env('enableMultiFacturation') ? 2 : 1 })}
        </Text>
        {!allowed && (
          <div className={styles.customerHeader__utility}>
            <Text type="secondary" className={styles.customerHeader__infoText}>
              {t('landing.notAuthorized')}
            </Text>
          </div>
        )}
        {allowed && showCustomerSearch}
      </PageHeader>
    </>
  );
};

export default CustomerHeader;
