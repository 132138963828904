import { useIsMobile } from 'utils/styles.utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import { IWiuzFiles } from 'store/products/types';
import {
  fetchWiuzProducts,
  selectDisplayAllOffers,
  selectPostalCode,
  selectWiuzFiles,
  selectWiuzIsLoading,
} from 'store/products';
import { selectTags } from 'store/customers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useState } from 'react';
import SpinWrap from 'components/shared/SpinWrap';
import { Button } from 'components/shared';
import { OrderDate } from 'components/savedCart/SavedCartTable/components/TableColumns';
import { Table } from 'antd';
import { selectTcTags } from '../../../store/user';
import styles from './styles.module.scss';

const ProductWiuzTableList: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const wiuzIsLoadingFiles = useAppSelector(selectWiuzIsLoading);
  const wiuzFiles = useAppSelector(selectWiuzFiles);
  const town = useAppSelector(selectPostalCode);
  const customerTags = useAppSelector(selectTags);
  const tcTags = useSelector(selectTcTags);
  const displayAllOffers = useSelector(selectDisplayAllOffers);
  const [wiuzLoadingImportId, setWiuzLoadingImportId] = useState('');
  const { t } = useTranslation();
  const tags = displayAllOffers ? [...(customerTags ?? []), ...tcTags] : customerTags;
  const isLoadingWiuzFile = useCallback((id: string): boolean => wiuzLoadingImportId === id, [wiuzLoadingImportId]);

  const TABLE_COLUMNS: Array<TableColumnDefinition<IWiuzFiles>> = [
    {
      title: <span className={`${styles.col} ${styles.col__first}`}>{t('common.filename')}</span>,
      className: `${styles.firstColumn}`,
      dataIndex: 'filename',
      render: (filename) => <div className={styles.content}>{filename}</div>,
    },
    {
      title: <span className={styles.col}>{t('common.uploadDate')}</span>,
      dataIndex: 'createdAt',
      render: (createdAt) => <OrderDate date={createdAt} />,
    },
    {
      title: <span className={styles.col}>{t('common.lastModification')}</span>,
      dataIndex: 'updatedAt',
      render: (updatedAt) => <div className={styles.content}>{updatedAt ? <OrderDate date={updatedAt} /> : '-'}</div>,
    },
    {
      title: <span className={styles.col}></span>,
      className: styles.actionsColumn,
      dataIndex: 'id',
      render: (id) => (
        <SpinWrap loading={isLoadingWiuzFile(id)}>
          <Button
            className={styles.button}
            disabled={isLoadingWiuzFile(id)}
            type="primary"
            onClick={async () => {
              setWiuzLoadingImportId(id);
              await dispatch(fetchWiuzProducts({ importId: id, page: 0, postcode: town?.postcode, tags }));
              setWiuzLoadingImportId('');
            }}
          >
            {t('common.view')}
          </Button>
        </SpinWrap>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Table
        columns={TABLE_COLUMNS}
        size={isMobile ? 'small' : 'large'}
        className={styles.container__wiuz}
        rowClassName={styles.row}
        rowKey={(order) => order.id}
        loading={wiuzIsLoadingFiles}
        dataSource={wiuzFiles}
        locale={{ emptyText: t('errors.noFilesFound') }}
        pagination={false}
      />
    </div>
  );
};

export default ProductWiuzTableList;
