import { User } from 'services';
import { RootState } from '../rootReducer';

export const selectUserGroups = (state: RootState): string[] => state.user.groups;
export const selectAllUsers = (state: RootState): User[] => state.users.users;
export const selectCurrentPageToken = (state: RootState): string => {
  const { currentPage, paginationTokens } = state.users;
  return paginationTokens[currentPage] || '';
};
export const selectNextPageToken = (state: RootState): string => {
  const { currentPage, paginationTokens } = state.users;
  return paginationTokens[currentPage + 1] || '';
};
export const selectPrevPageToken = (state: RootState): string => {
  const { currentPage, paginationTokens } = state.users;
  return paginationTokens[currentPage - 1] || '';
};
