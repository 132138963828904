import { setUser } from 'store/user';
import { useAppDispatch } from 'store/store';
import { Navigate } from 'react-router';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useLogout } from 'hooks';
import { useToken } from 'cookies';
import { getLoginPath } from 'constants/routes';

type Props = {
  children: ReactNode;
  redirectTo?: string;
};

export const LoggedRouteMiddleware: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const isInitialMount = useRef(true);
  const isRefreshingToken = useRef(false);
  const [isUserFetched, setIsUserFetched] = useState(false);
  const { children, redirectTo } = props;
  const { token, isExpired, refreshToken } = useToken();
  const { logout } = useLogout();
  const { getLoggedUser, refreshToken: updateRefreshToken } = useAuth();
  const expired = isExpired();

  useEffect(() => {
    if (!isInitialMount.current) {
      return;
    }

    if (!token || expired) {
      getLoggedUser(true)
        .then((res) => {
          if (res) {
            const reduxUser = {
              ...res,
              firstName: res.firstname,
              lastName: res.lastname,
            };
            dispatch(setUser(reduxUser));
          } else {
            logout();
          }
        })
        .catch(logout)
        .finally(() => {
          setIsUserFetched(true);
        });
    } else {
      setIsUserFetched(true);
    }

    isInitialMount.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!isUserFetched) {
    return null;
  }

  if (expired && !isRefreshingToken.current) {
    isRefreshingToken.current = true;
    updateRefreshToken(refreshToken)
      .then(() => {
        isRefreshingToken.current = false;
      })
      .catch(logout);
  }

  if (!token) {
    return <Navigate to={redirectTo || getLoginPath()} />;
  }

  return <>{children}</>;
};
