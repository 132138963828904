import { useAppDispatch, useAppSelector } from 'store/store';
import { SettingsState } from 'store/settings/types';
import { setSettingsState } from 'store/settings';
import { RootState } from 'store/rootReducer';
import { useCallback } from 'react';

type UseSetting<T = SettingsState> = {
  <K extends keyof T>(key: K): [T[K], (value: T[K]) => void];
};

export const useSettings: UseSetting = (key) => {
  const selector = useCallback((state: RootState) => state.settings[key], [key]);
  const selectedValue = useAppSelector(selector);
  const dispatch = useAppDispatch();

  const setSetting: ReturnType<UseSetting>[1] = useCallback(
    (value) => {
      dispatch(setSettingsState({ [key]: value }));
    },
    [dispatch, key],
  );

  return [selectedValue, setSetting];
};

type UseGetSettings<T = SettingsState> = {
  <K extends keyof T>(key: K): T[K];
};

export const useGetSettings: UseGetSettings = (key) => {
  const selector = useCallback((state: RootState) => state.settings[key], [key]);
  return useAppSelector(selector);
};

export const useSetSettings = <T extends SettingsState, K extends keyof T>(key: K): ((value: T[K]) => void) => {
  const dispatch = useAppDispatch();
  return useCallback((value: T[K]) => dispatch(setSettingsState({ [key]: value })), [dispatch, key]);
};
