export const my_profile_en = {
  lastname: 'Your surname',
  firstname: 'Your name',
  username: 'User Name',
  phoneNumber: 'Phone number',
  pageTitle: 'Profile',
  currentPassword: 'Your current password',
  changeYourPassword: 'Change your password',
  changeYourPasswordButton: 'Change your password',
  confirm: 'Confirm',
  saveChanges: 'Save changes',
  yourPersonalInformation: 'Your personal information',
  yourSettings: 'Your settings',
  yourGroups: 'Your groups',
  noGroups: 'No groups',
};
