import { DEFAULT_ORDER_FILTER, filterEmptyObjectKeys, ORDER_STATUS } from 'utils/order.utils';
import { IOrdersFilterParams } from 'types/order';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import 'moment/locale/fr';
import moment from 'moment';
import { useDateFormat } from 'hooks/useDateFormat';
import { Button } from 'components/shared';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Form, Input, Select, DatePicker } from 'antd';
import {
  clearFilters,
  fetchOrdersHistory,
  selectOrderHistoryParams,
  setFilters,
  setPage,
} from '../../../../../store/ordersHistory';
import { useToken } from '../../../../../cookies';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import styles from './styles.module.scss';

const OrdersFilters: FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { token } = useToken();
  const { size } = useSelector(selectOrderHistoryParams);
  const { t } = useTranslation();
  const dateFormatShort = useDateFormat('short');

  const onFinish = (values: IOrdersFilterParams) => {
    const orderFilterParameters = filterEmptyObjectKeys<IOrdersFilterParams>(values);
    if (orderFilterParameters.dateRange?.length) {
      orderFilterParameters['createdAt[0]'] = moment(orderFilterParameters.dateRange[0]._d).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
      orderFilterParameters['createdAt[1]'] = moment(orderFilterParameters.dateRange[1]._d).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
    }
    delete orderFilterParameters.dateRange;

    dispatch(setFilters(orderFilterParameters));
    dispatch(
      fetchOrdersHistory({
        token: token,
        filters: orderFilterParameters,
        page: 1,
        size: size,
      }),
    );
  };

  const clearInputs = () => {
    form.resetFields();
    dispatch(clearFilters());
    dispatch(setPage(1));
    dispatch(
      fetchOrdersHistory({
        token: token,
        filters: {
          status: DEFAULT_ORDER_FILTER,
        },
        page: 1,
      }),
    );
  };

  return (
    <div className={styles.filters}>
      <Form
        form={form}
        layout="horizontal"
        name="filters-form"
        onFinish={onFinish}
        className={styles.form}
        autoComplete="off"
        initialValues={{ status: DEFAULT_ORDER_FILTER }}
      >
        <div className={styles.filtersContainer}>
          <div className={styles.inputContainer}>
            <Form.Item name="company" className={styles.input}>
              <Input size="large" placeholder={String(t('order_history.company'))} allowClear />
            </Form.Item>
          </div>
          <div className={styles.inputContainer}>
            <Form.Item name="customerIdentifier" className={styles.input}>
              <Input size="large" placeholder={String(t('order_history.thirdPartyNumber'))} allowClear />
            </Form.Item>
          </div>
          <div className={styles.inputContainer}>
            <Form.Item name="dateRange" label="" className={styles.input}>
              <RangePicker size="middle" locale={locale} format={dateFormatShort} className={styles.input} />
            </Form.Item>
          </div>
          <div className={styles.inputContainer}>
            <Form.Item name="number" className={styles.input}>
              <Input size="large" placeholder={String(t('order_history.orderNumber'))} allowClear />
            </Form.Item>
          </div>
          <div className={styles.inputContainer}>
            <Form.Item name="status" label="" className={`${styles.input} ${styles.select}`}>
              <Select placeholder={String(t('order_history.status'))} mode="multiple" size="large" allowClear>
                {ORDER_STATUS.map((status) => {
                  return (
                    <Select.Option key={status.value} value={status.value}>
                      {status.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.filterButton} type="primary" htmlType="submit">
            {String(t('order_history.filterResults'))}
          </Button>
          <Button className={styles.filterButton} type="dashed" onClick={clearInputs}>
            {String(t('order_history.deleteFilters'))}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default OrdersFilters;
