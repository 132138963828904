import { IProductPriceData } from 'components/shared';
import { IProductOfferShippingMethod, IProductTaxes } from './product';

export enum DisplayOfferGroup {
  NONE = 'none',
  DEPARTURE = 'departure',
  SKU = 'sku',
}

export type IOffer = {
  OfferId: number;
  DepartureId: number;
  SupplierId: number;
  UnitPrice: number;
  SupplierUnitPrice: number;
  Stock: number;
  QuantityIncrement: number;
  MinimumOrderable: number;
  StartDate: string;
  EndDate: string;
  Days: number;
  AdjustmentAvailable: boolean;
  AvailableOn: string;
  UnavailableOn: string;
  ReservedForClub: boolean;
  Franco: boolean;
  Type: number;
  Panachage: boolean;
  PurchasableOffline: boolean;
  Online: boolean;
  VariantId: number;
  TruckConditioningId: number;
  UnitId: number;
  ProductId: number;
  IgnoreStock: boolean;
};
export type IDiscount = {
  DiscountedPrice: number;
  EndDate: string;
  Id: number;
  MinimumQuantity: number;
  ReservedForClub: boolean;
  StartDate: string;
  Type: number;
  Value: number;
};
export type IOfferPrice = {
  OfferId: number;
  RawPrice: number;
  DiscountedPrice: number;
  DiscountType: number;
  DiscountValue: number;
  ExpirationDate: string;
  DiscountReservedForClub: boolean;
  Discount: Array<IDiscount>;
  Shipping: number;
  IsTruck: boolean;
  Tag: Array<string>;
  Offer: IOffer;
};
export type IOfferVariant = {
  OfferId: number;
  RawPrice: number;
  DiscountedPrice: number;
  DiscountType: number;
  DiscountValue: number;
  ExpirationDate: string;
  DiscountReservedForClub: boolean;
  VariantId: number;
  Online?: boolean;
  OfferPrice: Array<IOfferPrice>;
};
export type IOfferProduct = {
  OfferId: number;
  RawPrice: number;
  DiscountedPrice: number;
  DiscountType: number;
  DiscountValue: number;
  ExpirationDate: string;
  DiscountReservedForClub: boolean;
  ProductId: number;
  Town: string;
  Country: string;
  Club: boolean;
  Online?: boolean;
  Variants: Array<IOfferVariant>;
};

export type IOfferResponse = {
  Error: any;
  Warning: any;
  Product: Array<IOfferProduct>;
  Panachage: any;
};

export type IStorageOffer = {
  offerId: number;
  productId: number;
  departureId: number;
  variantId: number;
  price: IProductPriceData;
  days?: number;
  stock?: number;
  quantityIncrement?: number;
  minimumOrderable?: number;
  shippingMethods?: Array<IProductOfferShippingMethod>;
  taxes: IProductTaxes;
  isTruck?: boolean;
  startDate?: string;
  endDate?: string;
  tags: Array<string>;
  purchasableOffline: boolean;
  online: boolean;
  ignoreStock?: boolean;
  discountOffers?: Array<IDiscount>;
};
