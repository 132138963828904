import { IShippingAddresses, ITown } from 'types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { Button, Text } from 'components/shared';
import { Form, Button as AntdButton } from 'antd';
import PostalCodeSearch from '../PostalCodeSearch';
import { unwrap } from '../../../../../utils/api.utils';
import { setFetchedTowns } from '../../../../../store/products';
import { setShippingAddress } from '../../../../../store/delivery';
import { PostcodeApiService } from '../../../../../services/postcodeServiceApi';
import { IPostalCodeFormProps } from './types';
import styles from './styles.module.scss';

const PostalCodeForm: FC<IPostalCodeFormProps> = (props: IPostalCodeFormProps) => {
  const { onConfirm, shippingAddresses } = props;
  const [form] = Form.useForm();
  const [selectedTown, setSelectedTown] = useState<Nullable<ITown>>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const displayLength = 3;
  const [postalCodePredefined, setPostalCodePredefined] = useState<string | null>(null);

  const handleClearPredefined = () => {
    setPostalCodePredefined(null);
  };

  const setTownId = (town: Nullable<ITown>) => {
    setSelectedTown(town);
  };
  const onFinish = () => {
    if (selectedTown) {
      form.resetFields();
      onConfirm(selectedTown.id, selectedTown.postcode);
    }
  };

  const fetchPostalCodes = async (postalCode: string, city: string) => {
    const postalCodes = unwrap(await PostcodeApiService.searchTowns(postalCode));
    let foundTown = postalCodes.find(
      (town) => town.postcode === postalCode && town.town.toLowerCase() === city.toLowerCase(),
    );
    /**
     * Different town names can affect the search result, so we need to check
     * if the postal code is unique and if it is we return it bc probably the name of the city
     * is spelled differently
     */
    if (!foundTown && postalCodes.length === 1 && postalCodes[0].postcode === postalCode) {
      foundTown = postalCodes[0];
    }

    if (foundTown) {
      dispatch(setFetchedTowns(postalCodes));
      onConfirm(foundTown.id, foundTown.postcode);
    } else {
      setPostalCodePredefined(postalCode);
    }
  };

  const setDeliveryAddress = async (shippingAddress: IShippingAddresses) => {
    await fetchPostalCodes(shippingAddress.postcodeLabel, shippingAddress.townLabel);
    dispatch(setShippingAddress(shippingAddress));
  };

  const displayShippingAddresse = useMemo(() => {
    if (showAllAddresses) {
      return shippingAddresses;
    }

    return shippingAddresses.slice(0, displayLength);
  }, [shippingAddresses, showAllAddresses]);

  return (
    <div className={styles.container}>
      <Text type="title" className={styles.title}>
        {t('modals.postalCodeTitle')}
      </Text>
      <Text type="subtitle-gray1" className={styles.subtitle}>
        {t('modals.postalCodeSubtitle')}
      </Text>
      {displayShippingAddresse.length > 0 && (
        <div className={styles.address_box}>
          <Text type="subtitle-gray8" className={styles.title}>
            {t('modals.availableDeliveryAddresses')}
          </Text>
          <div>
            <div className={styles.address_list}>
              {displayShippingAddresse.map((shippingAddress, key) => (
                <div key={key}>
                  <Text type="subtitle-gray8" className={styles.address}>
                    <span onClick={(_e) => setDeliveryAddress(shippingAddress)}>
                      <div className={styles.square}></div>
                      {shippingAddress.street} {shippingAddress.postcodeLabel} {shippingAddress.townLabel}
                    </span>
                  </Text>
                </div>
              ))}
            </div>
            {shippingAddresses.length > displayLength && (
              <AntdButton
                type="link"
                className={styles.buttonLink}
                onClick={() => setShowAllAddresses((currentValue) => !currentValue)}
              >
                {showAllAddresses ? t('modals.viewLessAddresses') : t('modals.viewMoreAddresses')}
              </AntdButton>
            )}
          </div>
        </div>
      )}
      <Text type="subtitle-gray8" className={styles.subtitle}>
        {t('modals.postalCode')}
      </Text>
      <Form form={form} layout="horizontal" className={styles.form} name="postal-code-form" onFinish={onFinish}>
        <div className={styles.form__input}>
          <PostalCodeSearch
            onFinish={setTownId}
            predefined={postalCodePredefined}
            clearPredefined={handleClearPredefined}
          />
        </div>
        <Button type="primary" className="modalContent__validateButton" htmlType="submit" disabled={false}>
          {t('modals.okButton')}
        </Button>
      </Form>
    </div>
  );
};

export default PostalCodeForm;
