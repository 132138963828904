import {
  selectCartComment,
  selectCartDeliveryComment,
  selectCartInvoiceComment,
  updateCartDeliveryComment,
  updateCartGlobalComment,
  updateCartInvoiceComment,
} from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';
import CartComment from 'components/product/CartTable/components/CartComment';
import { CartCommentsProps } from './CartComments.types';
import styles from './CartComments.module.scss';

const CartComments: React.FC<CartCommentsProps> = (_props: CartCommentsProps) => {
  const cartComment = useSelector(selectCartComment);
  const cartDeliveryComment = useSelector(selectCartDeliveryComment);
  const cartInvoiceComment = useSelector(selectCartInvoiceComment);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleCommentSave = (value: string) => {
    dispatch(updateCartGlobalComment(value));
  };

  const handleDeliveryCommentSave = (value: string) => {
    dispatch(updateCartDeliveryComment(value));
  };

  const handleInvoiceCommentSave = (value: string) => {
    dispatch(updateCartInvoiceComment(value));
  };

  return (
    <div className={styles.wrapper}>
      <CartComment
        key={'globalCartComment'}
        className={styles.comment__textarea}
        comment={cartComment}
        onSave={handleCommentSave}
        placeholder={t('common.comment')}
        label={t('common.globalCommentPlaceholder')}
        showLabel
      />
      <CartComment
        key={'deliveryComment'}
        className={styles.comment__textarea}
        comment={cartDeliveryComment}
        onSave={handleDeliveryCommentSave}
        placeholder={t('common.comment')}
        label={t('common.deliveryCommentPlaceholder')}
        showLabel
      />
      <CartComment
        key={'invoiceComment'}
        className={styles.comment__textarea}
        comment={cartInvoiceComment}
        onSave={handleInvoiceCommentSave}
        placeholder={t('common.comment')}
        label={t('common.invoiceCommentPlaceholder')}
        showLabel
      />
    </div>
  );
};

export default CartComments;
