import { translate } from 'utils/translation.utils';
import { createError } from 'utils/error.utils';
import { isApiOkResponse } from 'utils';
import { ErrorType } from 'types';
import { ApiResponse } from 'apisauce';
import { setAxiosError } from 'constants/errors';

type ThrowableErrorCallback<TResponse> = (response: TResponse) => ErrorType | void | false | null | undefined;
type ThrowableError<TResponse> = ErrorType | ThrowableErrorCallback<TResponse>;

export const unwrap = <T>(response: ApiResponse<T>, throwableError?: ThrowableError<ApiResponse<T>>): T => {
  if (!isApiOkResponse<T>(response)) {
    const httpStatusCode = response.status || 0;
    switch (true) {
      case httpStatusCode < 300:
        break;
      case httpStatusCode < 400:
        throw setAxiosError('CLIENT_ERROR', response.data);
      case httpStatusCode === 401:
        throw setAxiosError('UNAUTHORIZED_ERROR');
      default:
        throw setAxiosError(response.problem, response.data);
    }
  }

  if (typeof throwableError === 'function') {
    const newError = throwableError(response);
    if (typeof newError === 'object') {
      throw newError;
    }
  } else if (typeof throwableError === 'object' && !response.data) {
    throw throwableError;
  }

  if (response.problem) {
    throw setAxiosError(response.problem, { server: response.config?.baseURL });
  }

  if (!response.data) {
    throw createError(translate('No response data.'));
  }

  return response.data;
};

export const validate = <TResponse>(
  response: TResponse,
  throwableError: ThrowableErrorCallback<TResponse>,
  withReturnError = false,
): boolean | ErrorType | never => {
  const newError = throwableError(response);
  if (typeof newError === 'object' && newError !== null) {
    if (withReturnError) {
      return newError;
    }
    throw newError;
  }

  return true;
};
