import { clearFetchedTowns, clearPostalCodes } from 'store/products';
import { clearShippingAddresses, selectIsGroup, setCustomersGroupsView, setCustomersView } from 'store/customers';
import { clearCartState } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button } from 'components/shared';
import env from '../../../../../config';
import styles from './styles.module.scss';

const CustomersGroupSwitcher: FC = () => {
  const dispatch = useDispatch();
  const isGroup = useSelector(selectIsGroup);
  const { t } = useTranslation();

  return (
    <div className={styles.group}>
      <Button
        className={`${styles.button} ${styles.button__left} ${isGroup ? styles.button_disabled : ''}`}
        onClick={() => {
          dispatch(setCustomersView());
          isGroup && dispatch(clearCartState());
          dispatch(clearShippingAddresses());
          dispatch(clearFetchedTowns());
          dispatch(clearPostalCodes());
        }}
      >
        {t('common.farmer', { count: 1, context: env('theme') })}
      </Button>
      <Button
        className={`${styles.button} ${styles.button__right} ${!isGroup ? styles.button_disabled : ''}`}
        onClick={() => {
          dispatch(setCustomersGroupsView());
          isGroup && dispatch(clearCartState());
          dispatch(clearShippingAddresses());
          dispatch(clearFetchedTowns());
          dispatch(clearPostalCodes());
        }}
      >
        {t('common.multiBilling', { context: env('theme') })}
      </Button>
    </div>
  );
};

export default CustomersGroupSwitcher;
