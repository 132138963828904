// eslint-disable-next-line no-shadow
export enum LoggerType {
  ALGOLIA = 'ALGOLIA',
}

// eslint-disable-next-line no-shadow
export enum LoggerJSON {
  NONE = 'NONE',
  MINIMIZE = 'MINIMIZE',
  PRETTY = 'PRETTY',
}

export type LoggerOptions = {
  prefix: boolean;
  enabled: boolean;
  json: LoggerJSON;
};

export type LoggerParserInputOutput = string | number | boolean | null | undefined;

export type LoggerOutput = (...outputData: unknown[]) => void;

export type LoggerParser<T> = (
  parserData: T,
  output: LoggerOutput,
  loggerType: string,
) => LoggerParserInputOutput | LoggerParserInputOutput[] | void;

export interface LoggerWrite {
  (data: LoggerParserInputOutput): void | null;
  <T>(data: T, parser: LoggerParser<T>): void | null;
}

export type Logger = {
  write: LoggerWrite;
};
