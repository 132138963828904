import { sendMessage } from 'store/messages';
import { clearCustomersState } from 'store/customers';
import { clearCartState } from 'store/cart';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import { ValidatedType } from 'pages/Validated/Validated.types';
import { useLogout } from 'hooks';
import ValidatedHeader from 'components/validated/ValidatedHeader';
import { Layout } from 'antd';
import { clearOrderHistory } from '../../../store/ordersHistory';
import { getOrderPath, OrderRoute } from '../../../constants/routes';
import { ValidatedOrder, ValidatedQuote } from './components';
import styles from './ValidatedContainer.module.scss';

type ValidatedContainerProps = {
  type: `${ValidatedType}`;
  cartId?: string;
};

const ValidatedContainer: FC<ValidatedContainerProps> = (props: ValidatedContainerProps) => {
  const { type, cartId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = useLogout();

  useEffect(() => {
    dispatch(clearCartState());
    dispatch(clearCustomersState());
    dispatch(clearOrderHistory());
  }, [dispatch]);

  if (type === 'quote') {
    return (
      <Layout.Content className={styles.container}>
        <ValidatedQuote
          onClickLogout={async () => {
            if (!cartId) {
              dispatch(
                sendMessage({
                  message: t('common.unableToViewTheQuote'),
                  type: 'error',
                }),
              );
              return;
            }

            if (cartId) {
              return navigate(getOrderPath(OrderRoute.PRODUCT, cartId));
            }
          }}
          onCreateNewOrder={() => {
            navigate(getOrderPath(OrderRoute.CUSTOMER));
          }}
        />
      </Layout.Content>
    );
  }

  return (
    <Layout className={styles.layout}>
      <ValidatedHeader />
      <Layout.Content className={styles.container}>
        <ValidatedOrder
          onClickLogout={async () => {
            logout();
          }}
          onCreateNewOrder={() => {
            navigate(getOrderPath(OrderRoute.CUSTOMER));
          }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ValidatedContainer;
