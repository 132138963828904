import 'antd/dist/reset.css';
import './app/App.scss';
import { initDocument } from 'utils/document.utils';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import React from 'react';
import dotenv from 'dotenv';
import { Spin } from 'antd';
import { ConfigProvider } from 'antd';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { GlobalHandlers } from '@sentry/browser/dist/integrations';
import store, { persistor } from './store/store';
import { router } from './routes';
import reportWebVitals from './reportWebVitals';
import './locales/i18n';

dotenv.config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [
    new BrowserTracing({
      traceXHR: true,
    }),
    new GlobalHandlers({
      onerror: true,
      onunhandledrejection: true,
    }),
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACESAMPLE),
});

initDocument();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Spin size="large" />} persistor={persistor}>
        <ConfigProvider
          theme={{
            hashed: false,
            token: {
              fontFamily: "'Open Sans', sans-serif",
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

window._env_.REACT_APP_VERSION = process.env.REACT_APP_VERSION;

reportWebVitals();

console.log('app initiated v=' + window._env_.REACT_APP_VERSION);
