import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect } from 'react';
import moment from 'moment';
import { IAbandonedCartParams } from '../../types/abandonedCart';
import { fetchAbandonedCarts, selectAbandonedCartsLimit } from '../../store/abandonedCarts';
import AbandonedCartsTable from '../../components/abandonedCarts';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import styles from './AbandonedCarts.module.scss';

const AbandonedCarts: FC = () => {
  const dispatch = useDispatch();
  const size = useSelector(selectAbandonedCartsLimit);

  useEffect(() => {
    dispatch(
      fetchAbandonedCarts({
        filters: {
          'createdAt[$gte]': moment().startOf('day').subtract(1, 'month').format(CUSTOM_DATE_FORMAT.mysql.short),
          'createdAt[$lte]': moment().endOf('day').format(CUSTOM_DATE_FORMAT.mysql.short),
        } as unknown as IAbandonedCartParams,
        page: 1,
        size,
      }),
    );
  }, [dispatch, size]);

  return (
    <>
      <div className={styles.cartRequests}>
        <AbandonedCartsTable />
      </div>
    </>
  );
};

export default AbandonedCarts;
