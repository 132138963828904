export const cart_fr = {
  cartAttachmentUploadSuccess: '({{count}}) files has been uploaded.',
  noShippingMethods: 'No shipping methods',
  qtyEditableCheckboxLabel: 'Qté éditable',
  qtyEditableCheckboxTooltip: 'Le client pourra éditer la quantité avant de valider (dans la limite du stock).',
  removableCheckboxLabel: 'Suppression possible',
  removableCheckboxTooltip: "Le client pourra supprimer l'article avant de valider le devis.",
  replaceOffer: 'Remplacer l’offre actuelle',
  replace: 'Remplacer',
  articleInclusive: "Le client ne verra pas l'article s'il est exclu mais restera présent dans le devis",
  uploadMore: 'Télécharger plus de fichiers',
  cartAttachments: 'Pièces jointes',
  discountTooltip: 'Appliquer une remise',
  commentTooltip: 'Ajouter un commentaire au produit',
  deleteTooltip: 'Supprimer le produit',
};
