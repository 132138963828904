import { useAppSelector } from 'store/store';
import { selectCartProductByIndex } from 'store/cart';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import CartAttachmentUpload from 'components/product/CartTable/components/CartRowHeader/components/CartAttachmentUpload';
import { CartAttachments } from 'components/product/CartTable/components/CartRowHeader/components';
import styles from './CartAttachmentList.module.scss';

interface CartAttachmentListProps {
  productIndex: number;
}

export const CartAttachmentList: FunctionComponent<CartAttachmentListProps> = ({ productIndex }) => {
  const { t } = useTranslation();
  const product = useAppSelector(selectCartProductByIndex(productIndex));

  return (
    <div className={styles.cartAttachmentListContainre}>
      <span>{t('cart.cartAttachments')}:</span>
      <CartAttachments product={product} productIndex={productIndex} />
      <hr />
      <div className={styles.uploadMoreContainer}>
        <CartAttachmentUpload
          productIndex={productIndex}
          product={product}
          addMore={true}
          text={t('cart.uploadMore') as string}
        />
      </div>
    </div>
  );
};

export default CartAttachmentList;
