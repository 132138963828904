import { useAppDispatch, useAppSelector } from 'store/store';
import { ProductSearchType } from 'store/products/types';
import {
  fetchProducts,
  productsCleared,
  selectDisplayAllOffers,
  selectPostalCode,
  setOffersGroupBy,
  setDisplayAllOffers as setDisplayAllOffersAction,
  setExcludeNoStockOffers as setExcludeNoStockOffersAction,
  selectExcludeNoStockOffers,
} from 'store/products';
import { selectTags } from 'store/customers';
import { useSelector } from 'react-redux';
import { MdDiscount, MdOutlineDiscount } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import { ChangeEvent } from 'hoist-non-react-statics/node_modules/@types/react';
import { Input, Select, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Button } from '../../../../shared';
import { DisplayOfferGroup } from '../../../../../types/offer';
import { selectTcTags } from '../../../../../store/user';
import { selectCartId, selectIsSavedCart, selectRestoredUserTags } from '../../../../../store/cart';
import { useTheme } from '../../../../../hooks/useTheme';
import env from '../../../../../config';
import { ALGOLIA_SEARCH_FILTER } from 'constants/products/products';
import styles from './styles.module.scss';

const { Option } = Select;

type IProductSearchProps = {
  type: ProductSearchType;
  onTypeSearch?: boolean;
  value?: string;
  preloadSearch?: boolean;
  rowKey?: number;
  onSearchCompleted?: (inputValue?: string) => void;
};

const ProductSearch: FC<IProductSearchProps> = ({
  value,
  type,
  preloadSearch,
  rowKey,
  onTypeSearch = true,
  onSearchCompleted,
}: IProductSearchProps) => {
  const defaultFilter = ALGOLIA_SEARCH_FILTER[0].value;

  const [search, setSearch] = useState(value || '');
  const [filter, setFilter] = useState(defaultFilter);
  const [preload, setPreload] = useState(preloadSearch ?? false);
  const debouncedSearch = useDebounce(search);
  const town = useSelector(selectPostalCode);
  const customerTags = useAppSelector(selectTags);
  const tcTags = useSelector(selectTcTags);
  const restoredTcTags = useSelector(selectRestoredUserTags);
  const isSavedCart = useSelector(selectIsSavedCart);
  const cartId = useSelector(selectCartId);
  const stateDisplayAllOffers = useSelector(selectDisplayAllOffers);
  const stateExcludeNoStockOffers = useSelector(selectExcludeNoStockOffers);
  const excludeNoStockOffers = useRef(false);
  const displayAllOffers = useRef(false);
  const defaultGroupBy = env('productsSearchView');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const themeStyle = useTheme(styles);
  const enableSearchCategoryFilters = env('enableSearchCategoryFilters');
  const enableSearchCategoryFiltersForCustomerQuotation = env('enableSearchCategoryFiltersForCustomerQuotation');

  const dispatch = useAppDispatch();

  useEffect(() => {
    handleGroupChange(env('productsSearchView'));
    if (type === 'singleSearch') {
      excludeNoStockOffers.current = stateExcludeNoStockOffers;
      displayAllOffers.current = stateDisplayAllOffers;
    }
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const algoliaSearch = useCallback(async () => {
    const algoliaFilter = filter.length > 0 && `bu:${filter}`;
    let bestOfferTcTags = tcTags;
    if (isSavedCart && restoredTcTags && cartId) {
      bestOfferTcTags = [...bestOfferTcTags, ...restoredTcTags];
    }
    const tags = displayAllOffers.current ? [...(customerTags ?? []), ...bestOfferTcTags] : customerTags;

    const query = {
      search: debouncedSearch.trim(),
      filter: algoliaFilter,
      page: 0,
      postcode: town?.postcode,
      tags,
      excludeNoStockOffers: excludeNoStockOffers.current,
      ...(displayAllOffers.current && { displayAllOffers: displayAllOffers.current }),
    };

    setLoading(true);

    await dispatch(
      fetchProducts({
        ...query,
        searchType: type,
        ...(typeof rowKey !== 'undefined' && { rowKey }),
      }),
    );
    setLoading(false);
  }, [debouncedSearch, dispatch, filter, rowKey, town?.postcode, type]);

  const searchProducts = useCallback(async () => {
    dispatch(productsCleared);

    if (!preload) {
      setPreload(true);
      return;
    }

    await algoliaSearch();
    document.getElementById('main-layout-id')?.scrollTo(0, 0);
  }, [dispatch, preload, algoliaSearch]);

  useEffect(() => {
    if (!onTypeSearch) {
      return;
    }

    if (debouncedSearch.trim() === '') {
      dispatch(productsCleared);
    }

    searchProducts();
  }, [debouncedSearch, dispatch, searchProducts, onTypeSearch]);

  const handleFilterChange = (val: string) => setFilter(val);
  const handleGroupChange = (val: DisplayOfferGroup) => {
    dispatch(setOffersGroupBy(val));
  };

  const handleToggleDisplayAllOffers = async (val: boolean) => {
    displayAllOffers.current = val;
    if (type === 'singleSearch') {
      dispatch(setDisplayAllOffersAction(val));
    }
    await algoliaSearch();
    onSearchCompleted?.(search);
  };

  const handleToggleExcludeNoStockOffers = async (val: boolean) => {
    excludeNoStockOffers.current = val;
    if (type === 'singleSearch') {
      dispatch(setExcludeNoStockOffersAction(val));
    }
    await algoliaSearch();
    onSearchCompleted?.(search);
  };

  const categoryFilters = useMemo(() => {
    if (
      !enableSearchCategoryFilters ||
      (!enableSearchCategoryFiltersForCustomerQuotation && type === ProductSearchType.CUSTOMER_QUOTATION_SEARCH)
    ) {
      return null;
    }

    return (
      <Select className={styles.select} defaultValue={filter} size="large" onChange={handleFilterChange}>
        {ALGOLIA_SEARCH_FILTER.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    );
  }, [enableSearchCategoryFilters, enableSearchCategoryFiltersForCustomerQuotation, filter, type]);

  const groupByOptions = useMemo(() => {
    return [
      {
        value: DisplayOfferGroup.NONE,
        label: t('common.groupByOffer'),
      },
      {
        value: DisplayOfferGroup.DEPARTURE,
        label: t('common.groupByDeparture'),
      },
      {
        value: DisplayOfferGroup.SKU,
        label: t('common.groupBySku'),
      },
    ];
  }, [t]);

  const renderSelect = useCallback(() => {
    if (type === ProductSearchType.MULTIPLE_SEARCH || type === ProductSearchType.CUSTOMER_QUOTATION_SEARCH) {
      return null;
    }
    return (
      <Select
        className={styles.select}
        options={groupByOptions}
        defaultActiveFirstOption={false}
        defaultValue={defaultGroupBy}
        size="large"
        onChange={handleGroupChange}
      />
    );
  }, [defaultGroupBy, groupByOptions, handleGroupChange, type]);

  return (
    <div className={styles.wrapper}>
      <Input.Group className={themeStyle('inputGroup')}>
        {categoryFilters}
        <Input.Search
          className={styles.input}
          size="large"
          placeholder={t('forms.productSearchPlaceholder') as string}
          loading={loading}
          value={search}
          enterButton
          onSearch={async () => {
            await algoliaSearch();
            onSearchCompleted?.(search);
          }}
          onPressEnter={async () => {
            await algoliaSearch();
            onSearchCompleted?.(search);
          }}
          onChange={onChange}
          allowClear
        />
      </Input.Group>
      <div className={styles.displayAllOffersSwitchWrapper}>
        {renderSelect()}
        <Tooltip title={t('common.displayAllOffers')}>
          <Button
            type="dashed"
            size={'large'}
            className={styles.displayAllOffersButton}
            icon={displayAllOffers.current ? <MdDiscount /> : <MdOutlineDiscount />}
            onClick={() => handleToggleDisplayAllOffers(!displayAllOffers.current)}
          />
        </Tooltip>
        <Tooltip title={t('common.excludeNoStockOffers')}>
          <Button
            type="dashed"
            size={'large'}
            className={excludeNoStockOffers.current ? styles.excludeNoStockOffers : styles.displayAllOffersButton}
            icon={<InboxOutlined />}
            onClick={() => handleToggleExcludeNoStockOffers(!excludeNoStockOffers.current)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default ProductSearch;
