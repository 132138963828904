import { merge } from 'lodash';

export const filterColumns = <T>(columns: Array<T | false>): Array<T> => {
  return columns.filter((column) => !!column) as Array<T>;
};

export type TableWidthBuilderOptions = {
  throwDeplationError: boolean;
};

export type TableWidthBuilderReturn<T extends string> = {
  width: (column?: T) => string | undefined;
  getInputWidth: (column: T) => number;
  getColumnsInputWidth: (columns: Array<T>) => number;
  pickColumns: (columns: Array<T>) => Array<WidthColumn<T>>;
  total: number;
};

const defaultOptions: TableWidthBuilderOptions = {
  throwDeplationError: false,
};

export type WidthColumn<T> =
  | number
  | {
      width: number;
      column?: T;
      skip?: boolean;
    };

export const tableWidthBuilder = <K extends string>(
  columnsRatio: Array<WidthColumn<K>>,
  options: Partial<TableWidthBuilderOptions> = defaultOptions,
): TableWidthBuilderReturn<K> => {
  options = merge(defaultOptions, options);

  const getValue = (value: WidthColumn<K>): number => {
    if (typeof value === 'number') {
      return value;
    }

    return value.width;
  };

  const getPercentage = (value: WidthColumn<K>, totalValue: number): string => {
    if (typeof value === 'number') {
      return `${(value / totalValue) * 100}%`;
    }

    return `${(value.width / totalValue) * 100}%`;
  };

  const result: Array<{ column?: string; value: string }> = [];
  const total = columnsRatio.reduce((a, b) => getValue(a) + getValue(b), 0) as number;

  for (let i = columnsRatio.length - 1; i >= 0; i--) {
    const item = columnsRatio[i];

    if (typeof item === 'number') {
      result.push({ value: getPercentage(item, total) });
    } else if ((typeof item === 'object' && !item.skip) || (typeof item === 'object' && item.skip && item.column)) {
      result.push({ column: item.column, value: getPercentage(item, total) });
    }
  }

  const width = (column?: K): string | undefined => {
    if (result.length === 0) {
      if (options.throwDeplationError) {
        throw new Error('Depletion of the width. You must add a new column ratio.');
      }
    }

    if (!column) {
      const withoutColumns = result.filter((item) => !item.column);
      if (withoutColumns.length > 0) {
        const index = result.indexOf(withoutColumns.pop()!);
        if (index > -1) {
          const items = result.splice(index, 1);
          return items[0].value;
        }
      }
      return undefined;
    }

    const index = result.findIndex((item) => item.column === column);

    if (index > -1) {
      return result[index].value;
    }

    return undefined;
  };

  const getInputWidth = (column: K): number => {
    const found = columnsRatio.find((item) => {
      if (typeof item !== 'number' && column === item.column) {
        return true;
      }
      return false;
    });

    if (found && typeof found !== 'number') {
      return found.width;
    }

    return 0;
  };

  const getColumnsInputWidth = (columns: Array<K>): number => {
    return columns.reduce((acc, col) => {
      return acc + getInputWidth(col);
    }, 0);
  };

  const pickColumns = (columns: Array<K>): Array<WidthColumn<K>> => {
    return columnsRatio.filter((col) => {
      if (typeof col !== 'number' && col.column && columns.includes(col.column)) {
        return true;
      }
      return false;
    });
  };

  return {
    width,
    getInputWidth,
    getColumnsInputWidth,
    pickColumns,
    total,
  };
};
