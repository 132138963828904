import { unwrap } from 'utils/api.utils';
import { IGroupCustomer } from 'types/groups';
import { areGroupsAvailable } from 'store/customers/types';
import { CustomersApiService } from 'services/customersServiceApi';
import { ApiResponse } from 'apisauce';
import { ERRORS } from 'constants/errors';

interface FetchGroupCustomersStateAdapterProps {
  searchId: number;
  token: string;
}

export const fetchGroupCustomersStateAdapter = async ({
  searchId,
  token,
}: FetchGroupCustomersStateAdapterProps): Promise<ApiResponse<IGroupCustomer[], IGroupCustomer[]>> => {
  return CustomersApiService.getGroupCustomers(searchId, token);
};

export const fetchGroupCustomersSavedCartAdapter = fetchGroupCustomersStateAdapter;

export const fetchGroupCustomersAdapter = async (
  adapter: Promise<ApiResponse<IGroupCustomer[], IGroupCustomer[]>>,
): Promise<IGroupCustomer[]> => {
  return unwrap(await adapter, (response) => {
    if (!areGroupsAvailable(response)) {
      return ERRORS.customers.noGroups;
    }
  });
};
