import { ThemeSource } from 'types/theme';
import { config } from 'config';
import { Themes } from 'constants/theme';

export const getThemeName = (): Themes => {
  return config.theme;
};

export const createTheme = <T>(source: ThemeSource<T>): T => {
  const { theme } = config;

  if (Object.prototype.hasOwnProperty.call(source, theme)) {
    return source[theme] as T;
  }

  return source.base;
};
