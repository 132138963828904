import { useDispatch } from 'react-redux';
import React, { FC, useMemo } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import Button from '../Button';
import { useAppSelector } from '../../../store/store';
import { clearMultiSearchQueries, selectMultiSearchQueries } from '../../../store/products';

const ClearFileImportButton: FC = () => {
  const queries = useAppSelector(selectMultiSearchQueries);
  const uploaded = useMemo(() => queries.length > 0, [queries]);
  const dispatch = useDispatch();

  const clearFile = () => {
    dispatch(clearMultiSearchQueries());
  };
  return (
    <Button type="dashed" disabled={!uploaded} onClick={clearFile}>
      <ClearOutlined />
    </Button>
  );
};

export default ClearFileImportButton;
