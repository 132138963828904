export const forms_fr = {
  firstname: 'Nom du client',
  lastname: 'Prénom du client',
  createEmail: 'E-mail du client',
  chooseActivity: 'Activité',
  createPassword: 'Créer un mot de passe aléatorie',
  productSearchPlaceholder: 'Nom ou ID produit',
  customerNameOrNumber: 'Nom ou numéro client',
  gender: 'Genre',
  name: 'Nom',
  surname: 'Prénom',
  companyName: 'Raison sociale',
  address: 'Adresse',
  postalCode: 'Code postal',
  country: 'Pays',
  phoneNumber: 'Numéro de téléphone',
  postalCodePlaceholder: 'Insérer le code postal',
  chooseDeliveryMethodPlaceholder: 'Choisir la méthode de livraison',
};
