import { createTranslation } from 'utils/translation.utils';
import { createSavedCartState, isSavedCartGroupCustomer, productMessage } from 'utils/savedCart.utils';
import { createExportPDFVariables } from 'utils/pdf/utils';
import { successWithMessage } from 'utils/message.utils';
import { unwrap } from 'utils/api.utils';
import { createError, createObjectId, handleCatchError } from 'utils';
import { GetWiuzProductsResponse, IAlgoliaProduct } from 'types/product';
import { PDFVariables } from 'types/pdf';
import { IGroupCustomer } from 'types/groups';
import { ErrorType, ICustomer, IUser, PaginatedResponse } from 'types';
import store from 'store/store';
import {
  FetchAllResponse,
  FetchSavedCart,
  ISavedCartFilters,
  MarketplacePayload,
  RestoreSavedCartPayload,
  RestoreSavedCartStateResponse,
  SavedCartRestoredState,
  SavedCartSavedCustomersGroupState,
  SavedCartsResponse,
  SavedCartState,
  SavedCartUpdateData,
  SaveSavedCartResponse,
} from 'store/savedCart/types';
import { restoreSavedCartNormalizer } from 'store/savedCart/normalizers';
import { RootState } from 'store/rootReducer';
import { IFetchProductsResponse } from 'store/products/types';
import {
  fetchProductsSavedCartAdapter,
  fetchProductsWiuzAdapter,
  fetchRestoredProductsAdapter,
  fetchTownAdapter,
  fetchTownSavedCartAdapter,
} from 'store/products';
import { fetchGroupCustomersAdapter, fetchGroupCustomersSavedCartAdapter } from 'store/customers/adapters';
import { SAVED_CART_REDUCER_NAME } from 'store/common';
import { selectCartRequestId } from 'store/cartRequests';
import { SavedCartService } from 'services/savedCart.service';
import { ProductsService } from 'services/products.service';
import { cookie, ID_TOKEN } from 'cookies';
import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateProductItemMessage, SavedCartTable } from '../../types/savedCart';
import { CartService } from '../../services/cart.service';
import { DiscountType } from '../../components/shared/Discount/Discount.types';
import { getStepNumber, STEPPER } from 'constants/stepper';
import { ERRORS } from 'constants/errors';
import {
  fetchCartRequestFulfilledMatcher,
  fetchCartRequestPendingMatcher,
  fetchCartRequestRejectedMatcher,
  restoreSavedCartMatcher,
} from './matcher';

const trans = createTranslation('SavedCart');

const initialState: SavedCartState = {
  restore: {
    productErrors: {
      priceChanged: [],
      outOfStock: [],
      partialStock: [],
      noOfferFound: [],
      noProductFound: [],
      message: [],
      error: [],
    },
    deliveryErrors: [],
    showModal: false,
    expiredAt: null,
    expirationDays: 7,
    expirationHours: 0,
  },
  isLoading: false,
  isLoadingCount: false,
  error: null,
  filters: {
    createdEndDate: null,
    createdStartDate: null,
    search: null,
    expiredEndDate: null,
    expiredStartDate: null,
    archived: false,
    convertedPdf: true,
    atcCartNumber: null,
    status: null,
    customerIdentifier: null,
    assignedTcCode: null,
    assignedToMe: true,
  },
  page: 1,
  total: 0,
  limit: 0,
  skip: 0,
  data: [],
  states: {
    converted: 0,
    draft: 0,
    pending: 0,
    in_progress: 0,
    refused: 0,
    sent_to_customer: 0,
  },
};

const fetchSavedCartGroup = async (
  savedCartCustomers: SavedCartSavedCustomersGroupState,
): Promise<{
  groupCustomers: IGroupCustomer[];
}> => {
  const token = cookie.get(ID_TOKEN);
  const { selectedGroupCustomers = [] } = savedCartCustomers;

  const selectedGroupCustomer = selectedGroupCustomers.find((c) => c.isCustomerLead);

  if (!selectedGroupCustomer?.userId) {
    throw createError(trans(`The customer group no longer exists.`));
  }

  const [groupCustomers] = await Promise.all([
    (await fetchGroupCustomersAdapter(
      fetchGroupCustomersSavedCartAdapter({
        searchId: selectedGroupCustomer.userId,
        token,
      }),
    )) as unknown as PaginatedResponse<IGroupCustomer>,
  ]);

  return {
    groupCustomers: groupCustomers.data,
  };
};

const fetchSavedCartMultipleItems = async (
  savedCart: SavedCartRestoredState,
  tags?: string[],
  clubMemberExpDate?: Date,
): Promise<FetchAllResponse> => {
  const {
    cart: { products: cartProducts },
    wiuz,
  } = savedCart;

  const wiuzUploadedFilesPromise = wiuz?.selectedFile && ProductsService.getWiuzUploadedFiles();

  const fetchedTown = await fetchTownAdapter(fetchTownSavedCartAdapter({ townId: savedCart.town.id }));

  const productsToFetch = cartProducts.filter((c) => !c.isPlainProduct);

  const [fetchedProducts, wiuzFilesResponse] = await Promise.all([
    fetchRestoredProductsAdapter(
      fetchProductsSavedCartAdapter({
        productIds: productsToFetch.map((item) => item.productId),
        variantIds: productsToFetch.map((item) => item.variantId),
      }),
      savedCart.town.postcode || fetchedTown.postcode,
      tags,
      true,
      clubMemberExpDate,
    ),
    wiuzUploadedFilesPromise,
  ]);

  productsToFetch.forEach((product) => {
    const foundProduct = fetchedProducts.products.find((p) => p.productId === product.productId);
    if (!foundProduct) {
      fetchedProducts.products.push({
        ...product,
        cartRequestItemId: null,
        offerId: product.variantId,
        priceTotalWithTaxes: 0,
        totalPrice: 0,
        cvo: 0,
        rpd: 0,
        weight: 0,
        isTownRequired: false,
        availableSelectedQuantity: product.quantity,
        availableQuantity: product.quantity,
        noStock: false,
        enabled: true,
        wiuz: null,
        label: null,
        discountType: DiscountType.PERCENTAGE,
        discountValue: 0,
        supplierPrice: null,
        attachments: [],
        cartProductId: createObjectId(),
      } as unknown as IAlgoliaProduct);
      store.dispatch(
        addProductError({
          type: 'noProductFound',
          error: productMessage(product, trans('The product cannot be found')),
        }),
      );
    }

    const offers = fetchedProducts?.offers?.Product?.flatMap((p) => p?.Variants)
      .flatMap((v) => v?.OfferPrice)
      .find((o) => o.OfferId === product.offerId);

    if (!offers) {
      store.dispatch(
        addProductError({
          type: 'noOfferFound',
          error: productMessage(product, trans('The offer has expired')),
        }),
      );
    }
  });

  const wiuzFiles = wiuzFilesResponse ? unwrap(wiuzFilesResponse).data : [];
  const wiuzSelectedFile = wiuzFiles.find((item) => item.id === wiuz?.selectedFile?.id);

  let wiuzFile: GetWiuzProductsResponse | null = null;
  let algoliaProducts: IFetchProductsResponse | null = null;
  if (wiuzSelectedFile) {
    const { id: importId } = wiuzSelectedFile;
    wiuzFile = unwrap(await ProductsService.getWiuzProductsById(importId));
    const productIds = wiuzFile.rows.map((row) => row.product?.productId ?? undefined).filter((id) => !!id);

    if (productIds.length > 0) {
      algoliaProducts = await fetchRestoredProductsAdapter(
        fetchProductsWiuzAdapter({ productIds, page: 0 }),
        fetchedTown.postcode,
        tags,
      );
    }
  }

  return {
    town: fetchedTown,
    products: fetchedProducts,
    wiuz: {
      files: wiuzFiles,
      file: wiuzFile,
      algoliaProducts,
    },
    users: {} as unknown as IUser,
  };
};

const fetchSavedCart = async (savedCart: SavedCartRestoredState): Promise<FetchSavedCart> => {
  const { customers: savedCartCustomers, userTags } = savedCart;

  if (isSavedCartGroupCustomer(savedCartCustomers)) {
    const { groupCustomers } = await fetchSavedCartGroup(savedCartCustomers);
    const { selectedGroupId } = savedCartCustomers;

    const selectedGroup = groupCustomers.find((g) => g.id === selectedGroupId);

    if (!selectedGroup) {
      throw createError(trans(`The customer group no longer exists.`));
    }

    const {
      town: townGroup,
      products: productsGroup,
      wiuz: wiuzResult,
    } = await fetchSavedCartMultipleItems(
      savedCart,
      userTags ? selectedGroup.groupTags?.concat(userTags) : selectedGroup.groupTags,
    );

    return {
      __typename: 'FetchSavedCartGroupCustomer',
      groupCustomers,
      town: townGroup,
      products: productsGroup,
      wiuz: wiuzResult,
      users: null,
      userTags,
    };
  }

  const selectedCustomer = savedCartCustomers.selectedSearchedCustomer as ICustomer;

  const {
    town,
    products,
    wiuz: wiuzResult,
  } = await fetchSavedCartMultipleItems(
    savedCart,
    userTags ? selectedCustomer.tags?.concat(userTags) : selectedCustomer.tags,
    selectedCustomer.user.clubMemberExpDate,
  );

  return {
    __typename: 'FetchSavedCartSingleCustomer',
    customers: [selectedCustomer],
    town,
    products,
    wiuz: wiuzResult,
    users: selectedCustomer.user as unknown as IUser,
    userTags,
  };
};

export const saveSavedCart: AsyncThunk<
  SaveSavedCartResponse,
  {
    stepNumber: number;
    savePdfVariables?: boolean;
    allowEmptySave?: boolean;
    notifyOnSuccess?: boolean;
    attachPayload?: boolean;
  },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${SAVED_CART_REDUCER_NAME}/saveSavedCart`,
  async (
    {
      stepNumber,
      savePdfVariables = false,
      allowEmptySave = false,
      notifyOnSuccess = true,
      attachPayload = false,
    }: {
      stepNumber: number;
      savePdfVariables?: boolean;
      allowEmptySave?: boolean;
      notifyOnSuccess?: boolean;
      attachPayload?: boolean;
    },
    { rejectWithValue, getState },
  ) => {
    const state = getState();
    const cartRequestId = selectCartRequestId(state);

    try {
      allowEmptySave = allowEmptySave || !!cartRequestId;
      const savedCartState = createSavedCartState(state, { stepNumber, allowEmptySave });
      let pdfVariables: PDFVariables | undefined;

      if (savePdfVariables && stepNumber === getStepNumber(STEPPER.FOURTH)) {
        pdfVariables = createExportPDFVariables(state);
      }

      const { cartId, isSavedCart } = state.cart;

      const {
        cart: { id, cart },
        marketplacePayload,
      } = unwrap(
        await SavedCartService.save(
          {
            ...savedCartState,
            pdfVariables,
            cartRequestId,
            attachPayload,
          },
          cartId,
          isSavedCart,
        ),
      );

      if (cartRequestId) {
        unwrap(
          await CartService.patchCartRequest(cartRequestId, {
            atcCheckoutCartId: id,
          }),
        );
      }

      if (notifyOnSuccess) {
        return successWithMessage<SaveSavedCartResponse>(trans('The cart have been saved.'), {
          cart: { id, cartId: id, cart },
          marketplacePayload,
        });
      }

      return {
        cart: {
          id,
          cartId: id,
          cart,
        },
      };
    } catch (err) {
      console.error(err);
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const createMarketplace: AsyncThunk<
  unknown,
  { marketplacePayload: MarketplacePayload },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${SAVED_CART_REDUCER_NAME}/createMarketplace`,
  async ({ marketplacePayload }: { marketplacePayload: MarketplacePayload }, { rejectWithValue }) => {
    try {
      return unwrap(await CartService.createMarketplace(marketplacePayload));
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const restoreSavedCart: AsyncThunk<
  RestoreSavedCartStateResponse,
  SavedCartRestoredState,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${SAVED_CART_REDUCER_NAME}/restoreSavedCart`,
  async (savedCart, { rejectWithValue, getState }) => {
    try {
      const savedCartWithTags = { ...savedCart, userTags: getState().user.tags };
      const data = await fetchSavedCart(savedCartWithTags);
      return await restoreSavedCartNormalizer({
        savedCart: savedCartWithTags,
        data,
      });
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const getSavedCarts: AsyncThunk<
  SavedCartsResponse,
  { skip: number; limit: number; filters?: ISavedCartFilters },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${SAVED_CART_REDUCER_NAME}/getSavedCarts`,
  async ({ skip, limit, filters }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      return unwrap(await SavedCartService.getPage(skip, limit, filters, state.user.tcCode), (response) => {
        if (!response.data) {
          return ERRORS.savedCart.noSavedCarts;
        }
      });
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const getSavedCartStatusCount: AsyncThunk<
  SavedCartsResponse,
  { skip: number; limit: number; filters?: ISavedCartFilters },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${SAVED_CART_REDUCER_NAME}/getSavedCartStatusCount`,
  async ({ skip, limit, filters }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      return unwrap(await SavedCartService.getStatusCount(skip, limit, filters, state.user.tcCode));
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const updateSavedCart: AsyncThunk<
  SaveSavedCartResponse,
  { id: string; data: Partial<SavedCartUpdateData> },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${SAVED_CART_REDUCER_NAME}/updateSavedCart`, async ({ id, data }, { rejectWithValue }) => {
  try {
    return unwrap(await SavedCartService.update(id, data));
  } catch (err) {
    return handleCatchError(err, rejectWithValue, true);
  }
});

export const deleteSavedCart: AsyncThunk<
  RestoreSavedCartStateResponse,
  RestoreSavedCartPayload,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${SAVED_CART_REDUCER_NAME}/deleteSavedCart`, async ({ id, permanent }, { rejectWithValue }) => {
  try {
    return unwrap(await SavedCartService.delete(id, { permanent }));
  } catch (err) {
    return handleCatchError(err, rejectWithValue, true);
  }
});

export const assignToCart: AsyncThunk<
  SavedCartTable,
  { cartId: string; tcCode: string | null },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${SAVED_CART_REDUCER_NAME}/assignToCart`, async ({ cartId, tcCode }, { rejectWithValue }) => {
  try {
    return unwrap(await SavedCartService.assignToCart(cartId, tcCode));
  } catch (err) {
    return handleCatchError(err, rejectWithValue, true);
  }
});

export const cloneCart: AsyncThunk<{ id: string }, { cartId: string }, { rejectValue: ErrorType; state: RootState }> =
  createAsyncThunk(`${SAVED_CART_REDUCER_NAME}/cloneCart`, async ({ cartId }, { rejectWithValue }) => {
    try {
      return unwrap(await SavedCartService.cloneCart(cartId));
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  });

const savedCartSlice = createSlice({
  name: SAVED_CART_REDUCER_NAME,
  initialState,
  reducers: {
    clearSavedCartState() {
      return { ...initialState };
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setPagination(state, { payload }) {
      state.page = payload.page ?? 1;
      state.limit = payload.size;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    showRestoreModal(state, { payload }: PayloadAction<boolean>) {
      state.restore.showModal = payload;
    },
    addProductError(
      state,
      {
        payload,
      }: PayloadAction<{
        type: keyof SavedCartState['restore']['productErrors'];
        error: CreateProductItemMessage;
      }>,
    ) {
      const { error, type } = payload;
      const { productId, variantId } = error?.product ?? {};

      if (
        !Object.values(state.restore.productErrors)
          .flat()
          .some((e) => e?.product?.productId === productId && e?.product?.variantId === variantId)
      ) {
        state.restore.productErrors[type].push(error);
        state.restore.showModal = true;
      }
    },
    clearProductError(state) {
      state.restore.productErrors = initialState.restore.productErrors;
    },
    setFilters(state, { payload }: PayloadAction<Partial<ISavedCartFilters>>) {
      state.page = 1;
      if (Object.keys(payload).length > 0) {
        state.filters = payload;
      } else {
        state.filters = { ...initialState.filters };
      }
    },
    updateFilters(state, { payload }: PayloadAction<Partial<ISavedCartFilters>>) {
      state.page = 1;
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    clearFilters(state) {
      state.filters = { ...initialState.filters };
    },
    setExpiredAt(state, { payload }: PayloadAction<string | null>) {
      state.restore.expiredAt = payload;
    },
    setExpirationDays(state, { payload }: PayloadAction<number | null>) {
      state.restore.expirationDays = payload;
    },
    setExpirationHours(state, { payload }: PayloadAction<number | null>) {
      state.restore.expirationHours = payload;
    },
    setTcDataToCart(state, { payload }: PayloadAction<{ tcCode: string; tcName?: string; cartId: string }>) {
      state.data = state.data.map((cart) => {
        if (cart.id === payload.cartId) {
          cart.assignedTcCode = payload.tcCode;
          cart.assignedTcName = payload?.tcName ?? '';
        }
        return cart;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSavedCarts.fulfilled, (state, { payload }) => {
      const { limit, skip, total, data } = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        limit,
        skip,
        total,
        data,
      };
    });
    builder.addCase(getSavedCartStatusCount.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoadingCount: false,
        states: payload,
      };
    });
    builder.addCase(saveSavedCart.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });
    builder.addCase(saveSavedCart.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });
    builder.addCase(createMarketplace.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });
    builder.addCase(saveSavedCart.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getSavedCarts.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: [],
      };
    });
    builder.addCase(getSavedCartStatusCount.rejected, (state) => {
      return {
        ...state,
        isLoadingCount: false,
      };
    });
    builder.addCase(getSavedCarts.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });
    builder.addCase(getSavedCartStatusCount.pending, (state) => {
      return {
        ...state,
        isLoadingCount: true,
      };
    });
    builder.addCase(assignToCart.fulfilled, (state, { payload }) => {
      const cartIndex = state.data?.findIndex((c) => c.id === payload.id);

      if (cartIndex >= 0) {
        state.data[cartIndex].assignedTcCode = payload.assignedTcCode;
        state.data[cartIndex].assignedTcName = payload.assignedTcName;
      }

      return state;
    });
    builder.addMatcher(
      restoreSavedCartMatcher(),
      (state, { payload }: PayloadAction<RestoreSavedCartStateResponse>) => {
        state.restore.expiredAt = payload.expiredAt;
        state.restore.expirationDays = payload.expirationDays;
        state.restore.expirationHours = payload.expirationHours;
      },
    );
    builder.addMatcher(fetchCartRequestPendingMatcher(), (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addMatcher(fetchCartRequestRejectedMatcher(), (state) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addMatcher(fetchCartRequestFulfilledMatcher(), (state) => {
      state.isLoading = false;
      state.error = null;
    });
  },
});

export const {
  clearSavedCartState,
  setPagination,
  setIsLoading,
  setError,
  showRestoreModal,
  setPage,
  setFilters,
  updateFilters,
  clearFilters,
  setExpiredAt,
  setExpirationDays,
  setExpirationHours,
  addProductError,
  clearProductError,
} = savedCartSlice.actions;
export default savedCartSlice.reducer;
