import { useDispatch, useSelector } from 'react-redux';
import { MdDiscount, MdOutlineDiscount } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { Modal, Space, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styles from '../ProductPageHeader/components/ProductSearch/styles.module.scss';
import { Button } from '../../shared';
import {
  selectReplacementModalDisplayAllOffers,
  selectReplacementModalSku,
  selectReplacementModalVisible,
  setReplacementModalDisplayAllOffers,
  setReplacementModalProductIndex,
  setReplacementModalQuantity,
  setReplacementModalSku,
  setReplacementModalVisible,
} from '../../../store/products';
import ReplacementTable from './components/ReplacementTable';

const ReplacementModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const visible = useSelector(selectReplacementModalVisible);
  const sku = useSelector(selectReplacementModalSku);
  const displayAllOffers = useSelector(selectReplacementModalDisplayAllOffers);
  const [excludeNoStockOffers, setExcludeNoStockOffers] = useState(false);

  const onClose = () => {
    dispatch(setReplacementModalVisible(false));
    dispatch(setReplacementModalSku(null));
    dispatch(setReplacementModalQuantity(0));
    dispatch(setReplacementModalProductIndex(null));
    dispatch(setReplacementModalDisplayAllOffers(true));
  };

  const toggleDisplayAllOffers = () => dispatch(setReplacementModalDisplayAllOffers(!displayAllOffers));
  const toggleExcludeNoStockOffers = () => setExcludeNoStockOffers(!excludeNoStockOffers);

  const renderDiscountIcon = useCallback(() => {
    return displayAllOffers ? <MdDiscount /> : <MdOutlineDiscount />;
  }, [displayAllOffers]);

  return (
    <Modal
      title={
        <Space>
          {`${t('cart.replaceOffer')} ${t('common.code')} ${sku}`}
          <Tooltip title={t('common.displayAllOffers')}>
            <Button
              className={styles.button}
              type="dashed"
              size="large"
              icon={renderDiscountIcon()}
              onClick={toggleDisplayAllOffers}
            />
          </Tooltip>
          <Tooltip title={t('common.excludeNoStockOffers')}>
            <Button
              type="dashed"
              className={`${styles.button} ${excludeNoStockOffers ? styles.excludeNoStockOffers : ''}`}
              size="large"
              icon={<InboxOutlined />}
              onClick={toggleExcludeNoStockOffers}
            />
          </Tooltip>
        </Space>
      }
      width={1150}
      footer={null}
      open={visible}
      destroyOnClose
      onCancel={onClose}
    >
      <ReplacementTable excludeNoStockOffers={excludeNoStockOffers} />
    </Modal>
  );
};

export default ReplacementModal;
