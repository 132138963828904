import React from 'react';
import { Spin, SpinProps } from 'antd';
import styles from './SpinWrap.module.scss';

type SpinWrapProps = SpinProps & {
  children: React.ReactNode;
  loading: boolean;
};

const SpinWrap: React.FC<SpinWrapProps> = ({ children, loading, ...rest }: SpinWrapProps) => {
  if (loading) {
    return (
      <Spin wrapperClassName={styles.container} {...rest}>
        {children}
      </Spin>
    );
  }
  return <>{children}</>;
};

export default SpinWrap;
