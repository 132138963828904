import { selectCartProductsCount } from 'store/cart';
import { useSelector } from 'react-redux';
import { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'components/shared';
import { CART_HEADER_ID } from 'components/product/CartHeader/CartHeader';
import { MAIN_LAYOUT_ID } from 'components/layout/MainLayout/MainLayout';
import { Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const CartButton: FC = () => {
  const cartProductsCount = useSelector(selectCartProductsCount);
  const [mainLayoutRef, setMainLayoutRef] = useState<HTMLElement | null>(null);
  const cartHeaderRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const el = document.getElementById(MAIN_LAYOUT_ID);
    cartHeaderRef.current = document.getElementById(CART_HEADER_ID) || null;
    setMainLayoutRef(el);
  }, []);

  const handleScrollToView = () => {
    if (mainLayoutRef && cartHeaderRef.current) {
      const offset = 160;
      const cartHeaderTop = Math.abs(Math.ceil(cartHeaderRef.current.getBoundingClientRect().top)) - offset;
      mainLayoutRef.scrollTop = cartHeaderTop + mainLayoutRef.scrollTop;
    }
  };

  return (
    <Badge count={cartProductsCount}>
      <Button
        className={styles.button}
        onClick={handleScrollToView}
        type="default"
        size="large"
        icon={<ShoppingCartOutlined />}
      />
    </Badge>
  );
};

export default CartButton;
