import { selectAreCartItemsExpanded, setCartItemsExpanded, selectIsAnyProductSelected } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';
import ADButton from 'antd/lib/button';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const ExpandAllProducts: FC = () => {
  const areCartItemsExpanded = useSelector(selectAreCartItemsExpanded);
  const isCartEmpty = useSelector(selectIsAnyProductSelected);

  const dispatch = useDispatch();

  return (
    <ADButton
      icon={areCartItemsExpanded ? <DownOutlined /> : <UpOutlined />}
      shape="circle"
      disabled={!isCartEmpty}
      size="large"
      onClick={() => dispatch(setCartItemsExpanded(!areCartItemsExpanded))}
      className={`${styles.button} ${styles.button_switch}`}
    />
  );
};

export default ExpandAllProducts;
