export const saved_cart_fr = {
  savedCarts: 'Devis',
  atcCartNumber: 'Devis N°',
  clientInfo: 'Données client',
  nameSurname: 'Nom, Prénom',
  creationDate: 'Date de création',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  expiryDate: "Date d'expiration",
  step: 'Etape',
  conversionDate: 'Date de conversion',
  includeArchivedCarts: 'Inclure les devis archivés',
  includeOrderCarts: 'Inclure les devis convertis en commande ',
  in7Days: 'Dans 7 jours',
  in3Days: 'Dans 3 jours',
  details: 'Détails',
  restore: 'Restituer',
  archive: 'Archiver',
  clone: 'Cloner',
  deleteFilters: 'Effacer les filtres',
  filterResults: 'Filtrer les résultats',
  client: 'Client',
  netTotal: 'Montant HT',
  downloadPdf: 'Export PDF',
  unarchive: 'Désarchiver',
  productInfo: 'Produit Info',
  sku: 'SKU',
  qty: 'Qté',
  up: 'PU',
  total: 'Montant TTC',
  code: 'Code',
  status: 'Statut',
  state: 'Etat',
  tableColumnCreationDate: 'Créé le',
  thirdPartyNumber: 'N° Tiers',
  orderNumber: 'N° Devis',
  restoreNotAllowed: 'Pas de permissions pour restituer le devis.',
  archiveNotAllowed: 'Pas de permissions pour archiver le devis.',
  assign: 'Assigner',
  assignedTo: 'Assigné à {{assignedTcName}} ({{assignedTcCode}})',
  assignTcMessage: 'Assigner un TC',
  includeOnlyMine: 'Afficher seulement mes devis',
  cloneModalConfirmationTitle: 'Etes-vous sur de vouloir cloner le panier ?',
  restoreModalPriceChangeSingular: 'Le produit suivant a été mis à jour, car le prix a changé :',
  restoreModalPriceChangePlural: 'Les produits suivants ont été mis à jour, car le prix a changé :',
  restoreModalPartialStockSingular: "Le produit suivant n'est pas disponible, à cause, du manque de stock :",
  restoreModalPartialStockPlural: 'Les produits suivants ne sont pas disponibles, à cause, du manque de stock :',
  restoreModalOutOfStockSingular: "Le produit suivant n'est pas disponible, à cause, d'une rupture de stock :",
  restoreModalOutOfStockPlural: "Les produits suivants ne sont pas disponibles, à cause, d'une rupture de stock :",
  restoreModalNoProductFoundSingular: "Le produit suivant n'est pas disponible, car ils n'existent plus :",
  restoreModalNoProductFoundPlural: "Les produits suivants ne sont pas disponibles, car ils n'existent plus :",
  restoreModalNoOfferFoundSingular:
    "L'offre suivant n'est plus disponible car elle est périmée. Veuillez sélectionner une nouvelle offre grâce au bouton ",
  restoreModalNoOfferFoundPlural:
    'Les offres suivantes ne sont plus disponibles car elles sont périmées. Veuillez sélectionner une nouvelle offre grâce au bouton ',
};
