import { RestServiceApi } from 'services';
import { getToken } from 'cookies';
import { config } from 'config';
import { ApiResponse } from 'apisauce';
import { IDeparture } from '../store/products/types';

const departureBackendApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class DepartureServiceApi {
  public async getDepartures(): Promise<ApiResponse<IDeparture>> {
    return departureBackendApi.get(
      `/departures`,
      {},
      {
        ...departureBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }
}

export const DepartureService = new DepartureServiceApi();
