import { useAppDispatch, useAppSelector } from 'store/store';
import { fetchGroupCustomersWhitelist, selectGroupWhitelist, setGroupWhitelistPage } from 'store/customers';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { useToken } from 'cookies';
import env from 'config';
import { Text } from 'components/shared';
import { Spin, Pagination } from 'antd';
import { GroupRow } from './components';
import { GroupListProps } from './GroupList.types';
import styles from './ClientsList.module.scss';

const GroupList: React.FC<GroupListProps> = (props: GroupListProps) => {
  const { pageSize: limit = 50 } = props;
  const dispatch = useAppDispatch();
  const { token } = useToken();
  const { t } = useTranslation();
  const paginationRef = useRef<HTMLDivElement>(null);

  const { isLoading, search, page, total, data: groupWhitelist } = useAppSelector(selectGroupWhitelist);
  const pageChange = useRef<boolean>(false);

  useEffect(() => {
    const skip = (page - 1) * limit;
    dispatch(fetchGroupCustomersWhitelist({ token, skip, limit, search })).then(() => {
      if (pageChange.current === true) {
        pageChange.current = false;
        paginationRef.current?.scrollIntoView(true);
      }
    });
  }, [dispatch, page, limit, token, search]);

  const handlePageChange = useCallback(
    (nextPage) => {
      dispatch(setGroupWhitelistPage(nextPage));
      pageChange.current = true;
    },
    [dispatch],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        {isLoading && (
          <div className={styles.loading}>
            <Spin />
          </div>
        )}
        <div>
          {!isLoading && groupWhitelist.length === 0 && (
            <div className={styles.emptyList}>
              <Text type="subtitle">{t('common.emptyCustomerList', { context: env('theme') })}</Text>
            </div>
          )}
          {groupWhitelist.length > 0 && (
            <div>
              {groupWhitelist.map((group) => (
                <GroupRow key={group.id} group={group} />
              ))}
            </div>
          )}
        </div>
      </div>

      <div ref={paginationRef}>
        <Pagination
          defaultCurrent={page}
          current={page}
          total={total}
          pageSize={limit}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default GroupList;
