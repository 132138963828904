export const delay = (value?: number | boolean): { delay: number } | boolean => {
  const defaultValue = 300;

  if (typeof value === 'undefined') {
    value = defaultValue;
  }

  if (typeof value === 'boolean') {
    if (value) {
      return { delay: defaultValue };
    }
  }

  if (typeof value === 'number') {
    return { delay: value };
  }

  return false;
};
