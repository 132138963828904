import { unwrap } from 'utils/api.utils';
import { sendMessage } from 'store/messages';
import { NavMenuService } from 'services';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState, lazy, ReactNode, Suspense, createElement } from 'react';
import { FileFilled } from '@ant-design/icons';
import { selectUser, selectUsername } from '../store/user';
import { useAppDispatch, useAppSelector } from '../store/store';
import { selectLanguage, selectMenu, setMenu } from '../store/settings';
import { IMenuItem } from '../components/layout/NavigationBar/Components/NavBarItems/types';

export const useMenu = (): { menuItems: Array<IMenuItem>; isLoadingNavMenu: boolean } => {
  const lang = useAppSelector(selectLanguage);
  const user = useAppSelector(selectUser);
  const username = useAppSelector(selectUsername);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoadingNavMenu, setIsLoadingNavMenu] = useState(false);
  const navMenu = useAppSelector(selectMenu);

  const fetchNavMenu = useCallback(async () => {
    if (navMenu.length !== 0 || !username) return;
    try {
      setIsLoadingNavMenu(true);
      const menu = unwrap(await NavMenuService.getNavMenu());

      const filteredByRole = menu.filter((item) => {
        if (!item?.availableRoles) {
          return true;
        }

        return item.availableRoles.some((role) => (user?.groups || []).includes(role));
      });

      dispatch(setMenu(filteredByRole));
    } catch (e) {
      dispatch(sendMessage({ message: t('common.navMenuError'), type: 'error' }));
      dispatch(setMenu([]));
    } finally {
      setIsLoadingNavMenu(false);
    }
  }, [dispatch, navMenu.length, t, user?.groups, username]);

  useEffect(() => {
    fetchNavMenu();
  }, [fetchNavMenu]);

  const menuItems = useMemo((): Array<IMenuItem> => {
    return navMenu.map(({ key, label, path, iconClass }): IMenuItem => {
      let route = path;
      let text = 'unknown';
      let icon: ReactNode | null;

      if (Object.prototype.hasOwnProperty.call(label, lang)) {
        text = label[lang];
      }

      if (key === 'profile') {
        route = path.replace(/:id/, user.Username || username);
      }

      if (iconClass) {
        const lazyIcon = lazy(() =>
          import(`@ant-design/icons/es/icons/${iconClass}`).catch(() => ({ default: FileFilled })),
        );
        icon = <Suspense fallback={<FileFilled />}>{createElement(lazyIcon)}</Suspense>;
      }

      return {
        key,
        icon,
        text,
        route,
      };
    });
  }, [lang, navMenu, username, user.Username]);

  return { isLoadingNavMenu, menuItems };
};
