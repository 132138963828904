import { useIsMobile } from 'utils/styles.utils';
import { ErrorType } from 'types';
import { sendMessage } from 'store/messages';
import {
  selectPointListsShippingCategories,
  selectShippingAddress,
  selectShippingCategoriesByAddress,
  selectShippingAddressesError,
  selectDeliveryCustomError,
  clearCustomError,
} from 'store/delivery';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';

import env from 'config';
import { CartComments } from 'components/shared';
import Stepper from 'components/layout/Stepper';
import {
  ChangeAddressCart,
  DeliveryHeader,
  DeliverySummary,
  SidebarContainer,
  DeliveryCategory,
  ChangePostalCodeContainer,
  DeliveryFormChange,
} from 'components/delivery';
import { Layout } from 'antd';
import { usePageBlocker } from '../../hooks/usePageBlocker';
import styles from './Deliveries.module.scss';

const Deliveries: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isError = useSelector(selectShippingAddressesError);
  const customError = useSelector(selectDeliveryCustomError);
  const shippingAddress = useSelector(selectShippingAddress);
  const shippingCategoriesByAddress = useSelector(selectShippingCategoriesByAddress);
  const pointListsShippingCategories = useSelector(selectPointListsShippingCategories);
  const { blocker, confirmationModal } = usePageBlocker();

  useEffect(() => {
    if (isError && shippingAddress) {
      isError?.code === 18
        ? dispatch(sendMessage({ message: isError?.message, type: 'warning' }))
        : dispatch(sendMessage({ message: (isError as ErrorType).message, type: 'error' }));
    }
  }, [dispatch, shippingAddress, isError]);

  useEffect(() => {
    if (customError) {
      Object.entries(customError).forEach(([errName, details]) => {
        dispatch(
          sendMessage({
            message: t(`errors.${errName}`, { ids: details.map((d) => d.itemOfferId).join(', ') }),
            type: 'error',
          }),
        );
      });
      dispatch(clearCustomError());
    }
  }, [customError, dispatch, t]);

  const hasAnyPointListCategoryItem = pointListsShippingCategories.some((category) => category.itemList?.length);

  return (
    <>
      <DeliveryHeader />
      <DeliveryFormChange />
      <Layout.Content className={styles.container}>
        {pointListsShippingCategories.map(
          (shippingCategory) =>
            shippingCategory.itemList?.length && (
              <ChangePostalCodeContainer key={shippingCategory.shippingMethodId} {...shippingCategory} />
            ),
        )}
        <SidebarContainer
          sidebar={
            <>
              <ChangeAddressCart />
              <DeliverySummary />
            </>
          }
          disableSeparator={!hasAnyPointListCategoryItem}
        >
          {shippingCategoriesByAddress.map((shippingCategory) => (
            <DeliveryCategory key={shippingCategory.shippingMethodId} {...shippingCategory} />
          ))}
          {!!env('enableCartComments') && (
            <div className={styles.comment}>
              <CartComments />
            </div>
          )}
        </SidebarContainer>
      </Layout.Content>
      {blocker ? confirmationModal : null}
      {!isMobile && <Stepper />}
    </>
  );
};

export default Deliveries;
