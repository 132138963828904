import { sendMessage } from 'store/messages';
import { ICategorizedShippingItems } from 'store/delivery/types';
import { selectPointListByShippingMethodId, setPointListIdForCategory } from 'store/delivery';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useState } from 'react';
import ChronopostImage from 'assets/images/chronopost.png';
import { Image } from 'antd';
import SidebarContainer from '../SidebarContainer';
import DeliveryCardItem from '../DeliveryMethodsCard/DeliveryMethodsCard';
import DeliveryCategory from '../DeliveryCategory';
import PostalCodeModal from './components/PostalCodeModal';
import AddressContent from './components/AddressContent';
import styles from './ChangePostalCodeContainer.module.scss';

const ChangePostalCodeContainer: FC<ICategorizedShippingItems> = (category: ICategorizedShippingItems) => {
  const { pointList, shippingMethodId } = category;
  const dispatch = useDispatch();
  const pointListItem = useSelector(selectPointListByShippingMethodId(shippingMethodId));
  const [isDeliveryModalVisible, setIsDeliveryModalVisible] = useState(!pointListItem);

  const onCloseModal = () => {
    if (pointListItem) {
      setIsDeliveryModalVisible(false);
    } else {
      dispatch(sendMessage({ message: 'Veuillez sélectionner une adresse de code postal', type: 'error' }));
    }
  };

  const onPickAddress = (pointListId: string) => {
    dispatch(setPointListIdForCategory({ shippingMethodId, pointListId }));
    setIsDeliveryModalVisible(false);
  };

  return (
    <SidebarContainer
      sidebar={
        <>
          <DeliveryCardItem
            title={`Votre point relais`}
            buttonText={`Changer de point relais`}
            onClick={() => setIsDeliveryModalVisible(true)}
          >
            {pointListItem && (
              <div className={styles.container}>
                <Image className={styles.img} preview={false} alt="Chronopost Image" src={ChronopostImage} />{' '}
                <AddressContent {...pointListItem} />
              </div>
            )}
          </DeliveryCardItem>
          <PostalCodeModal
            selectedId={pointListItem?.id}
            pointsList={pointList}
            onClose={onCloseModal}
            onPickAddress={onPickAddress}
            visible={isDeliveryModalVisible}
          />
        </>
      }
    >
      <DeliveryCategory {...category} />
    </SidebarContainer>
  );
};

export default ChangePostalCodeContainer;
