import { calcTotalWithoutTaxes, calcTotalWithTaxes, createCartTotalAccumulator } from 'utils/cart.utils';
import { IStoreShippingItem } from 'types/shippingFees';
import { IOrder } from 'types/order';
import { INormalizedList, IShippingAddresses, ITown } from 'types';
import {
  ICategorizedShippingItems,
  IDeliveryState,
  ISelectCategoriesLabels,
  ISelectDeliveryPricesSummaryResponse,
} from 'store/delivery/types';
import { INormalizedGroupAddressList } from 'store/customers/types';
import { ICartProductItem, ICartState } from 'store/cart/types';
import moment from 'moment';

export type ShippingAddressesCustomersProps = {
  isGroup: boolean;
  selectedGroupId?: number;
  groupAddress?: INormalizedGroupAddressList;
};

type ShippingAddressesProps = {
  customers: ShippingAddressesCustomersProps;
  town: ITown | null;
  shippingAddresses: IDeliveryState['shippingAddresses'];
};

export const getShippingAddressById = (
  shippingAddressId: number,
  props: ShippingAddressesProps,
): IShippingAddresses | undefined => {
  const addresses = getShippingAddresses(props);
  return addresses.find((address) => address.id === shippingAddressId);
};

export const getShippingAddresses = ({
  customers,
  town,
  shippingAddresses,
}: ShippingAddressesProps): Array<IShippingAddresses> => {
  const { isGroup, selectedGroupId: groupId, groupAddress } = customers;
  const addresses = isGroup && groupAddress ? groupAddress[groupId || 0] : shippingAddresses;

  if (town) {
    return [...addresses].sort((firstAddress) => {
      if (firstAddress.townId === town.id) {
        return -1;
      }
      return 0;
    });
  }
  return addresses;
};

export const getParsedCartShippingItems = (products: ICartState['products']): Array<IStoreShippingItem> => {
  return Object.values(products).map((product) => ({
    offerId: product.offerId,
    quantity: product.quantity,
    unitPrice: product.offerPrice,
    weight: product.weight,
  }));
};

export const getDeliverySummary = ({
  products,
  shippingCategories,
}: {
  products: Array<ICartProductItem>;
  shippingCategories: INormalizedList<ICategorizedShippingItems>;
}): ISelectDeliveryPricesSummaryResponse => {
  const shippingCategoriesPrices = Object.values(shippingCategories).map((value) => {
    const smallestTva = value.products.reduce(
      (acc, product) => (acc < product.taxes.vat ? acc : product.taxes.vat),
      Infinity,
    );

    return {
      id: value.shippingMethodId,
      label: value.label,
      price: value.price || 0,
      hasItems: !!value.itemList?.length,
      tva: smallestTva === Infinity ? undefined : smallestTva,
    };
  });

  const cartTotal = createCartTotalAccumulator(products);

  const totalCvo = products.reduce((acc, product) => acc + (product.cvo || 0), 0);
  const totalProductsPrice = calcTotalWithoutTaxes(cartTotal) - totalCvo;
  const totalProductsPriceWithTaxes = calcTotalWithTaxes(cartTotal);
  const totalShippingFees = shippingCategoriesPrices.reduce((acc, product) => acc + product.price, 0);
  const totalProductsPriceWithFees = totalShippingFees + totalProductsPrice;
  const totalProductsPriceWithFeesAndTaxes = shippingCategoriesPrices.reduce((acc, product) => {
    const { price = 0 } = shippingCategories[product.id];

    if (product.tva) {
      return acc + price + price * product.tva;
    }

    return acc;
  }, totalProductsPriceWithTaxes);

  return {
    totalProductsPriceWithTaxes,
    totalProductsPrice,
    totalShippingFees,
    shippingCategoriesPrices,
    totalProductsPriceWithFees,
    totalProductsPriceWithFeesAndTaxes,
  };
};

export const getCategoriesLabels = (
  shippingCategories: INormalizedList<ICategorizedShippingItems>,
): ISelectCategoriesLabels => {
  return Object.values(shippingCategories).reduce<ISelectCategoriesLabels>((accumulator, category) => {
    accumulator[category.shippingMethodId] = category.label;
    return accumulator;
  }, {});
};

export const isDeliveryDateOverriden = (order: IOrder): boolean =>
  !!(order.deliveryOverride.startDate && order.deliveryOverride.endDate);
export const isDeliveryOverriden = (orderItem: ICartProductItem): boolean => orderItem.deliveryOverridden || false;

export const getDelayFromDateString = (date: Nullable<string>): number | undefined => {
  if (!date) {
    return;
  }
  return moment(date).endOf('day').diff(moment().endOf('day'), 'days');
};
