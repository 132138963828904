import { clearDeliveryState } from 'store/delivery';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useStepper } from 'hooks';
import { Button, Modal, Text } from 'components/shared';
import { Col, Row } from 'antd';
import styles from './styles.module.scss';

type IProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  openAddressModal: () => void;
};

const AddressWarningModal: FC<IProps> = (props: IProps) => {
  const { isModalVisible, handleCancel, openAddressModal } = props;
  const { goPrevStep } = useStepper();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goToStep2 = () => {
    dispatch(clearDeliveryState());
    goPrevStep();
  };

  return (
    <Modal title="Attention" open={isModalVisible} onCancel={handleCancel} closable={false}>
      <Text type="title-gray3" className={styles.title}>
        {t('errors.invalidDifferentPostalCode')}
      </Text>
      <Row gutter={[20, 10]}>
        <Col span={22} offset={1}>
          <Button type="primary" onClick={goToStep2}>
            {t('common.selectAnotherPostalCode')}
          </Button>
        </Col>
        <Col span={22} offset={1}>
          <Button type="dashed" onClick={openAddressModal}>
            {t('common.selectAnotherAddress')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddressWarningModal;
