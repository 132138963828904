import { isFulfilledAction, isPendingAction, isRejectedAction } from 'utils';
import { CART_REQUESTS_REDUCER_NAME, SAVED_CART_REDUCER_NAME } from 'store/common';

export const restoreSavedCartMatcher = () => isFulfilledAction(`${SAVED_CART_REDUCER_NAME}/restoreSavedCart`);
export const updateSavedCartMatcher = () => isFulfilledAction(`${SAVED_CART_REDUCER_NAME}/updateSavedCart`);

export const saveSavedCartMatcher = () => isFulfilledAction(`${SAVED_CART_REDUCER_NAME}/saveSavedCart`);

export const fetchCartRequestPendingMatcher = () =>
  isPendingAction(`${CART_REQUESTS_REDUCER_NAME}/fetchCartRequestAndCreateState`);

export const fetchCartRequestRejectedMatcher = () =>
  isRejectedAction(`${CART_REQUESTS_REDUCER_NAME}/fetchCartRequestAndCreateState`);

export const fetchCartRequestFulfilledMatcher = () =>
  isFulfilledAction(`${CART_REQUESTS_REDUCER_NAME}/fetchCartRequestAndCreateState`);
