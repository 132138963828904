import React from 'react';
import { CartAttachmentsProps } from './types';
import styles from './CartAttachments.module.scss';
import CartAttachment from './CartAttachment';

const CartAttachments: React.FC<CartAttachmentsProps> = ({ product, productIndex }: CartAttachmentsProps) => {
  const handleStopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const { attachments } = product;

  return (
    <div className={styles.wrapper} onClick={handleStopPropagation}>
      <div className={styles.attachments}>
        {(attachments || []).map((attachment) => (
          <CartAttachment key={attachment.id} productIndex={productIndex} attachment={attachment} />
        ))}
      </div>
    </div>
  );
};

export default CartAttachments;
