import { createTranslation } from 'utils/translation.utils';
import { ProductDeliveryFormatList } from 'types/format';

const trans = createTranslation('Format');

export const PRODUCT_DELIVERY_FORMAT: ProductDeliveryFormatList = {
  DEFAULT: {
    days: {
      singular: trans(`Delivery : %s day`),
      plural: trans('Delivery : %s days'),
    },
    betweenDates: trans(`Delivery : %s - %s`),
    betweenDatesFullMonth: trans(`Delivery : %s`),
    betweenDays: trans(`Delivery : %s - %s days`),
    beforeDate: trans(`Delivery : %s`),
    onDate: trans(`Delivery : %s`),
    none: ``,
  },
  GENERIC: {
    days: {
      singular: trans(`Delivery before : %s day`),
      plural: trans('Delivery before : %s days'),
    },
    betweenDates: trans(`Delivery between : %s - %s`),
    betweenDatesFullMonth: trans(`Delivery : %s`),
    betweenDays: trans(`Delivery between: %s - %s days`),
    beforeDate: trans(`Delivery before : %s`),
    onDate: trans(`Delivery on : %s`),
    none: `N/C`,
  },
  NO_LABEL: {
    days: {
      singular: trans(`%s day`),
      plural: trans('%s days'),
    },
    betweenDates: `%s - %s`,
    betweenDatesFullMonth: `%s`,
    betweenDays: trans(`%s - %s days`),
    beforeDate: ` %s`,
    onDate: `%s`,
    none: ``,
  },
};
