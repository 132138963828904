import { ThemeConfig } from 'types/theme';
import agricoLogo from 'assets/images/atc/agrico_logo.png';

const config: ThemeConfig = {
  favicon: 'favicon-agriconomie.ico',
  title: 'Agriconomie',
  loginLogo: agricoLogo,
};

export default config;
