import { Supplier } from 'types/suppliers';
import {
  IAlgoliaProduct,
  GetWiuzProductsResponse as GetWiuzFileResponse,
  IProductOfferShippingMethod,
  IProductTaxes,
  Option,
  WiuzProductRowData,
  IProductReplacement,
  IAlgoliaSubstitutionProduct,
  IAlgoliaEquivalentProduct,
  IWiuzUnitsAssociation,
  MultiSearchHeader,
  Unit,
} from 'types/product';
import { DisplayOfferGroup, IDiscount, IOfferProduct, IOfferResponse, IStorageOffer } from 'types/offer';
import { ErrorType, INormalizedList, ITown } from 'types';
import { ICustomerPostalCode } from 'store/cart/types';
import { IProductPriceData } from 'components/shared';
import { IShippingMethodResponse } from '../../types/shippingFees';

export interface IProductsState {
  isLoading: boolean;
  displayAllOffers: boolean;
  excludeNoStockOffers: boolean;
  displayOfferGroup: DisplayOfferGroup;
  productError: ErrorType | null;
  postcodeError: ErrorType | null;
  tableMinimized: boolean;
  wiuz: {
    selectedFile: WiuzSelectedFile | null;
    products: INormalizedList<IWiuzProductItem>;
    files: Array<IWiuzFiles>;
    filesError: ErrorType | null | undefined;
    isLoadingFiles: boolean;
    activeQuantity: WiuzActiveQuantity;
  };
  multiSearch: {
    queries: Array<MultiSearchHeader>;
    searchResults: Array<MultiSearchResult>;
  };
  customerQuotation: {
    searchResults: INormalizedList<CustomerQuotationSearchResult>;
  };
  searchedVariantsIds: number[];
  products: INormalizedList<IAlgoliaProduct>;
  offers: IProductsOffer;
  lastSearchReturnedNoResult: boolean;
  fetchedTowns: Array<ITown>;
  postalCodes: ICustomerPostalCode | null;
  isLoadingPostalCode: boolean;
  isClientMode: boolean;
  isLoadingSuppliers: boolean;
  suppliers: Array<Supplier>;
  warehouseStocks: {
    data: IWarehouseStockData;
    isLoading: boolean;
    sku: string;
    hideOutOfStock: boolean;
    rememberWarehouse: boolean;
    warehouseName: string;
    visibility: boolean;
  };
  departures: {
    [id: number]: IDeparture;
  };
  replacementModal: {
    visible: boolean;
    sku: string | null;
    productIndex: number | null;
    quantity: number;
    displayAllOffers: boolean;
    isLoading: boolean;
    searchedVariantIds: number[];
    products: INormalizedList<IAlgoliaProduct>;
    offers: IProductsOffer;
  };
  addProductModal: {
    visible: boolean;
    units: Unit[];
    isLoading: boolean;
  };
}

export interface IDeparture {
  id: number;
  name: string;
  countryCode: string;
}

export interface IProductIdName {
  id: number;
  name: string;
}

export interface IWarehouseStockData {
  [sku: string]: IWarehouseStockDataItem[];
}

export interface IWarehouseStockDataItem {
  variantId: number;
  sku: string;
  warehouseId: number;
  warehouseName: string;
  quantity: number;
  productName: string;
  conditioningQuantity: number;
  conditioningLabel: string;
  unitLabelSingular: string;
  unitLabelPlural: string;
}

export type MultiSearchResult = {
  products: INormalizedList<IAlgoliaProduct>;
  offers: IProductsOffer;
  searchedVariantsIds: number[];
};

export type CustomerQuotationSearchResult = {
  products: INormalizedList<IAlgoliaProduct>;
  offers: IProductsOffer;
  searchedVariantsIds: number[];
};

export type WiuzSelectedFile = {
  id: string;
  filename: string;
};

export interface IProductsOffer {
  list: INormalizedList<IStorageOffer>;
  byProduct: IOffersListByProductId;
}

export interface IWiuzFiles {
  id: string;
  createdAt: string;
  customerId: number;
  filename: string;
  updatedAt: string;
  userId: string;
}

export type IOffersListByProductId = {
  [variantId: number]: Array<number>;
};

export type IParsedProductItem = {
  id?: string;
  bu: string;
  amm?: string;
  name: string;
  conditioning?: string;
  offerOnline?: boolean;
  offer?: IOfferProduct;
  sku: string;
  shippingMethods?: Array<IProductOfferShippingMethod>;
  productId: number;
  variantId: number;
  offerId?: number;
  stock?: number;
  price?: IProductPriceData;
  disabled: boolean;
  unit: {
    singular: string;
    plural: string;
  };
  taxes: IProductTaxes;
  quantityIncrement?: number;
  minimumOrderable?: number;
  days?: number;
  isTruck?: boolean;
  startDate?: string;
  endDate?: string;
  tags?: Array<string>;
  sortingIndex: number;
  options?: Option;
  replacement?: IProductReplacement;
  substitutionProducts: IAlgoliaSubstitutionProduct[];
  equivalentProducts: IAlgoliaEquivalentProduct[];
  equivalentProductIds: Array<number>;
  substitutionProductIds: Array<number>;
  publicComment?: string;
  privateComment?: string;
  cartComment?: string;
  conditioningQuantity: number;
  purchasableOffline: boolean;
  online: boolean;
  flags?: Array<string>;
  productTags?: Array<string>;
  discountOffers?: Array<IDiscount>;
  ignoreStock?: boolean;
  metadata?: {
    business_note: string;
  } | null;
};

export type IParsedProductItemRowKey = IParsedProductItem & {
  rowKey: string;
  departureId?: number;
};

export type IWiuzProductItem = {
  id: string;
  importId: string;
  productId: number | undefined;
  name: string;
  unit: string;
  quantity: number;
  quantityToOrder: number;
  unitRelations: Array<IWiuzUnitsAssociation>;
  products: INormalizedList<IAlgoliaProduct>;
  offers: IProductsOffer;
  data: WiuzProductRowData;
  createdAt: string;
  updatedAt: string;
};

export type IParsedWiuzProductItem = Omit<IWiuzProductItem, 'products' | 'offers'> & {
  products: Array<IParsedProductItem>;
};

export type IFetchProductsParams = {
  search: string;
  page: number;
  filter?: string | null | false;
  pageSize?: number;
  postcode?: string;
  tags?: Array<string>;
  displayAllOffers?: boolean;
  searchType: ProductSearchType;
  rowKey?: number;
  excludeNoStockOffers?: boolean;
};

export type IFetchWiuzProductsParams = Omit<IFetchProductsParams, 'search' | 'searchType' | 'rowKey'> & {
  importId: string;
};

export type IFetchProductsResponse = {
  lastSearchReturnedNoResult?: boolean;
  offers?: IOfferResponse;
  products: Array<IAlgoliaProduct>;
  searchedVariantsIds: Array<number>;
  shippingMethods: Array<IShippingMethodResponse> | undefined;
};

export type IFetchProductsSingleResponse = IFetchProductsResponse & {
  searchType: ProductSearchType.SINGLE_SEARCH;
};
export type IFetchProductsMultiResponse = IFetchProductsResponse & {
  searchType: ProductSearchType.MULTIPLE_SEARCH;
  rowKey: number;
};

export type IFetchProductsCustomerQuotesResponse = IFetchProductsResponse & {
  searchType: ProductSearchType.CUSTOMER_QUOTATION_SEARCH;
  rowKey: number;
};

export type IFetchProductsResponseWithSearchType =
  | IFetchProductsSingleResponse
  | IFetchProductsMultiResponse
  | IFetchProductsCustomerQuotesResponse;

export type IFetchWiuzProductsResponse = {
  algoliaProducts: IFetchProductsResponse;
  wiuzFile: GetWiuzFileResponse;
};

export type ProductWithOffer = IAlgoliaProduct & { offer: IOfferProduct };
export type GroupedProductsWithoutSorting = {
  productsWithOffers: ProductWithOffer[];
  productsWithoutOffers: IAlgoliaProduct[];
};

export type WiuzActiveQuantity = {
  [wiuzProductId: string]: {
    [productKey: string]: number;
  };
};

export type FetchSuppliersResponse = Array<Supplier>;
export type FetchDeparturesResponse = Array<IDeparture>;
export type FetchSuppliersParams = void;

// eslint-disable-next-line no-shadow
export enum ProductSearchType {
  MULTIPLE_SEARCH = 'multipleSearch',
  SINGLE_SEARCH = 'singleSearch',
  CUSTOMER_QUOTATION_SEARCH = 'customerQuotationSearch',
}
