import { delay } from 'utils/loading.utils';
import { cloneCart } from 'store/savedCart';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Modal, Text, Button } from 'components/shared';
import { Col, Row } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../../store/store';
import styles from './styles.module.scss';

type CloneCartConfirmationModalProps = {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  cartId: string;
};

const CloneCartConfirmationModal: FC<CloneCartConfirmationModalProps> = ({ visible, onClose, cartId, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleClone = async () => {
    setIsLoading(true);
    const newCart = await dispatch(cloneCart({ cartId }));
    setIsLoading(false);

    if (isFulfilled(newCart)) {
      onSuccess();
      onClose();
    }
  };

  return (
    <Modal width="460px" className={styles.modal} open={visible} onCancel={onClose}>
      <Row>
        <Col span={22}>
          <Text type="title">{t('saved_cart.cloneModalConfirmationTitle')}</Text>
        </Col>
        <Col span={22}>
          <Row className={styles.actions} gutter={16}>
            <Col span={11}>
              <Button loading={delay(isLoading)} onClick={handleClone}>
                {t('common.confirm')}
              </Button>
            </Col>
            <Col span={11}>
              <Button onClick={onClose} type="dashed">
                {t('common.cancel')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={2} />
      </Row>
    </Modal>
  );
};

export default CloneCartConfirmationModal;
