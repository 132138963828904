import { ApiResponse, ApisauceConfig, ApisauceInstance, create } from 'apisauce';

export class RestServiceApi {
  api: ApisauceInstance;
  static baseURL = '';

  static defaultHeaders = {
    headers: {
      'content-type': 'application/json',
      'Cache-Control': 'no-store',
    },
  };

  constructor(baseURL: string) {
    RestServiceApi.baseURL = baseURL;
    this.api = create({ baseURL, ...RestServiceApi.defaultHeaders });
  }

  public getAuthenticateHeader(token: string): Partial<ApisauceConfig> {
    return {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  }

  async get<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return this.api.get(url, data, config);
  }

  async post<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return this.api.post(url, data, config);
  }

  async put<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return this.api.put(url, data, config);
  }

  async patch<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return this.api.patch(url, data, config);
  }

  async delete<T, R>(url: string, data?: T, config?: Partial<ApisauceConfig>): Promise<ApiResponse<R>> {
    return this.api.delete(url, data, config);
  }
}
