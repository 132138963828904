import { ThemeConfig } from 'types/theme';
import leftLogo from 'assets/images/perret/GroupePerretLogo.png';

const config: ThemeConfig = {
  favicon: 'favicon-perret.ico',
  title: 'Groupe Perret',
  loginLogo: leftLogo,
};

export default config;
