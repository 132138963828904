import {createExportPDFVariables} from 'utils/pdf/utils';
import {unwrap} from 'utils/api.utils';
import {
    calculateProductCVO,
    calculateProductPriceTotal,
    calculateProductPriceTotalWithTaxes,
    calculateProductRPD,
    createNoStockCartProductPayload,
    findCartProductIndex,
    handleCatchError,
    isApiOkResponse,
    isFulfilledAction,
    isPendingAction,
    isRejectedAction,
} from 'utils';
import {IHistoricalPrice, IProductType} from 'types/product';
import {PDFPayloadBuild, PDFPayloadRender} from 'types/pdf';
import {
    DeliveryOverride,
    ICreateOrderParam,
    IMasterOrderPlaceResponse,
    IOrder,
    IOrderResourceResponse,
    IValidateOrderParams,
} from 'types/order';
import {IOfferPrice, IOfferResponse} from 'types/offer';
import {FetchCartRequestAndCreateStateResponse} from 'types/cartRequest';
import {ErrorDetailsAtcCheckoutCartAttachment, ErrorType, INormalizedList, IUser} from 'types';
import {RestoreSavedCartStateResponse, SaveSavedCartResponse} from 'store/savedCart/types';
import {restoreSavedCartMatcher, updateSavedCartMatcher} from 'store/savedCart/matcher';
import {RootState} from 'store/rootReducer';
import {IParsedProductItem, IWiuzProductItem} from 'store/products/types';
import {SAVED_CART_REDUCER_NAME, CART_REDUCER_NAME, isNewOrderMatcher} from 'store/common';
import {fetchCartRequestAndCreateStateMatcher} from 'store/cartRequests/matcher';
import {getHistoricalPriceDataNormalizer} from 'store/cart/normalizers';
import {ProductsService} from 'services/products.service';
import {OrderApiService} from 'services/orderServiceApi';
import {CartService} from 'services/cart.service';
import {arrayMove} from 'react-sortable-hoc';
import {AsyncThunk, PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ActionType, EntityType} from '../../types/logs';
import {CustomersApiService} from '../../services/customersServiceApi';
import {ERRORS, setAxiosError} from 'constants/errors';
import {
    AddCartAttachmentParams,
    AddCartAttachmentThunkRespone,
    CartSortableIndex,
    DeleteCartAttachmentParams,
    ICartProductItem,
    ICartState,
    ICustomerProductInfoMap,
    IHistoricalPricePerSkuResponse,
    IUpdateCartProductsParams,
    IUpdateCartResponse,
    LastUnitPrices,
    SupplierPriceType,
} from './types';

const initialState: ICartState = {
    isCartUpdated: true,
    cartRequestId: null,
    cartId: null,
    atcCartNumber: null,
    isSavedCart: false,
    showPostalCodeModal: false,
    isLoading: false,
    isEco: false,
    sendQuoteByEmail: true,
    areItemsExpanded: true,
    error: null,
    products: [],
    groups: [],
    orders: {},
    users: null,
    historicalPrices: {
        isLoading: false,
        sku: '',
        data: {},
    },
    isOrdered: false,
    comment: null,
    deliveryComment: null,
    invoiceComment: null,
    attachmentStatus: {
        isError: false,
        isLoading: false,
        isSuccess: false,
        error: null,
    },
    lastUnitPrices: {
        lastInvoicedUnitPrice: {},
        lastQuotedUnitPrice: {},
    },
    restoredUserTags: [],
    logs: [],
    status: null,
    pendingLogs: {
        [ActionType.CREATE]: [],
        [ActionType.UPDATE]: {},
        [ActionType.DELETE]: [],
    },
};

export const addCartAttachment: AsyncThunk<
    AddCartAttachmentThunkRespone,
    AddCartAttachmentParams,
    { rejectValue: ErrorType<ErrorDetailsAtcCheckoutCartAttachment>; state: RootState }
> = createAsyncThunk(
    `${CART_REDUCER_NAME}/addCartAttachment`,
    async (
        {productIndex, cartProductId, cartId, productId, variantId, offerId, file}: AddCartAttachmentParams,
        {rejectWithValue},
    ) => {
        try {
            const attachments = unwrap(
                await CartService.addCartAttachment(
                    {
                        cartProductId,
                        cartId,
                        productId,
                        variantId,
                        offerId,
                    },
                    file,
                ),
            );
            return {productIndex, attachments};
        } catch (err) {
            return handleCatchError(err, rejectWithValue);
        }
    },
);

export const deleteCartAttachment: AsyncThunk<
    unknown,
    DeleteCartAttachmentParams,
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${CART_REDUCER_NAME}/deleteCartAttachment`, async ({id}, {rejectWithValue}) => {
    try {
        return unwrap(await CartService.delete(id));
    } catch (err) {
        return handleCatchError(err, rejectWithValue, true);
    }
});

export const deleteProductCartAttachments: AsyncThunk<
    unknown,
    { cartProductId: string },
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
    `${CART_REDUCER_NAME}/deleteProductCartAttachments`,
    async ({cartProductId}, {rejectWithValue}) => {
        try {
            return unwrap(await CartService.deleteProductCartAttachments(cartProductId));
        } catch (err) {
            return handleCatchError(err, rejectWithValue, true);
        }
    },
);

export const downloadCartAttachment: AsyncThunk<
    string,
    { key: string; bucket: string },
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${CART_REDUCER_NAME}/downloadCartAttachment`, async ({key, bucket}, {rejectWithValue}) => {
    try {
        return unwrap(await CartService.downloadCartAttachment(bucket, key));
    } catch (err) {
        return handleCatchError(err, rejectWithValue, true);
    }
});

export const updateProducts: AsyncThunk<
    IUpdateCartResponse,
    IUpdateCartProductsParams,
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
    `${CART_REDUCER_NAME}/updateProducts`,
    async ({postcode}: IUpdateCartProductsParams, {rejectWithValue, getState}) => {
        const {cart, customers, products, user} = getState();
        const {users} = cart;
        let tags: Array<string> = [];
        const allOffers = products.displayAllOffers;
        const excludeNoStockOffers = products.excludeNoStockOffers;

        if (!customers.isGroup) {
            tags = customers.customers[customers.selectedCustomer!].tags ?? [];
        } else {
            tags = customers.groupTags[customers.selectedGroupId!] ?? [];
        }
        if (allOffers) {
            tags.push(...user.tags);
        }

        const productIds = Object.values(cart.products).map((product) => product.productId);
        const cartProducts = Object.keys(cart.products).map((key) => cart.products[parseFloat(key)]);

        try {
            const bestOffersApiResponse = await ProductsService.getBestOffers({
                products: productIds,
                postcode,
                tags,
                allOffers,
                clubMemberExpDate: users?.clubMemberExpDate,
                excludeNoStockOffers,
            });

            if (!isApiOkResponse<IOfferResponse>(bestOffersApiResponse)) {
                throw setAxiosError(bestOffersApiResponse.problem);
            }

            const bestOffersProducts = [...(bestOffersApiResponse.data?.Product || [])];

            return {bestOffersProducts, cartProducts};
        } catch (err) {
            return handleCatchError(err, rejectWithValue);
        }
    },
);

export const getOrders: AsyncThunk<
    Array<IOrderResourceResponse>,
    Array<ICreateOrderParam>,
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
    `${CART_REDUCER_NAME}/getOrders`,
    async (orders: Array<ICreateOrderParam>, {rejectWithValue}) => {
        try {
            return unwrap(await OrderApiService.createOrderResource(orders.filter(Boolean)), (resp) => {
                if (!resp.data?.length) {
                    return ERRORS.validation.noOrders;
                }
            });
        } catch (err) {
            return handleCatchError(err, rejectWithValue);
        }
    },
);

export const getUsers: AsyncThunk<IUser, { userId: number }, { rejectValue: ErrorType; state: RootState }> =
    createAsyncThunk(`${CART_REDUCER_NAME}/getUsers`, async ({userId}, {rejectWithValue}) => {
        try {
            return unwrap(await CustomersApiService.getUser(userId));
        } catch (err) {
            return handleCatchError(err, rejectWithValue);
        }
    });

export const getHistoricalPriceData: AsyncThunk<
    IHistoricalPricePerSkuResponse,
    { sku: string; tcCode: string },
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${CART_REDUCER_NAME}/getHistoricalPriceData`, async ({sku, tcCode}, {rejectWithValue}) => {
    try {
        return unwrap(await CartService.getHistoricalPricePerSku(sku, tcCode));
    } catch (err) {
        return handleCatchError(err, rejectWithValue);
    }
});

export const orderValidation: AsyncThunk<
    IMasterOrderPlaceResponse,
    IValidateOrderParams,
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
    `${CART_REDUCER_NAME}/orderValidation`,
    async ({userId, token, groupOrder}: IValidateOrderParams, {rejectWithValue, getState}) => {
        const {
            user,
            delivery,
            customers,
            cart,
            products: {suppliers},
        } = getState();
        try {
            const placedOrder = await OrderApiService.placeOrder(
                userId.toString(),
                token,
                groupOrder,
                customers,
                cart,
                delivery,
                suppliers,
                user.username,
            );
            return OrderApiService.parseOrderApiResponseData(placedOrder.data);
        } catch (err) {
            console.error(err);
            return handleCatchError(err, rejectWithValue);
        }
    },
);

export const exportPDF: AsyncThunk<string, void, { rejectValue: ErrorType; state: RootState }> = createAsyncThunk(
    `${CART_REDUCER_NAME}/exportPDF`,
    async (_, {rejectWithValue, getState}) => {
        const state = getState();

        try {
            const variables = createExportPDFVariables(state);
            return unwrap(await CartService.exportToPDF({variables} as PDFPayloadBuild, 'build'));
        } catch (err) {
            return handleCatchError(err, rejectWithValue, true);
        }
    },
);

export const exportSavedCartPDF: AsyncThunk<
    string,
    { saveCartPdfId: number },
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${CART_REDUCER_NAME}/exportSavedCartPDF`, async ({saveCartPdfId}, {rejectWithValue}) => {
    try {
        return unwrap(await CartService.exportToPDF({saveCartPdfId} as PDFPayloadRender, 'render'));
    } catch (err) {
        return handleCatchError(err, rejectWithValue, true);
    }
});

export const getCartLastUnitPrices: AsyncThunk<
    LastUnitPrices,
    LastUnitPrices,
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(`${CART_REDUCER_NAME}/getCartLastUnitPrices`, async (data, {rejectWithValue}) => {
    return data;
});

export const getLastUnitPricesBySku: AsyncThunk<
    { lastInvoicedUnitPrice: IHistoricalPrice; lastQuotedUnitPrice: IHistoricalPrice },
    { sku: string; customerIdentifier: string; tcCode: string },
    { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
    `${CART_REDUCER_NAME}/getLastUnitPricesBySku`,
    async ({sku, customerIdentifier, tcCode}, {rejectWithValue}) => {
        try {
            const [lastInvoicedRes, lastQuotedRes] = await Promise.all([
                CartService.getLastUnitPricePerSkuPerCustomer(sku, customerIdentifier, tcCode!),
                CartService.getLastQuotedUnitPricePerSkuPerCustomer(sku, customerIdentifier),
            ]);

            return {
                lastInvoicedUnitPrice: isApiOkResponse(lastInvoicedRes) ? lastInvoicedRes.data : unwrap(lastInvoicedRes),
                lastQuotedUnitPrice: isApiOkResponse(lastQuotedRes) ? lastQuotedRes.data : unwrap(lastQuotedRes),
            };
        } catch (err) {
            return handleCatchError(err, rejectWithValue, true);
        }
    },
);

const cartSlice = createSlice({
    name: CART_REDUCER_NAME,
    initialState,
    reducers: {
        clearCartState() {
            return {...initialState};
        },
        /** @description this is temporary, the certificates should be moved to customers */
        clearCartStateExceptCertificates(state) {
            const override: Partial<ICartState> = {
                users: state.users ? {...state.users} : null,
            };
            return {
                ...initialState,
                ...override,
            };
        },
        setCartItemsExpanded(state, {payload}: PayloadAction<boolean>) {
            state.areItemsExpanded = payload;
        },
        setIsEco(state, {payload}: PayloadAction<boolean>) {
            state.isEco = payload;
        },
        clearUsers(state) {
            state.users = initialState.users;
        },
        setUsers(state, {payload}: PayloadAction<IUser>) {
            state.users = payload;
        },
        addProduct(state, {payload: product}: PayloadAction<ICartProductItem>) {
            // use offerId as id for create logs since items don't exist in the db
            const existingLog = state.pendingLogs[ActionType.CREATE].find((l) => l.itemId === product.offerId.toString());

            if (!existingLog) {
                state.pendingLogs[ActionType.CREATE].push({
                    itemId: product.offerId.toString(),
                    entity: EntityType.CART_ITEM,
                    type: ActionType.CREATE,
                    payload: {
                        label: product.label,
                        sku: product.sku,
                        quantity: product.quantity,
                        forcedUnitPrice: product.offerPrice,
                        createdAt: new Date().toISOString(),
                    },
                });
            }

            state.products.push({
                ...product,
                discountOffers: [...(product.discountOffers ?? [])].sort((a, b) => b.MinimumQuantity - a.MinimumQuantity),
            });
        },
        toggleCartProductEnabled(state, {payload}: PayloadAction<number>) {
            const findNoStockIndex = state.products.findIndex((product) => product.variantId === payload);
            const findStockIndex = state.products.findIndex((product) => product.offerId === payload);
            const index = findNoStockIndex === -1 ? findStockIndex : findNoStockIndex;
            state.products[index].enabled = !state.products[index].enabled;
        },
        updateProductReplacementName(
            state,
            {
                payload: {identifier, replacementName},
            }: PayloadAction<{ identifier: number | undefined; replacementName: string }>,
        ) {
            const findNoStockIndex = state.products.findIndex((product) => product.variantId === identifier);
            const findStockIndex = state.products.findIndex((product) => product.offerId === identifier);
            const index = findNoStockIndex === -1 ? findStockIndex : findNoStockIndex;
            state.products[index].replacement!.name = replacementName;
        },
        updateProductField(
            state,
            {
                payload: {identifier, value, field},
            }: PayloadAction<{
                identifier: number;
                value: string | number | null | undefined | boolean;
                field: keyof ICartProductItem;
            }>,
        ) {
            state.products[identifier] = {
                ...state.products[identifier],
                [field]: value,
            };
        },
        updateProductsFieldByOffers(
            state,
            {
                payload: {offerIds, value, field},
            }: PayloadAction<{
                offerIds: number[];
                value: string | number | null | undefined | boolean;
                field: keyof ICartProductItem;
            }>,
        ) {
            state.products.forEach((product, index) => {
                if (offerIds.includes(product.offerId)) {
                    state.products[index] = {
                        ...product,
                        [field]: value,
                    };
                }
            });
        },
        updateProductFields(
            state,
            {
                payload: {identifier, values},
            }: PayloadAction<{
                identifier: number;
                values: Partial<ICartProductItem>;
            }>,
        ) {
            state.products[identifier] = {
                ...state.products[identifier],
                ...values,
            };
        },
        updateCartGlobalComment(state, {payload}: PayloadAction<string>) {
            if (payload.length === 0) {
                state.comment = null;
                return;
            }
            state.comment = payload;
        },
        updateCartDeliveryComment(state, {payload}: PayloadAction<string>) {
            if (payload.length === 0) {
                state.deliveryComment = null;
                return;
            }
            state.deliveryComment = payload;
        },
        updateCartInvoiceComment(state, {payload}: PayloadAction<string>) {
            if (payload.length === 0) {
                state.invoiceComment = null;
                return;
            }
            state.invoiceComment = payload;
        },
        updateCartProductComment(
            state,
            {payload: {identifier, comment}}: PayloadAction<{ identifier: number | undefined; comment: string }>,
        ) {
            const findNoStockIndex = state.products.findIndex((product) => product.variantId === identifier);
            const findStockIndex = state.products.findIndex((product) => product.offerId === identifier);
            const index = findNoStockIndex === -1 ? findStockIndex : findNoStockIndex;
            if (comment.length === 0) {
                delete state.products[index].publicComment;
                return;
            }
            state.products[index].publicComment = comment;
        },
        setUpdatedCart(state, {payload}: PayloadAction<boolean>) {
            state.isCartUpdated = payload;
        },
        addNoStockProduct(state, {payload}: PayloadAction<IParsedProductItem>) {
            const findProduct = state.products.findIndex(
                (prod) => prod.variantId === payload.variantId && prod.productId === payload.productId,
            );
            if (findProduct !== -1) return;

            // use productId/variantId as id for create logs since items don't exist in the db
            const existingLog = state.pendingLogs[ActionType.CREATE].find(
                (l) => l.itemId === `${payload.productId}-${payload.variantId}`,
            );

            if (!existingLog) {
                state.pendingLogs[ActionType.CREATE].push({
                    itemId: `${payload.productId}-${payload.variantId}`,
                    entity: EntityType.CART_ITEM,
                    type: ActionType.CREATE,
                    payload: {
                        label: payload.name,
                        sku: payload.sku,
                        quantity: 0,
                        forcedUnitPrice: 0,
                        createdAt: new Date().toISOString(),
                    },
                });
            }

            const product = createNoStockCartProductPayload(payload);
            state.products.push(product);
        },
        replaceProduct(
            state,
            {
                payload,
            }: PayloadAction<{replacement: ICartProductItem; productIndex: number;}>,
        ) {
            const {replacement, productIndex} = payload;
            if (productIndex < 0) {
                return;
            }

            const prevProduct = state.products[productIndex];

            if (!prevProduct.id) {
                // update create log to match yet to be saved product
                const createLog = state.pendingLogs[ActionType.CREATE].find((l) => l.itemId === prevProduct.offerId.toString());

                createLog.itemId = replacement.offerId.toString();
                createLog.payload = {
                    ...createLog.payload,
                    label: replacement.label,
                    forcedUnitPrice: replacement.offerPrice,
                };

                if (state.pendingLogs[ActionType.UPDATE][prevProduct.offerId]) {
                    delete state.pendingLogs[ActionType.UPDATE][prevProduct.offerId];
                }

                state.products.splice(productIndex, 1, replacement);
            } else {
                state.products.splice(productIndex, 1, {
                    ...replacement,
                    id: prevProduct.id,
                });
            }
        },
        setQuantity(
            state,
            {payload: {id, quantity}}: PayloadAction<{ id: number; quantity: number; wiuzQuantity?: number }>,
        ) {
            const foundProduct = state.products.find((product) => product.offerId === id);
            const productId = foundProduct?.id;
            const updateLogs = state.pendingLogs[ActionType.UPDATE];

            if (foundProduct) {
                const itemId = productId || id.toString();
                const existingQuantityLog = updateLogs[itemId]?.quantity;

                // if we already have a log of this type for the item we update it
                if (existingQuantityLog) {
                    existingQuantityLog.payload = {
                        quantity,
                        sku: foundProduct.sku,
                        createdAt: new Date().toISOString(),
                    };
                } else {
                    updateLogs[itemId] = {
                        ...updateLogs[itemId],
                        quantity: {
                            itemId,
                            entity: EntityType.CART_ITEM,
                            type: ActionType.UPDATE,
                            payload: {
                                quantity,
                                sku: foundProduct.sku,
                                createdAt: new Date().toISOString(),
                            },
                        },
                    };
                }

                foundProduct.quantity = quantity;
            }
        },
        checkAndUpdateProductsType(
            state,
            {
                payload: {productPage, wiuzProducts},
            }: PayloadAction<{ productPage: IProductType; wiuzProducts: INormalizedList<IWiuzProductItem | null> }>,
        ) {
            const {products: cartProducts} = state;

            cartProducts.forEach((product) => {
                const {productId} = product;

                if (productPage === 'wiuz' && Object.prototype.hasOwnProperty.call(wiuzProducts, productId)) {
                    const wiuzProduct = wiuzProducts[productId];

                    product.wiuz = null;
                    if (wiuzProduct) {
                        const {id, unitRelations, unit} = wiuzProduct;
                        product.wiuz = {id, unitRelations, unit};
                    }
                } else {
                    product.wiuz = null;
                }
            });
        },

        changeCustomerProductAvailableQuantity(
            state,
            {
                payload: {productId, customerAvailableQuantity, customerIdentifier, productSelectedQuantity},
            }: PayloadAction<{
                productId: number;
                customerAvailableQuantity: number;
                customerIdentifier: string;
                productSelectedQuantity?: number;
            }>,
        ) {
            const foundProduct = state.products.find((product) => product.productId === productId);
            if (foundProduct && foundProduct.customerProductInfo) {
                let identifier = null;
                for (const key in foundProduct.customerProductInfo) {
                    if (customerIdentifier === String(foundProduct.customerProductInfo[key].customerIdentifier)) {
                        identifier = key;
                    }
                }
                if (!identifier) {
                    return;
                }

                foundProduct.customerProductInfo![identifier].availableCustomerQuantity = customerAvailableQuantity;

                if (productSelectedQuantity === 0) {
                    foundProduct.customerProductInfo![identifier].selectedQuantity = productSelectedQuantity;
                }
            }
        },
        changeProductAvailableQuantity(
            state,
            {payload: {offerId, quantity}}: PayloadAction<{ offerId: number; quantity: number }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === offerId);
            if (product) product.availableSelectedQuantity = quantity;
        },
        changeCustomerProductSelectedQuantity(
            state,
            {
                payload: {productId, customerIdentifier, productQuantity},
            }: PayloadAction<{
                productId: number;
                customerIdentifier: string;
                productQuantity: number;
            }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === productId);
            if (product && product.customerProductInfo) {
                let identifier = null;
                for (const key in product.customerProductInfo) {
                    if (customerIdentifier === product.customerProductInfo[key].customerIdentifier) {
                        identifier = key;
                    }
                }
                if (!identifier) {
                    return;
                }

                product.customerProductInfo![identifier].selectedQuantity = productQuantity;
            }
        },

        changeCustomerTruckProductSelectedQuantity(
            state,
            {
                payload: {
                    productId,
                    customerIdentifier,
                    productQuantity,
                    truckId,
                    totalTruckQuantity,
                    productAvailableSelectedQt,
                },
            }: PayloadAction<{
                productId: number;
                customerIdentifier: string;
                truckId: number;
                productQuantity: number;
                totalTruckQuantity: number;
                productAvailableSelectedQt: number;
            }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === productId);
            if (product && product.truckSplitInfo) {
                let identifier = null;
                for (const key in product.truckSplitInfo![truckId].customerProductInfo) {
                    if (customerIdentifier === product.truckSplitInfo![truckId].customerProductInfo[key].customerIdentifier) {
                        identifier = key;
                    }
                }

                if (!identifier) {
                    return;
                }

                product.truckSplitInfo![truckId].customerProductInfo[identifier].selectedQuantity = productQuantity;
                product.truckSplitInfo![truckId].totalTruckAvailable = totalTruckQuantity;
                product.availableSelectedQuantity = productAvailableSelectedQt;
            }
        },

        changeCustomerTruckProductAvailableQuantity(
            state,
            {
                payload: {productId, customerIdentifier, quantity, truckId},
            }: PayloadAction<{
                productId: number;
                customerIdentifier: string;
                truckId: number;
                quantity: number;
            }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === productId);
            if (product && product.truckSplitInfo) {
                let identifier = null;
                for (const key in product.customerProductInfo) {
                    if (customerIdentifier === product.customerProductInfo[key].customerIdentifier) {
                        identifier = key;
                    }
                }
                if (!identifier) {
                    return;
                }

                product.truckSplitInfo![truckId].customerProductInfo[identifier].availableCustomerQuantity = quantity;
            }
        },

        correctTruckAvailableQuantity(
            state,
            {
                payload: {offerId, truckNumber, customerIdentifier},
            }: PayloadAction<{ offerId: number; truckNumber: number; customerIdentifier: string }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === offerId);

            if (product) {
                let identifier: string | null = null;
                for (const key in product.customerProductInfo) {
                    if (customerIdentifier === product.customerProductInfo[key].customerIdentifier) {
                        identifier = key;
                    }
                }
                if (!identifier) {
                    return;
                }

                if (truckNumber) {
                    const {truckSplitInfo} = product;
                    if (truckSplitInfo) {
                        const splitInfo = truckSplitInfo[truckNumber];
                        if (splitInfo.totalTruckAvailable < 0) {
                            splitInfo.customerProductInfo[identifier].selectedQuantity =
                                splitInfo.customerProductInfo[identifier].availableCustomerQuantity;
                            splitInfo.totalTruckAvailable = 0;

                            Object.entries(splitInfo.customerProductInfo).forEach(([key, item]) => {
                                if (key !== identifier) {
                                    item.availableCustomerQuantity = item.selectedQuantity;
                                }
                            });
                        }
                    }
                } else {
                    const {customerProductInfo} = product;
                    if (customerProductInfo) {
                        if (product.availableSelectedQuantity < 0) {
                            (customerProductInfo as any)[customerIdentifier].selectedQuantity =
                                (customerProductInfo as any)[customerIdentifier].selectedQuantity + product.availableSelectedQuantity;
                            product.availableSelectedQuantity = 0;
                        }
                    }
                }
            }
        },

        removeGroupTruck(
            state,
            {
                payload: {productId, quantity, truckId, availableSelectedQt},
            }: PayloadAction<{
                productId: number;
                truckId: number;
                quantity: number;
                availableSelectedQt: number;
            }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === productId);
            if (product) {
                delete product.truckSplitInfo![truckId];
                product.quantity = quantity;
                product.availableSelectedQuantity = availableSelectedQt;
            }
        },
        changeTruckNumberValues(
            state,
            {
                payload: {productId, truckId, truckNumber},
            }: PayloadAction<{
                productId: number;
                truckId: number;
                truckNumber: number;
            }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === productId);
            if (product && product.truckSplitInfo) {
                if (product.truckSplitInfo![truckId]) {
                    product.truckSplitInfo![truckId].truckNumber = truckNumber;
                }
            }
        },

        addQuantityDiscount(state, {payload: {id, price}}: PayloadAction<{ id: number; price: number }>) {
            const foundProduct = state.products.find((product) => product.offerId === id);
            if (foundProduct) {
                foundProduct.price.price = price;
                foundProduct.offerPrice = price;
            }
        },

        recalculateProductPrices(state, {payload: id}: PayloadAction<number>) {
            const foundProduct = state.products.find((product) => product.offerId === id);
            if (foundProduct) {
                foundProduct.cvo = calculateProductCVO(foundProduct);
                foundProduct.rpd = calculateProductRPD(foundProduct);
                foundProduct.totalPrice = calculateProductPriceTotal(foundProduct);
                foundProduct.priceTotalWithTaxes = calculateProductPriceTotalWithTaxes(foundProduct);
            }
        },
        decreaseQuantity(state, {payload: id}: PayloadAction<number>) {
            const foundProduct = state.products.find((product) => product.offerId === id);
            const productId = foundProduct?.id;
            const updateLogs = state.pendingLogs[ActionType.UPDATE];

            if (foundProduct) {
                const itemId = productId || id.toString();
                const existingQuantityLog = updateLogs[itemId]?.quantity;

                // if we already have a log of this type for the item we update it
                if (existingQuantityLog) {
                    existingQuantityLog.payload = {
                        quantity: foundProduct.quantity - 1,
                        sku: foundProduct.sku,
                        createdAt: new Date().toISOString(),
                    };
                } else {
                    updateLogs[itemId] = {
                        ...updateLogs[itemId],
                        quantity: {
                            itemId,
                            entity: EntityType.CART_ITEM,
                            type: ActionType.UPDATE,
                            payload: {
                                quantity: foundProduct.quantity - 1,
                                sku: foundProduct.sku,
                                createdAt: new Date().toISOString(),
                            },
                        },
                    };
                }

                foundProduct.quantity--;
            }
        },
        increaseQuantity(state, {payload: id}: PayloadAction<number>) {
            const foundProduct = state.products.find((product) => product.offerId === id);
            const productId = foundProduct?.id;
            const updateLogs = state.pendingLogs[ActionType.UPDATE];

            if (foundProduct) {
                const itemId = productId || id.toString();
                const existingQuantityLog = updateLogs[itemId]?.quantity;

                // if we already have a log of this type for the item we update it
                if (existingQuantityLog) {
                    existingQuantityLog.payload = {
                        quantity: foundProduct.quantity + 1,
                        sku: foundProduct.sku,
                        createdAt: new Date().toISOString(),
                    };
                } else {
                    updateLogs[itemId] = {
                        ...updateLogs[itemId],
                        quantity: {
                            itemId,
                            entity: EntityType.CART_ITEM,
                            type: ActionType.UPDATE,
                            payload: {
                                quantity: foundProduct.quantity + 1,
                                sku: foundProduct.sku,
                                createdAt: new Date().toISOString(),
                            },
                        },
                    };
                }

                foundProduct.quantity++;
            }
        },
        removeProduct(state, {payload: id}: PayloadAction<number>) {
            const productIndex = state.products.findIndex((product) => product.offerId === id);

            const product = state.products[productIndex];

            // if it doesn't exist in the db, we need to remove the create/update logs we added previously
            if (!product.id) {
                const createLogIndex = state.pendingLogs[ActionType.CREATE].findIndex(
                    (l) => l.itemId === product.offerId.toString(),
                );
                state.pendingLogs[ActionType.CREATE].splice(createLogIndex, 1);

                if (state.pendingLogs[ActionType.UPDATE][id.toString()]) {
                    delete state.pendingLogs[ActionType.UPDATE][id.toString()];
                }
            } else {
                state.pendingLogs[ActionType.DELETE].push({
                    itemId: product.id,
                    entity: EntityType.CART_ITEM,
                    type: ActionType.DELETE,
                    payload: {
                        label: product.label,
                        sku: product.sku,
                        quantity: product.quantity,
                        forcedUnitPrice: product.offerPrice,
                        createdAt: new Date().toISOString(),
                    },
                });
            }

            state.products.splice(productIndex, 1);
        },
        setHistoricalPriceModalSku(state, {payload: sku}: PayloadAction<string>) {
            state.historicalPrices.sku = sku;
        },
        removeNoStockProduct(
            state,
            {payload: {productId, variantId}}: PayloadAction<{ productId: number; variantId: number }>,
        ) {
            const productIndex = state.products.findIndex(
                (product: ICartProductItem) => product.productId === productId && product.variantId === variantId,
            );

            if (productIndex === -1) return;

            const product = state.products[productIndex];

            // if it doesn't exist in the db, we need to remove the create/update logs we added previously
            if (!product.id) {
                const itemId = `${product.productId}-${product.variantId}`;
                const createLogIndex = state.pendingLogs[ActionType.CREATE].findIndex((l) => l.itemId === itemId);
                state.pendingLogs[ActionType.CREATE].splice(createLogIndex, 1);

                if (state.pendingLogs[ActionType.UPDATE][itemId]) {
                    delete state.pendingLogs[ActionType.UPDATE][itemId];
                }
            } else {
                state.pendingLogs[ActionType.DELETE].push({
                    itemId: product.id,
                    entity: EntityType.CART_ITEM,
                    type: ActionType.DELETE,
                    payload: {
                        label: product.label,
                        sku: product.sku,
                        quantity: product.quantity,
                        forcedUnitPrice: product.offerPrice,
                        createdAt: new Date().toISOString(),
                    },
                });
            }

            state.products.splice(productIndex, 1);
        },
        changeProductPrice(state, {payload: {id, price}}: PayloadAction<{ id: number; price: number }>) {
            const product = state.products.find((prod) => prod.offerId === id);
            const productId = product?.id;
            const updateLogs = state.pendingLogs[ActionType.UPDATE];
            const newPrice = Math.round(price);

            if (product) {
                if (price !== product.offerPrice) {
                    const itemId = productId || id.toString();
                    const existingForcedPriceLog = updateLogs[itemId]?.forcedUnitPrice;

                    // if we already have a log of this type for the item we update it
                    if (existingForcedPriceLog) {
                        existingForcedPriceLog.payload = {
                            forcedUnitPrice: newPrice,
                            sku: product.sku,
                            createdAt: new Date().toISOString(),
                        };
                    } else {
                        updateLogs[itemId] = {
                            ...updateLogs[itemId],
                            forcedUnitPrice: {
                                itemId,
                                entity: EntityType.CART_ITEM,
                                type: ActionType.UPDATE,
                                payload: {
                                    forcedUnitPrice: newPrice,
                                    sku: product.sku,
                                    createdAt: new Date().toISOString(),
                                },
                            },
                        };
                    }
                }

                product.offerPrice = newPrice;
            }
        },
        addNewTruckToProduct(
            state,
            {
                payload: {id, newTruckValue, availableSelectedQt},
            }: PayloadAction<{
                id: number;
                newTruckValue: { truckNumber: number; customerProductInfo: ICustomerProductInfoMap };
                availableSelectedQt: number;
            }>,
        ) {
            const product = state.products.find((prod) => prod.offerId === id);
            if (product) {
                product.truckSplitInfo = newTruckValue;
                product.availableSelectedQuantity = availableSelectedQt;
            }
        },

        setSupplierPrice(state, {payload: {id, data}}: PayloadAction<{ id: number; data: SupplierPriceType | null }>) {
            const index = findCartProductIndex(id, state.products);
            const product = state.products[index];
            product.supplierPrice = data;
        },

        updateSupplierPrice(
            state,
            {payload: {id, data}}: PayloadAction<{ id: number; data: Partial<SupplierPriceType> }>,
        ) {
            const index = findCartProductIndex(id, state.products);
            const product = state.products[index];
            const {supplierPrice} = product;

            if (supplierPrice) {
                product.supplierPrice = {
                    ...supplierPrice,
                    ...data,
                };
            }
        },
        setCartId: (state, {payload: objectId}: PayloadAction<string>) => {
            state.cartId = objectId;
        },
        setCartAtcCartNumber: (state, {payload: atcCodeNumber}: PayloadAction<string>) => {
            state.atcCartNumber = atcCodeNumber;
        },
        clearAttachmentStatus: (state) => {
            state.attachmentStatus = {...initialState.attachmentStatus};
        },
        removeCartAttachment: (
            state,
            {payload: {identifier, cartAttachmentId}}: PayloadAction<{ identifier: number; cartAttachmentId: string }>,
        ) => {
            const product = state.products[identifier];

            product.attachments = product.attachments.filter((item) => item.id !== cartAttachmentId);
        },
        updateOrderDeliveryOverride: (
            state,
            {payload}: PayloadAction<{ orderId: string; values: Partial<DeliveryOverride> }>,
        ) => {
            const {orderId, values} = payload;
            Object.entries(values).forEach(([key, value]) => {
                state.orders[orderId].deliveryOverride[key as keyof DeliveryOverride] = value;
            });
        },
        setCartRequestId: (state, {payload: cartRequestId}: PayloadAction<number | null>) => {
            state.cartRequestId = cartRequestId;
        },
        setCartSortOrder: (state, {payload: {oldIndex, newIndex}}: PayloadAction<CartSortableIndex>) => {
            state.products = arrayMove(state.products, oldIndex, newIndex);
        },
        setCartGroups: (state, {payload: groups}: PayloadAction<Array<string>>) => {
            state.groups = groups;
        },
        setCartGroupsSortOrder: (state, {payload: {oldIndex, newIndex}}: PayloadAction<CartSortableIndex>) => {
            state.groups = arrayMove(state.groups, oldIndex, newIndex);
        },
        setCartGroupsAndSortOrder: (
            state,
            {payload: {groups, oldIndex, newIndex}}: PayloadAction<CartSortableIndex & { groups: Array<string> }>,
        ) => {
            state.groups = arrayMove(groups, oldIndex, newIndex);
        },
        setShowPostalCodeModal: (state, {payload}: PayloadAction<boolean>) => {
            state.showPostalCodeModal = payload;
        },
        setSendQuoteByEmail: (state, {payload}: PayloadAction<boolean>) => {
            state.sendQuoteByEmail = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addCartAttachment.pending, (state) => {
            state.attachmentStatus.isLoading = true;
        });

        builder.addCase(addCartAttachment.rejected, (state, {payload}) => {
            state.attachmentStatus.isLoading = false;
            state.attachmentStatus.isError = true;
            state.attachmentStatus.error = payload || null;
        });

        builder.addCase(addCartAttachment.fulfilled, (state, {payload: {productIndex, attachments}}) => {
            state.attachmentStatus.isLoading = false;
            state.attachmentStatus.isError = false;
            state.attachmentStatus.isSuccess = true;

            const product = state.products[productIndex];
            product.attachments = [...product.attachments, ...attachments];
        });

        builder.addCase(updateProducts.fulfilled, (state, {payload: {bestOffersProducts, cartProducts}}) => {
            const allOffersIds = bestOffersProducts
                .flatMap((bestOfferProduct) => bestOfferProduct.Variants)
                .flatMap((variant) => variant.OfferPrice)
                .reduce(
                    (mapOfOffers: { [key: number]: IOfferPrice }, offer) => ({
                        ...mapOfOffers,
                        [offer.OfferId]: offer,
                    }),
                    {},
                );

            cartProducts.forEach((cartProduct, key) => {
                const matchingOffer = allOffersIds[cartProduct.offerId];

                if (matchingOffer) {
                    const {DiscountedPrice, RawPrice, DiscountValue, DiscountType} = matchingOffer;
                    const product = JSON.parse(
                        JSON.stringify({
                            ...state.products[key],
                            price: {
                                supplierUnitPrice: cartProduct.price.supplierUnitPrice,
                                initialPrice: RawPrice,
                                price: DiscountedPrice,
                                discount: DiscountValue,
                                discountType: DiscountType,
                            },
                            offerPrice: DiscountedPrice,
                        }),
                    );

                    product.cvo = calculateProductCVO(product);
                    product.rpd = calculateProductRPD(product);
                    product.totalPrice = calculateProductPriceTotal(product);
                    product.priceTotalWithTaxes = calculateProductPriceTotalWithTaxes(product);
                    state.products[key] = product;
                }
            });
        });
        builder.addCase(getOrders.fulfilled, (state, {payload}) => {
            const orders = payload.reduce<INormalizedList<IOrder>>((acc, order) => {
                acc[order.id] = {
                    ...order,
                    deliveryOverride: {},
                };
                return acc;
            }, {});

            state.orders = orders;
        });
        builder.addCase(orderValidation.fulfilled, (state) => {
            state.isOrdered = true;
        });
        builder.addCase(getHistoricalPriceData.pending, (state) => {
            state.historicalPrices.isLoading = true;
        });
        builder.addCase(getHistoricalPriceData.rejected, (state) => {
            state.historicalPrices.isLoading = false;
        });
        builder.addCase(getHistoricalPriceData.fulfilled, (state, {payload}) => {
            state.historicalPrices.isLoading = false;
            const {global, items} = getHistoricalPriceDataNormalizer(payload);
            if (state.historicalPrices.sku) {
                state.historicalPrices.data[state.historicalPrices.sku] = {
                    items,
                    global,
                };
            }
        });
        builder.addCase(getUsers.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.users = payload;
        });

        builder.addCase(getCartLastUnitPrices.fulfilled, (state, {payload}) => {
            state.lastUnitPrices = payload;
        });

        builder.addCase(
            getLastUnitPricesBySku.fulfilled,
            (
                state,
                {
                    payload,
                    meta: {
                        arg: {sku},
                    },
                },
            ) => {
                if (payload.lastInvoicedUnitPrice) {
                    state.lastUnitPrices.lastInvoicedUnitPrice[sku] = payload.lastInvoicedUnitPrice;
                }

                if (payload.lastQuotedUnitPrice) {
                    state.lastUnitPrices.lastQuotedUnitPrice[sku] = payload.lastQuotedUnitPrice;
                }
            },
        );

        builder.addMatcher(isPendingAction(`${CART_REDUCER_NAME}/`), (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addMatcher(isRejectedAction(`${CART_REDUCER_NAME}`), (state, {payload}) => {
            state.error = payload;
            state.isLoading = false;
        });
        builder.addMatcher(isFulfilledAction(`${CART_REDUCER_NAME}/`), (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addMatcher(isRejectedAction(`${CART_REDUCER_NAME}/orderValidation`), (state) => {
            state.isOrdered = false;
        });
        builder.addMatcher(isRejectedAction(`${CART_REDUCER_NAME}/exportPDF`), (state) => {
            state.isLoading = false;
            state.error = null;
        });
        builder.addMatcher(updateSavedCartMatcher(), (state) => {
            state.isLoading = false;
        });
        builder.addMatcher(
            restoreSavedCartMatcher(),
            (state, {payload}: PayloadAction<RestoreSavedCartStateResponse>) => {
                const {
                    id: cartId,
                    restoredUserTags,
                    state: {cart},
                } = payload;

                Object.assign<ICartState, ICartState>(state, initialState);
                Object.assign<ICartState, RestoreSavedCartStateResponse['state']['cart']>(state, cart);

                state.isSavedCart = true;
                state.cartId = cartId;
                state.restoredUserTags = restoredUserTags ?? [];
                state.showPostalCodeModal = false;
                state.isCartUpdated = true;
            },
        );

        builder.addMatcher(
            fetchCartRequestAndCreateStateMatcher(),
            (state, {payload}: PayloadAction<FetchCartRequestAndCreateStateResponse>) => {
                const {
                    cartRequestState: {cart},
                } = payload;

                Object.assign<ICartState, ICartState>(state, initialState);
                Object.assign<ICartState, FetchCartRequestAndCreateStateResponse['cartRequestState']['cart']>(state, cart);

                state.showPostalCodeModal = false;
            },
        );

        builder.addMatcher(
            isFulfilledAction(`${SAVED_CART_REDUCER_NAME}/saveSavedCart`),
            (state, {payload}: PayloadAction<SaveSavedCartResponse>) => {
                const {
                    cart: {id: cartId, cart},
                } = payload;
                state.isLoading = false;
                state.cartId = cartId;
                state.isSavedCart = true;
                state.isCartUpdated = false;
                state.pendingLogs = initialState.pendingLogs;
                state.products.forEach((product) => {
                    if (!product.id) {
                        // find newly created product and assign the id to the local state counterpart
                        const savedProduct = (cart?.products ?? []).find((sp) =>
                            product.noStock
                                ? sp.noStock && `${sp.productId}-${sp.variantId}` === `${sp.productId}-${sp.variantId}`
                                : sp.offerId === product.offerId,
                        );
                        if (savedProduct) {
                            product.id = savedProduct.id;
                        }
                    }
                });
            },
        );

        builder.addMatcher(isFulfilledAction(`${SAVED_CART_REDUCER_NAME}/createMarketplace`), (state) => {
            state.isLoading = false;
        });

        builder.addMatcher(isPendingAction(`${SAVED_CART_REDUCER_NAME}/`), (state) => {
            state.isLoading = true;
            state.error = null;
        });

        builder.addMatcher(isRejectedAction(`${SAVED_CART_REDUCER_NAME}/`), (state) => {
            state.isLoading = false;
        });

        builder.addMatcher(isNewOrderMatcher(), (state) => {
            state.cartId = initialState.cartId;
            state.isSavedCart = initialState.isSavedCart;
        });
    },
});

export const {
    setUpdatedCart,
    addNoStockProduct,
    addProduct,
    decreaseQuantity,
    increaseQuantity,
    setQuantity,
    changeProductPrice,
    recalculateProductPrices,
    removeProduct,
    removeNoStockProduct,
    setCartItemsExpanded,
    setIsEco,
    clearUsers,
    setUsers,
    clearCartState,
    clearCartStateExceptCertificates,
    changeCustomerProductAvailableQuantity,
    changeCustomerProductSelectedQuantity,
    changeProductAvailableQuantity,
    addNewTruckToProduct,
    changeCustomerTruckProductSelectedQuantity,
    changeCustomerTruckProductAvailableQuantity,
    correctTruckAvailableQuantity,
    removeGroupTruck,
    changeTruckNumberValues,
    checkAndUpdateProductsType,
    updateCartProductComment,
    updateCartGlobalComment,
    updateCartDeliveryComment,
    updateCartInvoiceComment,
    toggleCartProductEnabled,
    updateProductReplacementName,
    updateProductField,
    updateProductsFieldByOffers,
    updateProductFields,
    setSupplierPrice,
    setHistoricalPriceModalSku,
    setCartId,
    removeCartAttachment,
    updateOrderDeliveryOverride,
    setCartRequestId,
    setCartAtcCartNumber,
    setCartSortOrder,
    setCartGroups,
    setCartGroupsSortOrder,
    setCartGroupsAndSortOrder,
    setShowPostalCodeModal,
    setSendQuoteByEmail,
    replaceProduct,
} = cartSlice.actions;
export default cartSlice.reducer;
