import { FC } from 'react';

import { ISidebarContainerProps } from './SidebarContainer.types';
import styles from './SidebarContainer.module.scss';

const SidebarContainer: FC<ISidebarContainerProps> = (props: ISidebarContainerProps) => {
  const { disableSeparator = false, children, sidebar } = props;
  return (
    <div className={`${styles.container} ${!disableSeparator && styles.container_separator}`}>
      <div className={styles.content}>{children}</div>
      <div className={styles.sidebar}>{sidebar}</div>
    </div>
  );
};

export default SidebarContainer;
