import { FulfilledPayload, RejectedPayload } from 'utils/message.utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Message, MessageType } from './type';
import { ErrorType } from '../../types';

interface MessagesState {
  messages: Array<Message>;
}

const IdGenerator = {
  id: 1,
  newId() {
    return this.id++;
  },
};

const initialState: MessagesState = {
  messages: [],
};

const sendMessageReducer = (state: MessagesState, payload: MessageType) => {
  if (state.messages.length < 3) {
    state.messages = state.messages.concat({ id: IdGenerator.newId(), ...payload });
  } else {
    const [, ...other] = state.messages; //removes older message
    state.messages = other.concat({ id: IdGenerator.newId(), ...payload });
  }
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    clearMessagesState() {
      return { ...initialState };
    },
    sendMessage(state, { payload }: PayloadAction<MessageType>) {
      sendMessageReducer(state, payload);
    },
    deleteMessage(state, { payload: id }: PayloadAction<number>) {
      state.messages = state.messages.filter((message) => message.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected') && !action.type.includes('fetchProducts'),
      (state, { payload }: PayloadAction<RejectedPayload<ErrorType>>) => {
        if (payload && typeof payload === 'object' && payload?.__show_notification === true) {
          const messagePayload = {
            message: payload.message,
            type: 'error',
            name: payload.name,
            code: payload.code,
            showServerMessage: payload.showServerMessage ?? false,
            traceId: payload.traceId,
          } as unknown as MessageType;
          return sendMessageReducer(state, messagePayload);
        }
      },
    );

    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, { payload }: PayloadAction<Partial<FulfilledPayload>>) => {
        if (payload && typeof payload === 'object' && payload?.__show_notification) {
          const { message, type, name } = payload.__show_notification;

          delete payload['__show_notification'];

          return sendMessageReducer(state, {
            message,
            type,
            name,
          });
        }
      },
    );
  },
});

export const { sendMessage, deleteMessage, clearMessagesState } = messageSlice.actions;
export default messageSlice.reducer;
