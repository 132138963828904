import { combineReducers } from '@reduxjs/toolkit';
import users from './users/reducer';
import user from './user/reducer';
import settings from './settings/reducer';
import savedCart from './savedCart/reducer';
import products from './products/reducer';
import ordersHistory from './ordersHistory/reducer';
import messages from './messages/reducer';
import delivery from './delivery/reducer';
import customers from './customers/reducer';
import cartRequests from './cartRequests/reducer';
import cart from './cart/reducer';
import abandonedCarts from './abandonedCarts/reducer';

export const rootReducer = combineReducers({
  abandonedCarts,
  customers,
  cartRequests,
  products,
  messages,
  user,
  users,
  cart,
  delivery,
  ordersHistory,
  savedCart,
  settings,
});

export type RootState = ReturnType<typeof rootReducer>;
