import { useTheme } from 'hooks/useTheme';
import { PriceProps } from './Price.types';
import styles from './Price.module.scss';

const priceFormatting = (price: number) => {
  const splitPrice = (price / 100).toFixed(2).split('.');
  return {
    price: new Intl.NumberFormat('fr-FR').format(Number(splitPrice[0])),
    decimals: splitPrice[1],
  };
};

const Price = (props: PriceProps): JSX.Element => {
  const { unitPrice, className, unit, type } = props;
  const themeStyle = useTheme(styles);

  return (
    <span className={`${themeStyle('price')} ${className}`}>
      <span>{priceFormatting(unitPrice).price} </span>
      {type === 'PERCENTAGE' ? (
        <span className={styles.decimals}>{priceFormatting(unitPrice).decimals} %</span>
      ) : (
        <span className={styles.decimals}>
          €{priceFormatting(unitPrice).decimals}
          <span className={styles.unit}>{unit ? ` / ${unit}` : ''}</span>
        </span>
      )}
    </span>
  );
};

export default Price;
