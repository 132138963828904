import { CartSortableIndex, ICartProductItem } from 'store/cart/types';
import { SortableElement } from 'react-sortable-hoc';
import { Text } from 'components/shared';
import { SortableCartPorductsList } from 'components/product/CartTable/components/CartSortable';
import styles from './SortableCartProductGroupItem.module.scss';

type SortableCartProductGroupItemProps = {
  bu: string;
  products: Array<ICartProductItem>;
  onProductItemSortEnd: (args: CartSortableIndex) => void;
  findProductIndex: (product: ICartProductItem) => number;
  handleHistoricalPriceModal: (sku: string) => void;
};

const SortableCartProductGroupItem = SortableElement((props: SortableCartProductGroupItemProps) => {
  const { bu, products, onProductItemSortEnd, findProductIndex, handleHistoricalPriceModal } = props;
  return (
    <div>
      <Text type="subtitle-third" className={styles.groupName}>
        {typeof bu !== 'undefined' ? bu : ''}
      </Text>
      <div>
        <SortableCartPorductsList
          products={products}
          onSortEnd={onProductItemSortEnd}
          findProductIndex={findProductIndex}
          handleHistoricalPriceModal={handleHistoricalPriceModal}
          useDragHandle
          distance={5}
          lockAxis="y"
        />
      </div>
    </div>
  );
});

export default SortableCartProductGroupItem;
