import { FC } from 'react';
import { BILLING_TYPE } from '../CartRowInfo/types';
import CartRowInfo from '../CartRowInfo';
import { ICartRowContentProps } from './types';

const CartRowContent: FC<ICartRowContentProps> = (props: ICartRowContentProps) => {
  const { product, productIndex, displayHistoricalPriceModal } = props;

  return (
    <>
      <CartRowInfo
        product={product}
        productIndex={productIndex}
        displayHistoricalPriceModal={displayHistoricalPriceModal!}
        billingType={BILLING_TYPE.SINGLE}
      />
    </>
  );
};

export default CartRowContent;
