import { isSimpleCustomer } from 'utils/typeGuard.utils';
import { ICustomerGroupWithBillingDetails, ICustomerWithBillingDetails } from 'types/order';
import { ICustomer, ICustomerGroup, ISearchedCustomer } from 'types';
import { ICustomersGroupSelector } from 'store/customers/types';

export const getCustomerAddress = (customer: ICustomer): string => {
  const postcode = customer?.postcodeLabel ? `${customer.postcodeLabel} ` : '';
  const town = customer?.townLabel ? `${customer.townLabel} ` : '';
  return `${customer?.street}` + (postcode && town ? `, ${postcode} ${town}` : '');
};

export const getGroupLeader = (
  customersGroup: ICustomersGroupSelector[],
  groupId: number,
  leaderId: number | undefined,
): ICustomerGroup | undefined => {
  if (!leaderId) {
    return;
  }
  const selectdGroup = customersGroup.find((current) => current.groupId === groupId);
  if (!selectdGroup) {
    return;
  }
  return selectdGroup.customersList.find((groupCustomer) => groupCustomer.userId === leaderId);
};

export const isCustomerWithBillingDetails = (
  obj: ICustomerWithBillingDetails | ICustomerGroupWithBillingDetails,
): obj is ICustomerWithBillingDetails => {
  return obj.__typename === 'ICustomerWithBillingDetails';
};

export const isCustomerGroupWithBillingDetails = (
  obj: ICustomerWithBillingDetails | ICustomerGroupWithBillingDetails,
): obj is ICustomerGroupWithBillingDetails => {
  return obj.__typename === 'ICustomerGroupWithBillingDetails';
};

export const convertCustomerToSearchedCustomer = (customer: ICustomer): ISearchedCustomer => {
  const id = isSimpleCustomer(customer) ? customer.customerId : customer.id;
  const email = isSimpleCustomer(customer) ? customer.email : customer?.user?.email;
  const { createdAt: _createdAt, ...rest } = customer;
  return {
    ...rest,
    id,
    company: rest.company || '',
    email,
  };
};
