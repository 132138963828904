import { FC } from 'react';
import { Button } from 'antd';
import { IDeliveryMethodsCardProps } from './DeliveryMethodsCard.types';
import styles from './DeliveryMethodsCard.module.scss';

const DeliveryMethodsCard: FC<IDeliveryMethodsCardProps> = (props: IDeliveryMethodsCardProps) => {
  const { title, children, buttonText, onClick } = props;

  return (
    <div className={styles.card}>
      <div className={styles.card__title}>{title}</div>
      {children}
      <Button onClick={onClick} className={styles.card__button}>
        {buttonText}
      </Button>
    </div>
  );
};

export default DeliveryMethodsCard;
