import { getPathInfo } from 'utils/common.utils';
import { INormalizedStringList } from 'types';
import {
  ICartProductItem,
  ICartProductItemUnit,
  INormalizedVatList,
  ISelectAllCartTaxesResponse,
  SupplierPriceType,
} from 'store/cart/types';

type CartTotalCallback<T> = (acc: T, product: ICartProductItem) => T;

export const createCartTotalAccumulator = (products: Array<ICartProductItem>) => {
  return <T>(callack: CartTotalCallback<T>, initial: T): T => {
    return products.reduce<T>((acc, product) => {
      return callack(acc, product);
    }, initial);
  };
};

export const calcTotalWithoutTaxes = (cartTotal: ReturnType<typeof createCartTotalAccumulator>): number => {
  return cartTotal((acc, product) => acc + product.totalPrice, 0);
};

export const calcTotalWithTaxes = (cartTotal: ReturnType<typeof createCartTotalAccumulator>): number => {
  return cartTotal((acc, product) => acc + product.priceTotalWithTaxes, 0);
};

type GetSupplierUnitPriceArgs = {
  price?: {
    supplierUnitPrice?: number;
  };
  supplierPrice: SupplierPriceType | null;
};

export const getSupplierUnitPrice = (product: GetSupplierUnitPriceArgs): number =>
  product.supplierPrice ? product.supplierPrice.price : product.price?.supplierUnitPrice ?? 0;

export const calcTotalTaxes = (
  cartTotal: ReturnType<typeof createCartTotalAccumulator>,
): ISelectAllCartTaxesResponse => {
  return cartTotal(
    (acc, product) => {
      const { vatEnum } = acc;
      let { rpdSum, cvoSum, supplierSum } = acc;

      const vat = product.taxes.vat || 0;

      const productVat = product.totalPrice * vat;

      vatEnum[vat] = productVat + (vatEnum[vat] || 0);
      rpdSum = rpdSum + product.rpd;
      cvoSum = cvoSum + product.cvo;

      const supplierUnitPrice = getSupplierUnitPrice(product);

      supplierSum += supplierUnitPrice * product.quantity * product.quantityIncrement;

      return {
        vatEnum,
        rpdSum,
        cvoSum,
        supplierSum,
      };
    },
    {
      vatEnum: {} as INormalizedVatList,
      rpdSum: 0,
      cvoSum: 0,
      supplierSum: 0,
    },
  );
};

export const groupCartProducts = <T extends { bu: string }>(
  products: Array<T>,
  sort: Array<string> = [],
): INormalizedStringList<Array<T>> => {
  const unsorted = products.reduce<INormalizedStringList<Array<T>>>((acc, item) => {
    const { bu = 'Ungrouped' } = item;

    if (!Object.prototype.hasOwnProperty.call(acc, bu)) {
      acc[bu] = [];
    }

    acc[bu].push(item);

    return acc;
  }, {});

  if (!sort.length) {
    return unsorted;
  }

  const keys = Object.keys(unsorted);

  keys.sort((a, b) => {
    const aIndex = sort.indexOf(a);
    const bIndex = sort.indexOf(b);

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });

  return keys.reduce<INormalizedStringList<Array<T>>>((acc, key) => {
    acc[key] = unsorted[key];
    return acc;
  }, {});
};

type RenderUnitLabelArgs = {
  quantityIncrement: number;
  quantity: number;
  unit: ICartProductItemUnit;
};

export const renderUnitLabel = ({ quantityIncrement, quantity, unit }: RenderUnitLabelArgs): string =>
  quantityIncrement * quantity >= 2 ? unit.plural : unit.singular;

export const getAttachmentName = (path: string): string => getPathInfo(path).filename;
