import { ISavedCartFilters, SAVED_CART_STATUS } from 'store/savedCart/types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { camelCase } from 'lodash';
import { useDateFormat } from 'hooks/useDateFormat';
import { useDatePickerLocale } from 'hooks/locale/useDatePickerLocale';
import { usePermissions } from 'hooks';
import { Dayjs } from 'dayjs';
import { Button, Switch } from 'components/shared';
import SavedCartSearch from 'components/savedCart/SavedCartSearch/SavedCartSearch';
import { Form, Input, DatePicker, Space } from 'antd';
import { SavedCartStatuses } from '../../../../shared/SavedCartStatusFilter/SavedCartStatusFilter.types';
import SavedCartStatusFilter from '../../../../shared/SavedCartStatusFilter';
import { filterEmptyObjectKeys } from '../../../../../utils/order.utils';
import { useAppDispatch } from '../../../../../store/store';
import {
  clearFilters,
  selectSavedCartStates,
  setFilters,
  setPage,
  updateFilters,
} from '../../../../../store/savedCart';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import { SavedCartFiltersProps } from './types';
import styles from './styles.module.scss';

const ALLOWED_PERMISSIONS = ['QUOTE_CAN_LIST_TC_QUOTE'];
const SavedCartFilters: FC<SavedCartFiltersProps> = ({ filters }: SavedCartFiltersProps) => {
  const { archived, convertedPdf, assignedToMe, assignedTcCode } = filters;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const locale = useDatePickerLocale();
  const { t } = useTranslation();
  const dateFormatShort = useDateFormat('short');
  const [filteredStatus, setFilteredStatus] = useState<string | null>();
  const [datesFilter, setDatesFilter] = useState<[string, string] | null>(null);
  const states = useSelector(selectSavedCartStates);

  const { allowed } = usePermissions(ALLOWED_PERMISSIONS, false, false);
  const clearInputs = async () => {
    form.resetFields();
    dispatch(clearFilters());
    dispatch(setPage(1));
  };

  const onClearField = useCallback(() => {
    form.submit();
  }, [form]);

  const handleStatusChange = (newStatus: string) => {
    if (newStatus !== filteredStatus) {
      setFilteredStatus(newStatus);
    } else {
      setFilteredStatus(null);
    }
    form.submit();
  };

  const handleDateChange = (dates: [start: Dayjs | null, end: Dayjs | null] | null) => {
    const startDate = dates?.[0];
    const endDate = dates?.[1];
    startDate && endDate && setDatesFilter([startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]);
  };

  const filteredStatuses = useMemo(
    () =>
      SAVED_CART_STATUS.map(
        (status): SavedCartStatuses => ({
          value: status,
          label: t(`cartRequests.${camelCase(status)}`),
          count: states[status] || 0,
        }),
      ),
    [states, t],
  );

  const onFinish = (values: ISavedCartFilters) => {
    const saveCartFilterParameters = filterEmptyObjectKeys<ISavedCartFilters>({
      ...values,
      dateRangeCreate: datesFilter,
    });

    if (saveCartFilterParameters.dateRangeCreate?.length) {
      saveCartFilterParameters.createdStartDate = moment(saveCartFilterParameters.dateRangeCreate[0]).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
      saveCartFilterParameters.createdEndDate = moment(saveCartFilterParameters.dateRangeCreate[1]).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
    }

    delete saveCartFilterParameters.dateRangeCreate;
    dispatch(
      setFilters({
        ...saveCartFilterParameters,
        assignedTcCode,
        archived,
        convertedPdf,
        assignedToMe,
        status: filteredStatus,
      }),
    );
    dispatch(setPage(1));
  };

  const rangeConfig = {
    rules: [{ type: 'array' as const, message: 'Please select time!' }],
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <SavedCartStatusFilter statuses={filteredStatuses} value={filteredStatus} onChange={handleStatusChange} />
      </div>
      <div className={styles.filters}>
        <Form
          form={form}
          layout="horizontal"
          name="filters-form"
          onFinish={onFinish}
          className={styles.form}
          autoComplete="off"
          initialValues={{ remember: false }}
        >
          <div className={styles.filtersContainer}>
            <div className={styles.inputContainer}>
              <Form.Item name="search" label={''} className={styles.input}>
                <Input
                  size="large"
                  placeholder={String(t('saved_cart.clientInfo'))}
                  allowClear
                  onClear={onClearField}
                />
              </Form.Item>
            </div>

            <div className={styles.inputContainer}>
              <Form.Item name="customerIdentifier" className={styles.input}>
                <Input
                  size="large"
                  placeholder={String(t('saved_cart.thirdPartyNumber'))}
                  allowClear
                  onClear={onClearField}
                />
              </Form.Item>
            </div>

            <div className={styles.inputContainer}>
              <Form.Item name="dateRangeCreate" {...rangeConfig} className={styles.formRange}>
                <RangePicker
                  onChange={handleDateChange}
                  size="large"
                  locale={locale}
                  format={dateFormatShort}
                  className={styles.input}
                />
              </Form.Item>
            </div>

            <div className={styles.inputContainer}>
              <Form.Item name="atcCartNumber" className={styles.input}>
                <Input
                  size="large"
                  placeholder={String(t('saved_cart.orderNumber'))}
                  allowClear
                  onClear={onClearField}
                />
              </Form.Item>
            </div>

            {allowed && (
              <div className={styles.inputContainer}>
                <SavedCartSearch
                  value={assignedTcCode}
                  onChange={(user) => {
                    dispatch(updateFilters({ assignedTcCode: user?.code || null, assignedToMe: false }));
                  }}
                />
              </div>
            )}
          </div>

          <div className={styles.filterActions}>
            <Space direction="horizontal">
              <Switch
                label={String(t('saved_cart.includeOrderCarts'))}
                defaultChecked={convertedPdf}
                onChange={(checked) => {
                  dispatch(updateFilters({ convertedPdf: checked }));
                }}
              />
              <Switch
                label={String(t('saved_cart.includeArchivedCarts'))}
                defaultChecked={archived}
                onChange={(checked) => {
                  dispatch(updateFilters({ archived: checked }));
                }}
              />
              <Switch
                label={String(t('saved_cart.includeOnlyMine'))}
                defaultChecked={assignedToMe}
                onChange={(checked) => {
                  dispatch(updateFilters({ assignedToMe: checked, assignedTcCode: null }));
                }}
              />
            </Space>
            <Space direction="horizontal" style={{ marginLeft: 'auto' }}>
              <Button className={styles.filterButton} type="primary" htmlType="submit">
                {t('saved_cart.filterResults')}
              </Button>
              <Button className={styles.filterButton} type="dashed" onClick={clearInputs}>
                {t('saved_cart.deleteFilters')}
              </Button>
            </Space>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SavedCartFilters;
