import { priceParser } from 'utils';
import { selectDeliveryPricesSummary } from 'store/delivery';
import { getOrders, selectParsedOrdersItems } from 'store/cart';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useStepper } from 'hooks';
import { Summary, TextKeyValue } from 'components/shared';
import styles from './DeliverySummary.module.scss';

const DeliverySummary: FC = () => {
  const dispatch = useDispatch();
  const { goNextStep } = useStepper();
  const orders = useSelector(selectParsedOrdersItems);
  const { t } = useTranslation();
  const {
    shippingCategoriesPrices,
    totalProductsPrice,
    totalProductsPriceWithFees,
    totalShippingFees,
    totalProductsPriceWithFeesAndTaxes,
  } = useSelector(selectDeliveryPricesSummary);

  const onClickSubmit = async () => {
    dispatch(getOrders(orders));
    goNextStep();
  };

  return (
    <Summary onClick={onClickSubmit} buttonText={`${t('common.orderSummary')}`}>
      <TextKeyValue
        className={styles.price}
        size="large"
        label={t('common.totalProductHt') as string}
        value={priceParser(totalProductsPrice)}
      />
      <TextKeyValue
        className={styles.price}
        size="large"
        label={t('common.totalShippingCostWithoutVat') as string}
        value={priceParser(totalShippingFees)}
      />

      {shippingCategoriesPrices.map(
        (item) =>
          !!item.hasItems &&
          !!item.price && (
            <TextKeyValue
              className={styles.price}
              key={item.label}
              label={item.label}
              value={priceParser(item.price)}
            />
          ),
      )}

      <div className={styles.resultsBlock}>
        <TextKeyValue
          className={styles.price}
          size="large"
          label={t('common.netTotal') as string}
          value={priceParser(totalProductsPriceWithFees)}
        />
        <TextKeyValue
          className={styles.price}
          size="large"
          label={t('common.totalPrice') as string}
          value={priceParser(totalProductsPriceWithFeesAndTaxes)}
        />
      </div>
    </Summary>
  );
};

export default DeliverySummary;
