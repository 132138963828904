import { FC } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import LoginLogo from '../../../pages/Login/LoginLogo';
import styles from './ValidatedHeader.module.scss';

const ValidatedHeader: FC = () => {
  return (
    <PageHeader ghost={false} className={styles.header}>
      <LoginLogo />
    </PageHeader>
  );
};

export default ValidatedHeader;
