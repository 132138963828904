export const cart_requests_fr = {
  pageTitle: 'Demandes de devis client',
  cartRequestId: 'Demande N°',
  quoteNumber: 'N° Devis',
  client: 'Client',
  delivery: 'Livraison',
  deliveryPeriod: 'Periode de livraison',
  status: 'Statut',
  assignedTo: 'Assigné à',
  createdAt: 'Demande créée le',
  actions: 'Actions',
  draft: 'Brouillon',
  pending: 'En attente de chiffrage',
  inProgress: 'En cours de chiffrage',
  sentToCustomer: 'En attente de validation client',
  abandoned: 'Abandonné',
  recovered: 'Récupéré',
  converted: 'Converti',
  refused: 'Refusé',
  terminated: 'En attente de validation client',
  unassigned: 'Désassigner',
  assigned: 'Assigner à moi',
  actionButton: {
    pending: 'Chiffrer',
    inProgress: 'Chiffrer',
    sentToCustomer: 'Voir le devis',
    refused: 'Voir le devis',
    converted: 'Voir le devis',
  },
  processStatus: {
    0: 'Pas encore chiffré',
    1: 'Chiffré',
  },
  processedItems: 'Produits chiffrés',
  markAsQuoted: 'Marquer comme Chiffré',
  nameSurnameCompanyName: 'Nom, Prénom, Raison Sociale',
  productInfo: 'Info produit',
};
