import moment from 'moment';
import ObjectId from 'bson-objectid';

export type PathInfoType = {
  path: string;
  filename: string;
  name: string;
  extension: string;
  isHidden: boolean;
};

export const getPathInfo = (path: string): PathInfoType => {
  const newPath = path.split('?')[0];
  const filename = newPath.split('/').pop() || '';
  const split = filename?.split('.') || [];
  const extension = split?.pop() || '';
  let name = split?.join('.') || '';
  let isHidden = false;

  if (name?.length === 0) {
    name = filename;
    isHidden = true;
  }

  const result = {
    path,
    filename,
    name,
    extension,
    isHidden,
  };

  return result;
};

export const createObjectId = (): string => ObjectId().toHexString();
// create const with param tcCode string to generate string from tcCode and current date in format 'yymmddhhiiss' using momentjs
export const createAtcCartNumber = (tcCode: string): string => `${tcCode}-${moment().format('YYMMDDHHmmss')}`;

export const downloadFile = (props: { name: string; body: string }): void => {
  const { name, body } = props;
  const blob = URL.createObjectURL(body);
  const link = document.createElement('a');
  link.href = blob;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
