import { useAppDispatch, useAppSelector } from 'store/store';
import { selectCartProductByIndex, updateCartProductComment } from 'store/cart';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useState } from 'react';
import env from 'config';
import CartComment from 'components/product/CartTable/components/CartComment';
import ADButton from 'antd/lib/button';
import { Badge, Popover, Tooltip } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import styles from './ProductComment.module.scss';

interface ProductCommentProps {
  productIndex: number;
}
export const ProductComment: FunctionComponent<ProductCommentProps> = ({ productIndex }) => {
  const { t } = useTranslation();
  const [formVisible, setFormVisible] = useState(false);
  const product = useAppSelector(selectCartProductByIndex(productIndex));
  const { offerId, publicComment } = product;
  const dispatch = useAppDispatch();

  const handleCommentSave = (value: string) => {
    dispatch(
      updateCartProductComment({
        identifier: offerId,
        comment: value,
      }),
    );
  };

  if (!env('enableCartComments')) return null;

  return (
    <Tooltip title={t('cart.commentTooltip')}>
      <Popover
        content={
          <div className={styles.commentWrapper} onClick={(e) => e.stopPropagation()}>
            <CartComment
              key={offerId}
              comment={publicComment}
              onSave={handleCommentSave}
              placeholder={t('common.comment')}
              label={t('common.addCommentPlaceholder')}
              shortFlow={true}
            />
          </div>
        }
        popupVisible={formVisible}
        trigger={'click'}
        placement="left"
      >
        <Badge count={publicComment && publicComment?.length > 0 ? ' ' : undefined} size="small">
          <ADButton icon={<MessageOutlined />} type="text" shape="circle" onClick={() => setFormVisible(true)} />
        </Badge>
      </Popover>
    </Tooltip>
  );
};
export default ProductComment;
