import { useMatches, useNavigate } from 'react-router';
import { useCallback } from 'react';
import { useAppSelector } from '../store/store';
import { selectCartId, selectIsSavedCart } from '../store/cart';
import { getOrderPath } from '../constants/routes';
import { IStep, STEPPER } from 'constants/stepper';

export const getStepByIndex = (index: number): IStep | undefined =>
  Object.values(STEPPER).find((step) => step.index === index);

type IStepperHook = {
  goNextStep: (newOrder?: boolean) => void;
  goPrevStep: () => void;
  currentStep: IStep | undefined;
};

export const useStepper = (): IStepperHook => {
  const navigate = useNavigate();
  const route = useMatches();
  const cartId = useAppSelector(selectCartId);
  const isCartSaved = useAppSelector(selectIsSavedCart);

  const [matchRoute] = [...route].reverse();
  const getCurrentStep = useCallback(() => {
    return Object.values(STEPPER).find((step) => matchRoute?.id === step.routeId);
  }, [matchRoute]);

  const currentStep = getCurrentStep();

  const goNextStep = useCallback(
    (newOrder = false) => {
      if (currentStep?.nextIndex) {
        const nextStep = getStepByIndex(currentStep?.nextIndex);

        if (!nextStep) return false;
        if (cartId && !newOrder) {
          return navigate(getOrderPath(nextStep.route, cartId));
        }
        return navigate(getOrderPath(nextStep.route));
      }
    },
    [currentStep, navigate, cartId],
  );

  const goPrevStep = useCallback(() => {
    if (currentStep?.prevIndex !== undefined) {
      const prevStep = getStepByIndex(currentStep?.prevIndex);

      if (!prevStep) return false;
      if (cartId) {
        return navigate(getOrderPath(prevStep.route, cartId));
      }
      return navigate(getOrderPath(prevStep.route));
    }
  }, [currentStep, navigate, cartId]);

  return {
    goNextStep,
    goPrevStep,
    currentStep,
  };
};
