import { parseBool, parseDisplayOfferGroup, parseNumberOrDefault } from 'utils/global.utils';
import { Themes } from 'constants/theme';
import { DisplayOfferGroup } from './types/offer';

export type Env = ParitalUndefinned<{
  REACT_APP_BASE_URL: string;
  REACT_APP_ATC_CHECKOUT_BACKEND_URL: string;
  REACT_APP_ATC_CHECKOUT_BACKEND_V2_URL: string;
  REACT_APP_TOWN_BACKEND_URL: string;
  REACT_APP_PRODUCTS_BACKEND_URL: string;
  REACT_APP_GV_NO_PRICE_EDIT_OFFER_TYPES: string;
  REACT_APP_GV_NO_PRICE_EDIT_FLAG: string;
  REACT_APP_MS_SHIPPING_BACKEND_URL: string;
  REACT_APP_ORDERS_BACKEND_URL: string;
  REACT_APP_MS_AUTH_BASE_URL: string;
  REACT_APP_ALGOLIA_SEARCH_APP_ID: string;
  REACT_APP_ALGOLIA_SEARCH_API_KEY: string;
  REACT_APP_ALGOLIA_SEARCH_INDEX: string;
  REACT_APP_ALGOLIA_PRODUCTS_LIMIT: string;
  REACT_APP_ORDER_VALIDATION_BACKEND_URL: string;
  REACT_APP_FEATURE_ENABLE_MULTI_FACTURATION: string;
  REACT_APP_FEATURE_ENABLE_ADD_FARMER: string;
  REACT_APP_FEATURE_ENABLE_SAVE_CART: string;
  REACT_APP_THEME: string;
  REACT_APP_SHOP_URL: string;
  REACT_APP_FEATURE_ENABLE_WIUZ: string;
  REACT_APP_FEATURE_ENABLE_STOCK_COLUMN: string;
  REACT_APP_FEATURE_ENABLE_CART_COMMENTS: string;
  REACT_APP_FEATURE_ENABLE_EXPORT_PDF: string;
  REACT_APP_FEATURE_ENABLE_ORDER_VALIDATION: string;
  REACT_APP_FEATURE_ENABLE_ALGOLIA_LOG: string;
  REACT_APP_FEATURE_ENABLE_MULTI_SEARCH: string;
  REACT_APP_FEATURE_ENABLE_PDF_EXCLUDE_RPD: string;
  REACT_APP_FEATURE_ENABLE_GAMME_CGE: string;
  REACT_APP_FEATURE_ENABLE_ADD_PRODUCT_WITHOUT_OFFER: string;
  REACT_APP_MENU: string;
  REACT_APP_CART_ITEM_ATTACHMENTS_LINIT: string;
  REACT_APP_CART_ITEM_ATTACHMENT_MAX_SIZE: string;
  REACT_APP_CART_ITEM_ATTACHMENT_ALLOWED_EXTENSIONS: string;
  REACT_APP_FEATURE_SEARCH_WAREHOUSE_STOCK_COLUMN: string;
  REACT_APP_FEATURE_SEARCH_CATEGORY_FILTERS: string;
  REACT_APP_CUSTOMER_SERVICE_PHONE: string;
  REACT_APP_USER_GUIDE: string;
  REACT_APP_PRODUCTS_SEARCH_VIEW: string;
  REACT_APP_FEATURE_ENABLE_SEND_QUOTE_TO_CUSTOMER: string;
  REACT_APP_FEATURE_ENABLE_OVERRIDE_DELIVERY_DATE: string;
  REACT_APP_FEATURE_ENABLE_CART_REQUESTS: string;
  REACT_APP_FEATURE_ENABLE_ABANDONED_CARTS: string;
  REACT_APP_FEATURE_SEARCH_CATEGORY_FILTERS_FOR_CUSTOMER_QUOTATION: string;
  REACT_APP_FEATURE_ENABLE_LAST_PRICE: string;
  REACT_APP_FEATURE_ENABLE_PRICE_MARGIN: string;
  REACT_APP_FEATURE_ENABLE_RELOAD_SAVED_CART: string;
  REACT_APP_FEATURE_ENABLE_CUSTOMER_QUOTATION_UPDATE: string;
  REACT_APP_FEATURE_ALLOW_PLAIN_CART_ITEM: string;
  REACT_APP_FEATURE_ALLOW_CLONE_CART: string;
  REACT_APP_CHANNEL_CODE: string;
  REACT_APP_COUNTRY_CODE: string;
  REACT_APP_VERSION: string;
}>;

const { NODE_ENV } = process.env;

const isDevelopment = NODE_ENV === 'development';
const source = isDevelopment || NODE_ENV === 'test' ? process.env : window._env_;

const configParams = {
  appBaseUrl: source.REACT_APP_BASE_URL,
  atcCheckoutBackendUrl: source.REACT_APP_ATC_CHECKOUT_BACKEND_URL,
  atcCheckoutBackendV2Url: source.REACT_APP_ATC_CHECKOUT_BACKEND_V2_URL,
  townBackendUrl: source.REACT_APP_TOWN_BACKEND_URL,
  productsBackendUrl: source.REACT_APP_PRODUCTS_BACKEND_URL,
  noPriceEditOfferTypes: source.REACT_APP_GV_NO_PRICE_EDIT_OFFER_TYPES,
  noPriceEditFlag: source.REACT_APP_GV_NO_PRICE_EDIT_FLAG,
  msShippingBackendUrl: source.REACT_APP_MS_SHIPPING_BACKEND_URL,
  ordersBackendUrl: source.REACT_APP_ORDERS_BACKEND_URL,
  msAuthBaseUrl: source.REACT_APP_MS_AUTH_BASE_URL,
  algoliaSearchAppId: source.REACT_APP_ALGOLIA_SEARCH_APP_ID,
  algoliaSearchApiKey: source.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  algoliaSearchIndex: source.REACT_APP_ALGOLIA_SEARCH_INDEX,
  algoliaProductsLimit: Number(source.REACT_APP_ALGOLIA_PRODUCTS_LIMIT ?? 15),
  orderValidationBackendUrl: source.REACT_APP_ORDER_VALIDATION_BACKEND_URL,
  enableMultiFacturation: parseBool(source.REACT_APP_FEATURE_ENABLE_MULTI_FACTURATION ?? false),
  enableAddFarmer: parseBool(source.REACT_APP_FEATURE_ENABLE_ADD_FARMER ?? false),
  enableSaveCart: parseBool(source.REACT_APP_FEATURE_ENABLE_SAVE_CART ?? false),
  theme: (source.REACT_APP_THEME as Themes) || 'default',
  shopUrl: source.REACT_APP_SHOP_URL,
  featureWiuz: parseBool(source.REACT_APP_FEATURE_ENABLE_WIUZ ?? false),
  enableCartComments: parseBool(source.REACT_APP_FEATURE_ENABLE_CART_COMMENTS ?? false),
  enableStockColumn: parseBool(source.REACT_APP_FEATURE_ENABLE_STOCK_COLUMN ?? false),
  enableExportPdf: parseBool(source.REACT_APP_FEATURE_ENABLE_EXPORT_PDF ?? false),
  enableOrderValidation: parseBool(source.REACT_APP_FEATURE_ENABLE_ORDER_VALIDATION ?? false),
  enableAlgoliaLog: parseBool(source.REACT_APP_FEATURE_ENABLE_ALGOLIA_LOG ?? false),
  enableMultiSearch: parseBool(source.REACT_APP_FEATURE_ENABLE_MULTI_SEARCH ?? false),
  enablePdfExcludeRpd: parseBool(source.REACT_APP_FEATURE_ENABLE_PDF_EXCLUDE_RPD ?? false),
  enableGammeCge: parseBool(source.REACT_APP_FEATURE_ENABLE_GAMME_CGE ?? false),
  enableSearchWarehouseStockColumn: parseBool(source.REACT_APP_FEATURE_SEARCH_WAREHOUSE_STOCK_COLUMN ?? false),
  enableSearchCategoryFilters: parseBool(source.REACT_APP_FEATURE_SEARCH_CATEGORY_FILTERS ?? false),
  enableAddProductWithoutOffer: parseBool(source.REACT_APP_FEATURE_ENABLE_ADD_PRODUCT_WITHOUT_OFFER ?? false),
  customerServicePhone: source.REACT_APP_CUSTOMER_SERVICE_PHONE?.trim() || null,
  menu: source.REACT_APP_MENU || null,
  productsSearchView: parseDisplayOfferGroup(source.REACT_APP_PRODUCTS_SEARCH_VIEW, DisplayOfferGroup.NONE),
  cartItemAttachmentsLimit: parseNumberOrDefault(source.REACT_APP_CART_ITEM_ATTACHMENTS_LINIT, 5),
  get cartItemAttachmentMaxSize(): number {
    const defaultValue = '2MB';
    const mb = 1048576;
    const size = Number(
      (source.REACT_APP_CART_ITEM_ATTACHMENT_MAX_SIZE ?? defaultValue)
        .toLowerCase()
        .trim()
        .match(/([\d.]+)mb/i)?.[1],
    );
    return size * mb;
  },
  get cartItemAttachmentAllowedExtensions(): Array<string> {
    const defaultValue = 'pdf,jpg,jpeg,png';
    return (source.REACT_APP_CART_ITEM_ATTACHMENT_ALLOWED_EXTENSIONS ?? defaultValue)
      .split(',')
      .filter((ext) => ext.trim().length > 0);
  },
  userGuide: source.REACT_APP_USER_GUIDE || '',
  enableSendQuoteToCustomer: parseBool(source.REACT_APP_FEATURE_ENABLE_SEND_QUOTE_TO_CUSTOMER ?? false),
  enableOverrideDeliveryDate: parseBool(source.REACT_APP_FEATURE_ENABLE_OVERRIDE_DELIVERY_DATE ?? false),
  enableCartRequests: parseBool(source.REACT_APP_FEATURE_ENABLE_CART_REQUESTS ?? false),
  enableAbandonedCarts: parseBool(source.REACT_APP_FEATURE_ENABLE_ABANDONED_CARTS ?? false),
  enableSearchCategoryFiltersForCustomerQuotation: parseBool(
    source.REACT_APP_FEATURE_SEARCH_CATEGORY_FILTERS_FOR_CUSTOMER_QUOTATION ?? false,
  ),
  enableLastPrice: parseBool(source.REACT_APP_FEATURE_ENABLE_LAST_PRICE ?? false),
  enablePriceMargin: parseBool(source.REACT_APP_FEATURE_ENABLE_PRICE_MARGIN ?? false),
  enableReloadSavedCart: parseBool(source.REACT_APP_FEATURE_ENABLE_RELOAD_SAVED_CART ?? false),
  enableCustomerQuotationUpdate: parseBool(source.REACT_APP_FEATURE_ENABLE_CUSTOMER_QUOTATION_UPDATE ?? false),
  allowPlainCartItem: parseBool(source.REACT_APP_FEATURE_ALLOW_PLAIN_CART_ITEM ?? false),
  allowCloneCart: parseBool(source.REACT_APP_FEATURE_ALLOW_CLONE_CART ?? false),
  channelCode: source.REACT_APP_CHANNEL_CODE || '',
  countryCode: source.REACT_APP_COUNTRY_CODE || '',
};

export const config = {
  ...configParams,
  isDevelopment,
};

type Config = typeof config;

function env<K extends keyof Config>(key: K): Config[K] {
  return config[key];
}

export default env;
