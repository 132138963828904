import { calculateProductUnitCVO, calculateProductUnitRPD, createUrl } from 'utils/product.utils';
import { PDFProductItem, ShippingDateLabel, TypedICartProductItem } from 'types/pdf';
import { IOrderItem } from 'types/order';
import { ICategorizedShippingItems } from 'store/delivery/types';
import moment from 'moment';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';

type CreatePDFProductItemOptions = {
  orderItem: IOrderItem | undefined;
  shippingCategory: ICategorizedShippingItems;
};

export const createPDFProductItem = (
  product: TypedICartProductItem,
  options: CreatePDFProductItemOptions,
): PDFProductItem => {
  const { orderItem, shippingCategory } = options;

  const {
    bu,
    name,
    taxes,
    amm,
    publicComment: comment,
    replacement,
    productId,
    variantId,
    offerId,
    unit,
    conditioning,
    conditioningQuantity,
    quantity,
    quantityIncrement,
    offerPrice,
    totalPrice,
    priceTotalWithTaxes,
    rpd,
    cvo,
    productOptions,
    label,
    discountValue,
    discountType,
    overriddenDeliveryStartDate,
    overriddenDeliveryEndDate,
    deliveryOverridden,
  } = product;

  let initialPriceValue = 0;
  const { price } = product;
  if (price) {
    const { initialPrice, price: productPrice } = price;
    initialPriceValue = initialPrice ?? productPrice;
  }

  const isDeliveryOverridden = !!(deliveryOverridden && overriddenDeliveryStartDate && overriddenDeliveryEndDate);

  return {
    productId,
    variantId,
    offerId,
    position: 0,
    amm,
    bu,
    name,
    conditioning,
    conditioningQuantity,
    quantity,
    quantityIncrement,
    volume: quantity * quantityIncrement,
    volumeText: quantity * quantityIncrement >= 2 ? unit.plural : unit.singular,
    unitPrice: offerPrice,
    totalWithoutTax: totalPrice,
    totalWithTax: priceTotalWithTaxes,
    taxes,
    rpd: {
      unit: calculateProductUnitRPD(product),
      total: rpd,
    },
    cvo: {
      unit: calculateProductUnitCVO(product),
      total: cvo,
    },
    comment,
    replacement,
    inStock: !product.noStock,
    url: createUrl(productId),
    options: productOptions || '',
    unit,
    delivery: {
      // TODO: Refactor this method
      date: getDeliveryDate({
        product: {
          ...product,
          startDate: isDeliveryOverridden
            ? overriddenDeliveryStartDate
            : orderItem
            ? orderItem.deliveryStartDate
            : product.startDate,
          endDate: isDeliveryOverridden
            ? overriddenDeliveryEndDate
            : orderItem
            ? orderItem.deliveryEndDate
            : product.endDate,
          days: isDeliveryOverridden ? undefined : orderItem ? orderItem.deliveryDay : product.days,
        },
        type: orderItem ? 'orders' : 'product',
        shippingCategory,
      }),
      method: shippingCategory?.label || '',
    },
    label,
    discountValue,
    discountType,
    initialUnitPrice: initialPriceValue,
  };
};

const datesFormat = CUSTOM_DATE_FORMAT.fullMonthWithDay;

const renderDays = (label: ShippingDateLabel, minDelay?: number, maxDelay?: number): string => {
  if (minDelay && maxDelay) {
    const hasSameDelay = minDelay === maxDelay;
    const minDelayFormatted = moment().add({ days: minDelay }).utc().format(datesFormat);
    const maxDelayFormatted = moment().add({ days: maxDelay }).utc().format(datesFormat);
    const { on, between } = label;
    return hasSameDelay ? `${on} ${minDelayFormatted}` : `${between} ${minDelayFormatted} - ${maxDelayFormatted}`;
  }
  return '';
};

const renderDates = (label: ShippingDateLabel, startDate?: string, endDate?: string): string => {
  const momentStartDate = moment.utc(startDate).startOf('day').format(datesFormat);
  const momentEndDate = moment.utc(endDate).startOf('day').format(datesFormat);
  const { between } = label;
  return `${between} ${momentStartDate} - ${momentEndDate}`;
};

type DeliveryDateProduct = Pick<
  TypedICartProductItem,
  'days' | 'startDate' | 'endDate' | 'shippingMethods' | 'isTruck' | 'noStock'
>;

type DeliveryDateType = 'product' | 'orders';
type GetDeliveryDateOptions = {
  product: DeliveryDateProduct;
  type: DeliveryDateType;
  shippingCategory?: ICategorizedShippingItems;
};

export const getDeliveryDate = (options: GetDeliveryDateOptions): string => {
  const { product, type, shippingCategory } = options;
  const { days, startDate, endDate, shippingMethods: methods, isTruck, noStock } = product;
  const none = 'N/C';

  if (noStock) {
    return none;
  }

  const label: ShippingDateLabel = {
    before: `Livraison avant le:`,
    on: `Livraison le:`,
    between: `Livraison estimée entre:`,
  };

  const { before } = label;

  if (typeof days === 'number') {
    return `${before} ${moment().add({ days }).utc().format(datesFormat)}`;
  }

  if (isTruck) {
    return renderDates(label, startDate, endDate);
  }

  if (!isTruck && startDate && endDate) {
    return renderDates(label, startDate, endDate);
  }

  if (type === 'orders' && shippingCategory) {
    return renderDays(label, shippingCategory.minDelay, shippingCategory.maxDelay);
  }

  if (type === 'product' && methods?.length) {
    const method = methods.find(({ id }) => id === shippingCategory?.shippingMethodId);

    if (!method) {
      throw new Error('No shipping method was found.');
    }

    return renderDays(label, method.minDelay, method.maxDelay);
  }

  return none;
};
