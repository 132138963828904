import { unwrap } from 'utils/api.utils';
import { useAppSelector } from 'store/store';
import {
  selectCartHasAttachments,
  selectCartId,
  selectIsAnyProductSelected,
  selectIsSavedCart,
  removeProduct,
  selectCartProducts,
} from 'store/cart';
import { CartService } from 'services/cart.service';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Button, Modal, Text } from 'components/shared';
import ADButton from 'antd/lib/button';
import { Col, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const DeleteAllProducts: FC = () => {
  const isCartEmpty = useSelector(selectIsAnyProductSelected);
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const selectedCartId = useSelector(selectCartId);
  const isCartSaved = useSelector(selectIsSavedCart);
  const cartHasAttachments = useAppSelector(selectCartHasAttachments);
  const products = useAppSelector(selectCartProducts);

  const handleOpen = () => setIsModalVisible(true);
  const handleClose = () => setIsModalVisible(false);

  const onClickConfirm = async () => {
    for (const product of products || []) {
      dispatch(removeProduct(product.offerId));
    }

    if (!isCartSaved && selectedCartId && cartHasAttachments) {
      unwrap(await CartService.deleteAllCartAttachments(selectedCartId));
    }

    handleClose();
  };

  return (
    <>
      <ADButton
        className={styles.button}
        icon={<DeleteOutlined />}
        shape="circle"
        disabled={!isCartEmpty}
        type="dashed"
        color="red"
        size="large"
        danger
        onClick={handleOpen}
      />
      <Modal width="460px" className={styles.modal} open={isModalVisible} onCancel={handleClose}>
        <Row>
          <Col span={22}>
            <Text type="title">{t('common.emptyCartTitle')}</Text>
          </Col>
          <Col span={22}>
            <Row className={styles.buttons} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={11}>
                <Button onClick={onClickConfirm}>{t('common.confirm')}</Button>
              </Col>
              <Col span={11}>
                <Button onClick={handleClose} type="dashed">
                  {t('common.cancel')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={2} />
        </Row>
      </Modal>
    </>
  );
};

export default DeleteAllProducts;
