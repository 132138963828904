export const saved_cart_en = {
  savedCarts: 'Quotes',
  atcCartNumber: 'No quote',
  clientInfo: 'Client info',
  nameSurname: 'Name, Surname',
  creationDate: 'Creation date',
  startDate: 'Start date',
  endDate: 'End date',
  expiryDate: 'Expiry date',
  step: 'Step',
  conversionDate: 'Conversion date',
  includeArchivedCarts: 'Include archived quotes',
  includeOrderCarts: 'Include quotes converted to orders',
  in7Days: 'In 7 days',
  in3Days: 'In 3 days',
  details: 'Details',
  restore: 'Restore',
  archive: 'Archive',
  clone: 'Clone',
  deleteFilters: 'Delete filters',
  filterResults: 'Filter results',
  client: 'Client',
  netTotal: 'Total excl. VAT',
  downloadPdf: 'Download PDF',
  unarchive: 'Unarchive',
  productInfo: 'Product info',
  sku: 'SKU',
  qty: 'Qty',
  up: 'UP',
  total: 'Total incl. VAT',
  code: 'Code',
  status: 'Status',
  state: 'State',
  tableColumnCreationDate: 'Created at',
  thirdPartyNumber: 'Third party number',
  orderNumber: 'Order number',
  restoreNotAllowed: 'No permissions to allow cart restore.',
  archiveNotAllowed: 'No permissions to allow cart archive.',
  assign: 'Assign',
  assignedTo: 'Assigned to {{assignedTcName}} ({{assignedTcCode}})',
  assignTcMessage: 'Assign a TC',
  includeOnlyMine: 'Show only my quotes',
  cloneModalConfirmationTitle: 'Are you sure you want to clone the cart?',
  restoreModalPriceChangeSingular: 'The following product has been updated because the price has changed:',
  restoreModalPriceChangePlural: 'The following products have been updated because the price has changed:',
  restoreModalPartialStockSingular: 'The following product is not available due to lack of stock:',
  restoreModalPartialStockPlural: 'The following products are not available due to lack of stock:',
  restoreModalOutOfStockSingular: 'The following product is unavailable due to out of stock:',
  restoreModalOutOfStockPlural: 'The following products are not available due to out of stock:',
  restoreModalNoProductFoundSingular: 'The following product is not available because they no longer exist:',
  restoreModalNoProductFoundPlural: 'The following products are not available because they no longer exist:',
  restoreModalNoOfferFoundSingular:
    'The following offer is no longer available because it has expired. Please select a new offer using the button ',
  restoreModalNoOfferFoundPlural:
    'The following offers are no longer available because they have expired. Please select a new offer using the button ',
};
