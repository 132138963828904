import { DetailedCustomerTypename, ICustomer, ISimpleCustomer } from 'types';
import { ApiOkResponse } from 'apisauce';

export const isApiOkResponse = <K>(object: unknown): object is ApiOkResponse<K> =>
  (object as ApiOkResponse<K>)?.ok && (object as ApiOkResponse<K>).ok;

export const isTownAvailable = <K>(objectTownResponse: unknown): objectTownResponse is ApiOkResponse<K> =>
  (objectTownResponse as ApiOkResponse<K>).data !== null;

export const isSimpleCustomer = (
  customer: (ICustomer & { __typename?: DetailedCustomerTypename }) | ISimpleCustomer,
): customer is ISimpleCustomer => customer.__typename === 'SimpleCustomer';
