import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button, Text } from 'components/shared';
import { ProductFileImportConfirmationProps } from './types';
import styles from './ProductFileImportConfirmation.module.scss';

const ProductFileImportConfirmation: FC<ProductFileImportConfirmationProps> = ({
  closeModal,
}: ProductFileImportConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.productImportContainer}>
        <Text type="title" className={`${styles.title} ${styles.productImportTitle}`}>
          {t('common.fileImported')} !
        </Text>
        <Button size="large" type="primary" className={styles.button} onClick={() => closeModal()}>
          {t('common.closeWindow')}
        </Button>
      </div>
    </div>
  );
};

export default ProductFileImportConfirmation;
