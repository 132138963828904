import { ErrorType } from 'types';
import {
  CANCEL_ERROR,
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  PROBLEM_CODE,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  UNKNOWN_ERROR,
} from 'apisauce';
export type ATCErrorDetail = {
  code: number;
  message: string;
};
export type ATCError = {
  errors: ATCErrorDetail[];
  stacktrace?: string;
  id: string;
  timestamp: string;
  type: string;
};

export const setAxiosError = (error: PROBLEM_CODE | 'UNAUTHORIZED_ERROR', data: any = {}): ErrorType => {
  switch (error) {
    case CLIENT_ERROR: {
      const errors = Array.of<ATCErrorDetail>(...data.errors);
      if (data?.type === 'ValidationException') {
        return {
          code: 3,
          name: 'validation-error',
          showServerMessage: true,
          message: data?.errors.map((err: any) => err.message),
        };
      }
      if (data?.type === 'ForbiddenException') {
        return {
          code: errors[0].code,
          name: 'forbidden-error',
          message: errors[0].message,
        };
      }
      if (data?.type === 'NotFoundException') {
        return {
          code: errors[0].code,
          name: 'not-found-error',
          message: errors[0].message,
        };
      }
      if (data?.type === 'BadRequestException') {
        return {
          code: errors[0].code,
          name: 'bad-request-error',
          message: errors[0].message,
        };
      }
      if (data?.type === 'UnprocessableEntityException') {
        return {
          code: errors[0].code,
          name: 'unprocessable-entity-error',
          message: errors[0].message,
        };
      }

      return {
        code: 5,
        name: 'client-error',
        message: 'Erreur client',
        details: data,
      };
    }

    case SERVER_ERROR: {
      if (data instanceof Blob) {
        return {
          code: 5004,
          name: 'file-transfer-error',
          showServerMessage: false,
          message: 'Erreur interne du serveur',
        };
      }

      const errors = Array.of<ATCErrorDetail>(...(data?.errors ?? []));
      const isInternalError = errors[0]?.code >= 5000 && errors[0]?.code < 6000;
      if (isInternalError) {
        return {
          code: errors[0].code,
          name: 'internal-server-error',
          message: errors[0].message,
          traceId: data.id,
        };
      }

      return {
        code: 5004,
        name: 'internal-server-error',
        showServerMessage: false,
        message: 'Erreur interne du serveur',
        traceId: data.id,
      };
    }
    case TIMEOUT_ERROR:
      return {
        code: 5,
        name: 'timeout-error',
        showServerMessage: true,
        message: `Expiration du délai de connexion (${data.server})`,
      };
    case CONNECTION_ERROR:
      return {
        code: 5,
        name: 'connection-error',
        showServerMessage: true,
        message: `Erreur de connexion (${data.server})`,
      };
    case NETWORK_ERROR:
      return {
        code: 5,
        name: 'network-error',
        showServerMessage: true,
        message: `Erreur de réseau (${data.server})`,
      };
    case UNKNOWN_ERROR:
      return {
        code: 5,
        name: 'unknown-error',
        showServerMessage: true,
        message: 'Erreur inconnue',
      };
    case CANCEL_ERROR:
      return {
        code: 5,
        name: 'cancel-error',
        showServerMessage: true,
        message: "Annuler l'erreur",
      };
    case 'UNAUTHORIZED_ERROR':
      return {
        code: 7,
        name: 'unauthorized-error',
        showServerMessage: true,
        message: 'Non autorisée',
      };

    default:
      return {
        code: 5,
        name: 'axios-error',
        showServerMessage: true,
        message: 'Erreur axios',
      };
  }
};
