import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useEffect } from 'react';
import { Input, Table } from 'antd';
import { Modal, Text } from '../../../../shared';
import { priceParser } from '../../../../../utils';
import { ICustomModalProps } from '../../../../../types';
import { selectUserTcCode } from '../../../../../store/user';
import {
  IHistoricalPriceDataItem,
  IHistoricalPriceDataItemYear,
  IHistoricalPriceGlobalDataItem,
  IHistoricalPriceGlobalDataItemFiscalYear,
  IHistoricalPricePerSkuItem,
} from '../../../../../store/cart/types';
import {
  getHistoricalPriceData,
  getHistoricalPriceLoadingState,
  getHistoricalPricePerSku,
  getHistoricalPriceSku,
  setHistoricalPriceModalSku,
} from '../../../../../store/cart';
import { useTheme } from '../../../../../hooks/useTheme';
import styles from './styles.module.scss';
import inputStyles from './../../../ProductPageHeader/components/ProductSearch/styles.module.scss';

const HistoricalPriceModal: FC<ICustomModalProps> = (props: ICustomModalProps) => {
  const { visible, showModal } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const themeStyle = useTheme(inputStyles);
  const sku = useSelector(getHistoricalPriceSku);
  const tcCode = useSelector(selectUserTcCode);
  const isLoading = useSelector(getHistoricalPriceLoadingState);
  const historicalPriceData: IHistoricalPriceDataItem | undefined = useSelector(getHistoricalPricePerSku);
  const historicalGlobalPriceDatasource = useCallback(() => {
    if (historicalPriceData) return [historicalPriceData.global];
    return [];
  }, [historicalPriceData]);
  const historicalPricesDatasource = useCallback(() => {
    if (historicalPriceData) return historicalPriceData.items;
    return [];
  }, [historicalPriceData]);

  const fetchHistoricalPriceData = useCallback(() => {
    if (sku && tcCode) {
      dispatch(getHistoricalPriceData({ sku, tcCode }));
    }
  }, [dispatch, sku, tcCode]);

  useEffect(() => {
    if (visible && sku) {
      fetchHistoricalPriceData();
    }
  }, [fetchHistoricalPriceData, sku, visible]);

  const closeModal = () => {
    showModal(false);
  };

  const updateSku = (value: string) => {
    dispatch(setHistoricalPriceModalSku(value));
  };

  const GLOBAL_TABLE_COLUMNS: Array<TableColumnDefinition<IHistoricalPriceGlobalDataItem>> = [
    {
      title: <span>{t('modals.historicalPriceTableHeaderCa')} N</span>,
      dataIndex: 'current_fiscal_year',
      render: ({ ca }: IHistoricalPriceGlobalDataItemFiscalYear) => <div>{priceParser(ca)}</div>,
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderQty')} N</span>,
      dataIndex: 'current_fiscal_year',
      render: ({ totalQuantity }: IHistoricalPriceGlobalDataItemFiscalYear) => <div>{totalQuantity}</div>,
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderPmv')} N</span>,
      dataIndex: 'current_fiscal_year',
      render: ({ average_unit_price }: IHistoricalPriceGlobalDataItemFiscalYear) => (
        <div>{priceParser(average_unit_price)}</div>
      ),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderCa')} N-1</span>,
      dataIndex: 'last_fiscal_year',
      render: ({ ca }: IHistoricalPriceGlobalDataItemFiscalYear) => <div>{priceParser(ca)}</div>,
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderQty')} N-1</span>,
      dataIndex: 'last_fiscal_year',
      render: ({ totalQuantity }: IHistoricalPriceGlobalDataItemFiscalYear) => <div>{totalQuantity}</div>,
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderPmv')} N-1</span>,
      dataIndex: 'last_fiscal_year',
      render: ({ average_unit_price }: IHistoricalPriceGlobalDataItemFiscalYear) => (
        <div>{priceParser(average_unit_price)}</div>
      ),
    },
  ];

  const HISTORICAL_PRICES_TABLE_COLUMNS: Array<TableColumnDefinition<IHistoricalPricePerSkuItem>> = [
    {
      title: <span>{t('modals.historicalPriceTableHeaderCommercial')}</span>,
      dataIndex: 'atcName',
      width: '30%',
      render: (atcName: string) => <div> {atcName}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) => a.atcName.localeCompare(b.atcName),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderCustomerIdentifier')}</span>,
      dataIndex: 'customerIdentifier',
      width: '10%',
      render: (customerIdentifier: string) => <div>{customerIdentifier}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        a.customerIdentifier.localeCompare(b.customerIdentifier),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderCustomerAccount')}</span>,
      dataIndex: 'customerName',
      width: '12%',
      render: (customerName: string) => <div>{customerName}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        a.customerName.localeCompare(b.customerName),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderCa')} N</span>,
      dataIndex: 'current_fiscal_year',
      width: '8%',
      render: ({ ca }: IHistoricalPriceDataItemYear) => <div>{priceParser(parseFloat(ca))}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        parseFloat(a.current_fiscal_year!.ca) - parseFloat(b.current_fiscal_year!.ca),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderQty')} N</span>,
      dataIndex: 'current_fiscal_year',
      width: '8%',
      render: ({ totalQuantity }: IHistoricalPriceDataItemYear) => <div>{parseFloat(totalQuantity)}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        parseFloat(a.current_fiscal_year!.totalQuantity) - parseFloat(b.current_fiscal_year!.totalQuantity),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderPmv')} N</span>,
      dataIndex: 'current_fiscal_year',
      width: '8%',
      render: ({ averageUnitPrice }: IHistoricalPriceDataItemYear) => (
        <div>{priceParser(parseFloat(averageUnitPrice))}</div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        parseFloat(a.current_fiscal_year!.averageUnitPrice) - parseFloat(b.current_fiscal_year!.averageUnitPrice),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderCa')} N-1</span>,
      width: '8%',
      dataIndex: 'last_fiscal_year',
      render: ({ ca }: IHistoricalPriceDataItemYear) => <div>{priceParser(parseFloat(ca))}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        parseFloat(a.last_fiscal_year!.ca) - parseFloat(b.last_fiscal_year!.ca),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderQty')} N-1</span>,
      dataIndex: 'last_fiscal_year',
      width: '8%',
      render: ({ totalQuantity }: IHistoricalPriceDataItemYear) => <div>{parseFloat(totalQuantity)}</div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        parseFloat(a.last_fiscal_year!.totalQuantity) - parseFloat(b.last_fiscal_year!.totalQuantity),
    },
    {
      title: <span>{t('modals.historicalPriceTableHeaderPmv')} N-1</span>,
      dataIndex: 'last_fiscal_year',
      width: '8%',
      render: ({ averageUnitPrice }: IHistoricalPriceDataItemYear) => (
        <div>{priceParser(parseFloat(averageUnitPrice))}</div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: IHistoricalPricePerSkuItem, b: IHistoricalPricePerSkuItem) =>
        parseFloat(a.last_fiscal_year!.averageUnitPrice) - parseFloat(b.last_fiscal_year!.averageUnitPrice),
    },
  ];

  const globalTable = (
    <Table
      columns={GLOBAL_TABLE_COLUMNS}
      size={'small'}
      tableLayout={'auto'}
      loading={isLoading}
      dataSource={historicalGlobalPriceDatasource()}
      rowKey={(_, index) => `g_row_${index?.toString()}`}
      pagination={false}
    ></Table>
  );
  const historicalPriceTable = (
    <Table
      columns={HISTORICAL_PRICES_TABLE_COLUMNS}
      size={'small'}
      loading={isLoading}
      dataSource={historicalPricesDatasource()}
      scroll={{ y: 300 }}
      rowKey={(_, index) => `hp_row_${index?.toString()}`}
      pagination={false}
    ></Table>
  );

  return (
    <Modal className={styles.modal} destroyOnClose={true} footer={null} open={visible} onCancel={closeModal} title={''}>
      <div className={styles.tableMargin}>
        <Input.Group className={themeStyle('inputGroup')}>
          <Text type={'title-gray8'} className={styles.middle}>
            {t('common.codeProduct')}
          </Text>
          <Input.Search
            className={inputStyles.input}
            size="large"
            placeholder={t('forms.productSearchPlaceholder') as string}
            loading={isLoading}
            defaultValue={sku ?? ''}
            enterButton
            onSearch={updateSku}
          />
        </Input.Group>
      </div>
      <Text type={'title-gray3'} className={styles.tableMargin}>
        {t('common.volumePerItem')}
      </Text>
      <div>{globalTable}</div>
      <Text type={'title-gray3'} className={styles.tableMargin}>
        {t('common.volumePerItemPerCustomer')}
      </Text>
      <div className={styles.maxHeight}>{historicalPriceTable}</div>
    </Modal>
  );
};

export default HistoricalPriceModal;
