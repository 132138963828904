export function isEmpty(obj = {}): boolean {
  return Object.keys(obj).length === 0;
}

export function objSize(obj = {}): number {
  return Object.keys(obj).length;
}

export const isContainableObjectEmpty = (param: Array<unknown> | Record<string, unknown> | undefined): boolean => {
  return typeof param === 'undefined'
    ? true
    : Array.isArray(param)
    ? param.length === 0
      ? true
      : Object(param).every((object: Record<string, unknown>) => Object.values(object).every((value) => value === null))
    : Object.values(param).every((x) => x === null);
};
