import { FC } from 'react-router/node_modules/@types/react';
import Price from 'components/shared/Price/Price';
import styles from './InitialPrice.module.scss';

type InitialPriceProps = {
  price: number;
  initialPrice?: number;
  discount?: number;
  unit?: string;
};

const InitialPrice: FC<InitialPriceProps> = ({ price, initialPrice, unit }: InitialPriceProps) => {
  const isInitialPriceDifferentRowPrice = initialPrice !== undefined && initialPrice !== price;

  if (typeof initialPrice !== 'undefined' && isInitialPriceDifferentRowPrice) {
    return <Price unitPrice={initialPrice} className={styles.price} unit={unit} />;
  }

  if (typeof price !== 'undefined') {
    return <Price unitPrice={price} className={styles.price} unit={unit} />;
  }

  return null;
};

export default InitialPrice;
