import { unwrap } from 'utils/api.utils';
import { handleCatchError } from 'utils';
import { ErrorType } from 'types';
import { GroupsResponse, UserDeleteResponse, UserResponse, UserService } from 'services';
import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUsersState } from './types';

export const USERS_REDUCER_NAME = 'users';

const initialState: IUsersState = {
  isLoading: false,
  error: null,
  groups: [],
  users: [],
  currentPage: 0,
  paginationTokens: [],
};

export const fetchAllUsers: AsyncThunk<
  UserResponse,
  Record<string, string | number | Record<string, string>>,
  { rejectValue: ErrorType }
> = createAsyncThunk(`${USERS_REDUCER_NAME}/fetchAllUsers`, async (args, { rejectWithValue }) => {
  try {
    return unwrap(await UserService.getUsers(args));
  } catch (err) {
    return handleCatchError(err, rejectWithValue);
  }
});

export const fetchAllUsersInGroup: AsyncThunk<
  UserResponse,
  Record<string, string | number | Record<string, string>>,
  { rejectValue: ErrorType }
> = createAsyncThunk(`${USERS_REDUCER_NAME}/fetchAllUsers`, async (args, { rejectWithValue }) => {
  try {
    return unwrap(await UserService.getUsersInGroup(args));
  } catch (err) {
    return handleCatchError(err, rejectWithValue);
  }
});

export const hydrateUserGroups: AsyncThunk<GroupsResponse, string[], { rejectValue: ErrorType }> = createAsyncThunk(
  `${USERS_REDUCER_NAME}/hydrateUserGroups`,
  async (userIds, { rejectWithValue }) => {
    try {
      return unwrap(await UserService.getGroups(userIds));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const deleteUser: AsyncThunk<UserDeleteResponse, string, { rejectValue: ErrorType }> = createAsyncThunk(
  `${USERS_REDUCER_NAME}/deleteUser`,
  async (username, { rejectWithValue }) => {
    try {
      unwrap(await UserService.deleteUser(username));
      return { username };
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

const usersSlice = createSlice({
  name: USERS_REDUCER_NAME,
  initialState,
  reducers: {
    clearUserState() {
      return { ...initialState };
    },
    resetPaginationToken(state) {
      state.paginationTokens = [];
    },
    prevPage(state) {
      state.currentPage = state.currentPage - 1 === 0 ? 0 : state.currentPage - 1;
    },
    nextPage(state) {
      state.currentPage = state.currentPage + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
      state.users = payload?.Users || [];
      if (payload?.PaginationToken && !state.paginationTokens.includes(payload?.PaginationToken)) {
        if (state.paginationTokens.length === 0) {
          state.paginationTokens.push('');
        }

        state.paginationTokens.push(payload?.PaginationToken);
      }

      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAllUsers.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });
    builder.addCase(hydrateUserGroups.fulfilled, (state, { payload }) => {
      state.users = state.users.map((user) => {
        return { ...user, Groups: payload?.find((group) => group.username === user.username)?.Groups || [] };
      });
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.users = state.users.filter((user) => user.Username !== payload?.username);
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });
  },
});

export const { clearUserState, resetPaginationToken, prevPage, nextPage } = usersSlice.actions;
export default usersSlice.reducer;
