import { SortableContainer } from 'react-sortable-hoc';
import React from 'react';
import styles from './SortableCartProductGroup.module.scss';

type SortableCartProductGroupProps = {
  children: React.ReactNode;
};

const SortableCartProductGroup = SortableContainer((props: SortableCartProductGroupProps) => {
  const { children } = props;
  return <div className={styles.wrapper}>{children}</div>;
});

export default SortableCartProductGroup;
