export const date_fr = {
  day: 'jour',
  days: 'jours',
  hour: 'heure',
  hours: 'heures',
  minute: 'minute',
  minutes: 'minutes',
  second: 'seconde',
  seconds: 'secondes',
  in: 'dans',
  ago: 'il y a',
  and: 'et',
};
