export const errors_fr = {
  invalidStepNumber: 'Start by choosing a farmer',
  invalidQuantitySelected: 'Une partie de la quantité d’un produit sélectionné n’a pas été attribuée',
  noData: 'Pas de données',
  minimumFourCharacters: 'Veuillez saisir au moins {{count}} caractères',
  noCertificateFound: 'Aucun certificat trouvé',
  noAddressProvided: "Vous n'avez pas indiqué d'adresse",
  invalidDifferentPostalCode:
    'Le code postal de votre adresse de livraison est différent de celui introduit initialement sur l’étape 2',
  invalidFileUpload: 'Une erreur est survenue. Impossible de télécharger le fichier',
  noFilesFound: 'Aucun fichier trouvé',
  invalidDiscountPercentageValue: 'Le pourcentage doit être compris entre 0% et 100%',
  invalidDiscountAmountValue: 'Le montant doit être compris entre 0 et le prix de l’article',
  cartAttachmentExceededLimit: 'Vous avez atteint la limite de téléchargement.',
  cartAttachmentUnsupportedFileFormat: 'Le type de fichier n’est pas pris en charge.',
  cartAttachmentUnexpectedError: 'Erreur de téléchargement inattendue pour ({{count}}) fichiers.',
  discountValueTooHigh: 'La remise est trop élevée, veuillez diminuer.',
  offer_without_shipping_fees: 'Cette méthode de livraison n’est pas disponible pour les offres {{ids}}.',
  offer_not_found: 'Offres introuvables: {{ids}}.',
  notAuthorized: 'Vous n’êtes pas autorisé à accéder à cette page',
  userNotFound: 'Utilisateur introuvable',
  missingTcCode: 'Vous n’êtes pas un TC valide',
  saveCartBeforeFileUpload: 'Veuillez sauvegarder votre panier pour ajouter une pièce jointe.',
  1: 'Accès interdit',
  2: 'Non trouvé',
  3: 'Erreur de validation',
  4: 'Erreur',
  5: 'Erreur client',
  6: 'Erreur serveur',
  7: 'Non autorisé',
  9: 'Erreur',
  201: "Aucun produit n'a été trouvé",
  1000: 'Impossible d’accéder à la ressource',
  1001: 'Vous n’êtes pas connecté',
  1002: 'Vous n’avez pas les permissions',
  1003: 'Vous n’êtes pas autorisé à voir ce devis',
  2000: 'Panier enregistré introuvable',
  2001: 'Demande de panier introuvable',
  2002: 'Client introuvable',
  2003: 'Ville introuvable',
  2004: 'Utilisateur introuvable',
  2005: 'Pièce jointe de panier introuvable',
  2006: 'Importation Wiuz introuvable',
  2007: 'Données d’importation Wiuz introuvables',
  4000: 'Impossible de mettre à jour le TC assigné du panier converti',
  4001: 'Impossible de mettre à jour le TC assigné de la demande de panier converti',
  4002: 'Panier non prêt à être envoyé au marketplace',
  4003: 'Type de fichier non valide ou non pris en charge',
  4004: 'Le fichier a dépassé la limite de taille maximale',
  4005: 'En-tête de fichier non valide',
  4006: 'La demande de panier existe déjà',
  4007: 'Un autre TC a déjà chiffré cette demande de devis',
  4008: "La demande de panier n'a pas de panier qui lui est assigné",
  4009: 'Le panier ne peut pas être enregistré car il est déjà converti',
  5000: "Fichier vide ou erreur d'analyse du fichier",
  5001: "Une erreur s'est produite lors de la récupération du fichier demandé",
  5002: 'Erreur de base de données',
  5003: "Une erreur s'est produite lors de la création du PDF",
  5004: "Une erreur s'est produite lors du traitement de votre demande",
};
