import { IStoreShippingItem, IShippingFeesResponse, IShippingFeesUserInfo } from 'types/shippingFees';
import { IShippingAddresses, PaginatedResponse } from 'types';
import { getToken } from 'cookies';
import { ApiResponse } from 'apisauce';

import { config } from '../config';
import { DELIVERY_ADDRESS } from 'constants/routes/apiRoutes';
import { RestServiceApi } from './api.service';

type IGetShippingAddressesParams = { userId: number; $limit: number; id?: number[] };
export type ICalculateShippingFeesPrams = {
  deliveryPreference?: Array<unknown>;
  itemList: Array<IStoreShippingItem>;
  userInfo: IShippingFeesUserInfo;
};

const shippingApi = new RestServiceApi(config.msShippingBackendUrl || '');
const deliveryApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');
export class ShippingServiceApi {
  async calculateShippingFees(
    itemList: Array<IStoreShippingItem>,
    userInfo: IShippingFeesUserInfo,
  ): Promise<ApiResponse<IShippingFeesResponse>> {
    return shippingApi.post<ICalculateShippingFeesPrams, IShippingFeesResponse>('calculate', {
      itemList: itemList.map((item) => ({
        ...item,
        quantity: Math.ceil(item.quantity),
      })),
      userInfo,
    });
  }

  async getShippingMethods(offerId: Array<number>): Promise<ApiResponse<IShippingFeesResponse>> {
    return shippingApi.post('/list', { offerId });
  }

  async getShippingAddresses(
    userId: number,
    token: string,
  ): Promise<ApiResponse<PaginatedResponse<IShippingAddresses>>> {
    const headers = deliveryApi.getAuthenticateHeader(token);
    return deliveryApi.get<IGetShippingAddressesParams, PaginatedResponse<IShippingAddresses>>(
      DELIVERY_ADDRESS,
      { userId, $limit: 50 },
      headers,
    );
  }

  async getShippingAddressesByIds(
    userId: number,
    id?: number[],
  ): Promise<ApiResponse<PaginatedResponse<IShippingAddresses>>> {
    return deliveryApi.get<IGetShippingAddressesParams, PaginatedResponse<IShippingAddresses>>(
      DELIVERY_ADDRESS,
      { userId, id, $limit: 50 },
      {
        ...deliveryApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  async createShippingAddresses(
    createAddressParams: Partial<IShippingAddresses>,
    token: string,
  ): Promise<ApiResponse<IShippingAddresses>> {
    const headers = deliveryApi.getAuthenticateHeader(token);
    return deliveryApi.post<Partial<IShippingAddresses>, IShippingAddresses>(
      DELIVERY_ADDRESS,
      { ...createAddressParams },
      headers,
    );
  }
}

export const ShippingApiService = new ShippingServiceApi();
