export const my_profile_fr = {
  lastname: 'Votre prénom',
  firstname: 'Votre nom',
  username: "Nom d'utilisateur",
  phoneNumber: 'Numéro de téléphone',
  pageTitle: 'Profil',
  currentPassword: 'Votre mot de passe actuel',
  changeYourPassword: 'Modifier votre mot de passe',
  changeYourPasswordButton: 'Modifier votre mode de passe',
  confirm: 'Valider',
  saveChanges: 'Valider les changements',
  yourPersonalInformation: 'Vos informations personnelles',
  yourSettings: 'Vos paramétrages',
  yourGroups: 'Vos groupes',
  noGroups: 'Aucun groupe',
};
