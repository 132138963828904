import { FC } from 'react';
import styles from './FlagList.module.scss';

type IFlagListProps = {
  className?: string;
  flags?: Array<string>;
  productTags?: Array<string>;
};

const FlagList: FC<IFlagListProps> = (props: IFlagListProps) => {
  const { className, flags, productTags } = props;

  return (
    <div className={`${styles.list} ${className}`}>
      {flags?.map((flag) => (
        <span className={styles.flag} key={flag}>
          {flag}
        </span>
      ))}
      {productTags?.map((tag) => (
        <span className={styles.tag} key={tag}>
          {tag}
        </span>
      ))}
    </div>
  );
};

export default FlagList;
