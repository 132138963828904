import { IShippingFeeItem, IStoreShippingItem } from 'types/shippingFees';
import { CreateProductItemMessage } from 'types/savedCart';
import {
  GetWiuzProductsResponse,
  IAlgoliaProduct,
  IProductOfferShippingMethod,
  IProductReplacement,
  IProductTaxes,
  IProductType,
} from 'types/product';
import { IStorageOffer } from 'types/offer';
import { Log } from 'types/logs';
import { IGroupCustomer } from 'types/groups';
import {
  ErrorType,
  ICustomer,
  ICustomerGroup,
  INormalizedList,
  ISearchedCustomer,
  IShippingAddresses,
  ITown,
  IUser,
  PartialBy,
} from 'types';
import {
  IFetchProductsResponse,
  IOffersListByProductId,
  IProductsState,
  IWiuzFiles,
  WiuzSelectedFile,
} from 'store/products/types';
import { ICategorizedShippingItems, IDeliveryState, ShippingDetails } from 'store/delivery/types';
import {
  INormalizedCustomersList,
  INormalizedGroupAddressList,
  INormalizedGroupCustomersList,
  INormalizedGroupTagsList,
} from 'store/customers/types';
import {
  ICartItemAttachment,
  ICartProductItem,
  ICartProductWiuz,
  ICustomerPostalCode,
  ICustomerProductInfoMap,
  ITruckSplitInfoMap,
  PendingLog,
  SupplierPriceType,
} from 'store/cart/types';
import { IProductPriceData, IProductPriceProps } from 'components/shared';
import { ICartRequestDetail } from '../../types/cartRequest';
import { DiscountSource, DiscountType } from '../../components/shared/Discount/Discount.types';

export type SavedCartState = SavedCartsResponse & {
  restore: {
    productErrors: RestoreSavedCartStateResponse['productErrors'];
    deliveryErrors: RestoreSavedCartStateResponse['deliveryErrors'];
    showModal: boolean;
    expiredAt: string | null;
    expirationDays: number | null;
    expirationHours: number | null;
  };
  isLoading: boolean;
  isLoadingCount: boolean;
  filters: ISavedCartFilters;
  states: ISavedCartStates;
  error: ErrorType | null;
  page: number;
};

export type ISavedCartFilters = {
  search?: string | null;
  createdStartDate?: string | null;
  createdEndDate?: string | null;
  expiredStartDate?: string | null;
  expiredEndDate?: string | null;
  dateRangeCreate?: [string, string] | null;
  dateRangeExpire?: [string, string] | null;
  archived?: boolean;
  convertedPdf?: boolean;
  assignedToMe?: boolean;
  status?: string | null;
  atcCartNumber?: string | null;
  customerIdentifier?: string | null;
  assignedTcCode?: string | null;
};

export type ISavedCartStates = {
  converted: number;
  draft: number;
  pending: number;
  in_progress: number;
  refused: number;
  sent_to_customer: number;
};

export type ISavedCartRequestPayload = {
  $skip?: number;
  $limit?: number;
  $search?: ISavedCartFilters['search'];
  'createdAt[$gte]'?: string | null;
  'createdAt[$lt]'?: string | null;
  'updatedAt[$gte]'?: ISavedCartFilters['expiredStartDate'];
  'updatedAt[$lt]'?: ISavedCartFilters['expiredEndDate'];
  '$or[0][customers.selectedSearchedCustomer.firstName][$search]'?: string;
  '$or[1][customers.selectedSearchedCustomer.lastName][$search]'?: string;
  '$or[2][customers.selectedSearchedCustomer.company][$search]'?: string;
  '$or[3][customers.selectedGroupCustomers.firstName][$search]'?: string;
  '$or[4][customers.selectedGroupCustomers.lastName][$search]'?: string;
  '$or[5][customers.selectedGroupCustomers.customerIdentifier][$search]'?: string;
  '$or[6][customers.selectedGroupCustomers.company][$search]'?: string;
  '[customers.selectedSearchedCustomer.customerIdentifier][$search]'?: string;
  'cart.atcCartNumber'?: string;
  '$sort[createdAt]'?: number;
  '$sort[updatedAt]'?: number;
  status?: string;
  $sort?: Record<string, unknown>;
  archived?: boolean;
  convertedPdf?: boolean;
};

export type RestoreSavedCartPayload = {
  id: string;
  permanent?: boolean;
};

export type SaveSavedCartResponse = {
  cart: {
    id: string;
    cartId: string;
    cart?: {
      products: ICartProductItem[];
    };
  };
  marketplacePayload?: MarketplacePayload;
};

export type RestoreSavedCartResponse = {
  savedCart: SavedCartRestoredState;
  data: FetchSavedCart;
};

export type SavedCartSavedCustomerSingleState = {
  isGroup: false;
  selectedCustomer: number;
  selectedSearchedCustomer: {
    id: number;
    userId: number;
    firstName: string;
    lastName: string;
    customerIdentifier: string;
    company: string;
    email?: string;
  };
  billingCustomer: ICustomer;
};

export type SavedCartSavedCustomersGroupState = {
  isGroup: true;
  selectedGroupId: number;
  selectedGroupCustomers: Array<ICustomerGroup>;
  selectedSearchedGroupCustomer: {
    id: number;
    userId: number;
    customerIdentifier: string;
    company?: string;
    email?: string;
  };
  groupTags?: Array<string>;
};

export type SavedCartsResponse = {
  data: SavedCartRestoredState[];
  limit: number;
  skip: number;
  total: number;
};

export type SavedCartStatusCount = {
  states: ISavedCartStates;
};

export type SavedCartSavedCustomerState = SavedCartSavedCustomerSingleState | SavedCartSavedCustomersGroupState;

export enum SavedCartStatusEnum {
  'PENDING' = 'pending',
  'IN_PROGRESS' = 'in_progress',
  'SENT_TO_CUSTOMER' = 'sent_to_customer',
  'CONVERTED' = 'converted',
  'REFUSED' = 'refused',
}

export const SAVED_CART_STATUS = [
  SavedCartStatusEnum.PENDING,
  SavedCartStatusEnum.IN_PROGRESS,
  SavedCartStatusEnum.SENT_TO_CUSTOMER,
  SavedCartStatusEnum.CONVERTED,
  SavedCartStatusEnum.REFUSED,
];

export type SavedCartUpdateData = Omit<SavedCartSavedState, '_id'> & {
  status?: typeof SavedCartStatusEnum[keyof typeof SavedCartStatusEnum];
  archivedAt?: string | null;
  assignedTcCode?: Nullable<string>;
};

export type SavedCartRestoredState = Omit<SavedCartSavedState, 'cart' | 'wiuz'> & {
  id: string;
  status: typeof SavedCartStatusEnum[keyof typeof SavedCartStatusEnum];
  expired: boolean;
  userId: number;
  deletedAt: string | null;
  archivedAt: string | null;
  convertedAt: string | null;
  refusedAt: string | null;
  sentToCustomerAt: string | null;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  userTags?: string[];
  cart: Omit<SavedCartSavedState['cart'], 'products'> & {
    products: SavedCartRestoredProductItem[];
  };
  wiuz?: SavedCartSavedState['wiuz'];
  assignedTcCode?: string;
  assignedTcName?: string;
  cartRequest?: ICartRequestDetail;
  logs: Log[];
  isCartRequest?: boolean;
  addressId?: number;
};

export type SavedCartWiuzSavedState = {
  page: IProductType;
  selectedFile: WiuzSelectedFile | null;
};

export type SavedCartSavedState = {
  town: {
    id: number;
    town: string;
    postcode: string;
    countryCode: string;
  };
  customers: SavedCartSavedCustomerState;
  cart: {
    cartRequestId: number | null;
    isEco: boolean;
    products: SavedCartSateProductItem[];
    comment: string | null;
    deliveryComment: Nullable<string>;
    invoiceComment: Nullable<string>;
    atcCartNumber: Nullable<string>;
    sendQuoteByEmail: boolean;
    groups: Array<string>;
    logs: Log[];
  };
  delivery?: {
    shippingCategories?: INormalizedList<ICategorizedShippingItems>;
    shippingFees?: INormalizedList<IShippingFeeItem>;
    shippingAddress: IShippingAddresses;
    shippingItems: SavedCartSateShippingItem[];
    categoryPointList: Array<{
      shippingMethodId: number;
      pointListId: string;
    }>;
    shippingDetails: ShippingDetails;
  };
  stepNumber: number;
  wiuz: SavedCartWiuzSavedState;
  masterOrderId?: string;
  saveCartPdfId?: number;
  cartRequestId?: Nullable<number>;
  convertedAt?: string;
  sentToCustomerAt?: string | null;
  updatedAt?: string | null;
  expiredAt: string | null;
  expirationDays: number | null;
  expirationHours: number | null;
  pendingLogs: PendingLog[];
};

export type SavedCartSateShippingItem = {
  offerId: number;
  shippingMethodId?: number;
};

export type SavedCartStateProductItemKey = keyof SavedCartSateProductItem;
export type SavedCartSateProductItem = {
  id: string;
  cartRequestItemId: number | null;
  cartProductId: string;
  productId: number;
  offerId: number;
  offerType?: number;
  variantId: number;
  quantity: number;
  name: string;
  label: string | null;
  discountValue?: number;
  discountType?: DiscountType;
  discountSource?: DiscountSource;
  conditioning: string;
  conditioningQuantity?: number;
  sku: string;
  price: IProductPriceProps;
  offerPrice: number;
  publicComment?: string;
  shippingMethods: Array<IProductOfferShippingMethod> | undefined;
  unit: {
    singular: string;
    plural: string;
  };
  taxes: IProductTaxes;
  quantityIncrement: number;
  minimumOrderable: number;
  priceTotalWithTaxes: number;
  totalPrice: number;
  cvo: number;
  rpd: number;
  stock: number;
  weight: number;
  days?: number;
  isTownRequired: boolean;
  isTruck?: boolean;
  startDate?: string;
  endDate?: string;
  supplierId?: number;
  departureId?: number;
  initialPrice?: IProductPriceData;
  productOptions?: string;
  availableSelectedQuantity: number;
  availableQuantity: number;
  customerProductInfo?: ICustomerProductInfoMap;
  truckSplitInfo?: ITruckSplitInfoMap;
  wiuz: ICartProductWiuz;
  enabled: boolean;
  noStock: boolean;
  replacement?: IProductReplacement;
  supplierPrice?: SupplierPriceType | null;
  attachments: Array<ICartItemAttachment>;
  qtyEditable?: boolean | null;
  removable?: boolean | null;
  enforcedPrice?: boolean;
  isPlainProduct?: boolean;
  ignoreStock?: boolean;
  supplierReference?: boolean;
  overriddenDeliveryStartDate?: string;
  overriddenDeliveryEndDate?: string;
  deliveryOverridden?: boolean;
  metadata?: {
    business_note: string;
  } | null;
  deliveryStartDate?: string;
  deliveryEndDate?: string;
};

// Make the new fields as optionals because the old mongodb documents don't have them
export type SavedCartRestoredProductItem = Optional<
  SavedCartSateProductItem,
  | 'enabled'
  | 'unit'
  | 'conditioning'
  | 'price'
  | 'quantityIncrement'
  | 'noStock'
  | 'label'
  | 'discountValue'
  | 'discountType'
  | 'removable'
  | 'qtyEditable'
>;

type RestoreSavedCartCustomersState = {
  isGroup: boolean;
  customers: INormalizedCustomersList;
};

export type RestoreSavedCartCustomersGroupState = RestoreSavedCartCustomersState & {
  groupTags: INormalizedGroupTagsList;
  groupAddress: INormalizedGroupAddressList;
  selectedSearchedGroupCustomer: ISearchedCustomer;
  selectedGroupCustomers: INormalizedGroupCustomersList;
  selectedGroupId: number;
};

export type RestoreSavedCartCustomersSingleState = RestoreSavedCartCustomersState & {
  selectedSearchedCustomer?: ISearchedCustomer;
  selectedCustomer?: number;
};

export type RestoreSavedCartStateResponse = {
  state: RestoreSavedCartState;
  id: string;
  productErrors: {
    priceChanged: CreateProductItemMessage[];
    outOfStock: CreateProductItemMessage[];
    noOfferFound: CreateProductItemMessage[];
    noProductFound: CreateProductItemMessage[];
    partialStock: CreateProductItemMessage[];
    message: CreateProductItemMessage[];
    error: CreateProductItemMessage[];
  };
  deliveryErrors: ErrorType[];
  restoredUserTags?: string[];
  expiredAt: string;
  expirationDays: number | null;
  expirationHours: number | null;
};

export type RestoreSavedCartState = {
  customers: RestoreSavedCartCustomersGroupState | RestoreSavedCartCustomersSingleState;
  products: {
    wiuz: PartialBy<Pick<IProductsState['wiuz'], 'selectedFile' | 'products' | 'files'>, 'products' | 'selectedFile'>;
    postalCodes: ICustomerPostalCode | null;
    isLoadingPostalCode: boolean;
    products: INormalizedList<IAlgoliaProduct>;
    offers: {
      list: INormalizedList<IStorageOffer>;
      byProduct: IOffersListByProductId;
    };
  };
  cart: {
    cartRequestId: number | null;
    isEco: boolean;
    sendQuoteByEmail: boolean;
    isLoadingCertificateUserId: number;
    comment: string | null;
    deliveryComment: Nullable<string>;
    invoiceComment: Nullable<string>;
    products: Array<ICartProductItem>;
    atcCartNumber: Nullable<string>;
    users: Nullable<IUser>;
    groups: Array<string>;
    logs: Log[];
    status: `${SavedCartStatusEnum}`;
  };
  delivery: {
    shippingAddress?: IShippingAddresses;
    shippingAddresses?: Array<IShippingAddresses>;
    shippingItems?: Array<IStoreShippingItem>;
    shippingFees?: INormalizedList<IShippingFeeItem>;
    shippingCategories?: INormalizedList<ICategorizedShippingItems>;
    categoryPointList?: INormalizedList<string>;
    sortedShippingCategories?: IDeliveryState['sortedShippingCategories'];
    shippingDetails?: ShippingDetails;
  };
  settings: {
    PRODUCT_PAGE: IProductType;
  };
};

export type FetchSavedCartSingleCustomer = {
  __typename: 'FetchSavedCartSingleCustomer';
  customers: ICustomer[];
  town: ITown;
  products: IFetchProductsResponse;
  wiuz: FetchAllResponse['wiuz'];
  users: IUser | null;
};

export type FetchSavedCartGroupCustomer = {
  __typename: 'FetchSavedCartGroupCustomer';
  groupCustomers: IGroupCustomer[];
  town: ITown;
  products: IFetchProductsResponse;
  wiuz: FetchAllResponse['wiuz'];
  users: IUser | null;
};

export type FetchSavedCart = (FetchSavedCartSingleCustomer | FetchSavedCartGroupCustomer) & {
  userTags?: string[];
};

export type FetchAllResponse = {
  town: ITown;
  products: IFetchProductsResponse;
  wiuz: {
    files: IWiuzFiles[];
    file: GetWiuzProductsResponse | null;
    algoliaProducts: IFetchProductsResponse | null;
  };
  users: IUser | null;
};

export type MarketplacePayload = {
  countryCode: string;
  locale: string;
  userId: number;
  expiresAt: string;
  tcEmail: string;
  externalSource: string;
  externalId: string;
  metadata?: Record<string, string | number | boolean>;
  items: OrderItem[];
  shippingAddress: {
    id: number;
    firstName?: string;
    lastName?: string;
    comment?: string;
    phoneNumber?: string;
    townPostcode: number;
    townCity: string;
  };
  billingAddress: {
    id: number;
    townPostcode: number;
    townCity: string;
  };
  withdrawalPoints: WithdrawalPoint[];
};

export type WithdrawalPoint = {
  id: number;
  shippingMethodId: number;
  name: string;
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  zipCode: string;
};

export type OrderItem = {
  variantId: number;
  offerId: number;
  unitPrice: number;
  quantity: number;
  quantityIncrement: number;
  label: string;
  shippingMethod: ShippingMethod;
  metadata?: Record<string, string | number | boolean>;
};

export type ShippingMethod = {
  id: number;
  type: string;
  minDelay: number;
  maxDelay: number;
  amount: number;
  label: string;
};

export type IMarketplaceProductTaxes = {
  cvo?: number;
  ecoContribution?: number;
  rpd?: number;
  vat: number;
};
