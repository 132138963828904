import { selectLanguage } from 'store/settings';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import locale_fr from 'antd/es/date-picker/locale/fr_FR';
import locale_en from 'antd/es/date-picker/locale/en_GB';

export const useDatePickerLocale = (): PickerLocale => {
  const lang = useSelector(selectLanguage);

  const locale = useMemo(() => {
    switch (lang) {
      case 'en':
        return locale_en;
      case 'fr':
      default:
        return locale_fr;
    }
  }, [lang]);

  return locale;
};
