import { useAppSelector } from 'store/store';
import { selectTotalWiuzActiveQuantity } from 'store/products';
import { selectWiuzProductCartQuantity } from 'store/cart';
import { FC, memo } from 'react';
import { ProductQtySelectedProps } from './types';
import styles from './styles.module.scss';

const ProductQtySelected: FC<ProductQtySelectedProps> = memo(
  ({ productId, unit, quantityToOrder, quantity, unitRelations, wiuz }: ProductQtySelectedProps) => {
    const quantityInCart = useAppSelector(selectWiuzProductCartQuantity(productId, unit, unitRelations));
    const activeQuantity = useAppSelector(selectTotalWiuzActiveQuantity(productId));
    const totalQuantity = quantityInCart + activeQuantity + quantity;

    const displayQuantity = () => {
      if (wiuz) {
        return `${quantityToOrder} ${unit}`;
      }
      return `${totalQuantity} / ${quantityToOrder} ${unit}`;
    };

    return <span className={styles.title}>{displayQuantity()}</span>;
  },
);

export default ProductQtySelected;
