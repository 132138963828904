import { getDeliveryDate } from 'utils';
import { IProductOfferShippingMethod } from 'types/product';
import { FC, useCallback } from 'react';
import { Tooltip } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { PRODUCT_DELIVERY_FORMAT } from 'constants/format/delivery';
import { IShippingMethodsProps } from './types';
import styles from './styles.module.scss';

const getTitle = (shippingMethods?: Array<IProductOfferShippingMethod>) => (
  <>
    {shippingMethods?.map((method) => (
      <p className={styles.methodLabel} key={method.id}>
        {method.label}
      </p>
    ))}
  </>
);

const ShippingMethods: FC<IShippingMethodsProps> = (props: IShippingMethodsProps) => {
  const {
    product: { shippingMethods: methods, days, isTruck, startDate, endDate },
  } = props;

  const renderDates = useCallback(() => {
    return getDeliveryDate(
      {
        startDate,
        endDate,
      },
      PRODUCT_DELIVERY_FORMAT.NO_LABEL,
    );
  }, [endDate, startDate]);

  const renderDays = (minDelay?: number, maxDelay?: number) => {
    if (minDelay && maxDelay) {
      return getDeliveryDate(
        {
          delay: { min: minDelay, max: maxDelay },
        },
        PRODUCT_DELIVERY_FORMAT.NO_LABEL,
      );
    }
    return '-';
  };

  if (methods && methods.length === 0) {
    return <span>N/C</span>;
  }

  if (typeof days === 'number') {
    return (
      <div className={styles.container}>
        <Tooltip placement="right" title={getTitle(methods)}>
          <div className={styles.method}>
            <span className={styles.method__text}>{days} jours</span>
            {!!methods?.length && <MenuUnfoldOutlined className={styles.method__icon} />}
          </div>
        </Tooltip>
      </div>
    );
  }

  if (!isTruck && startDate && endDate) {
    return (
      <div className={styles.container}>
        <Tooltip placement="right" title={getTitle(methods)}>
          <div className={styles.method}>
            <span className={styles.method__text}>{renderDates()}</span>
            {!!methods?.length && <MenuUnfoldOutlined className={styles.method__icon} />}
          </div>
        </Tooltip>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {methods &&
        methods.map((method, id) => (
          <Tooltip key={id} placement="right" title={getTitle([method])}>
            <div className={styles.method}>
              <span className={styles.method__text}>{renderDays(method.minDelay, method.maxDelay)}</span>
              <MenuUnfoldOutlined className={styles.method__icon} />
            </div>
          </Tooltip>
        ))}
    </div>
  );
};

export default ShippingMethods;
