import { useIsMobile } from 'utils/styles.utils';
import { clearProductsState } from 'store/products';
import {
  selectAllCustomers,
  selectGroupAddressBySelectedGroup,
  selectGroupTagsBySelectedGroup,
  selectIsGroup,
  selectSelectedCustomer,
  selectSelectedGroupCustomers,
} from 'store/customers';
import { clearCartState } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useSetSettings } from 'hooks/useSettings';
import { useStepper } from 'hooks';
import { Text, TagsList } from 'components/shared';
import Stepper from 'components/layout/Stepper';
import GroupList from 'components/customers/ClientsList/GroupList';
import CustomersList from 'components/customers/ClientsList/CustomersList';
import { CustomerHeader, SubmitButton } from 'components/customers';
import classNames from 'classnames';
import { Layout } from 'antd';
import { IssuesCloseOutlined } from '@ant-design/icons';
import { clearSavedCartState } from '../../store/savedCart';
import { clearDeliveryState } from '../../store/delivery';
import { clearCartRequestsState } from '../../store/cartRequests';
import DeliveryCardItem from '../../components/delivery/DeliveryMethodsCard';
import styles from './Customers.module.scss';

const CustomersContainer = () => (
  <Layout.Content className={styles.container}>
    <div className={styles.main}>
      <CustomersList pageSize={50} />
    </div>
  </Layout.Content>
);

const GroupCustomersContainer = () => {
  const dispatch = useDispatch();
  const customers = useSelector(selectAllCustomers);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const { goNextStep } = useStepper();
  const setProductPage = useSetSettings('PRODUCT_PAGE');
  const { t } = useTranslation();
  const selectedGroupCustomer = useSelector(selectSelectedGroupCustomers);
  const groupAddress = useSelector(selectGroupAddressBySelectedGroup);
  const groupTags = useSelector(selectGroupTagsBySelectedGroup);

  const onClickSubmit = () => {
    dispatch(clearDeliveryState());
    dispatch(clearCartState());
    dispatch(clearCartRequestsState());
    dispatch(clearSavedCartState());
    dispatch(clearProductsState());
    setProductPage('default');
    goNextStep(true);
  };

  const isSelected = customers.find((customer) => customer.id === selectedCustomer);

  return (
    <>
      <Layout.Content className={styles.container__grouped}>
        <GroupList pageSize={50} />
        <div className={classNames(styles.containerCertificates)}>
          <div className={styles.groupAddress}>
            {groupAddress &&
              groupAddress.map((shippingAddress, idx) => (
                <DeliveryCardItem
                  title={t('common.shippingAddress', { count: groupAddress.length })}
                  key={`${shippingAddress.id}-${idx}`}
                >
                  {shippingAddress && (
                    <div className={styles.container}>
                      <Text type="subtitle-thin">
                        {shippingAddress.firstName} {shippingAddress.lastName}
                      </Text>
                      <Text type="subtitle-thin">
                        {shippingAddress.street} {shippingAddress.postcodeLabel} {shippingAddress.townLabel}
                      </Text>
                      <Text type="subtitle-thin">{shippingAddress.phone1 || shippingAddress.phone2}</Text>
                    </div>
                  )}
                </DeliveryCardItem>
              ))}
          </div>
          {groupTags?.length > 0 && (
            <div className={styles.tags}>
              <div className={styles.headerTags}>
                <IssuesCloseOutlined className={styles.icon} />
                <Text type="title-gray8">{t('common.availableTags')}</Text>
              </div>
              <div className={styles.containerTags}>
                <TagsList tags={groupTags} />
              </div>
            </div>
          )}
          {isSelected && (
            <div className={styles.tags}>
              <div className={styles.headerTags}>
                <IssuesCloseOutlined className={styles.icon} />
                <Text type="title-gray8">{t('common.availableTags')}</Text>
              </div>
              <div className={styles.containerTags}>
                <TagsList tags={isSelected.tags} />
              </div>
            </div>
          )}
        </div>
      </Layout.Content>
      {(isSelected && selectedCustomer !== undefined) || selectedGroupCustomer.length >= 2 ? (
        <SubmitButton onSubmit={onClickSubmit} />
      ) : null}
    </>
  );
};

const Customers: FC = () => {
  const isGroup = useSelector(selectIsGroup);
  const isMobile = useIsMobile();

  return (
    <>
      <CustomerHeader />
      {!isGroup && <CustomersContainer />}
      {isGroup && <GroupCustomersContainer />}
      {!isMobile && <Stepper />}
    </>
  );
};

export default Customers;
