import { useIsTabletSizes } from 'utils/styles.utils';
import { selectCurrentGroupTags, selectSelectedGroupCustomers } from 'store/customers';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import CopyClipboard from 'components/shared/CopyClipboard';
import { OtherInfo, Popover, TagsList } from 'components/shared';
import { Divider } from 'antd';
import { StarFilled } from '@ant-design/icons';
import styles from './GroupCustomerInfo.module.scss';

const GroupCustomerInfo: React.FC = () => {
  const selectedCustomers = useSelector(selectSelectedGroupCustomers);
  const groupTags = useSelector(selectCurrentGroupTags);
  const groupLeader = selectedCustomers.find((member) => member.userId === member.leaderId);
  const isTablet = useIsTabletSizes();

  const customerPopupContent = useMemo(() => {
    return (
      <div>
        {selectedCustomers.map((customer, index) => {
          const isLeader = customer?.leaderId === customer?.userId;
          return (
            <>
              <div className={styles.memberRow} key={customer.id}>
                <div className={styles.memberInfo}>
                  {isLeader && <StarFilled className={styles.leaderIcon} />}
                  {customer?.firstName} {customer?.lastName}
                  <br />
                  {customer?.customerIdentifier}
                  <p className={styles.address}>
                    {customer?.street}, {customer?.townLabel}, {customer?.postcodeLabel}
                  </p>
                  {customer?.phone1 || customer?.phone2}
                  <br />
                  <div>
                    {customer?.email} <CopyClipboard text={customer?.email} />
                  </div>
                </div>
                <OtherInfo
                  className={styles.reducePadding}
                  restrictedPrecursor={customer?.user?.restrictedPrecursor}
                  regulatedPrecursor={customer?.user?.regulatedPrecursor}
                  certExpiryDate={customer?.user?.certiphytoExpiredDate}
                />
              </div>
              {index !== selectedCustomers.length - 1 && <Divider />}
            </>
          );
        })}
        {groupTags.length > 0 && (
          <>
            <Divider />
            <div className={styles.marginBottom}>
              <TagsList tags={groupTags} />
            </div>
          </>
        )}
      </div>
    );
  }, [groupTags, selectedCustomers]);

  return (
    <div className={styles.wrapper}>
      <Popover
        placement={isTablet ? 'bottom' : 'bottomLeft'}
        content={customerPopupContent}
        title={null}
        overlayClassName={styles.overlay}
      >
        {groupLeader?.firstName} {groupLeader?.lastName} ({groupLeader?.customerIdentifier})
      </Popover>
    </div>
  );
};

export default GroupCustomerInfo;
