import { ABANDONED_CARTS_REDUCER_NAME } from 'store/common';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import { getPersistConfig } from 'redux-deep-persist';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import localForage from 'localforage';
import { Action, ThunkDispatch, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { USERS_REDUCER_NAME } from './users';
import { RootState, rootReducer } from './rootReducer';
import { PRODUCTS_REDUCER_NAME } from './products';
import { ORDERS_HISTORY_REDUCER_NAME } from './ordersHistory';

const config = getPersistConfig({
  key: 'root',
  storage: localForage,
  rootReducer,
  blacklist: [
    ABANDONED_CARTS_REDUCER_NAME,
    ORDERS_HISTORY_REDUCER_NAME,
    'message',
    `${USERS_REDUCER_NAME}.paginationToken`,
    `${USERS_REDUCER_NAME}.paginationTokens`,
    `${USERS_REDUCER_NAME}.currentPage`,
    `${PRODUCTS_REDUCER_NAME}.replacementModal`,
  ],
});

const persistedReducer = persistReducer<RootState>(config, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);

export type AppThunkDispatch = ThunkDispatch<RootState, null, Action<string>>;

export default store;
