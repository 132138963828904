import { IGroupCustomer } from 'types/groups';
import { ICustomer, ICustomerGroup } from 'types';
import { INormalizedCustomersList, INormalizedGroupAddressList, INormalizedGroupTagsList } from 'store/customers/types';

export const fetchGroupCustomersNormalizer = (
  payload: Array<IGroupCustomer>,
): {
  customers: Array<ICustomerGroup>;
  groupAddress: INormalizedGroupAddressList;
  groupTags: INormalizedGroupTagsList;
} => {
  const customers = payload?.reduce<Array<ICustomerGroup>>((accumulator, group) => {
    group.groupMember.forEach((groupMember) => {
      groupMember.groupMemberCustomer.forEach((groupMemberCustomer) => {
        const customer = {
          ...groupMemberCustomer.customer,
          leaderId: group.leaderId,
          groupName: group.name,
          groupId: group.id,
        } as ICustomerGroup;
        if (customer.userId === group.leaderId) accumulator.unshift(customer);
        else accumulator.push(customer);
      });
    });

    return accumulator;
  }, []);
  const groupAddress = payload.reduce<INormalizedGroupAddressList>((acc, group) => {
    acc[group.id] = group.groupAddress;
    return acc;
  }, {});

  const groupTags = payload.reduce<INormalizedGroupTagsList>((acc, group) => {
    acc[group.id] = group.groupTags ?? [];
    return acc;
  }, {});

  return {
    customers,
    groupAddress,
    groupTags,
  };
};

export const setSelectedGroupCustomerNormalizer = (
  customer: ICustomerGroup,
): {
  key: `${number}-${number}`;
  value: ICustomerGroup;
} => {
  return {
    key: `${customer.id}-${customer.groupId}`,
    value: customer,
  };
};

export const fetchCustomersNormalizer = (payload: Array<ICustomer>): { customers: INormalizedCustomersList } => {
  const customers = payload.reduce<INormalizedCustomersList>((accumulator, customer, currentIndex) => {
    accumulator[customer.id] = {
      ...customer,
      sortIndex: currentIndex,
    };
    return accumulator;
  }, {});

  return { customers };
};
