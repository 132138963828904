import { ITown } from 'types';
import {
  clearFetchedTowns,
  fetchTowns,
  selectFetchedTowns,
  selectIsLoading,
  selectPostcodeError,
  setPostcodeError,
} from 'store/products';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { ERRORS } from 'constants/errors';
import { IPostalCodeSearchProps } from './types';
import styles from './styles.module.scss';

const PostalCodeSearch: FC<IPostalCodeSearchProps> = (props: IPostalCodeSearchProps) => {
  const [searchedTowns, setSearchedTowns] = useState<Array<ITown>>();
  const [searchId, setSearchId] = useState<number>();
  const [postcode, setPostcode] = useState<string>('');
  const { onFinish, predefined, clearPredefined } = props;
  const [inputValue, setInputValue] = useState<string>(predefined || '');

  const dispatch = useDispatch();
  const towns = useSelector(selectFetchedTowns);
  const postcodeError = useSelector(selectPostcodeError);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (predefined) {
      onSearch(predefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefined]);

  useEffect(() => {
    if (postcodeError) {
      setSearchedTowns([]);
    } else {
      setSearchedTowns(towns);
    }
  }, [postcodeError, towns]);

  const onSearch = (search: string) => {
    setInputValue(search);
    setPostcode(search);

    if (search.length < 4) {
      dispatch(setPostcodeError(ERRORS.towns.limitCharacters));
    } else {
      dispatch(fetchTowns({ postcode: search }));
    }

    if (inputValue) {
      clearPredefined();
    }
  };

  const onChange = (value: number) => {
    setSearchId(value);
    const foundTown = searchedTowns?.find((item) => item.id === value);
    onFinish(foundTown);
    dispatch(clearFetchedTowns());
  };

  return (
    <div className={styles.content}>
      {postcodeError && !!postcode.length && <span className={styles.error}>{postcodeError.message}</span>}
      <Select
        notFoundContent={isLoading && postcode.length > 3 ? <Spin className={styles.loadingIcon} /> : false}
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        searchValue={inputValue}
        open={!!inputValue}
        onSearch={onSearch}
        onChange={onChange}
        className={styles.input}
        size={'large'}
        value={searchId}
        suffixIcon={<Spin size="large" />}
      >
        {searchedTowns?.map((item, index) => (
          <Select.Option key={`${item.id} ${index}`} value={item.id}>
            {item.town}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default PostalCodeSearch;
