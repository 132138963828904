import { useAppDispatch, useAppSelector } from 'store/store';
import { useEffect } from 'react';
import { setPostalCodeLoading } from '../store/products';
import { getShippingAddresses } from '../store/delivery';
import { selectedUserIdForShippingAddress, selectIsGroup } from '../store/customers';
import { useToken } from '../cookies';

export const useFetchDeliveryShippingAddresses = (): null => {
  const dispatch = useAppDispatch();
  const { token } = useToken();
  const userId = useAppSelector(selectedUserIdForShippingAddress);
  const isGroup = useAppSelector(selectIsGroup);

  useEffect(() => {
    if (userId && token && !isGroup) {
      dispatch(
        getShippingAddresses({
          userId,
          token,
        }),
      );
    }
    if (isGroup) {
      dispatch(setPostalCodeLoading(false));
    }
  }, []);
  return null;
};
