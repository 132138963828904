import {
  getCategoriesLabels,
  getDeliverySummary,
  getParsedCartShippingItems,
  getShippingAddresses,
} from 'utils/delivery.utils';
import { IPointListItem, IStoreShippingItem } from 'types/shippingFees';
import { IShippingAddresses, ErrorType, INormalizedList } from 'types';
import { calculateDeliveryDays } from 'store/cart';
import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  ISelectCategoriesLabels,
  ICategorizedShippingItems,
  ISelectDeliveryPricesSummaryResponse,
  ShippingDetailsItem,
  ShippingFeeError,
} from './types';

export const selectParsedCartShippingItems = (state: RootState): Array<IStoreShippingItem> => {
  const { products } = state.cart;
  return getParsedCartShippingItems(products.filter((prod) => !prod.noStock && prod.enabled && !prod.isPlainProduct));
};

export const selectDeliveryShippingItems = (state: RootState): Array<IStoreShippingItem> =>
  state.delivery.shippingItems;

export const selectPointListByShippingMethodId =
  (shippingMethodId: number) =>
  (state: RootState): IPointListItem | undefined => {
    const pointListId = state.delivery.categoryPointList[shippingMethodId];
    if (state.delivery.shippingCategories[shippingMethodId]) {
      const { pointList } = state.delivery.shippingCategories[shippingMethodId];

      if (pointListId && pointList?.length) {
        return pointList.find((pointListItem) => pointListItem.id === pointListId);
      }
    }

    return undefined;
  };

export const selectShippingCategoriesById =
  (id: number) =>
  (state: RootState): ICategorizedShippingItems => {
    return state.delivery.shippingCategories[id];
  };

export const selectShippingCategories = (state: RootState): Record<string, ICategorizedShippingItems> => {
  return state.delivery.shippingCategories;
};

export const selectSortedShippingCategories = createSelector(selectShippingCategories, (shippingCategories) => {
  return Object.values(shippingCategories)
    .map((shippingCategory): ICategorizedShippingItems & { deliveryStartDate: string; deliveryEndDate: string } => {
      const delivery = calculateDeliveryDays('', '', null, shippingCategory);
      return {
        ...shippingCategory,
        ...delivery,
      };
    })
    .sort((a, b) => moment(a.deliveryStartDate).diff(moment(b.deliveryStartDate)));
});

export const selectShippingAddress = (state: RootState): IShippingAddresses | undefined => {
  return state.delivery.shippingAddress;
};

export const selectShippingAddresses = (state: RootState): Array<IShippingAddresses> => {
  const {
    customers,
    products: { postalCodes: town },
    delivery: { shippingAddresses },
  } = state;
  return getShippingAddresses({ customers, town, shippingAddresses });
};

export const selectCategoriesLabels = (state: RootState): ISelectCategoriesLabels => {
  return getCategoriesLabels(state.delivery.shippingCategories);
};

export const selectShippingAddressesError = (state: RootState): ErrorType | null => state.delivery.error;

export const selectShippingCategoriesByAddress = (state: RootState): Array<ICategorizedShippingItems> => {
  const { byAddress } = state.delivery.sortedShippingCategories;
  return byAddress.map((id) => state.delivery.shippingCategories[id]);
};

export const selectPointListsShippingCategories = (state: RootState): Array<ICategorizedShippingItems> => {
  const { byPointLists } = state.delivery.sortedShippingCategories;
  return byPointLists.map((id) => state.delivery.shippingCategories[id]);
};

export const selectDeliveryPricesSummary = (state: RootState): ISelectDeliveryPricesSummaryResponse => {
  const {
    delivery: { shippingCategories },
    cart: { products },
  } = state;

  return getDeliverySummary({ shippingCategories, products: Object.values(products.filter((prod) => prod.enabled)) });
};

export const selectDeliveryCategories = (state: RootState): Array<ICategorizedShippingItems> => {
  return Object.values(state.delivery.shippingCategories);
};

export const selectShippingDetailsItems = (state: RootState): INormalizedList<ShippingDetailsItem> =>
  state.delivery.shippingDetails.shippingItems;

export const selectDeliveryCustomError = (state: RootState): Record<string, ShippingFeeError[]> | null =>
  state.delivery.customError;
