import React, { FC } from 'react';
import Icon from '@ant-design/icons';

type CustomIconProps = IconProps & {
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const CustomIcon: FC<CustomIconProps> = ({ svg, color, ...rest }: CustomIconProps) => {
  return <Icon component={svg} {...rest} style={{ color }} />;
};

export default CustomIcon;
