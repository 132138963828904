import { IStoreShippingItem } from 'types/shippingFees';
import { categorizeShippingItems, selectDeliveryShippingItems, selectSortedShippingCategories } from 'store/delivery';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useMemo } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { IProductInfoProps } from './types';
import styles from './styles.module.scss';

const ProductSwitch: FC<IProductInfoProps> = (props: IProductInfoProps) => {
  const {
    product: { offerId },
    category: { shippingMethodId, itemList },
    categoriesLabels,
  } = props;
  const dispatch = useDispatch();
  const deliveryShippingItems = useSelector(selectDeliveryShippingItems);

  const sortedAllShippingMethodsIds = useSelector(selectSortedShippingCategories).map((item) => item.shippingMethodId);

  const shippingMethodIds = useMemo(() => {
    return [
      ...(itemList?.find((item) => item.offerId === offerId) || { shippingMethodIds: [] }).shippingMethodIds,
    ].sort((a, b) => {
      return sortedAllShippingMethodsIds.indexOf(a) - sortedAllShippingMethodsIds.indexOf(b);
    });
  }, [itemList, offerId, sortedAllShippingMethodsIds]);

  const onChange = (e: RadioChangeEvent) => {
    const shipMethodId = e.target.value;
    const updatedShippingItems = deliveryShippingItems.reduce<Array<IStoreShippingItem>>(
      (accumulator, shippingItem) => {
        if (shippingItem.offerId === offerId) {
          accumulator.push({ ...shippingItem, shippingMethodId: shipMethodId });
        } else {
          accumulator.push(shippingItem);
        }
        return accumulator;
      },
      [],
    );

    dispatch(categorizeShippingItems(updatedShippingItems));
  };

  return (
    <Radio.Group className={styles.group} onChange={onChange} value={shippingMethodId}>
      <Space direction="vertical">
        {shippingMethodIds.map((id) => (
          <Radio className={styles.group__item} key={id} value={id}>
            {categoriesLabels[id]}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};
export default ProductSwitch;
