import { FC, useRef, useState } from 'react';
import { Text } from 'components/shared';
import classNames from 'classnames';
import TextArea from 'antd/es/input/TextArea';
import { Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ICartCommentProps } from './types';
import styles from './styles.module.scss';

const CartComment: FC<ICartCommentProps> = (props: ICartCommentProps) => {
  const { comment, className, placeholder, label, showLabel = false, shortFlow = false, onSave } = props;

  const [showCommentInput, setShowCommentInput] = useState(false);
  const [commentInput, setCommentInput] = useState(comment ?? '');
  const closeButtonRef = useRef(null);

  const toggleComment = () => {
    if (showCommentInput || shortFlow)
      return (
        <div className={styles.textareaWrapper}>
          <TextArea
            onChange={(e) => setCommentInput(e.target.value)}
            onBlur={(e) => {
              if (e.relatedTarget !== closeButtonRef.current || shortFlow) {
                setCommentInput(e.target.value);
                saveComment();
              }
            }}
            placeholder={placeholder}
            value={commentInput}
            size={'large'}
            rows={3}
            cols={48}
            className={styles.textarea}
          />
        </div>
      );

    if (!comment) {
      return label;
    }

    return (
      <div>
        {comment
          .trim()
          .split('\n')
          .map((line, index) => (
            <div className={styles.commentLine} key={index}>
              {line || <span>&nbsp;</span>}
            </div>
          ))}
      </div>
    );
  };

  const saveComment = () => {
    setShowCommentInput(false);
    onSave(commentInput);
  };

  const editComment = () => {
    if (typeof comment !== 'undefined' && comment !== null) setCommentInput(comment);
    setShowCommentInput(true);
  };

  const toggleActions = () => {
    if (showCommentInput)
      return (
        <>
          <Button
            onClick={saveComment}
            icon={<CheckCircleOutlined />}
            className={`${styles.group__button} ${styles.group__button__check}`}
          />
          <Button
            ref={closeButtonRef}
            onClick={() => setShowCommentInput(false)}
            icon={<CloseCircleOutlined />}
            className={`${styles.group__button} ${styles.group__button__cancel}`}
          />
        </>
      );
    return (
      <Button
        onClick={editComment}
        icon={<EditOutlined />}
        className={`${styles.group__button} ${styles.group__button__edit}`}
      />
    );
  };

  return (
    <div className={classNames(styles.container, className || '')}>
      {showLabel && (
        <Text className={styles.label} type="subtitle">
          {label} :
        </Text>
      )}
      <div className={styles.group}>
        {toggleComment()}
        {!shortFlow && toggleActions()}
      </div>
    </div>
  );
};

export default CartComment;
