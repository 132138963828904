export const landing_fr = {
  createOrderTitle_one: 'Rechercher votre client par son nom, prénom, raison sociale ou n° client.',
  createOrderTitle_other: 'Rechercher votre client par son nom, prénom, raison sociale ou n° client.',
  createOrder: 'Créer une commande',
  orderHistory: 'Historique des commandes',
  profile: 'Mon profil',
  savedCarts: 'Paniers sauvegardés',
  userGuide: 'Guide d’utilisation',
  cartRequests: 'Demande de devis client',
  abandonedCarts: 'Paniers abandonnés',
  pageTitle: 'Création devis/commande',
  searchHere: 'Rentrez votre recherche ici...',
  notAuthorized: "Vous n'êtes pas autorisé à créer un devis",
};
