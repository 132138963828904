import { withTheme } from 'theme/connect';
import { FC } from 'react';

import { LoginLogoProps } from 'pages/Login/LoginLogo/types';
import * as theme from './theme';

const LoginLogo: FC<LoginLogoProps> = (_props: LoginLogoProps) => {
  return null;
};

export default withTheme(LoginLogo)(theme);
