/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Button } from 'components/shared';
import Input from 'antd/lib/input/Input';
import { Select } from 'antd';
import styles from './Filters.module.scss';

interface FiltersProps {
  onChange: (filters: Record<string, string>) => void;
}
export const Filters: FunctionComponent<FiltersProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const { getAllGroups } = useAuth();
  const [filters, setFilters] = useState({
    attribute: '',
    value: '',
  });
  const [groups, setGroups] = useState<string[]>([]);
  const options = useMemo(() => {
    return [
      { label: t('users.selectFilter'), value: '' },
      { label: t('users.username'), value: 'email' },
      { label: t('users.firstname'), value: 'firstname' },
      { label: t('users.lastname'), value: 'lastname' },
      { label: t('users.groups'), value: 'groups' },
    ];
  }, [t]);

  const groupsOptions = useMemo(() => {
    return groups.map((group) => ({ label: group, value: group }));
  }, [groups]);

  const attributeChangeCb = (filterAttribute: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, attribute: filterAttribute }));
  };
  const groupChangeCb = (filterAttribute: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, value: filterAttribute }));
  };

  const inputChangeCb = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilters) => ({ ...prevFilters, value: e.target.value }));
  };

  const applyFilterCb = () => {
    const currentFilters = { [filters.attribute]: filters.value };
    onChange(currentFilters);
  };
  const clearFilterCb = () => {
    setFilters({ attribute: '', value: '' });
    onChange({});
  };

  useEffect(() => {
    getAllGroups().then((res) => {
      setGroups(res?.Groups?.map((group) => group.GroupName) || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.col}>
        <Select options={options} onChange={attributeChangeCb} className={styles.select} defaultValue="" />
      </div>

      {filters?.attribute && filters?.attribute !== 'groups' && (
        <div className={styles.col}>
          <Input type="text" className={styles.input} onChange={inputChangeCb} />
        </div>
      )}

      {filters?.attribute === 'groups' && (
        <div className={styles.col}>
          <Select options={groupsOptions} className={styles.select} defaultValue="" onChange={groupChangeCb} />
        </div>
      )}
      {filters?.attribute && filters?.value && (
        <>
          <div className={styles.colButton}>
            <Button type="primary" className={styles.button} onClick={applyFilterCb}>
              {t('users.applyFilter')}
            </Button>
          </div>
          <div className={styles.colButton}>
            <Button type="primary" className={styles.button} onClick={clearFilterCb}>
              {t('users.clearFilter')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
