import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Button, Modal, Text } from 'components/shared';
import { Space } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import CreateCustomerForm from '../CreateCustomerForm';
import env from '../../../../../config';
import styles from './styles.module.scss';

const CreateCustomer: FC = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <Space direction="horizontal" className={styles.content}>
      <Text type="secondary" className={styles.orText}>
        {t('common.or')}
      </Text>
      <div className={styles.group} onClick={() => setIsFormVisible(true)}>
        <Button className={styles.button} type="primary" icon={<UserAddOutlined />} />
        <Text type="subtitle-primary">{t('common.addFarmer', { context: env('theme') })}</Text>
      </div>
      <Modal width={740} className={styles.modal} open={isFormVisible} onCancel={() => setIsFormVisible(false)}>
        <div className={styles.container}>
          <Text type="title" className={styles.container__title}>
            {t('landing.createAccount')}
          </Text>
          <CreateCustomerForm onSubmit={() => setIsFormVisible(false)} />
        </div>
      </Modal>
    </Space>
  );
};

export default CreateCustomer;
