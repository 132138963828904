import { translate } from 'utils/translation.utils';
import { ErrorMessage, RejectedPayload } from 'utils/message.utils';
import { ExceptionStatusType } from 'antd/lib/result';
import * as Sentry from '@sentry/react';
import { ErrorType } from '../types';

/**
 * Returns error object to handle network errors
 * @param err
 */
export const getErrorObject = (err: TypeError): ErrorType => {
  return {
    code: 503,
    message: err.message,
    name: err.name,
  };
};

export const createError = (message: string, code = 503, name = 'unknown'): ErrorType => {
  return {
    code,
    message,
    name,
    showServerMessage: true,
  };
};

export const createGenericError = (): ErrorType => {
  console.error('an error has occured generating order items');
  return createError(translate('An error has occurred.'));
};

/**
 * Returns error object when there is database connection error
 * @param err
 */
export const getDatabaseConnectionError = (err: any): ErrorType => {
  return {
    code: err.httpStatus,
    message: err.payload.message.name,
    name: 'Database connection error',
  };
};

export const handleCatchError = (err: any, rejectWithValue: any, showMessage = true): any => {
  if (showMessage) {
    err = errorWithMessage(err);
  }

  Sentry.captureException(err);
  if (err && typeof err === 'object' && err?.__show_notification === true && err?.statusCode && err?.message) {
    const { statusCode, message } = (err as unknown as { details: { message: string; statusCode: number } })?.details;

    if (statusCode === 404) {
      const translation = translate(`${message}`);
      err.message = translation === `${message}` ? err.message : translation;
    }
    return rejectWithValue(err);
  }

  if (err instanceof TypeError) {
    return rejectWithValue(getErrorObject(err));
  }

  if (err.httpStatus === 500) {
    return rejectWithValue(getDatabaseConnectionError(err));
  }
  return rejectWithValue(err);
};

export const errorResultParser = (error: any) => {
  const errorCodeClass = getErrorCodeClass(error.code);
  let status: ExceptionStatusType = 500;
  switch (errorCodeClass) {
    case 1:
      status = 403;
      break;
    case 2:
      status = 404;
      break;
    default: {
      status = 500;
    }
  }
  return {
    status,
    code: error.code,
    group: errorCodeClass,
  };
};

export const getErrorCodeClass = (code = 9): number => {
  return parseInt(String(code).charAt(0));
};

const errorWithMessage = (err: ErrorMessage): RejectedPayload<ErrorType> => {
  if (err instanceof Error) {
    const error = createError(translate('An error has occurred.'));
    // eslint-disable-next-line no-console

    return {
      ...error,
      __show_notification: true,
    };
  }

  return {
    ...err,
    __show_notification: true,
  };
};
