import { NavMenuItem } from 'types/navMenu';
import { RestServiceApi } from 'services';
import { getToken } from 'cookies';
import { config } from 'config';
import { ApiResponse } from 'apisauce';

const msAtcBackendApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class NavMenuServiceApi {
  public async getNavMenu(): Promise<ApiResponse<Array<NavMenuItem>>> {
    return msAtcBackendApi.get(
      `/nav-menu`,
      {
        '$sort[order]': 1,
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }
}

export const NavMenuService = new NavMenuServiceApi();
