import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import { translations_fr } from './fr';
import { translations_en } from './en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translations_en,
      } as const,
      fr: {
        translation: translations_fr,
      } as const,
    },
    supportedLngs: ['en', 'fr'],
    saveMissing: true,
    nsSeparator: false,
  });

export default i18n;
