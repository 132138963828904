import { useTranslation } from 'react-i18next';
import React from 'react';
import { UnderConstructionProps } from 'pages/UnderConstruction/UnderConstruction.types';
import { Text } from 'components/shared';
import laptopImage from 'assets/images/laptopImage.svg';
import { Image } from 'antd';
import styles from './UnderConstruction.module.scss';

const UnderConstruction: React.FC<UnderConstructionProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <div>
          <Image className={styles.image} src={laptopImage} preview={false} />
        </div>
        <div>
          <Text type="secondary" className={styles.text}>
            {t('This section is under construction')}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
