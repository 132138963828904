import { priceParser } from 'utils';
import { IOrderStatus } from 'types/order';
import { FC, memo } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';

const OrderDate: FC<{ date: string }> = memo(({ date }: { date: string }) => {
  const datesFormat = 'lll';
  const momentDate = moment(date).utc(true).format(datesFormat);
  return <div>{momentDate}</div>;
});

const TotalPrice: FC<{ price: number }> = memo(({ price }: { price: number }) => {
  return <div>{priceParser(price)} </div>;
});

type ClientProps = {
  firstName: string;
  lastName: string;
  company?: string;
  customerIdentifier: string;
};

const Client: FC<ClientProps> = memo(({ firstName, lastName, customerIdentifier, company }: ClientProps) => {
  return (
    <>
      <div>
        {firstName} {lastName}
      </div>
      {company && <div>{company}</div>}
      <div>{customerIdentifier}</div>
    </>
  );
});

const OrderStatus: FC<{ status: IOrderStatus }> = memo(({ status }: { status: IOrderStatus }) => {
  return <div className={`${styles['status' + status.type]} ${styles.status}`}>{status.label} </div>;
});

export { OrderDate, TotalPrice, Client, OrderStatus };
