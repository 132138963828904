import { FC, MouseEvent } from 'react';
import { Text } from 'components/shared';
import { Radio, Empty } from 'antd';
import { IHomeAddressesListProps } from './types';
import styles from './styles.module.scss';

const HomeAddressesList: FC<IHomeAddressesListProps> = (props: IHomeAddressesListProps) => {
  const { onSubmit, list, selectedId } = props;

  const onSelectItem = (e: MouseEvent<HTMLInputElement>) => {
    const id = Number(e.currentTarget.value);
    const shippingAddress = list.find((item) => item.id === id);
    shippingAddress && onSubmit(shippingAddress);
  };

  return (
    <>
      {list.length ? (
        <Radio.Group value={selectedId} className={styles.picker}>
          {list.map((address, index) => {
            return (
              <Radio onClick={onSelectItem} className={styles.item} value={address.id} key={index}>
                <Text type="subtitle-gray3" className={`${styles.item__text} ${styles.item__text_ellipsis}`}>
                  {address.firstName} {address.lastName}
                </Text>
                <Text type="subtitle-thin" className={styles.item__text}>
                  {address.street} {address.postcodeLabel} {address.townLabel}
                </Text>
                <Text type="subtitle-thin" className={styles.item__text}>
                  {address.phone1 || address.phone2}
                </Text>
              </Radio>
            );
          })}
        </Radio.Group>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default HomeAddressesList;
