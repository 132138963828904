import { IStoreShippingItem } from 'types/shippingFees';
import { selectDeliveryShippingItems, categorizeShippingItems, selectSortedShippingCategories } from 'store/delivery';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import Button from 'components/shared/Button';
import { Form, Select } from 'antd';
import styles from './DeliveryFormChange.module.scss';

const { Option } = Select;

const DeliveryFormChange: FC = () => {
  const dispatch = useDispatch();
  const shippingCategoriesList = useSelector(selectSortedShippingCategories);
  const deliveryShippingItems = useSelector(selectDeliveryShippingItems);
  const [inputValue, setInputValue] = useState(0);
  const { t } = useTranslation();

  const handleOption = (e: number) => {
    setInputValue(e);
  };

  const onSubmit = () => {
    const products = shippingCategoriesList.map((product) => product.products);
    const parsedCartShippingItems = deliveryShippingItems.map((item) => {
      const mappedProductsToShippingMethods: Array<IStoreShippingItem> = [];
      products.forEach((prod, index) => {
        const finded = prod.find((prodCategory) => prodCategory.offerId === item.offerId);
        const shippingCateogry = shippingCategoriesList[index];
        const foundItem = shippingCateogry.itemList?.find((currentItem) => currentItem.offerId === finded?.offerId);
        const isShippingMethodId = foundItem?.shippingMethodIds.find((id) => id === inputValue);
        if (isShippingMethodId) {
          mappedProductsToShippingMethods.push({
            ...item,
            shippingMethodId: inputValue,
          });
        }
      });
      return mappedProductsToShippingMethods;
    });
    const flattenedCartShippingItems = parsedCartShippingItems.flat();
    const finalCartShippingItems = deliveryShippingItems.map((item) => {
      const updedShippingItem = flattenedCartShippingItems.find((a) => a?.offerId === item.offerId);
      if (updedShippingItem) {
        return updedShippingItem;
      } else {
        return item;
      }
    });

    dispatch(categorizeShippingItems(finalCartShippingItems));
  };

  return (
    <Form className={styles.form}>
      <Select
        className={styles.select}
        value={undefined}
        onChange={handleOption}
        size={'large'}
        placeholder={`${t('forms.chooseDeliveryMethodPlaceholder')}`}
      >
        {shippingCategoriesList.map((item) => (
          <Option key={item.shippingMethodId} value={item.shippingMethodId}>
            {item.label}
          </Option>
        ))}
      </Select>
      <Button type="primary" className={styles.button} htmlType="button" onClick={onSubmit}>
        {t('common.apply')}
      </Button>
    </Form>
  );
};

export default DeliveryFormChange;
