export enum EntityType {
  CART = 'cart',
  CART_ITEM = 'cart_item',
}

export enum ActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE',
  STATUS_UPDATE = 'STATUS_UPDATE',
  PUSH = 'PUSH',
}

export interface Log {
  id: number;
  entity: EntityType;
  itemId: string;
  author: string;
  message: string;
  payload: Record<string, unknown>;
  action: ActionType;
  params: Record<string, unknown>;
  diff: Record<string, unknown>;
  createdAt: string;
  deletedAt: string;
}
