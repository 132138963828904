import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Price from 'components/shared/Price/Price';
import { TagsList, Text } from 'components/shared';
import styles from '../ValidationProduct/ValidationProduct.module.scss';
import { IValidationNoStockContainerProps } from '../ValidationNoStockContainer/ValidationNoStockContainer';

const ValidationNoStockProduct: FC<IValidationNoStockContainerProps> = (props: IValidationNoStockContainerProps) => {
  const { product } = props;
  const { name, tags, conditioning } = product;
  const { t } = useTranslation();

  return (
    <div className={styles.product}>
      <div className={styles.info}>
        <Text type="subtitle-third">
          {name} - {conditioning}
        </Text>
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.unitPrice')}: {<Price unitPrice={0} className={styles.unitPrice} />}
        </Text>
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.quantity')}: 0
        </Text>
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.volume')}: 0
        </Text>
        <TagsList tags={tags} />
        <br />
        <Text type="subtitle-gray1" className={styles.total}>
          {t('common.total')}: <Price unitPrice={0} className={styles.totalPrice} />
        </Text>
      </div>
    </div>
  );
};

export default ValidationNoStockProduct;
