import { FC } from 'react';
import { SwitchProps } from 'components/shared/Switch/types';
import { Switch as ADSwitch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import styles from './Switch.module.scss';

const Switch: FC<SwitchProps> = ({ label, ...rest }: SwitchProps) => {
  const Child = () => {
    return (
      <ADSwitch
        onClick={(_, evt) => evt.preventDefault()}
        className={styles.switch}
        checkedChildren={<CheckOutlined onClick={(evt) => evt.preventDefault()} />}
        unCheckedChildren={<CloseOutlined onClick={(evt) => evt.preventDefault()} />}
        {...rest}
      />
    );
  };

  return (
    <div className={styles.container}>
      {label ? (
        <label className={styles.lable}>
          <Child />
          <div className={styles.text}>{label}</div>
        </label>
      ) : (
        <Child />
      )}
    </div>
  );
};

export default Switch;
