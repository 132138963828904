export const order_history_fr = {
  orderHistory: 'Commandes',
  orderNumber: 'N° de commande',
  status: 'Statut',
  client: 'Client',
  creationDate: 'Date de création',
  netTotal: 'Montant HT',
  total: 'Montant TTC',
  display: 'Visualiser',
  name: 'Nom',
  surname: 'Prénom',
  company: 'Raison sociale',
  thirdPartyNumber: 'N° Tiers',
  tableOrderNumber: 'N° Commande',
  tableStatus: 'Statut',
  deleteFilters: 'Effacer les filtres',
  filterResults: 'Filtrer les résultats',
  up: 'PU',
  qty: 'Qté',
  sku: 'SKU',
  details: 'Détails',
  page: 'Page',
  productInfo: 'Produit info',
  code: 'Code',
  pu: 'PU',
  notAllowedOrderList: 'Vous n’avez pas la permission de visualiser les commandes.',
};
