import { setGroupWhitelistFilter, setGroupWhitelistPage, setWhitelistFilter, setWhitelistPage } from 'store/customers';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import { Input } from 'antd';
import { CustomersFilterProps } from './CustomersFilter.types';
import styles from './CustomersFilter.module.scss';

const CustomersFilter: React.FC<CustomersFilterProps> = ({ isGroup }: CustomersFilterProps) => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();
  const minChar = 3;

  const debouncedFilter = useDebounce(filter, 300);

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const filterCustomers = useCallback(() => {
    dispatch(setWhitelistPage(1));
    dispatch(setWhitelistFilter(debouncedFilter));
  }, [debouncedFilter, dispatch]);

  const filterGroupCustomers = useCallback(() => {
    dispatch(setGroupWhitelistPage(1));
    dispatch(setGroupWhitelistFilter(debouncedFilter));
  }, [debouncedFilter, dispatch]);

  useEffect(() => {
    if (debouncedFilter.length >= minChar || debouncedFilter.length === 0) {
      isGroup ? filterGroupCustomers() : filterCustomers();
    }

    setShowError(() => debouncedFilter.length < minChar && debouncedFilter.length !== 0);
  }, [debouncedFilter, dispatch, filterCustomers, filterGroupCustomers, isGroup]);

  return (
    <div className={styles.wrapper}>
      {showError && <span className={styles.error}>{t('errors.minimumFourCharacters', { count: 3 })}</span>}
      <Input
        placeholder={t('landing.searchHere').toString()}
        size="large"
        className={styles.input}
        onChange={(event) => handleFilter(event.target.value)}
      />
    </div>
  );
};

export default CustomersFilter;
