import { DateFormat, DateFormatRecord } from 'types';
import { DATE_FORMAT, DEFAULT_LOCALE, LocaleKey, LOCALE_ISO } from 'constants/locale';

export const getBrowserLocale = (): LocaleKey => {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).userLanguage
  );
};

export function getDateFormat(lang: keyof DateFormatRecord): DateFormat;
export function getDateFormat(lang: keyof DateFormatRecord, formatType: keyof DateFormat): string;
export function getDateFormat(lang: keyof DateFormatRecord, formatType?: keyof DateFormat): string | DateFormat {
  if (!formatType) {
    return DATE_FORMAT[lang];
  }
  return DATE_FORMAT[lang][formatType];
}

export function getLocaleDateFormat(): DateFormat;
export function getLocaleDateFormat(formatType: keyof DateFormat): string;
export function getLocaleDateFormat(formatType?: keyof DateFormat): string | DateFormat {
  const locale = getBrowserLocale();
  let soruce = LOCALE_ISO[DEFAULT_LOCALE];

  if (Object.prototype.hasOwnProperty.call(LOCALE_ISO, locale)) {
    soruce = LOCALE_ISO[locale];
  }

  if (!formatType) {
    return soruce.dateFormat;
  }
  return soruce.dateFormat[formatType];
}
