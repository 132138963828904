import { createTheme } from 'utils/theme.utils';
import { ThemeSource } from 'types/theme';
import { FC } from 'react';

export function withTheme<Props>(base: FC<Props>) {
  return (source: Omit<ThemeSource<FC<Props>>, 'base'>) =>
    (props: Props): ReturnType<FC<Props>> => {
      const Component = createTheme({ ...source, base });
      return <Component {...props} />;
    };
}
