import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Text } from 'components/shared';
import WarningIcon from 'assets/images/WarningIcon.png';
import TickIcon from 'assets/images/TickIcon.png';
import { Image } from 'antd';
import { ValidatedProps } from './types';
import styles from './ValidatedQuote.module.scss';

type ValidatedQuoteProps = ValidatedProps;

const ValidatedQuote: React.FC<ValidatedQuoteProps> = (props: ValidatedQuoteProps) => {
  const { onClickLogout, onCreateNewOrder } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.container} style={{ marginTop: 150 }}>
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <div>
            <Image preview={false} src={TickIcon} className={styles.checkmarkImage} />
          </div>
          <div>
            <Text className={styles.title} type="title-gray8">
              {t('common.quoteValidated')}!
            </Text>
          </div>
        </div>

        <Text className={styles.content__subtitle} type="title-gray8">
          {t('common.quatationSubtitle')}!
        </Text>

        <div className={styles.warningWrapper}>
          <Image preview={false} src={WarningIcon} className={styles.image} />
          <Text className={styles.warning} type="title-gray8">
            {t('common.quatationWarning')}!
          </Text>
        </div>

        <div className={styles.content__buttons}>
          <Button
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              onClickLogout().finally(() => {
                setIsLoading(false);
              });
            }}
            className={`${styles.button} ${styles.button_dashed}`}
            type="default"
          >
            {t('common.viewQuote')}
          </Button>
          <Button onClick={onCreateNewOrder} className={`${styles.button} ${styles.button_full}`}>
            {t('common.createNewQuote')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ValidatedQuote;
