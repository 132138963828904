import { FC } from 'react';
import { useStepper } from 'hooks';
import { LeftOutlined } from '@ant-design/icons';
import Button from '../Button';
import { IBackButtonProps } from './BackButton.types';

const BackButton: FC<IBackButtonProps> = (_props: IBackButtonProps) => {
  const { goPrevStep } = useStepper();

  return (
    <Button type="dashed" onClick={goPrevStep}>
      <LeftOutlined />
    </Button>
  );
};

export default BackButton;
