import { useIsMobile, useIsTabletSizes } from 'utils/styles.utils';
import { priceParser, quantityParser } from 'utils';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ProductPrice, Quantity, Text } from 'components/shared';
import classNames from 'classnames';
import { Button, Input, Space, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import styles from '../CartRowContent/styles.module.scss';
import ProductWarning from '../../../ProductTable/components/ProductWarning';
import {
  setReplacementModalProductIndex,
  setReplacementModalQuantity,
  setReplacementModalSku,
  setReplacementModalVisible,
} from '../../../../../store/products';
import { ICartRowContentNoStockProps } from './types';

const CartRowContentNoStock: FC<ICartRowContentNoStockProps> = (props: ICartRowContentNoStockProps) => {
  const {
    product: { taxes, productId, variantId, offerId, quantity, sku },
    productIndex,
  } = props;
  const isMobile = useIsMobile();
  const isTabletSizes = useIsTabletSizes();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOpenOffersModal = () => {
    dispatch(setReplacementModalSku(sku));
    dispatch(setReplacementModalVisible(true));
    dispatch(setReplacementModalQuantity(quantity ?? 0));
    dispatch(setReplacementModalProductIndex(productIndex));
  };

  const voidChange = () => {
    return;
  };
  return (
    <div className={classNames(styles.row, styles.row__noStock)}>
      <div className={styles.column}>
        <div className={styles.column__element}>
          <Quantity
            onChange={voidChange}
            onIncrease={voidChange}
            onDecrease={voidChange}
            maximum={0}
            value={0}
            minimum={0}
            subTitle={`${quantityParser(0, 0)}`}
            disabled={true}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.column__element}>
          <Text type="subtitle-gray1" className={styles.column__element__prix_initial}>
            {t('common.initialNetPrice')}
          </Text>
          <ProductPrice price={0} isProduct={false} />
        </div>
        {isMobile ? (
          <div className={`${styles.column__element} ${styles.column__element_group}`}>
            <Text type="subtitle-gray1">{t('common.forcedNetPrice')}</Text>
            <Input type="string" disabled={true} onChange={voidChange} value={0} className={styles.input} />
          </div>
        ) : (
          !!taxes.cvo && (
            <div className={styles.column__element}>
              <Text type="subtitle-gray5">{t('common.ofWhichCvo')}</Text>
              <Text type="subtitle-gray3">0</Text>
            </div>
          )
        )}
      </div>
      <Space align="start">
        <div className={styles.column}>
          {isMobile ? (
            !!taxes.cvo && (
              <div className={styles.column__element}>
                <Text type="subtitle-gray5">{t('common.ofWhichCvo')}</Text>
                <Text type="subtitle-gray3">0</Text>
              </div>
            )
          ) : (
            <div className={`${styles.column__element} ${styles.column__element_group}`}>
              <Text type="subtitle-gray1">{t('common.forcedNetPrice')}</Text>
              <Text type="subtitle-gray3">{priceParser(0)}</Text>
            </div>
          )}
          {!!taxes.rpd && (
            <div className={styles.column__element}>
              <Text type="subtitle-gray5">{t('common.rpdTax')}</Text>
              <Text type="subtitle-gray3">{priceParser(0)}</Text>
            </div>
          )}
        </div>
        <Tooltip title={t('cart.replaceOffer')}>
          <Button
            className={styles.replaceOfferButton}
            type="text"
            shape="circle"
            onClick={handleOpenOffersModal}
            icon={<SwapOutlined />}
          />
        </Tooltip>
      </Space>
      <div className={styles.column}>
        <div className={styles.column__element}>
          <Text type="subtitle-gray1">{t('common.netTotal')}</Text>
          <Text type="subtitle-gray3">{priceParser(0)}</Text>
        </div>
        {isTabletSizes && (
          <div className={styles.column__element}>
            <Text type="subtitle-gray5" className={styles.centered}>
              {t('common.totalPrice')}
            </Text>
            <Text type="subtitle-gray3">
              <span className={styles.block}>{priceParser(0)}</span>
            </Text>
          </div>
        )}
      </div>
      {!isTabletSizes && (
        <div className={styles.column}>
          <div className={`${styles.column__element} ${styles.column__element_group}`}>
            <Text type="subtitle-gray1" className={styles.centered}>
              {t('common.totalPrice')}
            </Text>
            <Text type="subtitle-gray3">
              <span className={styles.block}>{priceParser(0)}</span>
            </Text>
          </div>
        </div>
      )}
      <ProductWarning productId={productId} variantId={variantId} offerId={offerId} />
    </div>
  );
};

export default CartRowContentNoStock;
