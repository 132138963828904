import { capitalizeFirstLetter } from 'utils/string.utils';
import { AppThunkDispatch } from 'store/store';
import { deleteMessage, selectMessages } from 'store/messages';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { IconType } from 'antd/lib/notification/interface';
import { notification } from 'antd';
import { getErrorCodeClass } from '../../../utils';
import { useLogout } from '../../../hooks';

export const Messages: FC = () => {
  const messagesToDisplay = useSelector(selectMessages);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { logout } = useLogout();
  const placement = 'bottomRight';
  const { t } = useTranslation();

  const getValueByType = (type: IconType) => {
    if (type === 'warning') return 'Attention';
    return type;
  };

  React.useEffect(() => {
    const handleClose = (id: number) => dispatch(deleteMessage(id));

    messagesToDisplay.forEach(({ id, message, type = 'success', code, showServerMessage = true, traceId }) => {
      let displayMessage;
      const title = capitalizeFirstLetter(getValueByType(type));
      let displayTitle;

      if (type === 'error') {
        displayMessage = t(`errors.${code}`);

        if (traceId) {
          displayMessage = (
            <>
              {displayMessage}
              <br />
              ID: {traceId}
            </>
          );
        }

        displayTitle = t(`errors.${getErrorCodeClass(code)}`);
      }

      notification[type]({
        message: code ? displayTitle : title,
        key: id.toString(),
        description: showServerMessage ? message : displayMessage,
        placement,
        onClose: () => handleClose(id),
      });
    });
  }, [messagesToDisplay, dispatch, logout, t]);
  return null;
};
