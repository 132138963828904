import { unwrap } from 'utils/api.utils';
import { isApiOkResponse } from 'utils';
import { IShippingAddresses, PaginatedResponse } from 'types';
import { ShippingApiService } from 'services';
import { ApiResponse } from 'apisauce';
import { setAxiosError, setStatusCodeError } from 'constants/errors';

type FetchShippingAddressesAdapter = ApiResponse<PaginatedResponse<IShippingAddresses>>;

type FetchShippingAddressesStateAdapterProps = {
  userId: number;
  token: string;
};
export const fetchShippingAddressesStateAdapter = async ({
  userId,
  token,
}: FetchShippingAddressesStateAdapterProps): Promise<FetchShippingAddressesAdapter> => {
  return ShippingApiService.getShippingAddresses(userId, token);
};

export const fetchShippingAddressesSavedCartAdapter = fetchShippingAddressesStateAdapter;

export const fetchShippingAddressesAdapter = async (
  adapter: Promise<FetchShippingAddressesAdapter>,
): Promise<IShippingAddresses[]> => {
  const data = unwrap(await adapter, (response) => {
    if (!isApiOkResponse(response)) {
      const statusCode = response.originalError.response?.data.code;
      if (statusCode) {
        throw setStatusCodeError(statusCode);
      }
      throw setAxiosError(response.problem);
    }
  });

  return data?.data || [];
};
