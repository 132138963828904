import { useToken } from 'cookies';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { config } from '../config';
import { GroupsResponse, LoginResponse } from './useAuth.types';
const { atcCheckoutBackendUrl } = config;

const getHeaders = (token: string) => {
  return {
    headers: {
      authorization: `Bearer ${token}`,
      'Cache-Control': 'no-store',
    },
  };
};

type ConfirmUserType = Pick<LoginResponse, 'ChallengeName' | 'Session' | 'ChallengeParameters'>;
export type UseAuth = () => {
  login: (username: string, password: string) => Promise<LoginResponse>;
  confirmUser: (password: string, data: ConfirmUserType) => Promise<LoginResponse>;
  logout: (username: string) => Promise<void>;
  refreshToken: (token: string) => Promise<LoginResponse>;
  forgotPassword: (username: string) => Promise<void>;
  confirmForgotPassword: (username: string, newPassword: string, code: string) => Promise<void>;
  getLoggedUser: (revalidate?: boolean) => Promise<LoginResponse['User']>;
  getUser: (username: string) => Promise<LoginResponse['User']>;
  editProfile: (data: Partial<LoginResponse['User']>, username?: string) => Promise<LoginResponse['User']>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  createUser: (data: Partial<LoginResponse['User']>) => Promise<LoginResponse['User']>;
  getAllGroups: () => Promise<GroupsResponse>;
  deleteUsers: (usernames: string[]) => Promise<void>;
  addUsersToGroups: (groupIds: string[], usernames: string[]) => Promise<void>;
  removeUsersFromGroups: (groupIds: string[], usernames: string[]) => Promise<void>;
};

export const useAuth: UseAuth = () => {
  const { idToken, setToken, removeToken } = useToken();
  const msAuthBaseUrl = `${atcCheckoutBackendUrl}/auth`;
  const login = async (username: string, password: string) => {
    try {
      const res: AxiosResponse<LoginResponse> = await axios.post(`${msAuthBaseUrl}/login`, {
        username,
        password,
        withDecodedToken: true,
      });

      if (res.data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        return res.data;
      }

      if (!res.data.AuthenticationResult) {
        throw new Error('Authentification failed');
      }

      const expiresIn = res.data.User ? res.data.User.exp - res.data.User.auth_time : 3600;

      setToken(
        res.data.AuthenticationResult.IdToken,
        res.data.AuthenticationResult.AccessToken,
        res.data.AuthenticationResult.RefreshToken,
        expiresIn,
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const confirmUser = async (password: string, data: ConfirmUserType) => {
    try {
      const res: AxiosResponse<LoginResponse> = await axios.post(`${msAuthBaseUrl}/confirm-user`, {
        password,
        ...data,
        withDecodedToken: true,
      });

      if (!res.data.AuthenticationResult) {
        throw new Error('Authentification failed');
      }

      setToken(
        res.data.AuthenticationResult.IdToken,
        res.data.AuthenticationResult.AccessToken,
        res.data.AuthenticationResult.RefreshToken,
        res.data.AuthenticationResult.ExpiresIn,
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const refreshToken = async (rToken: string) => {
    try {
      const res: AxiosResponse<LoginResponse> = await axios.post(`${msAuthBaseUrl}/refresh-token`, {
        token: rToken,
        withDecodedToken: true,
      });

      setToken(
        res.data.AuthenticationResult.IdToken,
        res.data.AuthenticationResult.AccessToken,
        // refresh token not part of response so just keep existing one
        rToken,
        res.data.AuthenticationResult.ExpiresIn,
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const logout = async () => {
    try {
      const res: AxiosResponse<void> = await axios.post(`${msAuthBaseUrl}/logout`, {
        token: idToken,
      });
      removeToken();
      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const forgotPassword = async (username: string) => {
    try {
      const res: AxiosResponse<void> = await axios.post(`${msAuthBaseUrl}/forgot-password`, {
        username,
      });
      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const confirmForgotPassword = async (username: string, password: string, confirmationCode: string) => {
    try {
      const res = await axios.post(`${msAuthBaseUrl}/confirm-forgot-password`, {
        username,
        password,
        confirmationCode,
      });

      return res.data;
    } catch (error) {
      throw error as AxiosError<Error>;
    }
  };
  const getLoggedUser = async (revalidate = false) => {
    try {
      const res: AxiosResponse<LoginResponse['User']> = await axios.get(
        `${msAuthBaseUrl}/me?revalidate=${revalidate}`,
        {
          ...getHeaders(idToken),
        },
      );
      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const getUser = async (username: string) => {
    try {
      const res: AxiosResponse<LoginResponse['User']> = await axios.get(`${msAuthBaseUrl}/users/${username}`, {
        ...getHeaders(idToken),
      });
      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const editProfile = async (data: Partial<LoginResponse['User']>, username?: string) => {
    try {
      const url = `${msAuthBaseUrl}/${username ? `users/${username}` : 'me'}`;
      const res = await axios.put(url, data, {
        ...getHeaders(idToken),
      });

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };

  const createUser = async (data: Partial<LoginResponse['User']>) => {
    try {
      const res = await axios.post(`${msAuthBaseUrl}/users`, data, {
        ...getHeaders(idToken),
      });

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const res = await axios.post(
        `${msAuthBaseUrl}/change-password`,
        {
          oldPassword,
          newPassword,
          accessToken: idToken,
        },
        {
          ...getHeaders(idToken),
        },
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };

  const getAllGroups = async () => {
    try {
      const res = await axios.get(`${msAuthBaseUrl}/groups`, {
        ...getHeaders(idToken),
      });

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.data?.errors) {
      return (error as AxiosError)?.response?.data?.errors.map((err: any) => {
        return new Error(err.message);
      });
    }
    return new Error('Something went wrong');
  };

  const addUsersToGroups = async (groups: string[], usernames: string[]) => {
    try {
      const res = await axios.post(
        `${msAuthBaseUrl}/add-users-to-groups`,
        {
          groups,
          usernames,
        },
        {
          ...getHeaders(idToken),
        },
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const removeUsersFromGroups = async (groups: string[], usernames: string[]) => {
    try {
      const res = await axios.post(
        `${msAuthBaseUrl}/remove-users-from-groups`,
        {
          groups,
          usernames,
        },
        {
          ...getHeaders(idToken),
        },
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };
  const deleteUsers = async (usernames: string[]) => {
    try {
      const res = await axios.post(
        `${msAuthBaseUrl}/delete-users`,
        {
          usernames,
        },
        {
          ...getHeaders(idToken),
        },
      );

      return res.data;
    } catch (error) {
      throw handleError(error);
    }
  };

  return {
    login,
    logout,
    refreshToken,
    confirmUser,
    forgotPassword,
    confirmForgotPassword,
    getLoggedUser,
    getUser,
    editProfile,
    changePassword,
    createUser,
    getAllGroups,
    deleteUsers,
    addUsersToGroups,
    removeUsersFromGroups,
  };
};
