import { users_fr } from './users';
import { saved_cart_fr } from './saved_cart';
import { order_history_fr } from './order_history';
import { my_profile_fr } from './my_profile';
import { modals_fr } from './modals';
import { mass_action_fr } from './mass_action';
import { logs_fr } from './logs';
import { login_fr } from './login';
import { landing_fr } from './landing';
import { forms_fr } from './forms';
import { errors_fr } from './errors';
import { date_fr } from './date';
import { common_fr } from './common';
import { cart_requests_fr } from './cart_requests';
import { cart_fr } from './cart';
import { abandoned_carts_fr } from './abandoned_carts';
/** Type "Translation" will make sure there are no missing translations */
export const translations_fr: Translations = {
  common: {
    ...common_fr,
  },
  login: {
    ...login_fr,
  },
  landing: {
    ...landing_fr,
  },
  forms: {
    ...forms_fr,
  },
  modals: {
    ...modals_fr,
  },
  errors: {
    ...errors_fr,
  },
  order_history: {
    ...order_history_fr,
  },
  my_profile: {
    ...my_profile_fr,
  },
  saved_cart: {
    ...saved_cart_fr,
  },
  cart: {
    ...cart_fr,
  },
  cartRequests: {
    ...cart_requests_fr,
  },
  abandonedCarts: {
    ...abandoned_carts_fr,
  },
  date: {
    ...date_fr,
  },
  users: {
    ...users_fr,
  },
  massAction: {
    ...mass_action_fr,
  },
  logs: {
    ...logs_fr,
  },
};
