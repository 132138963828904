import { FC } from 'react';
import { ITextProps } from './Text.types';
import styles from './Text.module.scss';

const Text: FC<ITextProps> = (props: ITextProps) => {
  const { type = 'default', className = '', children, onClick = () => null, style = {} } = props;

  const getStylesByType = () => {
    switch (type) {
      case 'title':
        return styles.text__title;
      case 'title-gray3':
        return `${styles.text__title} ${styles.text__title_gray3}`;
      case 'title-gray8':
        return `${styles.text__title} ${styles.text__title_gray8}`;
      case 'title-white':
        return `${styles.text__title} ${styles.text__title_white}`;
      case 'subtitle':
        return styles.text__subtitle;
      case 'subtitle-primary':
        return `${styles.text__subtitle} ${styles.text__subtitle_primary}`;
      case 'subtitle-gray3':
        return `${styles.text__subtitle} ${styles.text__subtitle_gray3}`;
      case 'subtitle-thin':
        return `${styles.text__subtitle} ${styles.text__subtitle_thin}`;
      case 'subtitle-gray5':
        return `${styles.text__subtitle} ${styles.text__subtitle_gray5}`;
      case 'subtitle-gray1':
        return `${styles.text__subtitle} ${styles.text__subtitle_gray1}`;
      case 'subtitle-gray8':
        return `${styles.text__subtitle} ${styles.text__subtitle_gray8}`;
      case 'subtitle-third':
        return `${styles.text__subtitle} ${styles.text__subtitle_third}`;
      case 'secondary':
        return styles.text__secondary;
      case 'secondary-primary':
        return `${styles.text__secondary} ${styles.text__secondary_primary}`;
      case 'secondary-gray1':
        return `${styles.text__secondary} ${styles.text__secondary_gray1}`;
      case 'secondary-gray3':
        return `${styles.text__secondary} ${styles.text__secondary_gray3}`;
      case 'secondary-gray8':
        return `${styles.text__secondary} ${styles.text__secondary_gray8}`;
      case 'secondary-thin':
        return `${styles.text__secondary} ${styles.text__secondary_thin}`;
    }
  };

  return (
    <p onClick={onClick} style={style} className={`${styles.text} ${getStylesByType()} ${className}`}>
      {children}
    </p>
  );
};

export default Text;
