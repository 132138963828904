import { isEmpty } from 'utils/object.utils';
import { ErrorType } from 'types';
import { AppThunkDispatch } from 'store/store';
import { sendMessage } from 'store/messages';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Button } from 'components/shared';
import NotFound from 'components/layout/NotFound';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Col, Form, Input, Layout, Modal, Row } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { getUsersPath } from 'constants/routes';
import { firstnameRules, lastnameRules, phoneNumberRules, usernameRules } from './EditUser.validators';
import { IUserDetailsFormValues } from './EditUser.types';
import styles from './EditUser.module.scss';
const modalBodyStyles = {
  borderRadius: '10px',
  background: 'white',
};

export const EditUser: FunctionComponent = () => {
  const { t } = useTranslation();
  const [groups, setGroups] = useState<string[]>([]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const { createUser, editProfile, getUser, getAllGroups } = useAuth();
  const [userDetails] = Form.useForm();
  const [userFormValues, setUserFormValues] = useState<IUserDetailsFormValues>({
    firstname: '',
    lastname: '',
    username: '',
    phoneNumber: '',
    groups: [],
  });

  const onSubmitUserDetailsForm = async (value: IUserDetailsFormValues) => {
    try {
      const valueToSend = { ...value, groups: userFormValues.groups || [] };
      username === 'new' ? await createUser(valueToSend) : await editProfile(valueToSend, username);
      setShowSuccessDialog(true);
      setDialogText(
        username === 'new' ? (t('users.newUserAdded') as string) : (t('users.userDetailsUpdated') as string),
      );
      setUserFormValues(value);
    } catch (error) {
      dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
    }
  };

  const onDialogCloseCb = () => {
    setShowSuccessDialog(false);
    setDialogText('');
    navigate(getUsersPath());
  };
  const clickedGroupCheckbox = (e: CheckboxChangeEvent, group: string) => {
    const currentGroups = userFormValues.groups || [];
    const newGroups = e.target.checked ? [...currentGroups, group] : currentGroups.filter((g) => g !== group);
    const currentValues = userDetails.getFieldsValue();

    setUserFormValues({ ...currentValues, groups: newGroups });
    userDetails.setFieldsValue({ ...currentValues, groups: newGroups });
  };
  useEffect(() => {
    getAllGroups().then((res) => {
      setGroups(res?.Groups?.map((group) => group.GroupName) || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (username === 'new') return;
    getUser(username as string)
      .then((user) => {
        if (!user) return;
        const userData = {
          firstname: user.firstname || '',
          lastname: user.lastname || '',
          username: user.username,
          phoneNumber: user.phoneNumber,
          groups: user.groups || [],
        };
        userDetails.setFieldsValue(userData);
        setUserFormValues(userData);
      })
      .catch((error) => {
        dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
        navigate(getUsersPath());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Content className={styles.profile}>
      <h3 className={styles.title}>{t(username === 'new' ? 'users.newUserPageTitle' : 'users.editUserPageTitle')}</h3>
      <NotFound showOnRule showContent={!isEmpty(userFormValues)}>
        <Row className={styles.content}>
          <Col className={styles.content__main} span={11}>
            <h3 className={styles.subtitle}>{t('users.personalInformation')}</h3>
            <Form
              form={userDetails}
              className={styles.form}
              layout="vertical"
              name="profile"
              initialValues={userFormValues}
              onFinish={onSubmitUserDetailsForm}
            >
              <Form.Item
                shouldUpdate
                rules={firstnameRules}
                className={styles.form__input}
                label={t('users.lastname')}
                name="firstname"
              >
                <Input className={styles.input} />
              </Form.Item>
              <Form.Item
                rules={lastnameRules}
                className={styles.form__input}
                label={t('users.firstname')}
                name="lastname"
              >
                <Input className={styles.input} />
              </Form.Item>
              <Form.Item
                rules={usernameRules}
                className={styles.form__input}
                label={t('users.username')}
                name="username"
              >
                <Input className={styles.input} type="email" />
              </Form.Item>
              <Form.Item
                rules={phoneNumberRules}
                className={styles.form__input}
                label={t('users.phoneNumber')}
                name="phoneNumber"
              >
                <Input className={styles.input} />
              </Form.Item>
              <Form.Item className={styles.form__button}>
                <Button type="primary" className={styles.button} htmlType="submit">
                  {t('users.saveChanges')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col className={styles.content__image} span={11}>
            <h3 className={styles.subtitle}>{t('users.yourGroups')}</h3>
            {groups?.map((group) => (
              <Checkbox
                key={group}
                checked={userFormValues?.groups?.includes(group)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  clickedGroupCheckbox(e, group);
                }}
              >
                {group}
              </Checkbox>
            ))}
          </Col>
        </Row>
      </NotFound>
      <Modal
        width={'246px'}
        footer={null}
        open={showSuccessDialog}
        bodyStyle={modalBodyStyles}
        onCancel={onDialogCloseCb}
      >
        <div className={styles.modal}>
          <CheckCircleFilled className={styles.modal__icon} />
          <p className={styles.modal__text}>{dialogText}</p>
        </div>
      </Modal>
    </Layout.Content>
  );
};
export default EditUser;
