export const modals_fr = {
  inputPostalCode: 'Indiquez votre code postal',
  postalCodeSubtitle: "Cela est nécessaire afin d'afficher les prix et la disponibilité des produits",
  postalCodeTitle: 'Code postal',
  postalCode: 'Saisissez un code postal',
  availableDeliveryAddresses: 'Choisissez une adresse enregistrée',
  noTownFound: 'Aucune ville trouvée',
  validatePostalCode: 'Valider le code postal',
  viewLessAddresses: "Voir moins d'adresses",
  viewMoreAddresses: "Voir plus d'adresses",
  searchProductMessage: 'Faites votre sélection de produits en renseignant leur nom ou leur référence.',
  postalCodeChangeMessage:
    'Changer votre code postal rechargera votre panier avec les contraintes de la nouvelle localité.',
  changePostalCode: 'Changer le code postal',
  shippingAddress: 'Adresse de livraison',
  addShippingAddress: 'Ajouter une adresse de livraison',
  changeShippingAddress: "Changer l'adresse de livraison",
  createNewAddress: 'Créer une nouvelle adresse',
  yourGroupDeliveryAddressTitle: 'Votre group adresse de livraison',
  yourDeliveryAddressTitle: 'Votre adresse de livraison',
  selectYourFile: 'Sélectionnez votre fichier',
  findFile: 'Trouver mon fichier',
  wiuzSubtitle:
    "Pour retrouver vos préconisations dans IZI, générez votre plan d'approvisionnement dans Wiuz et importez votre fichier",
  wiuzSupplyPlanTip: "Votre plan d'approvisionnement Wiuz se trouve à cet emplacement",
  wiuzFileTip:
    'Attention, le dossier WIUZ est parfois nommé INVIVO en fonction de votre poste. Le nom du fichier est de la forme << PreparationCommande_ToWork_AXEREAL_XXXXX.tab >>.',
  historicalPriceModalTitle: 'Historique Produit directement en saisie de BdC',
  historicalPriceTableHeaderCa: 'C.A',
  historicalPriceTableHeaderQty: 'Qté',
  historicalPriceTableHeaderPmv: 'P.M.V',
  historicalPriceTableHeaderCommercial: 'Commercial',
  historicalPriceTableHeaderCustomerIdentifier: 'Code',
  historicalPriceTableHeaderCustomerAccount: 'Compte',
  warehouseStockTitle: 'Stock des dépôts',
  warehouseName: 'Dépôt',
  warehouseStockQuantity: 'Stock',
  warehouseStockSku: 'SKU',
  warehouseStockProductName: 'Nom du produit',
  gammeCGEAttention:
    "Attention! Commande avec remise: CGE. Ne concerne que les Complements Nutritionnels (hors cubitainer). Ne pas mettre au panier d'autre produits",
  okButton: 'OK',
  postalCodeAlternativSearchText: 'Renseigner un code postal de livraison',
  leavePageConfirmationTitle: 'Un devis est en cours, souhaitez-vous le sauvegarder ?',
  leavePageConfirmationAcceptButton: 'Oui',
  leavePageConfirmationDeclineButton: 'Non',
  discountPopoverTitle: 'Promotions',
  discountPopoverStartDate: 'Date de début',
  discountPopoverEndDate: 'Date de fin',
  discountPopoverDiscount: 'Promotion',
  discountPopoverMinimumOrder: 'Qté. min.',
  discountPopoverUndefined: 'Non défini',
  confirmationModalAcceptButton: 'Oui',
  confirmationModalDeclineButton: 'Non',
  confirmationModalSendValidationTitle: 'Etes vous sur de vouloir valider la commande?',
  confirmationModalSendQuoteTitle: 'Etes vous sur de vouloir publier le devis au client?',
  confirmationPopupKeepPriceTitle: 'Voulez-vous conserver le prix HT forcé ?',
  addProductModalTitle: 'Ajouter un nouvel article',
  addProductModalRequestedProduct: 'Nom du produit demandé',
  addProductModalSuggestedProduct: 'Nom du produit proposé',
};
