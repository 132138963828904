import { renderUnitLabel } from 'utils/cart.utils';
import { quantityParser } from 'utils';
import { SelectOptionAttrs } from 'types';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectSuppliers } from 'store/products';
import { setSupplierPrice } from 'store/cart';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Text } from 'components/shared';
import {
  SupplierPriceCartProduct,
  SupplierPriceFromValues,
} from 'components/product/CartTable/components/CartRowInfo/components/SupplierPriceModal/SupplierPriceModal.types';
import { useForm } from 'antd/lib/form/Form';
import { Rule } from 'antd/lib/form';
import { Form, Input, Select, Space, InputNumber } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './SupplierPriceModal.module.scss';
const { TextArea } = Input;

const createRule = (message: string): Array<Rule> => {
  return [{ required: true, message }];
};

interface SupplierPriceModalProps {
  product: SupplierPriceCartProduct;
  visible: boolean;
  showModal: (value: boolean) => void;
}

const SupplierPriceModal: React.FC<SupplierPriceModalProps> = ({
  product,
  visible,
  showModal,
}: SupplierPriceModalProps) => {
  const { quantityIncrement, price, offerId, supplierPrice } = product;
  const { supplierUnitPrice = 0 } = price;
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const suppliers = useAppSelector(selectSuppliers);
  const [supplierQuantity, setSupplierQuantity] = useState(supplierPrice?.quantity || 0);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const options: Array<SelectOptionAttrs<number>> = useMemo(() => {
    const list = suppliers.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    return [
      {
        value: 0,
        label: t('common.supplierPriceNone'),
      },
      ...list.sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [suppliers, t]);

  const initialValues: SupplierPriceFromValues = useMemo(() => {
    if (!supplierPrice) {
      const inputPrice = supplierUnitPrice / 100;

      return {
        supplierId: 0,
        quantity: 0,
        price: inputPrice,
        note: '',
      };
    }

    const { note, price: priceValue, ...rest } = supplierPrice;

    return {
      ...rest,
      note: note || '',
      price: priceValue / 100,
    };
  }, [supplierUnitPrice, supplierPrice]);

  const renderUnit = useMemo(() => {
    const { unit, quantityIncrement: qi } = product;
    return renderUnitLabel({
      quantity: supplierQuantity,
      unit,
      quantityIncrement: qi,
    });
  }, [product, supplierQuantity]);
  const renderQtyAndUnit = `${quantityParser(supplierQuantity, quantityIncrement)} ${renderUnit}`;

  const onFinish = useCallback(
    async (formData: SupplierPriceFromValues) => {
      dispatch(
        setSupplierPrice({
          id: offerId,
          data: {
            ...formData,
            price: formData.price * 100,
          },
        }),
      );
      showModal(false);
    },
    [dispatch, offerId, showModal],
  );

  const supplierNameRules: Array<Rule> = [
    {
      required: true,
      message: t('common.supplierPriceSupplierRule') as string,
    },
    (_params) => ({
      validator: (_, value: string) => {
        const valueNbr = Number(value);

        if (isNaN(valueNbr) || valueNbr === 0) {
          return Promise.reject(new Error(t('common.supplierPriceSupplierRule') as string));
        }
        return Promise.resolve();
      },
    }),
  ];

  const supplierPriceRules: Array<Rule> = [
    {
      required: true,
      message: t('common.supplierPricePriceRule') as string,
    },
    (_params) => ({
      validator: (_, value: string) => {
        const valueNbr = Number(value);
        if (isNaN(valueNbr)) {
          return Promise.reject(new Error(t('common.supplierPricePriceValidationNumeric') as string));
        }

        if (valueNbr < 0) {
          return Promise.reject(new Error(t('common.supplierPricePriceValidationMin', { value: 0 }) as string));
        }

        return Promise.resolve();
      },
    }),
  ];

  const handleQuantityChange = useCallback((value: number | null) => {
    setSupplierQuantity(value || 0);
  }, []);

  const handleCancel = useCallback(() => {
    form.resetFields();

    const formQuantity = form.getFieldValue('quantity');
    setSupplierQuantity(formQuantity);

    showModal(false);
  }, [form, showModal]);

  return (
    <Modal width={520} className={styles.modal} footer={null} open={visible} onCancel={handleCancel} centered={false}>
      <div className={styles.wrapper}>
        <Text type="title" className={styles.title}>
          {t('common.supplierPurchasePrice')}
        </Text>

        <Text type="subtitle-gray1" className={styles.subtitle}>
          {t('common.supplierPurchasePriceSubtitle')}
        </Text>

        <Form
          form={form}
          initialValues={initialValues}
          className={styles.form}
          layout="horizontal"
          onFinish={onFinish}
          name="supplier-price-change"
        >
          <Form.Item
            className={styles.form__item}
            name="supplierId"
            label={t('common.supplierName')}
            rules={supplierNameRules}
          >
            <Select className={styles.select} size="large" optionFilterProp={'label'} showSearch options={options} />
          </Form.Item>
          <Text type="subtitle-gray1" className={styles.subtitle}>
            {t('common.commercialConditions')}
          </Text>

          <Form.Item
            className={styles.form__item}
            name="quantity"
            label={t('common.supplierPriceQuantity')}
            rules={createRule(t('common.supplierPriceQuantityRule'))}
          >
            <InputNumber
              onChange={handleQuantityChange}
              className={styles.form__item__inputNumber}
              addonAfter={renderQtyAndUnit}
              decimalSeparator=","
              min={0}
              size="large"
              precision={0}
            />
          </Form.Item>

          <Form.Item
            className={styles.form__item}
            name="price"
            label={t('common.supplierPrice')}
            rules={supplierPriceRules}
          >
            <InputNumber
              className={styles.form__item__inputNumber}
              addonAfter={`€ / ${product.unit.singular}`}
              size="large"
              decimalSeparator=","
              precision={2}
            />
          </Form.Item>

          <Text type="subtitle-gray1" className={styles.subtitle}>
            {t('common.supplierPriceNote')}
          </Text>

          <Form.Item name="note">
            <TextArea rows={4} />
          </Form.Item>

          <Space direction="horizontal">
            <Button className={styles.button} htmlType="submit" icon={<CheckOutlined />}>
              {t('common.supplierPriceSubmitButton')}
            </Button>

            <Button className={styles.button} type="dashed" onClick={handleCancel} icon={<CloseOutlined />}>
              {t('common.supplierPriceCancelButton')}
            </Button>
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default SupplierPriceModal;
