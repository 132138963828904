import translations, { TranslationDomain } from 'constants/translations';

export type TranslationsVars = (string | number)[];

const outputCache: string[] = [];

const renderTranslation = (template: string, vars: TranslationsVars) => {
  return vars.reduce<string>((acc, current) => acc.replace(/%s/, String(current)), template);
};

export const createTranslation = (domain: TranslationDomain) => {
  return (str: string, ...vars: TranslationsVars): string => {
    if (!Object.prototype.hasOwnProperty.call(translations[domain], str)) {
      const output = `${str} <<< Translation missing :: ${domain}`;
      if (outputCache.indexOf(output) === -1) {
        outputCache.push(output);
        // eslint-disable-next-line no-console
        console.log(output);
      }
      return renderTranslation(str, vars);
    }

    return renderTranslation(translations[domain][str], vars);
  };
};

export const translate = (str: string, ...vars: TranslationsVars): string => {
  const trans = createTranslation('global');
  return trans(str, ...vars);
};
