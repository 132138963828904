import { unwrap } from 'utils/api.utils';
import { Supplier } from 'types/suppliers';
import {
  ApiErrorData,
  ICreateOrderParam,
  ICustomerGroupWithBillingDetails,
  ICustomerWithBillingDetails,
  IMasterMultipleOrderResponse,
  IMasterOrderGetRequest,
  IMasterOrderItem,
  IMasterOrderPlaceResponse,
  IMasterOrderRequest,
  IMasterOrderResponse,
  IMasterPlaceOrderRequest,
  IOrder,
  IOrderBillingAddress,
  IOrderItem,
  IOrderMetadata,
  IOrderRequest,
  IOrderResourceResponse,
  IOrdersFilterParams,
  IOrderShippingAddress,
  IOrderShippingMethod,
} from 'types/order';
import env from 'config';
import { ApiErrorResponse, ApiResponse } from 'apisauce';
import { createGenericError } from '../utils';
import { IPointListItem, IShippingCategories } from '../types/shippingFees';
import { IGroupAddress } from '../types/groups';
import { PaginatedResponse } from '../types';
import { IOrderHistoryEntry } from '../store/ordersHistory/types';
import { ICartState as IDeliveryState } from '../store/delivery/types';
import { CustomersState } from '../store/customers/types';
import { ICartState } from '../store/cart/types';
import { ERRORS } from '../constants/errors';
import { config } from '../config';
import { RestServiceApi } from './api.service';

const orderApi = new RestServiceApi(config.ordersBackendUrl || '');
const orderValidationApi = new RestServiceApi(config.orderValidationBackendUrl || '');
const msAtcCheckoutApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class OrderServiceApi {
  createOrderResource(params: Array<ICreateOrderParam>): Promise<ApiResponse<Array<IOrderResourceResponse>>> {
    const headers = {
      Accept: 'application/json',
    };

    return orderApi.post('/distribute', params, { headers });
  }

  async placeOrder(
    userId: string,
    token: string,
    groupOrder: boolean,
    customers: CustomersState,
    cart: ICartState,
    delivery: IDeliveryState,
    suppliers: Array<Supplier>,
    tcEmail: string,
  ): Promise<ApiResponse<IMasterOrderPlaceResponse>> {
    /**
     * Step 1 - Create the Master Order
     */
    const masterOrderData = unwrap(await this.createMasterOrderApi(userId, token));

    const masterOrder: IMasterOrderResponse = this.parseOrderApiResponseData(masterOrderData);

    /**
     * Step 2 - Create order
     */
    unwrap(
      await OrderApiService.createOrderApi(
        masterOrder.id,
        cart,
        customers,
        delivery,
        token,
        groupOrder,
        suppliers,
        tcEmail,
      ),
      (response) => {
        const errorResponse = response as unknown as ApiErrorResponse<ApiErrorData>;
        if (errorResponse.data?.detail) {
          return ERRORS.order.customeError(errorResponse.data?.detail);
        }
      },
    );

    /**
     * Step 3 - Place order
     */

    return this.placeOrderApi(masterOrder.id, token);
  }

  parseOrderApiResponseData<T>(data: T | undefined): T {
    if (typeof data === 'undefined') throw new Error('Data is missing');
    return data;
  }

  async placeOrderApi(uuid: string, token: string): Promise<ApiResponse<IMasterOrderPlaceResponse>> {
    const payload = {
      resourceId: uuid,
    } as IMasterPlaceOrderRequest;
    return orderValidationApi.post<IMasterPlaceOrderRequest, IMasterOrderPlaceResponse>(
      `/master-order/${uuid}/place`,
      payload,
      orderValidationApi.getAuthenticateHeader(token),
    );
  }

  async createMasterOrderApi(userId: string, token: string): Promise<ApiResponse<IMasterOrderResponse>> {
    const payload = {
      locale: 'fr',
      countryCode: 'FR',
      userId,
    } as IMasterOrderRequest;

    return orderValidationApi.post<IMasterOrderRequest, IMasterOrderResponse>(
      '/master-order',
      payload,
      orderValidationApi.getAuthenticateHeader(token),
    );
  }

  async createOrderApi(
    masterOrderId: string,
    cart: ICartState,
    customers: CustomersState,
    delivery: IDeliveryState,
    token: string,
    groupOrder: boolean,
    suppliers: Array<Supplier>,
    tcEmail: string,
  ): Promise<ApiResponse<IMasterMultipleOrderResponse>> {
    const orders: Map<string, IOrderRequest> = this.splitOrderRequest(
      masterOrderId,
      cart,
      customers,
      delivery,
      groupOrder,
      suppliers,
      tcEmail,
    );

    /**
     * Creating an array of IOrderRequest
     */
    const masterOrderRequest: Array<IOrderRequest> = [];
    orders.forEach((value) => {
      masterOrderRequest.push(value);
    });

    return orderValidationApi.put<IOrderRequest[], IMasterMultipleOrderResponse>(
      `/master-order/${masterOrderId}/orders`,
      masterOrderRequest,
      orderValidationApi.getAuthenticateHeader(token),
    );
  }

  private getOrderItems(
    cart: ICartState,
    order: IOrder,
    groupOrder: boolean,
    suppliers: Array<Supplier>,
  ): Array<IMasterOrderItem> {
    const masterOrderItems: Array<IMasterOrderItem> = order.orderItems.map((orderItem: IOrderItem) => {
      const product = cart.products.find((prod) => prod.offerId === parseInt(orderItem.offerId));
      const orderOrderDetails = {
        offerId: orderItem.offerId,
        quantity: orderItem.quantity,
        quantityIncrement: product?.quantityIncrement,
        offerPrice: product?.offerPrice,
        initialPrice: product?.price.initialPrice,
        vatRate: product?.taxes.vat,
        rpdTax: product?.taxes.rpd,
        cvoTax: product?.taxes.cvo,
        ecoContribution: product?.taxes.ecoContribution,
      };
      if (groupOrder) {
        const [offerId, truckId] = orderItem.offerId.split('-').map((value) => parseInt(value));
        const cartProduct = cart.products.find((prod) => prod.offerId === parseInt(orderItem.offerId));
        // order.customerId is of customer.customerIdentifier value
        const productCustomerIdentifier = order.customerId;
        orderOrderDetails.quantity = 0;

        if (orderItem.isTruck) {
          if (!cartProduct?.truckSplitInfo?.[truckId]) {
            console.error('could not find truck split info', truckId, cartProduct);
            throw createGenericError();
          }
          let customerIdentifier = null;
          const truckSplit = cartProduct.truckSplitInfo[truckId];
          for (const key in truckSplit.customerProductInfo) {
            if (productCustomerIdentifier === truckSplit.customerProductInfo[key].customerIdentifier) {
              customerIdentifier = key;
            }
          }
          if (!customerIdentifier) {
            console.error('could not find customer for truck', productCustomerIdentifier, truckSplit);
            throw createGenericError();
          }
          const customerQuantities = truckSplit.customerProductInfo[customerIdentifier];
          orderOrderDetails.quantityIncrement =
            customerQuantities.selectedQuantity * (cartProduct.conditioningQuantity ?? 1);
          orderOrderDetails.quantity = 1;
          orderOrderDetails.offerId = String(offerId).toString();
        } else {
          let customerIdentifier = null;
          if (!cartProduct?.customerProductInfo) {
            console.error('missing product of customer product info', cartProduct, cartProduct?.customerProductInfo);
            throw createGenericError();
          }

          for (const key in cartProduct.customerProductInfo) {
            if (productCustomerIdentifier === cartProduct.customerProductInfo[key].customerIdentifier) {
              customerIdentifier = key;
            }
          }

          if (!customerIdentifier || !cartProduct?.customerProductInfo?.[customerIdentifier]) {
            throw createGenericError();
          }

          const customerQuantities = cartProduct.customerProductInfo[productCustomerIdentifier];
          orderOrderDetails.quantity = customerQuantities.selectedQuantity / (cartProduct.quantityIncrement ?? 1);
        }
      }

      const getBuyingPriceFields = () => {
        if (product?.supplierPrice) {
          const { supplierId: overrideSupplierId, price } = product.supplierPrice;
          return {
            supplier_name: suppliers.find((item) => item.id === overrideSupplierId)?.name || '',
            buying_price_overrided: price,
            ...(product?.price?.supplierUnitPrice && {
              buying_price: product.price.supplierUnitPrice,
            }),
          };
        }

        return {
          ...(product?.price?.supplierUnitPrice && {
            buying_price: product.price.supplierUnitPrice,
          }),
        };
      };

      const getDeliveryDate = () => {
        const { deliveryOverridden, overriddenDeliveryStartDate, overriddenDeliveryEndDate } = product ?? {};
        if (env('enableOverrideDeliveryDate') && deliveryOverridden) {
          return {
            deliveryStartDate: overriddenDeliveryStartDate,
            deliveryEndDate: overriddenDeliveryEndDate,
            deliveryDay: undefined,
          };
        }

        return {
          deliveryStartDate: orderItem.deliveryStartDate,
          deliveryEndDate: orderItem.deliveryEndDate,
          deliveryDay: orderItem.deliveryDay,
        };
      };

      const getDiscounts = (multiDiscounts = false) => {
        const discounts = [];
        const discountType: { [key: number]: string } = {
          0: 'percentage',
          1: 'amount',
          2: 'per_unit',
          3: 'per_quantity',
        };

        if (product?.discountValue) {
          const productDiscount = {
            from: 'product',
            source: product?.discountSource?.toString(),
            value: product?.discountValue,
            type: product?.discountType,
          };
          if (!multiDiscounts) {
            return productDiscount;
          }

          discounts.push(productDiscount);
        }

        if (product?.offer?.DiscountValue) {
          const offerDiscount = {
            from: 'offer',
            source: product?.discountSource?.toString(),
            value: product?.offer?.DiscountValue / 100,
            type: discountType[product?.offer?.DiscountType],
          };
          if (!multiDiscounts) {
            return offerDiscount;
          }
          discounts.push(offerDiscount);
        }
        return discounts.length > 0 ? discounts : null;
      };

      return {
        variantId: product?.variantId.toString(),
        offerId: orderOrderDetails.offerId,
        label: product?.name,
        supplierId: product?.supplierId?.toString(),
        departureId: product?.departureId?.toString() === '0' ? null : product?.departureId?.toString(),
        quantity: orderOrderDetails.quantity,
        quantityIncrement: orderOrderDetails.quantityIncrement,
        unitPrice: orderOrderDetails.offerPrice,
        rawUnitPrice: orderOrderDetails.initialPrice,
        vatRate: orderOrderDetails.vatRate,
        rpdTax: orderOrderDetails.rpdTax,
        cvoTax: orderOrderDetails.cvoTax,
        ecoContributionTax: orderOrderDetails.ecoContribution,
        ...getDeliveryDate(),
        metadata: {
          ...(getDiscounts(false) && {
            discount: getDiscounts(false),
          }),
          sku: product?.sku,
          uploaded_files: product?.attachments.map((item) => item.url) || [],
          product: product?.name || undefined,
          requested_product_label: product?.label || undefined,
          comment: product?.publicComment || undefined,
          ...getBuyingPriceFields(),
        },
      } as IMasterOrderItem;
    });

    return masterOrderItems;
  }

  splitOrderRequest(
    masterOrderId: string,
    cart: ICartState,
    customers: CustomersState,
    delivery: IDeliveryState,
    groupOrder: boolean,
    suppliers: Array<Supplier>,
    tcEmail: string,
  ): Map<string, IOrderRequest> {
    const orderList: Map<string, IOrderRequest> = new Map<string, IOrderRequest>();
    Object.entries(cart.orders).forEach(([orderId, order]) => {
      const shippingAddress = this.getShippingAddressByOrder(order, delivery, customers, groupOrder);
      const billingAddress = this.getBillingCustomerAddress(order, customers, groupOrder);
      const shippingMethod = this.getShippingMethod(order, delivery);
      const items = this.getOrderItems(cart, order, groupOrder, suppliers);
      const ecoMetadata = this.getEcoMetadata(cart);

      let metadata: IOrderMetadata = {
        ...ecoMetadata,
        tc_email: tcEmail,
        atc_cart_id: cart.cartId || undefined,
        atc_cart_number: cart.atcCartNumber || undefined,
        cart_request_id: cart.cartRequestId || undefined,
        send_quote_to_customer_by_email: cart.sendQuoteByEmail || undefined,
        delivery_comment: cart.deliveryComment || undefined,
        invoice_comment: cart.invoiceComment || undefined,
        comment: cart.comment || undefined,
      };
      /**
       * If shippingAddress is type = warehouse we need to extract the ID and add it as a warehouse id in orders metadata
       */
      if (shippingAddress?.type === 'warehouse' && shippingAddress?.type_id !== 0) {
        metadata = {
          ...metadata,
          shipping_method_warehouse_id: shippingAddress.type_id,
        };
      }

      /**
       * If shippingAddress type is address we need to add a special metadata for the insee value
       */
      if (shippingAddress?.type === 'address' && shippingAddress?.insee) {
        metadata = {
          ...metadata,
          order_address_town_insee: shippingAddress.insee,
        };
      }
      /**
       * Clean keys not used from shippingAddress
       */

      if (shippingAddress) {
        Object.prototype.hasOwnProperty.call(shippingAddress, 'insee') && delete shippingAddress['insee'];
        Object.prototype.hasOwnProperty.call(shippingAddress, 'type') && delete shippingAddress['type'];
        Object.prototype.hasOwnProperty.call(shippingAddress, 'type_id') && delete shippingAddress['type_id'];
      }

      const payload = {
        masterOrderId: masterOrderId,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        shippingMethod: shippingMethod,
        items,
        metadata: metadata,
      } as IOrderRequest;
      orderList.set(orderId, payload);
    });
    return orderList;
  }

  getCustomerByIdentifier(
    customers: CustomersState,
    customerIdentifier: string,
    groupOrder: boolean,
  ): ICustomerWithBillingDetails | ICustomerGroupWithBillingDetails {
    if (groupOrder) {
      return {
        __typename: 'ICustomerGroupWithBillingDetails',
        ...(Object.values(customers.selectedGroupCustomers).find((c) => c.customerIdentifier === customerIdentifier) ||
          {}),
      } as ICustomerGroupWithBillingDetails;
    }

    const customer = Object.values(customers.customers).find((c) => c.customerIdentifier === customerIdentifier);

    return {
      __typename: 'ICustomerWithBillingDetails',
      ...(customer || {}),
    } as ICustomerWithBillingDetails;
  }

  getGroup(delivery: IDeliveryState): IGroupAddress {
    return delivery.shippingAddress ?? ({} as IGroupAddress);
  }

  getShippingAddressByOrder(
    order: IOrder,
    delivery: IDeliveryState,
    customers: CustomersState,
    groupOrder: boolean,
  ): IOrderShippingAddress | undefined {
    const shippingMethodId: number = parseInt(order.shippingMethodId);
    const { byAddress } = delivery.sortedShippingCategories;
    const deliveryShippingLocationType = byAddress.includes(shippingMethodId) ? 'address' : 'warehouse';
    const customer = groupOrder
      ? this.getGroup(delivery)
      : this.getCustomerByIdentifier(customers, order.customerId, groupOrder);
    let deliveryShippingAddress: IOrderShippingAddress = {} as IOrderShippingAddress;
    let pointList = {} as IPointListItem;
    let categoryPointListId = '';
    let insee = '';
    switch (deliveryShippingLocationType) {
      case 'address':
        deliveryShippingAddress = {
          comment: delivery.shippingAddress?.comment ?? '',
          gender: delivery.shippingAddress?.gender ?? 1,
          lastName: delivery.shippingAddress?.lastName ?? '',
          firstName: delivery.shippingAddress?.firstName ?? '',
          company: delivery.shippingAddress?.company ?? null,
          street: delivery.shippingAddress?.street ?? '',
          town: delivery.shippingAddress?.townLabel ?? '',
          postalCode: delivery.shippingAddress?.postcodeLabel ?? '',
          countryCode: delivery.shippingAddress?.countryCode ?? '',
          phoneNumber: delivery.shippingAddress?.phone1 ?? delivery.shippingAddress?.phone2 ?? null,
          type_id: delivery.shippingAddress?.id || 0,
        };
        insee = delivery.shippingAddress?.coreTown?.insee || '';
        break;
      case 'warehouse':
        categoryPointListId = delivery.categoryPointList[shippingMethodId];
        pointList =
          delivery.shippingCategories[shippingMethodId].pointList?.find(
            (value: IPointListItem) => value.id === categoryPointListId,
          ) ?? ({} as IPointListItem);
        deliveryShippingAddress = {
          comment: pointList?.name ?? '',
          gender: 1,
          lastName: customer.lastName,
          firstName: customer.firstName,
          company: customer.company ?? null,
          street: pointList?.address.address1 ?? '',
          town: pointList?.address.city ?? '',
          postalCode: pointList?.address.zipCode ?? '',
          countryCode: pointList?.address.country ?? '',
          phoneNumber: customer.phone1 ?? customer.phone2 ?? null,
          type_id: parseInt(pointList?.id || '0'),
        };
    }

    return {
      ...deliveryShippingAddress,
      type: deliveryShippingLocationType,
      insee: insee,
    } as IOrderShippingAddress;
  }

  getBillingCustomerAddress(order: IOrder, customers: CustomersState, groupOrder: boolean): IOrderBillingAddress {
    const customer = this.getCustomerByIdentifier(customers, order.customerId, groupOrder);

    return {
      comment: customer.comment,
      gender: customer?.gender ?? 1,
      lastName: customer.lastName,
      firstName: customer.firstName,
      company: customer.company,
      street: customer.street,
      town: customer.townLabel,
      postalCode: customer.postcodeLabel,
      countryCode: customer.countryCode,
      phoneNumber: customer.phone1 ?? customer.phone2,
      vatNumber: customer.vatIntracomNumber,
      companyNumber: customer.companyNumber,
      customerId: customer.customerId.toString(),
    } as IOrderBillingAddress;
  }

  getShippingMethodDetails(shippingMethodId: number, delivery: IDeliveryState): IOrderShippingMethod {
    const shippingCategory: IShippingCategories = delivery.shippingCategories[shippingMethodId];
    const shippingFee = delivery.shippingFees[shippingMethodId];
    return {
      label: shippingCategory.label,
      type: shippingCategory.type,
      minDelay: shippingCategory.minDelay,
      maxDelay: shippingCategory.maxDelay,
      amount: shippingFee.price,
      vatRate: 0,
      shippingMethodId,
    } as IOrderShippingMethod;
  }

  getShippingMethod(order: IOrder, delivery: IDeliveryState): IOrderShippingMethod {
    const shippingMethodId: number = parseInt(order.shippingMethodId);
    return this.getShippingMethodDetails(shippingMethodId, delivery);
  }

  async getOrders(
    token: string,
    filters: IOrdersFilterParams,
    page = 1,
    size = 30,
  ): Promise<ApiResponse<PaginatedResponse<IOrderHistoryEntry>>> {
    const payload = {
      ...filters,
      $skip: (page - 1) * size,
      $limit: size,
    } as IMasterOrderGetRequest;

    const auth = msAtcCheckoutApi.getAuthenticateHeader(token);

    return msAtcCheckoutApi.get<IMasterOrderGetRequest, PaginatedResponse<IOrderHistoryEntry>>('/orders', payload, {
      ...auth,
      headers: {
        ...auth.headers,
      },
    });
  }

  getEcoMetadata = (cart: ICartState): Pick<IOrderMetadata, 'eco'> => {
    if (!env('enableGammeCge')) {
      return {};
    }

    return {
      eco: cart.isEco,
    };
  };
}

export const OrderApiService = new OrderServiceApi();
