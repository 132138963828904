import React, { useMemo } from 'react';
import { Button } from 'components/shared';
import classNames from 'classnames';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CustomerSelectedCheckProps } from './CustomerSelectedCheck.types';
import styles from './CustomerSelectedCheck.module.scss';

const CustomerSelectedCheck: React.FC<CustomerSelectedCheckProps> = ({
  isSelected,
  isSimpleCustomer = false,
  disabled,
}: CustomerSelectedCheckProps) => {
  const disableClickStyle: React.CSSProperties = { pointerEvents: 'none' };
  const buttonIcon = useMemo(() => {
    return isSimpleCustomer ? <CloseOutlined className={styles.icon} /> : <CheckOutlined className={styles.icon} />;
  }, [isSimpleCustomer]);
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapper__simpleCustomer]: isSimpleCustomer,
        [styles.disabled]: disabled,
      })}
    >
      {isSelected ? (
        <Button
          style={disableClickStyle}
          className={classNames(styles.button, styles.button__selected)}
          icon={buttonIcon}
        />
      ) : (
        <Button type="default" style={disableClickStyle} className={classNames(styles.button)} icon={buttonIcon} />
      )}
    </div>
  );
};

export default CustomerSelectedCheck;
