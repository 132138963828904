import { delay } from 'utils/loading.utils';
import { useAppDispatch } from 'store/store';
import { assignToCart } from 'store/savedCart';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Modal, Text, Button } from 'components/shared';
import { AssignmentModalProps } from 'components/savedCart/SavedCartTable/components/AssignmentModal/types';
import SavedCartSearch from 'components/savedCart/SavedCartSearch/SavedCartSearch';
import { Space } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import styles from './styles.module.scss';

const AssignmentModal: FC<AssignmentModalProps> = ({ visible, onClose, cartId }: AssignmentModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedTc, setSelectedTc] = useState<{ id: string; code: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    if (selectedTc) {
      setLoading(true);
      const response = await dispatch(assignToCart({ cartId, tcCode: selectedTc?.code }));
      setLoading(false);
      if (isFulfilled(response)) {
        onClose();
      }
    }
  };

  return (
    <Modal width="460px" className={styles.modal} open={visible} onCancel={onClose} destroyOnClose={true}>
      <Space direction="vertical" className={styles.container}>
        <Text type="title" className={styles.title}>
          {t('saved_cart.assignTcMessage')}
        </Text>
        <div className={styles.inputContainer}>
          <SavedCartSearch
            value={selectedTc?.id}
            onChange={(tc) => {
              setSelectedTc(tc || null);
            }}
          />
        </div>
        <Space className={styles.actions}>
          <Button loading={delay(loading)} disabled={!selectedTc} onClick={onSubmit}>
            {t('common.confirm')}
          </Button>
          <Button onClick={onClose} type="dashed">
            {t('common.cancel')}
          </Button>
        </Space>
      </Space>
    </Modal>
  );
};

export default AssignmentModal;
