/* eslint-disable react/prop-types */
import { useNavigate } from 'react-router';
import { FunctionComponent } from 'react';
import { Button, Result } from 'antd';
import { getOrderPath, OrderRoute } from '../../../constants/routes';
import { INotAuthorizedProps } from './NotAuthorized.types';

export const NotAuthorized: FunctionComponent<INotAuthorizedProps> = (props) => {
  const { children, showContent, showOnRule, redirectRoute } = props;
  const history = useNavigate();

  const onClickBackToHome = () => history(redirectRoute || getOrderPath(OrderRoute.CUSTOMER));

  if (showOnRule && showContent && children) {
    return <>{children}</>;
  }

  return (
    <>
      <Result
        className="result"
        status="403"
        title="403"
        subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page."
        extra={
          <Button onClick={onClickBackToHome} type="primary">
            Retour à la maison
          </Button>
        }
      />
    </>
  );
};

export default NotAuthorized;
