export const modals_en = {
  inputPostalCode: 'Enter your postal code',
  postalCodeSubtitle: 'This is required in order to display the prices and the availability of the products',
  postalCode: 'Postal code',
  availableDeliveryAddresses: 'Choose a saved address',
  noTownFound: 'No town has been found',
  validatePostalCode: 'Validate the postcode',
  viewLessAddresses: 'View less addresses',
  viewMoreAddresses: 'View more addresses',
  searchProductMessage: 'Make your selection of products by entering their name or reference.',
  postalCodeChangeMessage:
    'When changing the postal code, the availability and prices of the products will be updated.',
  changePostalCode: 'Change postal code',
  shippingAddress: 'Shipping address',
  addShippingAddress: 'Add a shipping address',
  changeShippingAddress: 'Change shipping address',
  createNewAddress: 'Create a new address',
  yourGroupDeliveryAddressTitle: 'Your group delivery address',
  yourDeliveryAddressTitle: 'Your delivery address',
  selectYourFile: 'Select your file',
  findFile: 'Find my file',
  wiuzSubtitle: 'To find your recommendations in IZI, generate your supply plan in Wiuz and import your file.',
  wiuzSupplyPlanTip: 'Your Wiuz supply plan can be found here',
  wiuzFileTip:
    'Please note that the WIUZ folder is sometimes named INVIVO depending on your position. The name of the file is of the form << PreparationCommande_ToWork_AXEREAL_XXXXX.tab >>.',
  historicalPriceModalTitle: 'Historical prices',
  historicalPriceTableHeaderCa: 'S.V',
  historicalPriceTableHeaderQty: 'Qty',
  historicalPriceTableHeaderPmv: 'P.M.V',
  historicalPriceTableHeaderCommercial: 'Commercial',
  historicalPriceTableHeaderCustomerIdentifier: 'Code',
  historicalPriceTableHeaderCustomerAccount: 'Account',
  warehouseStockTitle: 'Warehouse stock',
  warehouseName: 'Warehouse name',
  warehouseStockQuantity: 'Quantity',
  warehouseStockSku: 'Sku',
  warehouseStockProductName: 'Product name',
  gammeCGEAttention:
    'Warning! Order with discount: CGE. Only contains Nutritional Supplements (excluding cubitainer). Do not put other products in the cart',
  okButton: 'OK',
  postalCodeAlternativSearchText: 'Enter a delivery postal code',
  leavePageConfirmationTitle: 'A quote is in progress, do you want to save it?',
  leavePageConfirmationAcceptButton: 'Yes',
  leavePageConfirmationDeclineButton: 'No',
  discountPopoverTitle: 'Discounts',
  discountPopoverStartDate: 'Start date',
  discountPopoverEndDate: 'End date',
  discountPopoverDiscount: 'Discount',
  discountPopoverMinimumOrder: 'Min. qty.',
  discountPopoverUndefined: 'Not defined',
  confirmationModalAcceptButton: 'Yes',
  confirmationModalDeclineButton: 'No',
  confirmationModalSendValidationTitle: 'Please confirm the order conversion?',
  confirmationModalSendQuoteTitle: 'Please confirm the quote sending?',
  confirmationPopupKeepPriceTitle: 'Do you want to keep the original price?',
  addProductModalTitle: 'Add a new product',
  addProductModalRequestedProduct: 'Requested product name',
  addProductModalSuggestedProduct: 'Suggested product name',
};
