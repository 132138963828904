import { FC } from 'react';
import styles from './TagsList.module.scss';

type ITagsListProps = {
  className?: string;
  tags?: Array<string>;
};

const TagsList: FC<ITagsListProps> = (props: ITagsListProps) => {
  const { className, tags } = props;

  return (
    <div className={`${styles.list} ${className}`}>
      {tags?.map((tag) => (
        <span className={styles.tag} key={tag}>
          {tag}
        </span>
      ))}
    </div>
  );
};

export default TagsList;
