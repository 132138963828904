import { Supplier } from 'types/suppliers';
import { useAppDispatch, useAppSelector } from 'store/store';
import { fetchSuppliers, selectIsLoadingSuppliers, selectSuppliers } from 'store/products';
import { useEffect } from 'react';

type UseFetchSupplierReturnType = {
  data: Array<Supplier>;
  isLoading: boolean;
};

export const useFetchSuppliers = (): UseFetchSupplierReturnType => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(selectSuppliers);
  const isLoading = useAppSelector(selectIsLoadingSuppliers);

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, [dispatch]);

  return {
    data,
    isLoading,
  };
};
