import { useIsSmallTablet } from 'utils/styles.utils';
import { selectIsGroup } from 'store/customers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import SaveCartButton from 'components/shared/SaveCartButton';
import { BackButton, Text } from 'components/shared';
import { CustomerInfo } from 'components/product/ProductPageHeader/components/CustomerInfo';
import { Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { GroupCustomerInfo } from '../../product/ProductPageHeader/components/GroupCustomerInfo';
import styles from './DeliveryHeader.module.scss';

const DeliveryHeader: FC = () => {
  const isSmallTablet = useIsSmallTablet();
  const { t } = useTranslation();
  const isGroup = useSelector(selectIsGroup);

  return (
    <>
      <PageHeader ghost={false} className={styles.header}>
        <div className={styles.container}>
          <Text type="subtitle-gray8" className={styles.title}>
            {t('common.chooseDeliveryMethod')}
          </Text>
          <Space direction="horizontal" align="center" className={styles.content__left}>
            {!isGroup && <CustomerInfo />}
            {isGroup && <GroupCustomerInfo />}
            {!isSmallTablet && <BackButton step="2" />}
            <SaveCartButton />
          </Space>
        </div>
      </PageHeader>
    </>
  );
};

export default DeliveryHeader;
