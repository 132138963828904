import { getDateFormat, getLocaleDateFormat } from 'utils';
import { DateFormat } from 'types';
import { selectLanguage } from 'store/settings';
import { useSelector } from 'react-redux';

export function useDateFormat(): DateFormat;
export function useDateFormat(formatType: keyof DateFormat): string;
export function useDateFormat(formatType?: keyof DateFormat): string | DateFormat {
  const lang = useSelector(selectLanguage);
  return getDateFormat(lang, formatType as keyof DateFormat);
}

export function useLocaleDateFormat(): DateFormat;
export function useLocaleDateFormat(formatType: keyof DateFormat): string;
export function useLocaleDateFormat(formatType?: keyof DateFormat): string | DateFormat {
  return getLocaleDateFormat(formatType as keyof DateFormat);
}
