import { SavedCartStatusEnum } from 'store/savedCart/types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { camelCase } from 'lodash';
import env from 'config';
import { Text } from 'components/shared';
import { Button, Col, Row, Tooltip } from 'antd';
import { ShoppingCartOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../store/store';
import { setAddProductModalVisible } from '../../../store/products';
import { selectAtcCartNumber, selectCartStatus } from '../../../store/cart';
import ExpandAllProducts from './components/ExpandAllProducts';
import DeleteAllProducts from './components/DeleteAllProducts';
import styles from './CartHeader.module.scss';

export const CART_HEADER_ID = 'cart-header-id';

const CartHeader: FC = () => {
  const { t } = useTranslation();
  const atcCartNumber = useAppSelector(selectAtcCartNumber);
  const status = useAppSelector(selectCartStatus);
  const dispatch = useDispatch();
  const title = useMemo(
    () => (atcCartNumber ? `${t('saved_cart.atcCartNumber')} ${atcCartNumber}` : t('common.cart')),
    [atcCartNumber, t],
  );

  const openAddProductModal = () => {
    dispatch(setAddProductModalVisible(true));
  };

  return (
    <Row className={styles.header} id={CART_HEADER_ID} gutter={10}>
      <Col>
        <ShoppingCartOutlined className={styles.icon} />
        <Text type="title" className={styles.text}>
          {title}
        </Text>
      </Col>
      <Col>
        <Text type="title" className={styles.text}>
          {'-'}
        </Text>
      </Col>
      <Col>
        <div className={`${styles[status || SavedCartStatusEnum.IN_PROGRESS]} ${styles.status}`}>
          {status
            ? t(`cartRequests.${camelCase(status)}`)
            : t(`cartRequests.${camelCase(SavedCartStatusEnum.IN_PROGRESS)}`)}
        </div>
      </Col>
      <Col className={styles.test}>
        {env('allowPlainCartItem') && (
          <Tooltip>
            <Button
              type="primary"
              ghost
              shape="circle"
              size="large"
              onClick={openAddProductModal}
              icon={<PlusOutlined />}
            />
          </Tooltip>
        )}
        <DeleteAllProducts />
        <ExpandAllProducts />
      </Col>
    </Row>
  );
};

export default CartHeader;
