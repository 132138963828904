import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import Button from '../Button';
import { ICopyClipboardProps } from './CopyClipboard.types';
import styles from './CopyClipboard.module.scss';

const CopyClipboard: FC<ICopyClipboardProps> = (props: ICopyClipboardProps) => {
  const { text } = props;
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const copyTextToClipboard = async () => {
    const copyText = text || '';
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(copyText);
    } else {
      return document.execCommand('copy', true, copyText);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button className={styles.button} onClick={handleCopyClick} type={'dashed'} size={'small'}>
      {isCopied ? t('common.copied') : t('common.copy')}
    </Button>
  );
};

export default CopyClipboard;
