import { useIsTabletSizes } from 'utils/styles.utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import { clearWiuzSelectedFile, selectTableMinimizeState, selectWiuzSelectedFile } from 'store/products';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { Button, Text } from 'components/shared';
import { ProductTableWiuz, ProductWiuzTableList } from 'components/product';
import { Row, Col } from 'antd';
import { FileDoneOutlined, FileSearchOutlined, LeftOutlined } from '@ant-design/icons';
import styles from './WiuzContainer.module.scss';

const WiuzContainer: FC = () => {
  const currentView = useAppSelector(selectTableMinimizeState);
  const isTablet = useIsTabletSizes();
  const selectedFile = useAppSelector(selectWiuzSelectedFile);
  const { id: wiuzImportId, filename: wiuzFile } = selectedFile || {};
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isLoadedFile = useCallback(() => !!(wiuzImportId && wiuzFile), [wiuzImportId, wiuzFile]);
  const handeBackToFileList = () => {
    dispatch(clearWiuzSelectedFile());
  };
  return (
    <div className={styles.container}>
      <Row className={styles.header}>
        <Col flex={1}>
          {isLoadedFile() && wiuzFile ? (
            <div>
              <Row className={styles.tableTitle}>
                <Col>
                  <FileDoneOutlined className={styles.icon} />
                  <Text type="title" className={styles.text}>
                    {t('common.products')}
                  </Text>
                </Col>
                <Col>
                  <Text type="subtitle-gray1" className={styles.tableFilename}>
                    {wiuzFile}
                  </Text>
                </Col>
              </Row>
            </div>
          ) : (
            <>
              <FileSearchOutlined className={styles.icon} />
              <Text type="title" className={styles.text}>
                {t('common.uploadedFilesTitle')}
              </Text>
            </>
          )}
        </Col>
        <Col>
          {isLoadedFile() && (
            <Button className={styles.button} type="dashed" onClick={handeBackToFileList}>
              <LeftOutlined />
              {t('common.backToFilelist')}
            </Button>
          )}
        </Col>
      </Row>
      {!wiuzImportId && <ProductWiuzTableList />}
      {(currentView || isTablet) && wiuzImportId && (
        <div>
          <ProductTableWiuz />
        </div>
      )}
    </div>
  );
};

export default WiuzContainer;
