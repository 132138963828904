import { IDeliveryState } from 'store/delivery/types';

export const deliveryInitialState: IDeliveryState = {
  isLoading: false,
  error: null,
  customError: null,
  shippingAddress: undefined,
  shippingAddresses: [],
  shippingItems: [],
  shippingFees: {},
  shippingCategories: {},
  categoryPointList: {},
  sortedShippingCategories: {
    byAddress: [],
    byPointLists: [],
  },
  shippingDetails: {
    shippingItems: {},
  },
};
