import { ThemeConfig } from 'types/theme';
import leftLogo from 'assets/images/img_logo_white.png';

const config: ThemeConfig = {
  favicon: 'favicon-izi.ico',
  title: 'IZI',
  loginLogo: leftLogo,
};

export default config;
