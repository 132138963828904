import { FC } from 'react';
import NavigationBar from 'components/layout/NavigationBar';
import { Layout } from 'antd';
import { Props } from './MainLayout.types';
import styles from './MainLayout.module.scss';

export const MAIN_LAYOUT_ID = 'main-layout-id';

const MainLayout: FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <Layout hasSider={true} className={styles.main}>
      <NavigationBar />
      <Layout id={MAIN_LAYOUT_ID} className={styles.main__rightBlock}>
        {children}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
