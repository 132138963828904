import { useAppSelector } from 'store/store';
import { selectTableMinimizeState, selectWiuzImportId, tableMinimizedSet } from 'store/products';
import { selectIsAnyProductSearched } from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useMemo } from 'react';
import { useGetSettings } from 'hooks/useSettings';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Button from '../Button';
import styles from './HideProductsButton.module.scss';

const HideProductsButton: FC = () => {
  const currentView = useSelector(selectTableMinimizeState);
  const isAnyProductSearched = useSelector(selectIsAnyProductSearched);
  const dispatch = useDispatch();
  const productPage = useGetSettings('PRODUCT_PAGE');
  const wiuzImportId = useAppSelector(selectWiuzImportId);

  const isEnabled = useMemo(() => {
    if (productPage === 'default' && isAnyProductSearched) {
      return true;
    }

    if (productPage === 'wiuz' && wiuzImportId) {
      return true;
    }

    return false;
  }, [productPage, isAnyProductSearched, wiuzImportId]);

  const changeViewCb = () => {
    dispatch(tableMinimizedSet(!currentView));
  };

  useEffect(() => {
    if (isEnabled) {
      dispatch(tableMinimizedSet(true));
      window.scrollTo(0, 0);
    } else {
      dispatch(tableMinimizedSet(false));
    }
  }, [dispatch, isEnabled]);

  return (
    <Button disabled={!isEnabled} className={styles.button} type="dashed" onClick={changeViewCb}>
      {currentView ? <DownOutlined /> : <UpOutlined />}
    </Button>
  );
};

export default HideProductsButton;
