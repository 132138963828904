import { FC } from 'react';
import CustomIcon from 'icons/CustomIcon';

import { ReactComponent as SVG } from './../assets/icons/PictoSilo.svg';

const PictoSiloIcon: FC<IconProps> = (props: IconProps) => {
  return <CustomIcon svg={SVG} {...props} />;
};

export default PictoSiloIcon;
