import { PDFPayload, PDFQuery } from 'types/pdf';
import { MarketplacePayload } from 'store/savedCart/types';
import { RestServiceApi } from 'services';
import moment from 'moment/moment';
import { getToken } from 'cookies';
import env, { config } from 'config';
import { ApiResponse } from 'apisauce';
import { IHistoricalPrice } from '../types/product';
import {
  ICartRequest,
  ICartRequestDetail,
  ICartRequestItemAttributes,
  ICartRequestItemPatchAttributes,
  ICartRequestParams,
} from '../types/cartRequest';
import { IAbandonedCartParams } from '../types/abandonedCart';
import {
  AddCartAttachmentResponse,
  AddCartAttachmentServicePayload,
  IHistoricalPricePerSkuItem,
  LastUnitPrices,
} from '../store/cart/types';

const msAtcBackendApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class CartServiceApi {
  public async getLastUnitPricePerSkuPerCustomer(
    sku: string,
    customerIdentifier: string,
    tcCode: string,
  ): Promise<ApiResponse<IHistoricalPrice>> {
    return msAtcBackendApi.get(
      `/historical-price/getLastUnitPricePerSkuPerCustomer`,
      {
        sku: sku,
        customer_identifier: customerIdentifier,
        tcCode: tcCode,
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async getHistoricalPricePerSku(sku: string, tcCode: string): Promise<ApiResponse<IHistoricalPricePerSkuItem>> {
    return msAtcBackendApi.get(
      `/historical-price/getPricePerSku`,
      {
        sku: sku,
        tcCode: tcCode,
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async getLastQuotedUnitPricePerSkuPerCustomer(
    sku: string,
    customerIdentifier: string,
  ): Promise<ApiResponse<IHistoricalPrice>> {
    return msAtcBackendApi.get(
      `/historical-price/getLastQuotedUnitPricePerSkuPerCustomer`,
      {
        sku: sku,
        customer_identifier: customerIdentifier,
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }
  public async exportToPDF(payload: PDFPayload, adapter: PDFQuery['adapter']): Promise<ApiResponse<string>> {
    const query: PDFQuery = {
      type: 'pdf',
      adapter,
    };

    const queryString = new URLSearchParams(query).toString();

    return msAtcBackendApi.post<PDFPayload, string>(`/generate?${queryString}`, payload, {
      ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      responseType: 'blob',
    });
  }

  public async addCartAttachment(
    { cartId, productId, offerId, variantId, cartProductId }: AddCartAttachmentServicePayload,
    file: File,
  ): Promise<ApiResponse<AddCartAttachmentResponse>> {
    const fd = new FormData();

    fd.append('cartId', cartId);
    fd.append('cartProductId', cartProductId);
    fd.append('productId', String(productId));
    fd.append('variantId', String(variantId));
    fd.append('offerId', String(offerId));
    fd.append('files', file);

    return msAtcBackendApi.post<FormData, AddCartAttachmentResponse>(
      '/carts/attachments',
      fd,
      msAtcBackendApi.getAuthenticateHeader(getToken()),
    );
  }

  public async delete(id: string): Promise<ApiResponse<unknown>> {
    return msAtcBackendApi.delete<Record<string, never>, unknown>(
      `/carts/attachments/${id}`,
      {},
      msAtcBackendApi.getAuthenticateHeader(getToken()),
    );
  }

  public async deleteProductCartAttachments(cartProductId: string): Promise<ApiResponse<unknown>> {
    return msAtcBackendApi.delete<Record<string, never>, unknown>(
      `/carts/attachments/?cartProductId=${cartProductId}`,
      {},
      msAtcBackendApi.getAuthenticateHeader(getToken()),
    );
  }

  public async deleteAllCartAttachments(cartId: string): Promise<ApiResponse<unknown>> {
    return msAtcBackendApi.delete<Record<string, never>, unknown>(
      `/carts/attachments/?cartId=${cartId}`,
      {},
      msAtcBackendApi.getAuthenticateHeader(getToken()),
    );
  }

  public async downloadCartAttachment(bucket: string, key: string): Promise<ApiResponse<string>> {
    return msAtcBackendApi.get<Record<string, never>, string>(
      `/carts/attachments/download?bucket=${bucket}&key=${key}`,
      {},
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
        responseType: 'blob',
      },
    );
  }

  public async createMarketplace(payload: MarketplacePayload): Promise<ApiResponse<unknown>> {
    return msAtcBackendApi.post(`/marketplace`, payload, {
      ...msAtcBackendApi.getAuthenticateHeader(getToken()),
    });
  }
  public async getCartRequests(filters: ICartRequestParams, page: number, size: number): Promise<ApiResponse<unknown>> {
    const countryCode = env('countryCode');
    const { 'createdAt[$gte]': createdAt, 'createdAt[$lte]': createdBy, ...rest } = filters;
    return msAtcBackendApi.get<unknown, string>(
      `/cart-requests`,
      {
        ...rest,
        ...(createdAt &&
          createdBy && {
            createdAt: [createdAt, createdBy],
          }),
        $limit: size,
        $skip: (page - 1) * size,
        '$sort[createdAt]': -1,
        ...(countryCode && {
          countryCode,
        }),
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }
  public async getAbandonedCarts(
    filters: IAbandonedCartParams,
    page: number,
    size: number,
  ): Promise<ApiResponse<unknown>> {
    const countryCode = env('countryCode');

    return msAtcBackendApi.get<unknown, string>(
      `/abandoned-carts`,
      {
        ...(size > 0 && {
          $skip: (page - 1) * size,
          $limit: size,
        }),
        ...(filters.search && {
          search: filters.search,
        }),
        ...(filters['createdAt[$gte]'] &&
          filters['createdAt[$lte]'] && {
            createdAt: [
              moment(filters['createdAt[$gte]']).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              moment(filters['createdAt[$lte]']).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            ],
          }),
        ...(countryCode && {
          countryCode: countryCode,
        }),
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async getCartRequest(id: number, isAssigned?: boolean): Promise<ApiResponse<ICartRequestDetail>> {
    return msAtcBackendApi.get(
      `/cart-requests/${id}`,
      {
        isAssigned: isAssigned || false,
      },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async patchCartRequestItem(
    id: number,
    data: Partial<ICartRequestItemPatchAttributes>,
  ): Promise<ApiResponse<ICartRequestItemAttributes>> {
    return msAtcBackendApi.patch<Partial<ICartRequestItemPatchAttributes>, ICartRequestItemAttributes>(
      `/cart-requests/item/${id}`,
      { ...data },
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }

  public async patchCartRequest(id: number, data: Partial<ICartRequest>): Promise<ApiResponse<ICartRequest>> {
    return msAtcBackendApi.patch<Partial<ICartRequest>, ICartRequest>(`/cart-requests/${id}`, data, {
      ...msAtcBackendApi.getAuthenticateHeader(getToken()),
    });
  }

  public async getCartLastUnitPrices(cartId: string): Promise<ApiResponse<LastUnitPrices>> {
    return msAtcBackendApi.get(
      `/historical-price/getCartLastUnitPrices/${cartId}`,
      {},
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }
}

export const CartService = new CartServiceApi();
