import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button } from 'components/shared';
import { ISubmitButtonProps } from './SubmitButton.types';
import styles from './SubmitButton.module.scss';

const SubmitButton: FC<ISubmitButtonProps> = ({ onSubmit }: ISubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.submit}>
      <Button size="large" className={styles.button} onClick={onSubmit}>
        {t('common.next')}
      </Button>
    </div>
  );
};

export default SubmitButton;
