export * from './axiosErrors';
export * from './statusCodeErrors';

export const ERRORS = {
  products: {
    noProducts: {
      code: 201,
      name: 'no-products',
      message: "Aucun produit n'a été trouvé",
    },
  },

  towns: {
    noTowns: {
      code: 3,
      name: 'no-towns',
      message: 'Aucune ville trouvée',
    },
    limitCharacters: {
      code: 4,
      name: 'more-characters',
      message: 'Veuillez saisir au moins 4 caractères',
    },
  },
  customers: {
    noGroups: {
      code: 13,
      name: 'no-groups',
      message: 'Aucune groupe trouvée',
    },
  },
  delivery: {
    noSelectedAddress: {
      code: 18,
      name: 'no-address',
      message: "Indiquez l'adresse de livraison",
    },
    noSelectedCustomer: {
      code: 19,
      name: 'no-customer',
      message: "Il n'y a pas de commerçant sélectionné dans la première étape",
    },
    noSelectedTown: {
      code: 20,
      name: 'no-town',
      message: "Vous n'avez pas sélectionné de ville à l'étape 2",
    },
    townsNotCoincide: {
      code: 21,
      name: 'towns-not-coincide',
      message:
        'Le code postal de votre adresse de livraison est différent de celui introduit initialement sur l’étape 2. Veuillez introduire le même code postal sur l’étape précédente.',
    },
    noShippingMethods: {
      code: 22,
      name: 'no-shipping-methods',
      message: "Il n'y a pas d'adresses de livraison",
    },
  },
  validation: {
    noOrders: {
      code: 23,
      name: 'no-order-methods',
      message: 'Pas de données',
    },
  },
  order: {
    customeError: (label: string): { code: number; name: string; message: string } => {
      return { code: 25, name: 'order-error', message: label };
    },
  },
  savedCart: {
    noSavedCarts: {
      code: 25,
      name: 'no-saved-carts',
      message: 'Aucun paniers sauvegardés trouvé',
    },
  },
};
