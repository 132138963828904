import { convertCustomerToSearchedCustomer, getCustomerAddress } from 'utils/customer.utils';
import { ICustomerGroup } from 'types';
import { useAppDispatch } from 'store/store';
import {
  clearPreviousGroupSelection,
  clearSelectedGroupCustomer,
  clearSelectedGroupCustomerState,
  setSearchedGroupCustomer,
  setSelectedGroupCustomer,
  setSelectedGroupId,
} from 'store/customers';
import React, { useCallback, useMemo } from 'react';
import { OtherInfo, Text } from 'components/shared';
import { CustomerSelectedCheck } from 'components/customers/ClientsList/components/CustomerSelectedCheck';
import classNames from 'classnames';
import { StarFilled } from '@ant-design/icons';
import { GroupCustomerRowProps } from './GroupCustomerRow.types';
import styles from './GroupCustomerRow.module.scss';

const GroupCustomerRow: React.FC<GroupCustomerRowProps> = (props: GroupCustomerRowProps) => {
  const { groupCustomer, groupLeader, selectedGroupCustomers, selectedGroupId, onSelectChange } = props;
  const { id, groupId, leaderId, firstName, lastName, customerIdentifier, company, enabled = false } = groupCustomer;
  const dispatch = useAppDispatch();
  const hasGroupLeader = !!groupLeader;

  const isLeader = useMemo(() => groupLeader?.id === groupCustomer.id, [groupCustomer.id, groupLeader?.id]);

  const isSelected = useMemo(
    (): boolean =>
      !!selectedGroupCustomers.find(
        (member) => member.id === groupCustomer.id && member.groupId === groupCustomer.groupId,
      ),
    [groupCustomer.groupId, groupCustomer.id, selectedGroupCustomers],
  );

  const isDifferentGroupSelected = useCallback(
    (grCustomer: ICustomerGroup) => selectedGroupId && selectedGroupId !== grCustomer.groupId,
    [selectedGroupId],
  );

  const selectGroupCustomer = useCallback(
    (grCustomer: ICustomerGroup) => {
      if (isDifferentGroupSelected(grCustomer)) {
        dispatch(clearPreviousGroupSelection());
      }

      dispatch(setSelectedGroupCustomer(grCustomer));
      dispatch(setSelectedGroupId(grCustomer.groupId));

      if (groupLeader) {
        dispatch(setSearchedGroupCustomer(convertCustomerToSearchedCustomer(groupLeader)));
      }
    },
    [dispatch, groupLeader, isDifferentGroupSelected],
  );

  const setSelected = useCallback(() => {
    const userIds: number[] = [];

    selectGroupCustomer(groupCustomer);
    userIds.push(groupCustomer.userId);

    if (selectedGroupCustomers.length === 0 && !isLeader && groupLeader) {
      selectGroupCustomer(groupLeader);
      userIds.push(groupLeader.userId);
    }
    onSelectChange(true);
  }, [groupCustomer, groupLeader, isLeader, onSelectChange, selectGroupCustomer, selectedGroupCustomers]);

  const clearSelected = useCallback(() => {
    dispatch(clearSelectedGroupCustomer({ id, groupId }));
    if (isLeader && leaderId) {
      dispatch(clearSelectedGroupCustomerState());
    }
    onSelectChange(false);
  }, [dispatch, groupId, id, isLeader, leaderId, onSelectChange]);

  const handleSelectGroupCustomer = useCallback(
    () => (isSelected ? clearSelected() : setSelected()),
    [clearSelected, isSelected, setSelected],
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.row, {
          [styles.row__noLeader]: !hasGroupLeader,
          [styles.row__disabled]: !enabled,
        })}
        onClick={enabled ? handleSelectGroupCustomer : undefined}
      >
        <div className={classNames(styles.column, styles.column__customer)}>
          <div>
            <Text className={styles.name}>
              {isLeader && <StarFilled className={styles.icon} />} {firstName} {lastName}
            </Text>
          </div>
          {!!company && (
            <div>
              <Text className={styles.text}>{company}</Text>
            </div>
          )}
          <div>
            <Text className={styles.text}>{customerIdentifier}</Text>
          </div>
        </div>
        <div className={classNames(styles.column, styles.column__address)}>
          <div>{getCustomerAddress(groupCustomer)}</div>
          <div>{<Text>{groupCustomer.phone1 || groupCustomer.phone2}</Text>}</div>
        </div>

        <OtherInfo
          className={classNames(styles.column, styles.column__otherinfo)}
          restrictedPrecursor={groupCustomer?.user?.restrictedPrecursor}
          regulatedPrecursor={groupCustomer?.user?.regulatedPrecursor}
          certExpiryDate={groupCustomer?.user?.certiphytoExpiredDate}
        />

        <div className={classNames(styles.column, styles.column__checkmark)}>
          <CustomerSelectedCheck isSelected={isSelected} disabled={!enabled} />
        </div>
      </div>
    </div>
  );
};

export default GroupCustomerRow;
