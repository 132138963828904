import { IPointListItem } from 'types/shippingFees';
import { FC } from 'react';
import styles from './styles.module.scss';

const AddressContent: FC<IPointListItem> = (address: IPointListItem) => {
  const { name, distance } = address;
  const addressString = address.address.address1 || address.address.address2 || address.address.address3;
  const zipCode = address.address.zipCode;
  const city = address.address.city;

  return (
    <div className={styles.address}>
      <p className={`${styles.text} ${styles.text__title}`}>{name}</p>
      <p className={`${styles.text} ${styles.text__address}`}>{`${addressString} ${zipCode} ${city}`}</p>
      <p className={`${styles.text} ${styles.text__distance}`}>Distance: {distance}m</p>
    </div>
  );
};

export default AddressContent;
