import { RestoreSavedCartStateResponse } from 'store/savedCart/types';
import { restoreSavedCartMatcher } from 'store/savedCart/matcher';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SettingsState } from './types';
import { NavMenuItem } from '../../types/navMenu';
import { getLanguagesList, Language } from '../../locales';

export const SETTINGS_REDUCER_NAME = 'settings';

const initialState: SettingsState = {
  PRODUCT_PAGE: 'default',
  language: getLanguagesList()[1] as Language,
  menu: [],
};

const cacheSlice = createSlice({
  name: SETTINGS_REDUCER_NAME,
  initialState,
  reducers: {
    clearSettingsState: () => {
      return {
        ...initialState,
      };
    },

    setLanguage(state, { payload }: PayloadAction<Language>) {
      state.language = payload;
    },

    setMenu(state, { payload }: PayloadAction<NavMenuItem[]>) {
      state.menu = payload;
    },

    setSettingsState(state, { payload }: PayloadAction<Partial<SettingsState>>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      restoreSavedCartMatcher(),
      (state, { payload }: PayloadAction<RestoreSavedCartStateResponse>) => {
        const {
          state: { settings },
        } = payload;

        state.PRODUCT_PAGE = settings.PRODUCT_PAGE;
      },
    );
  },
});

export const { clearSettingsState, setSettingsState, setLanguage, setMenu } = cacheSlice.actions;

export default cacheSlice.reducer;
