import { selectShippingAddress } from 'store/delivery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { Text, Button, EcoCheckbox } from 'components/shared';
import { PaperClipOutlined } from '@ant-design/icons';
import ConfirmationModal from '../ConfirmationModal';
import { ISummaryProps } from './Summary.types';
import styles from './Summary.module.scss';

const Summary: FC<ISummaryProps> = (props: ISummaryProps) => {
  const shippingAddress = useSelector(selectShippingAddress);
  const { children, buttonText, className, onClick: submitOrderValidation, disabled, orderValidation } = props;
  const { t } = useTranslation();

  const isDisabled = useMemo(() => {
    if (!shippingAddress) {
      return true;
    }

    if (disabled) {
      return true;
    }

    return false;
  }, [shippingAddress, disabled]);

  return (
    <div className={`${styles.summary} ${className}`}>
      <div className={styles.header}>
        <PaperClipOutlined className={styles.icon} />
        <Text type="title-white">{t('common.summary')}</Text>
      </div>
      <div className={styles.content}>
        {children}

        <div className={styles.ecoBlock}>
          <EcoCheckbox light />
        </div>
      </div>
      <ConfirmationModal
        onClose={() => undefined}
        onConfirm={submitOrderValidation}
        title={t('modals.confirmationModalSendValidationTitle')}
      >
        {(show) => (
          <Button
            className={styles.button}
            type="primary"
            onClick={orderValidation ? show : submitOrderValidation}
            disabled={isDisabled}
          >
            {buttonText}
          </Button>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default Summary;
