import { useIsTablet } from 'utils/styles.utils';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import LoginLogo from 'pages/Login/LoginLogo';
import { useTheme } from 'hooks/useTheme';
import { ForgotPasswordModal, LoginForm } from 'components/login';
import { Image } from 'antd';
import LanguageSelector from '../../components/shared/LanguageSelector';
import { themeConfig } from 'constants/theme';
import styles from './Login.module.scss';

const Login: FC = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const isTablet = useIsTablet();
  const style = useTheme(styles);
  const { t } = useTranslation();

  return (
    <div className={style('loginPage')}>
      <div className={style('mainContent')}>
        <div className={style('mainContent__leftContent')}>
          <div className={style('mainContent__leftContent_background')}>
            {!isTablet && <Image preview={false} className={style('logo')} src={themeConfig.loginLogo} alt="logo" />}
            <span className={styles.mainContent__leftContent_bienvenueLabel}>{t('login.welcomeTitle')}</span>
          </div>
        </div>
        <div className={styles.mainContent__rightContent}>
          <LanguageSelector className={styles.languageSelector} />
          <LoginLogo />
          <LoginForm onClickForgotPassword={() => setShowForgotPassword(true)} />
          <ForgotPasswordModal onClose={() => setShowForgotPassword(false)} visible={showForgotPassword} />
        </div>
      </div>
    </div>
  );
};

export default Login;
