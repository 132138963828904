export const common_en = {
  or: 'or',
  requiredField: 'This field is required',
  noData: 'No data',
  minimumFourCharacters: 'Please enter at least {{count}} characters',
  noFarmerSelected: 'No client selected',
  noFarmerSelected_perret: 'No client selected',
  noFarmerSelected_izi: 'No farmer selected',
  next: 'Next',
  type: 'Type',
  number: 'Number',
  expiryDate: 'Expiry date',
  verified: 'Verified',
  uploaded: 'Uploaded',
  refused: 'Refused',
  group: 'Group',
  billing: 'Billing',
  billingAddress: 'Billing address',
  delivery: 'Delivery',
  shippingAddress_one: 'Shipping address',
  shippingAddress_other: 'Shipping addresses',
  searchProducts: 'Search products',
  searchProductsCartRequest: 'Search by cart request',
  searchProductsMulti: 'Search multiple products',
  wiuz: 'Wiuz Recommendation',
  certificateTitle: 'Phytosanitary Certification',
  availableTags: 'Available tags',
  addFarmer: 'Add a client',
  addFarmer_perret: 'Add a client',
  addFarmer_izi: 'Add a farmer',
  createAccount: 'Create an account',
  continue: 'Continue',
  cancel: 'Cancel',
  farmer_one: 'Farmer',
  farmer_other: 'Farmers',
  farmer_izi_one: 'Agriculteur',
  farmer_izi_other: 'Agriculteurs',
  multiBilling: 'Multi-billing',
  multiBilling_izi: 'Group delivery',
  multiBilling_ax: 'Group delivery',
  catalog: 'Catalog',
  shipping: 'Shipping',
  validation: 'Validation',
  cart: 'Cart',
  returnToStep: 'Return to step',
  saveCart: 'Save cart',
  productInfo: 'Product',
  reference: 'Reference',
  stock: 'Stock',
  netPrice: 'Net price',
  quantity: 'Quantity',
  includedArticle: 'Included article',
  initialNetPrice: 'Initial net price',
  forcedPrice: 'Forced price',
  forcedNetPrice: 'Forced NET price',
  buyingPrice: 'Buying price',
  totalProductHt: 'Total products net price',
  totalProductTtc: 'Total products gross price',
  netTotal: 'Net total',
  totalPrice: 'Gross Total',
  rpdNotIncluded: 'RPD not included',
  rpdTax: 'RPD tax',
  totalRpdTax: 'Total RPD',
  cvoTax: 'CVO tax',
  totalRpd: 'Total RPD',
  totalCvo: 'Total CVO',
  ofWhichCvo: 'Of which CVO',
  vat: 'VAT',
  cgeProductLine: 'CGE product line',
  selectShippingMethods: 'Select shipping methods',
  emptyCartTitle: 'Would you like to empty the cart?',
  confirm: 'Confirm',
  shippingMethods: 'Shipping methods',
  comment: 'Comment',
  addCommentPlaceholder: 'Product comment',
  globalCommentPlaceholder: 'Global comment',
  deliveryCommentPlaceholder: 'Delivery note',
  invoiceCommentPlaceholder: 'Invoice note',
  totalShippingCostWithoutVat: 'Total shipping cost without VAT',
  chooseDeliveryMethod: 'Please choose one or more delivery methods',
  apply: 'Apply',
  summary: 'Summary',
  orderSummary: 'Order summary',
  selectAnotherAddress: 'Select another address',
  selectAnotherPostalCode: 'Select another postal code',
  unitPrice: 'Unit price',
  conditioning: 'Conditioning',
  total: 'Total',
  volume: 'Volume',
  truck: 'Truck',
  validateOrder: 'Validate order',
  orderCompletedSuccessfully: 'Order completed successfully',
  importWiuzButton: 'Import WIUZ recommendation',
  uploadedFilesTitle: 'Uploaded files',
  filename: 'Filename',
  uploadDate: 'Upload date',
  lastModification: 'Last modification',
  fileImported: 'Your file is imported',
  seeWiuz: 'SEE PRECO WIUZ',
  products: 'Products',
  backToFilelist: 'Back to file list',
  view: 'View',
  orderValidated: 'Order validated',
  quoteValidated: 'Quote sent',
  createNewOrder: 'Create a new order',
  createNewQuote: 'Create a new quote',
  viewQuote: 'See quote',
  online: 'Online',
  offline: 'Offline',
  closeWindow: 'Close window',
  multiSearchImportButton: 'Import multi search',
  multiSearchText: 'Multi search text',
  margin: 'Margin',
  notAvailable: 'Not available',
  discount: 'including {{value}} {{type}} discount',
  customerMode: 'Customer mode',
  displayAllOffers: 'Display all offers',
  excludeNoStockOffers: 'Exclude offers with 0 stock',
  discountApplied: 'Discount applied',
  lastPrice: 'Last price',
  supplierPurchasePrice: 'Supplier purchase price',
  supplierPurchasePriceSubtitle: 'This information is not sent to Gestion Co.',
  commercialConditions: 'Commercial conditions',
  supplierPriceQuantity: 'Quantity',
  supplierPrice: 'Price',
  supplierPriceNote: 'Ratings and comments Supplier purchase price',
  supplierName: 'Supplier',
  supplierPriceSubmitButton: 'Ok',
  supplierPriceCancelButton: 'Cancel',
  supplierPriceSupplierRule: 'The supplier is required.',
  supplierPriceQuantityRule: 'The quantity is required.',
  supplierPricePriceRule: 'The price is required.',
  supplierPriceNone: '- none -',
  lastInvoicedPrice: 'Last invoiced price',
  lastQuotedPrice: 'Last quoted price',
  tooltipCartRequestColumnHeader: 'Cart request number',
  tooltipProductsOffered: 'Product offered to the customer',
  tooltipProductsRequested: 'Product requested by the customer',
  supplierPricePriceValidationNumeric: 'The value is not numeric.',
  supplierPricePriceValidationMin: 'The value must be grather than {{value}}.',
  supplierPricePriceValidationMax: 'The value must be less than selling price ({{price}}).',
  invoiceNumber: 'Invoice Number',
  invoiceDate: 'Invoice Date',
  purchaseDate: 'Purchase Date',
  purchaseOrderNumber: 'Quote Number',
  inputPriceInvalid: 'The forced price must be higher than the buying price.',
  blockedPrice: 'To change the forced price, please remove the discounts.',
  sku: 'SKU',
  volumePerItem: 'Volume per item',
  volumePerItemPerCustomer: 'Volume per item per customer',
  groupByOffer: 'Display by offers',
  groupByDeparture: 'Display by departures',
  groupBySku: 'Display by product',
  undefined: 'Undefined',
  underConstruction: 'This section is under construction',
  emptyCustomerList: 'Search for a customer by name, surname or customer number using the search bar',
  searchTc: 'Search for a TC',
  emptyCustomerListNoSearchResults: 'No results for your search',
  code: 'Code',
  contact: 'Contact',
  customerServicePhone: 'Customer service',
  codeProduct: 'Product code',
  sendCustomerQuote: 'Publish quote to customer',
  notifyCustomerQuestion: 'Notify customer by email ?',
  selectDeliveryDate: 'Select delivery date',
  selectDeliveryDateTo: 'to',
  infoUser: 'User information',
  account: 'Account',
  active: 'Active',
  inactive: 'Inactive',
  lastConnection: 'Last connection',
  email: 'Email',
  productName: 'Product name',
  UP: 'U.P.',
  quatationSubtitle: 'The quote was sent to the customer on his profile and was sent to you in PDF format by email.',
  quatationWarning: 'The customer needs an account to view the quote on the platform.',
  navMenuError: 'Unable to load the navigation menu.',
  copy: 'Copy',
  copied: 'Copied',
  offer: 'Offer',
  cartRequestExpirationDays: 'Days',
  cartRequestExpirationHours: 'Hours',
  cartRequestExpirationQuote: 'Quote expiration',
  createdOn: 'Created on',
  updatedAt: 'Updated on',
  expiresOn: 'Expires on',
  expiredAt: 'Expired at',
  convertedAt: 'Converted on',
  convertedStatus: 'Converted',
  refusedAt: 'Refused on',
  refusedStatus: 'Refused',
  unableToViewTheQuote: 'Unable to view the quote',
  warehouseStockName: 'Warehouse',
  hideOutOfStock: 'Hide out of stock',
  rememberWarehouse: 'Remember warehouse',
  actions: 'Actions',
  sentToCustomerAt: 'Sent to customer on',
  payment: 'Payment',
  certAuth: 'Authorize the purchase of phyto',
  regulatedPrecursor: 'Regulated explosive precursor',
  restrictedPrecursor: 'Restricted explosive precursor',
  otherInfo: 'Other information',
  noCertificateMessage:
    'You are adding a product subject to the phytosanitary certificate. This customer does not have a valid phytosanitary certificate!',
  quantitySmallerThanMinimum: 'The quantity you set is less than minimun value. The minimun value was set.',
  quantityBigerThanMaximum: 'The quantity you set is more than maximun value. The maximum value was set.',
  noStockMessage: 'Insufficient stock for chosen offer',
  unit: 'Unit',
  conditioningQuantity: 'Conditioning quantity',
  supplierReference: 'Supplier reference',
  uploadsTooltip: 'Add attachment (not forwarded to client).',
};
