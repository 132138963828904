import { ValidatedType } from '../../pages/Validated/Validated.types';

export enum OrderRoute {
  ORDER = 'order',
  CUSTOMER = 'client',
  PRODUCT = 'product',
  DELIVERY = 'delivery',
  VALIDATION = 'validation',
}

export const getValidatedRoute = (): string => `/validated/:type/:cartId?`;
export const getValidatedPath = (type: ValidatedType, cartId: Nullable<string> = null): string =>
  `/validated/${type}${cartId ? `/${cartId}` : ''}`;

export const getOrderRoute = (route: OrderRoute): string => `${route}`;

export const getOrderPath = (route: OrderRoute, cartId?: string): string =>
  `/${OrderRoute.ORDER}${cartId ? `/${cartId}` : ''}/${route}`;
