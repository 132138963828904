import { handleCatchError, isFulfilledAction, isPendingAction, isRejectedAction } from 'utils';
import { ErrorType, PaginatedResponse } from 'types';
import { OrderApiService } from 'services/orderServiceApi';
import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_ORDER_FILTER } from '../../utils/order.utils';
import { unwrap } from '../../utils/api.utils';
import { IFetchOrdersHistory, IOrderHistoryEntry, IOrdersHistoryState } from './types';

export const ORDERS_HISTORY_REDUCER_NAME = 'orders-history';

export const fetchOrdersHistory: AsyncThunk<
  PaginatedResponse<IOrderHistoryEntry>,
  IFetchOrdersHistory,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${ORDERS_HISTORY_REDUCER_NAME}/fetchOrdersHistory`,
  async ({ token, filters, page, size }: IFetchOrdersHistory, { rejectWithValue }) => {
    try {
      return unwrap(await OrderApiService.getOrders(token, filters, page, size));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

const initialState: IOrdersHistoryState = {
  isLoading: false,
  ordersHistoryList: {},
  page: 1,
  total: 0,
  size: 30,
  filters: {
    status: DEFAULT_ORDER_FILTER,
  },
  error: null,
};

const ordersHistorySlice = createSlice({
  name: ORDERS_HISTORY_REDUCER_NAME,
  initialState,
  reducers: {
    clearOrderHistoryState() {
      return { ...initialState };
    },
    clearOrderHistory(state) {
      state.ordersHistoryList = {};
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setPagination(state, { payload }) {
      state.page = payload.page ?? 1;
      state.size = payload.size;
    },
    clearFilters(state) {
      state.filters = initialState.filters;
    },
    setFilters(state, { payload }) {
      state.filters = payload;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchOrdersHistory.fulfilled,
        (state, { payload }: PayloadAction<PaginatedResponse<IOrderHistoryEntry>>) => {
          state.ordersHistoryList = payload.data.reduce((accumulator, order) => {
            return Object.assign(accumulator, { [order.number]: order });
          }, {});
          state.total = payload.total;
        },
      )

      .addMatcher(isPendingAction(`${ORDERS_HISTORY_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${ORDERS_HISTORY_REDUCER_NAME}/`), (state, { payload }) => {
        state.isLoading = false;
        state.ordersHistoryList = {};
        state.error = payload;
      })
      .addMatcher(isFulfilledAction(`${ORDERS_HISTORY_REDUCER_NAME}/`), (state) => {
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { clearOrderHistory, setPagination, setPage, clearFilters, setFilters } = ordersHistorySlice.actions;

export default ordersHistorySlice.reducer;
