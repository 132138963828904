import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { CheckSquareFilled, CloseSquareFilled, InfoCircleFilled } from '@ant-design/icons';
import Text from '../Text';
import styles from './OtherInfo.module.scss';

type OtherInfoProps = {
  certExpiryDate?: string;
  regulatedPrecursor?: boolean;
  restrictedPrecursor?: boolean;
  className?: string;
};

const OtherInfo: FunctionComponent<OtherInfoProps> = ({
  certExpiryDate,
  regulatedPrecursor,
  restrictedPrecursor,
  className = '',
}: OtherInfoProps) => {
  const { t } = useTranslation();
  const date = moment(certExpiryDate).utc(true);
  let dateColor: string;
  let ExpiryIcon = CheckSquareFilled;
  if (!date || !date.isValid() || date.isBefore(moment(), 'day')) {
    dateColor = 'var(--red)';
    ExpiryIcon = CloseSquareFilled;
  } else if (date.isSameOrBefore(moment().add(3, 'months'), 'day')) {
    dateColor = 'var(--orange)';
  } else {
    dateColor = 'var(--green)';
  }

  return (
    <div className={classNames(styles.column, className)}>
      <Text className={styles.name}>{t('common.otherInfo')}</Text>
      <div>
        <ExpiryIcon style={{ color: dateColor, marginRight: 5 }} />
        <Text>{t('common.certAuth')}</Text>
        {certExpiryDate && (
          <Tooltip placement="top" title={`${t('common.expiresOn')}: ${date.format('DD/MM/YYYY')}`}>
            <InfoCircleFilled className={styles.infoBullet} />
          </Tooltip>
        )}
      </div>
      {regulatedPrecursor ? (
        <div>
          <CheckSquareFilled style={{ color: 'var(--green)', marginRight: 5 }} />
          <Text>{t('common.regulatedPrecursor')}</Text>
        </div>
      ) : (
        <div>
          <CloseSquareFilled style={{ color: 'var(--red)', marginRight: 5 }} />
          <Text>{t('common.regulatedPrecursor')}</Text>
        </div>
      )}
      {restrictedPrecursor ? (
        <div>
          <CheckSquareFilled style={{ color: 'var(--green)', marginRight: 5 }} />
          <Text>{t('common.restrictedPrecursor')}</Text>
        </div>
      ) : (
        <div>
          <CloseSquareFilled style={{ color: 'var(--red)', marginRight: 5 }} />
          <Text>{t('common.restrictedPrecursor')}</Text>
        </div>
      )}
    </div>
  );
};
export default OtherInfo;
