import { useAppSelector } from 'store/store';
import { CartSortableIndex, ICartProductItem } from 'store/cart/types';
import {
  selectCartProducts,
  selectGroupedCartProducts,
  setCartGroupsAndSortOrder,
  setCartSortOrder,
  setHistoricalPriceModalSku,
} from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useCallback, useState } from 'react';
import { SortableCartProductGroup, SortableCartProductGroupItem } from './components/CartSortable';
import { HistoricalPriceModal } from './components';

const CartTable: FC = () => {
  const dispatch = useDispatch();
  const cartProducts = useSelector(selectCartProducts);
  const selectedGroupedCartProducts = useAppSelector(selectGroupedCartProducts);
  const [historicalPriceModalVisible, setHistoricalPriceModalVisible] = useState(false);

  const findProductIndex = useCallback((product: ICartProductItem) => cartProducts.indexOf(product), [cartProducts]);

  const onProductItemSortEnd = useCallback(
    ({ oldIndex, newIndex }: CartSortableIndex) => {
      dispatch(setCartSortOrder({ oldIndex, newIndex }));
    },
    [dispatch],
  );

  const onGroupItemSortEnd = useCallback(
    ({ oldIndex, newIndex }: CartSortableIndex) => {
      dispatch(setCartGroupsAndSortOrder({ groups: Object.keys(selectedGroupedCartProducts), oldIndex, newIndex }));
    },
    [dispatch, selectedGroupedCartProducts],
  );

  const handleHistoricalPriceModal = useCallback(
    (sku: string) => {
      dispatch(setHistoricalPriceModalSku(sku));
      setHistoricalPriceModalVisible(true);
    },
    [dispatch],
  );

  return (
    <>
      <HistoricalPriceModal visible={historicalPriceModalVisible} showModal={setHistoricalPriceModalVisible} />

      <SortableCartProductGroup onSortEnd={onGroupItemSortEnd} lockAxis="y">
        {Object.entries(selectedGroupedCartProducts).map(([bu, products], index) => (
          <SortableCartProductGroupItem
            key={String(bu)}
            index={index}
            bu={bu}
            products={products}
            handleHistoricalPriceModal={handleHistoricalPriceModal}
            onProductItemSortEnd={onProductItemSortEnd}
            findProductIndex={findProductIndex}
          />
        ))}
      </SortableCartProductGroup>
    </>
  );
};

export default CartTable;
