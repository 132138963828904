import { useAppDispatch, useAppSelector } from 'store/store';
import { fetchDepartures, selectDepartures, selectIsLoading } from 'store/products';
import { useEffect } from 'react';
import { IDeparture } from '../store/products/types';

type UseFetchDeparturesReturnType = {
  data: Record<number, IDeparture>;
  isLoading: boolean;
};

export const useFetchDepartures = (): UseFetchDeparturesReturnType => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(selectDepartures);
  const isLoading = useAppSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchDepartures());
  }, [dispatch]);

  return {
    data,
    isLoading,
  };
};
