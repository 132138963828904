import { SortableHandle } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import env from 'config';
import { CartAttachment } from 'components/shared';
import CartComment from 'components/product/CartTable/components/CartComment';
import ADButton from 'antd/lib/button';
import { Space, Tooltip } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styles from '../CartRowHeader/styles.module.scss';
import {
  removeNoStockProduct,
  updateCartProductComment,
  updateProductReplacementName,
} from '../../../../../store/cart';
import { ICartRowHeaderNoStockProps } from './types';

const CartRowHeaderNoStock: FC<ICartRowHeaderNoStockProps> = (props: ICartRowHeaderNoStockProps) => {
  const { product, productIndex } = props;
  const { name, variantId, productId, conditioning, publicComment, metadata } = product;
  const identifer = variantId;
  const dispatch = useDispatch();
  const [replacementName, toggleReplacementName] = useState(false);
  const { t } = useTranslation();

  const onChangeProductReplacementName = (inputText: string, identifier: number) => {
    dispatch(
      updateProductReplacementName({
        identifier,
        replacementName: inputText,
      }),
    );
  };

  const handleCommentSave = (value: string) => {
    dispatch(
      updateCartProductComment({
        identifier: identifer,
        comment: value,
      }),
    );
  };

  return (
    <div className={styles.headerContainer}>
      <div>
        <div className={styles.header}>
          <Space className={styles.info}>
            <div className={styles.header__title}>
              <div>
                {metadata?.business_note && (
                  <Tooltip overlay={metadata.business_note}>
                    {name} - {conditioning}
                  </Tooltip>
                )}
                {!metadata?.business_note && (
                  <>
                    {name} - {conditioning}
                  </>
                )}{' '}
              </div>
            </div>
          </Space>

          <Space size="small" onClick={(e) => e.stopPropagation()}>
            <CartAttachment productIndex={productIndex} />
            <ADButton
              onClick={() => dispatch(removeNoStockProduct({ productId, variantId }))}
              icon={<DeleteOutlined />}
              type="text"
              color="red"
              shape="circle"
              danger
            />
          </Space>
        </div>
        {product.replacement && (
          <div
            onClick={(e) => {
              toggleReplacementName(true);
              e.stopPropagation();
            }}
          >
            {!replacementName && (
              <span>
                {product.replacement.name} <EditOutlined className={styles.actionButton} />
              </span>
            )}
            {replacementName && (
              <>
                <input
                  size={32}
                  onChange={(e) => onChangeProductReplacementName(e.target.value, product.offerId)}
                  onBlurCapture={(e) => {
                    toggleReplacementName(false);
                    e.stopPropagation();
                  }}
                  onBlur={(e) => {
                    toggleReplacementName(false);
                    e.stopPropagation();
                  }}
                  value={product.replacement.name}
                />
                <CheckCircleOutlined
                  className={styles.actionButton}
                  onClick={(e) => {
                    toggleReplacementName(false);
                    e.stopPropagation();
                  }}
                />
              </>
            )}
          </div>
        )}
        {!!env('enableCartComments') && (
          <div className={styles.commentWrapper} onClick={(e) => e.stopPropagation()}>
            <CartComment
              key={variantId}
              comment={publicComment}
              onSave={handleCommentSave}
              placeholder={t('common.comment')}
              label={t('common.addCommentPlaceholder')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SortableHandle(CartRowHeaderNoStock);
