import { useIsMobile } from 'utils/styles.utils';
import { useAppSelector } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, memo, useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Client, Text } from '../../shared';
import { OrderDate } from '../../ordersHistory/OrdersHistoryTable/components/TableColumns';
import { getDeliveryDate, nullStringToMinus, priceParser, quantityParser } from '../../../utils';
import {
  AbandonedCartItemDetailsProps,
  IAbandonedCart,
  IAbandonedCartItem,
  IAbandonedCartParams,
} from '../../../types/abandonedCart';
import {
  fetchAbandonedCarts,
  isLoadingAbandonedCarts,
  resetAbandonedCartParams,
  selectAbandonedCartsFilters,
  selectAbandonedCartsList,
  selectAbandonedCartsListById,
  selectAbandonedCartsTotal,
  setPagination,
} from '../../../store/abandonedCarts';
import { PRODUCT_DELIVERY_FORMAT } from '../../../constants/format/delivery';
import AbandonedCartFilters from './components/AbandonedCartFilters';
import styles from './AbandonedCartsTable.module.scss';

const AbandonedCartsTable: FC = () => {
  const abandonedCartsList = useSelector(selectAbandonedCartsList);
  const total = useSelector(selectAbandonedCartsTotal);
  const filters = useSelector(selectAbandonedCartsFilters);
  const isLoading = useSelector(isLoadingAbandonedCarts);
  const [abandonedCartDetailsId, setAbandonedCartDetailsId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(resetAbandonedCartParams());
    };
  }, [dispatch]);

  const isRowExpanded = (id: number) => {
    return Boolean(id && id === abandonedCartDetailsId);
  };

  const handleClickDetails = (id: number) => {
    if (id && abandonedCartDetailsId === id) {
      setAbandonedCartDetailsId(null);
    } else {
      setAbandonedCartDetailsId(id);
    }
  };

  const stepMap: Record<number, string> = {
    1: t('common.cart'),
    2: t('forms.address'),
    3: t('common.delivery'),
    4: t('common.payment'),
  };

  const TABLE_COLUMNS: Array<TableColumnDefinition<IAbandonedCart>> = [
    {
      title: <span className={styles.col}>{t('cartRequests.client')}</span>,
      align: 'left',
      dataIndex: undefined,
      render: ({ customer, user }: IAbandonedCart) => {
        if (customer) return <Client {...customer} />;
        return <div>{user.emailCanonical}</div>;
      },
    },
    {
      title: <span className={styles.col}>{t('abandonedCarts.abandonedStep')}</span>,
      dataIndex: 'status',
      align: 'center',
      render: (status: number) => <div>{stepMap[status]}</div>,
    },
    {
      title: <span className={styles.col}>{t('abandonedCarts.numberOfProducts')}</span>,
      dataIndex: 'items',
      align: 'center',
      render: (items: Array<IAbandonedCartItem>) => <div>{items.length}</div>,
    },
    {
      title: <span className={styles.col}>{t('order_history.creationDate')}</span>,
      dataIndex: 'createdAt',
      align: 'center',
      render: (createdAt: string) => <OrderDate date={createdAt} />,
    },
    {
      title: <span className={styles.col}>{t('saved_cart.netTotal')}</span>,
      dataIndex: 'items',
      align: 'center',
      render: (items: IAbandonedCartItem[]) => (
        <div>{priceParser(items.reduce((acc, item) => acc + item.totalHt, 0))}</div>
      ),
    },
    {
      title: <span className={styles.col}>{t('saved_cart.details')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: ({ cartId, items }: IAbandonedCart) => (
        <>
          {items.length > 0 && (
            <Button
              className={styles.dropdown}
              onClick={() => handleClickDetails(cartId)}
              type="secondary"
              icon={<UnorderedListOutlined />}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('abandonedCarts.pageTitle')}</h3>
      <AbandonedCartFilters />
      <Table
        columns={TABLE_COLUMNS}
        size={isMobile ? 'small' : 'large'}
        className={styles.container__table}
        rowClassName={styles.row}
        rowKey={(abandonedCart) => abandonedCart.cartId}
        loading={isLoading}
        dataSource={abandonedCartsList}
        pagination={false}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 0,
          expandedRowClassName: () => styles.expandableRow,
          expandRowByClick: false,
          expandedRowRender: ({ cartId }: IAbandonedCart) => <AbandonedCartItemDetails cartId={cartId} />,
          rowExpandable: ({ cartId }) => isRowExpanded(cartId),
          expandIcon: () => null,
          expandIconColumnIndex: -1,
          expandedRowKeys: abandonedCartsList.map((abandonedCart) => abandonedCart.cartId),
        }}
      />
      {total > 0 && (
        <Pagination
          className={styles.container__pagination}
          defaultCurrent={1}
          total={total}
          disabled={false}
          defaultPageSize={30}
          pageSizeOptions={['20', '30', '50', '100']}
          onChange={(current, pageSize) => {
            dispatch(
              setPagination({
                page: current,
                size: pageSize,
              }),
            );
            dispatch(
              fetchAbandonedCarts({
                filters: filters as unknown as IAbandonedCartParams,
                page: current,
                size: pageSize,
              }),
            );
          }}
        />
      )}
    </div>
  );
};

const AbandonedCartItemDetails: FC<AbandonedCartItemDetailsProps> = memo(
  ({ cartId }: AbandonedCartItemDetailsProps) => {
    const abandonedCart = useAppSelector(selectAbandonedCartsListById(cartId));
    const { t } = useTranslation();

    if (!abandonedCart) {
      return null;
    }
    const { items } = abandonedCart;

    const TABLE_COLUMNS: Array<TableColumnDefinition<IAbandonedCartItem>> = [
      {
        title: <span className={styles.col}>{t('cartRequests.productInfo')}</span>,
        dataIndex: 'label',
        width: '40%',
        align: 'left',
        render: (label: string) => <Text type="subtitle-thin">{label}</Text>,
      },
      {
        title: <span className={styles.col}>{t('common.code')}</span>,
        dataIndex: 'sku',
        align: 'center',
        render: (sku: string) => <Text type="subtitle-thin">{nullStringToMinus(sku)}</Text>,
      },
      {
        title: <span className={styles.col}>{t('common.quantity')}</span>,
        dataIndex: undefined,
        align: 'center',
        render: ({ quantity, quantityIncrement, labelPlural, labelSingular }: IAbandonedCartItem) => (
          <Text type="subtitle-thin">
            {quantityParser(quantity, quantityIncrement)}{' '}
            {quantityIncrement * quantity >= 2 ? labelPlural : labelSingular}
          </Text>
        ),
      },
      {
        title: <span className={styles.col}>{t('cartRequests.deliveryPeriod')}</span>,
        dataIndex: undefined,
        align: 'center',
        render: ({ deliveryEndDate, deliveryStartDate, deliveryDay }: IAbandonedCartItem) => (
          <Text type="subtitle-thin">
            {getDeliveryDate(
              {
                startDate: deliveryStartDate,
                endDate: deliveryEndDate,
                days: deliveryDay,
              },
              PRODUCT_DELIVERY_FORMAT.NO_LABEL,
            ) || '-'}
          </Text>
        ),
      },
      {
        title: <span className={styles.col}>{t('common.UP')}</span>,
        dataIndex: 'unitPrice',
        align: 'center',
        render: (unitPrice: number) => <Text type="subtitle-thin">{priceParser(unitPrice)}</Text>,
      },
      {
        title: <span className={styles.col}>{t('saved_cart.netTotal')}</span>,
        dataIndex: undefined,
        align: 'center',
        render: (record) => {
          const { totalHt } = record;
          return <Text type="subtitle-thin">{priceParser(totalHt)}</Text>;
        },
      },
    ];

    return (
      <div className={styles.detailsContainer}>
        <Table columns={TABLE_COLUMNS} className={styles.table} dataSource={items} pagination={false} />
      </div>
    );
  },
);

export default AbandonedCartsTable;
