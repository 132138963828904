/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Button, Dropdown, Modal } from 'components/shared';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Menu } from 'antd';
import { DeleteOutlined, GroupOutlined } from '@ant-design/icons';
import styles from './MassAction.module.scss';

interface MassActionProps {
  selectedRowKeys: string[];
  onFinishedAction: () => void;
}

export const MassAction: FunctionComponent<MassActionProps> = ({ selectedRowKeys, onFinishedAction }) => {
  const { getAllGroups, addUsersToGroups, removeUsersFromGroups, deleteUsers } = useAuth();
  const [action, setAction] = useState<string>('');
  const [modalVisible, setModalVisible] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const { t } = useTranslation();

  const confirmCb = async () => {
    switch (action) {
      case 'assignToGroups':
        await assignToGroupsCb();
        break;
      case 'removeFromGroups':
        await removeFromGroupsCb();
        break;
      case 'delete':
        await deleteCb();
        break;
      default:
        break;
    }

    onFinishedAction();
    setModalVisible(false);
    setSelectedGroups([]);
    setAction('');
  };
  const assignToGroupsCb = async () => {
    await addUsersToGroups(selectedGroups, selectedRowKeys);
  };
  const removeFromGroupsCb = async () => {
    await removeUsersFromGroups(selectedGroups, selectedRowKeys);
  };
  const deleteCb = async () => {
    await deleteUsers(selectedRowKeys);
  };

  const getDropdownMenuItems = () => {
    return [
      {
        key: 'assignToGroups',
        label: t('massAction.assignToGroups'),
        icon: <GroupOutlined />,
        onClick: () => {
          setAction('assignToGroups');
          setModalVisible(true);
        },
      },
      {
        key: 'removeFromGroups',
        label: t('massAction.removeFromGroups'),
        icon: <GroupOutlined />,
        onClick: () => {
          setAction('removeFromGroups');
          setModalVisible(true);
        },
      },
      {
        key: 'delete',
        label: t('massAction.delete'),
        icon: <DeleteOutlined />,
        onClick: () => {
          setAction('delete');
          setModalVisible(true);
        },
      },
    ];
  };

  const clickedGroupCheckbox = (e: CheckboxChangeEvent, group: string) => {
    setSelectedGroups((prev) => {
      return e.target.checked ? [...prev, group] : prev.filter((g) => g !== group);
    });
  };

  useEffect(() => {
    if (!groups)
      getAllGroups().then((res) => {
        setGroups(res?.Groups?.map((group) => group.GroupName) || []);
      });
  }, [getAllGroups, groups]);

  if (selectedRowKeys.length === 0) {
    return null;
  }

  return (
    <>
      <Dropdown
        lableIcon={
          <>
            {t('massAction.selected')} {selectedRowKeys.length} rows
          </>
        }
        overlay={() => <Menu items={getDropdownMenuItems()} />}
      />
      <Modal
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setAction('');
          setSelectedGroups([]);
        }}
      >
        <h3>{t(`massAction.${action}`)}</h3>
        {action === 'delete' && <p>{t('massAction.deleteConfirmation')}</p>}
        {action && action !== 'delete' && (
          <div className={styles.checkbox_container}>
            {groups?.map((group) => (
              <Checkbox
                className={styles.checkbox}
                key={group}
                checked={selectedGroups.includes(group)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  clickedGroupCheckbox(e, group);
                }}
              >
                {group}
              </Checkbox>
            ))}
          </div>
        )}
        <Button className={styles.confirm_button} type="primary" onClick={confirmCb}>
          {t('massAction.confirm')}
        </Button>
      </Modal>
    </>
  );
};
export default MassAction;
