import { priceParser } from 'utils';
import { ILastPriceType } from 'store/cart/types';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import moment from 'moment';
import { useDateFormat } from 'hooks/useDateFormat';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { Text } from '../../../../../../shared';
import { LastPriceProps } from './LastPrice.types';
import styles from './LastPrice.module.scss';

const LastPrice: React.FC<LastPriceProps> = (props: LastPriceProps) => {
  const { sku, lastInvoicedPrice, lastQuotedPrice, displayHistoricalPriceModal } = props;
  const lastInvoicedNumber = lastInvoicedPrice?.invoiceNumber;
  const lastInvoiceCreatedDate = lastInvoicedPrice?.createdAt;
  const lastPurchaseOrderNumber = lastQuotedPrice?.purchaseOrderNumber;
  const lastQuotedCreatedDate = lastQuotedPrice?.createdAt;

  const { t } = useTranslation();
  const dateFormatShort = useDateFormat('short');

  const handleShowHistoryModalPrice = useCallback(
    (type: ILastPriceType) => {
      if (type !== 'lastInvoicedPrice') return;
      if (typeof displayHistoricalPriceModal !== 'undefined') displayHistoricalPriceModal(sku);
    },
    [displayHistoricalPriceModal, sku],
  );

  const renderInvoiceTooltip = useCallback(() => {
    const displayDate = moment(lastInvoiceCreatedDate).utc(true).format(dateFormatShort);
    return (
      <div>
        {!!lastInvoicedNumber && (
          <div>
            {t('common.invoiceNumber')}: {lastInvoicedNumber}
          </div>
        )}
        <div>
          {t('common.invoiceDate')}: {displayDate}
        </div>
      </div>
    );
  }, [dateFormatShort, lastInvoiceCreatedDate, lastInvoicedNumber, t]);

  const renderQuotedPriceTooltip = useCallback(() => {
    const displayDate = moment(lastQuotedCreatedDate).utc(true).format(dateFormatShort);
    return (
      <div>
        {!!lastPurchaseOrderNumber && (
          <div>
            {t('common.purchaseOrderNumber')}: {lastPurchaseOrderNumber}
          </div>
        )}
        <div>
          {t('common.purchaseDate')}: {displayDate}
        </div>
      </div>
    );
  }, [lastQuotedCreatedDate, dateFormatShort, lastPurchaseOrderNumber, t]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.lastPrice}>
        <Tooltip title={renderInvoiceTooltip()}>
          {lastInvoicedNumber && (
            <span>
              <InfoCircleOutlined />
            </span>
          )}
        </Tooltip>
        <span>
          <HistoryOutlined onClick={() => handleShowHistoryModalPrice('lastInvoicedPrice')} />
        </span>
        <Text
          style={{ cursor: 'pointer' }}
          type="subtitle-gray1"
          onClick={() => handleShowHistoryModalPrice('lastInvoicedPrice')}
        >
          {t(`common.lastInvoicedPrice`)}: {priceParser(lastInvoicedPrice?.unitPrice ?? 0)}
        </Text>
        <div>
          {lastPurchaseOrderNumber && (
            <Tooltip title={renderQuotedPriceTooltip()}>
              <span>
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          )}
        </div>
        <div>&nbsp;</div>
        <Text type="subtitle-gray1">
          {t(`common.lastQuotedPrice`)}: {priceParser(lastQuotedPrice?.unitPrice ?? 0)}
        </Text>
      </div>
    </div>
  );
};

export default LastPrice;
