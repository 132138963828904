import { FC } from 'react';
import { Text } from 'components/shared';
import ADModal from 'antd/lib/modal/Modal';
import { Image } from 'antd';
import { IModalProps } from './Modal.types';
import styles from './Modal.module.scss';

const Modal: FC<IModalProps> = (props: IModalProps) => {
  const { children, className, bodyStyle, imageProps, title, ...remainingProps } = props;
  const { className: imageClassName, src, ...remainingImageProps } = imageProps || {};
  return (
    <ADModal
      centered
      footer={remainingProps.footer || null}
      className={`${styles.modal} ${className || ''}`}
      bodyStyle={{
        background: 'white',
        borderRadius: '10px',
        ...bodyStyle,
      }}
      {...remainingProps}
    >
      {!!title && (
        <Text className={styles.title} type="title">
          {title}
        </Text>
      )}
      {src && (
        <div className={`${styles.imageHolder}`}>
          <Image
            src={src}
            preview={false}
            className={`${styles.imageHolder__img} ${imageClassName}`}
            {...remainingImageProps}
          />
        </div>
      )}
      {children}
    </ADModal>
  );
};

export default Modal;
