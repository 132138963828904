import { Moment } from 'moment';

export const isFullMonth = (startDate: Moment, endDate: Moment): boolean => {
  const sameMonth = startDate.isSame(endDate, 'month');
  if (sameMonth) {
    const diffDays = endDate.diff(startDate, 'days') + 1;
    const daysInMonth = startDate.daysInMonth();
    if (diffDays === daysInMonth) {
      return true;
    }
  }

  return false;
};
