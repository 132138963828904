import { RootState } from 'store/rootReducer';
import { IOrderHistoryEntry } from './types';

export const selectOrderHistoryList = (state: RootState): Array<IOrderHistoryEntry> =>
  Object.values(state.ordersHistory.ordersHistoryList);

export const selectIsLoadingOrders = (state: RootState): boolean => state.ordersHistory.isLoading;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectOrderHistoryParams = (state: RootState) => {
  return {
    filters: state.ordersHistory.filters,
    page: state.ordersHistory.page,
    size: state.ordersHistory.size,
    total: state.ordersHistory.total,
  };
};
