import { editFloatNumber } from 'utils';
import { FC } from 'react';
import { CartSelectedQuantityProps } from 'components/product/CartTable/components/CartSelectedQuantity/types';

const CartSelectedQuantity: FC<CartSelectedQuantityProps> = ({
  totalQuantity,
  availableQuantity,
}: CartSelectedQuantityProps) => {
  return (
    <>
      {editFloatNumber(totalQuantity - availableQuantity)} / {editFloatNumber(totalQuantity)}
    </>
  );
};

export default CartSelectedQuantity;
