import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Text } from 'components/shared';
import { Table } from 'antd';
import { getDeliveryDateFormatted, priceParser, quantityParser } from '../../../../../utils';
import { IOrderHistoryEntryItem } from '../../../../../store/ordersHistory/types';
import { PRODUCT_DELIVERY_FORMAT } from '../../../../../constants/format/delivery';
import { OrderHistoryDetailsProps } from './types';
import styles from './styles.module.scss';

export const OrderHistoryDetails: FC<OrderHistoryDetailsProps> = ({ items }: OrderHistoryDetailsProps) => {
  const { t } = useTranslation();

  if (!items || items.length < 1) {
    return null;
  }

  const TABLE_COLUMNS: Array<TableColumnDefinition<IOrderHistoryEntryItem>> = [
    {
      title: <span className={styles.col}>{t('order_history.productInfo')}</span>,
      dataIndex: undefined,
      align: 'left',
      width: '40%',
      render: (item) => (
        <Text type="subtitle-thin" style={{ textAlign: 'left' }}>{`${item.label} - ${item.variantId}`}</Text>
      ),
    },
    {
      title: <span className={styles.col}>{t('order_history.code')}</span>,
      dataIndex: 'sku',
      align: 'center',
      render: (sku) => <Text type="subtitle-thin">{sku}</Text>,
    },
    {
      title: <span className={styles.col}>{t('order_history.qty')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: (item) => (
        <>
          <Text type="subtitle-thin">
            {quantityParser(item.quantity, 1)} {item.conditioningName}
          </Text>
          {item.conditioningName && (
            <>
              <br />
              <Text type="subtitle-thin">
                {quantityParser(item.quantity, Number(item.conditioningQuantity))} {item.variantUnitLabel}
              </Text>
            </>
          )}
        </>
      ),
    },
    {
      title: <span className={styles.col}>{t('cartRequests.deliveryPeriod')}</span>,
      dataIndex: undefined,
      align: 'center',
      render: (item) => {
        const { deliveryStartDate, deliveryEndDate, deliveryDay } = item;

        const formatted = getDeliveryDateFormatted(
          {
            days: deliveryDay ?? undefined,
            startDate: deliveryStartDate ?? undefined,
            endDate: deliveryEndDate ?? undefined,
            delay: {},
          },
          PRODUCT_DELIVERY_FORMAT.NO_LABEL,
        );
        const deliveryDate = typeof formatted === 'string' ? formatted : formatted.date;

        return <Text type="subtitle-thin">{deliveryDate}</Text>;
      },
    },
    {
      title: <span className={styles.col}>{t('order_history.pu')}</span>,
      dataIndex: 'unitPrice',
      align: 'center',
      render: (unitPrice) => <Text type="subtitle-thin">{priceParser(unitPrice)}</Text>,
    },
    {
      title: <span className={styles.col}>{t('order_history.netTotal')}</span>,
      dataIndex: 'totalHT',
      align: 'center',
      render: (netTotal) => <Text type="subtitle-thin">{priceParser(netTotal)}</Text>,
    },
    {
      title: <span className={styles.col}>{t('order_history.total')}</span>,
      align: 'center',
      dataIndex: undefined,
      render: (item) => <Text type="subtitle-thin">{priceParser(item.unitsTotal + item.adjustmentsTotal)}</Text>,
    },
  ];

  return (
    <div className={styles.container}>
      <Table columns={TABLE_COLUMNS} className={styles.table} dataSource={items} pagination={false} />
    </div>
  );
};

export default OrderHistoryDetails;
