import { createTranslation, translate, TranslationsVars } from 'utils/translation.utils';
import { TranslationDomain } from 'constants/translations';

type UseTranslateReturn = {
  (str: string, ...vars: TranslationsVars): string;
};

export const useTranslation = (domain: TranslationDomain | undefined): UseTranslateReturn => {
  if (domain) {
    return createTranslation(domain);
  }
  return translate;
};

export default useTranslation;
