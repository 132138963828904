import { quantityParser } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Switch, Text } from '../../../../shared';
import { IWarehouseStockDataItem } from '../../../../../store/products/types';
import {
  getWarehouseStocks,
  selectWarehouseName,
  selectWarehouseRememberName,
  selectWarehouseStockData,
  selectWarehouseStockLoading,
  selectWarehouseStockSku,
  selectWarehouseStockVisibility,
  setWarehouseName,
  setWarehouseRememberName,
  setWarehouseStockModalSku,
  setWarehouseStockModalVisibility,
} from '../../../../../store/products';
import styles from './styles.module.scss';

const WarehouseStockModal: FC = () => {
  const { t } = useTranslation();
  const sku = useSelector(selectWarehouseStockSku);
  const warehouseName = useSelector(selectWarehouseName);
  const rememberWarehouseName = useSelector(selectWarehouseRememberName);
  const visible = useSelector(selectWarehouseStockVisibility);
  const [inputSku, setInputSku] = useState(sku ?? '');
  const dispatch = useDispatch();
  const isLoading = useSelector(selectWarehouseStockLoading);
  const [warehouseInputName, setWarehouseInputName] = useState(warehouseName ?? '');
  const [hideOutOfStock, setHideOutOfStock] = useState(true);
  const [rememberWarehouseCheck, setRememberWarehouseCheck] = useState(rememberWarehouseName);
  const warehouseStockDataItems: IWarehouseStockDataItem[] = useSelector(selectWarehouseStockData);

  const fetchWarehouseStockData = useCallback(
    (skuValue: string | null, whName: string, hoos: boolean) => {
      if (!skuValue) {
        return;
      }
      dispatch(getWarehouseStocks({ sku: skuValue, warehouseName: whName, hideOutOfStock: hoos }));
    },
    [dispatch],
  );

  const searchResults = useCallback(() => {
    dispatch(setWarehouseStockModalSku(inputSku));
    fetchWarehouseStockData(inputSku, warehouseInputName, hideOutOfStock);
  }, [dispatch, fetchWarehouseStockData, hideOutOfStock, inputSku, warehouseInputName]);

  useEffect(() => {
    if (!visible) {
      return () => null;
    }

    const subscription = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        searchResults();
      }
    };

    document.addEventListener('keydown', subscription);

    return () => {
      document.removeEventListener('keydown', subscription);
    };
  }, [visible, searchResults]);

  useEffect(() => {
    if (visible) {
      fetchWarehouseStockData(sku || '', warehouseName ?? '', true);
    } else {
      dispatch(setWarehouseStockModalSku(''));
      if (!rememberWarehouseName) {
        setWarehouseInputName('');
        dispatch(setWarehouseRememberName(false));
        dispatch(setWarehouseName(''));
      }
      setHideOutOfStock(true);
    }
  }, [dispatch, fetchWarehouseStockData, sku, visible]);

  useEffect(() => {
    setInputSku(sku ?? '');
    fetchWarehouseStockData(inputSku, warehouseInputName, hideOutOfStock);
  }, [sku, hideOutOfStock, fetchWarehouseStockData]);

  const handleSearch = () => {
    searchResults();
  };

  useEffect(() => {
    if (rememberWarehouseCheck) {
      dispatch(setWarehouseName(warehouseInputName));
      dispatch(setWarehouseRememberName(true));
    }
  }, [dispatch, rememberWarehouseCheck, warehouseInputName]);

  const handleHideOutOfStock = (checked: boolean) => {
    setHideOutOfStock(() => checked);
  };

  const handleRememberWarehouse = (checked: boolean) => {
    setRememberWarehouseCheck(() => checked);
    dispatch(setWarehouseRememberName(checked));
  };

  const closeModal = () => {
    dispatch(setWarehouseStockModalVisibility(false));
  };

  const TABLE_COLUMNS: Array<TableColumnDefinition<IWarehouseStockDataItem>> = [
    {
      title: <span>{t('modals.warehouseName')}</span>,
      dataIndex: 'warehouseName',
      width: '25%',
      render: (warehouseName) => <div>{warehouseName}</div>,
    },
    {
      title: <span>{t('modals.warehouseStockQuantity')}</span>,
      dataIndex: undefined,
      align: 'center',
      width: '15%',
      render: ({ quantity, conditioningQuantity, unitLabelSingular, unitLabelPlural, conditioningLabel }) => {
        const quantityParsed = Number(quantityParser(quantity, conditioningQuantity));
        return (
          <div>
            <div>
              {quantityParsed} {quantityParsed >= 2 ? unitLabelPlural : unitLabelSingular}
            </div>
            <Text type="subtitle-thin">
              {quantity} {conditioningLabel}
            </Text>
          </div>
        );
      },
    },
    {
      title: <span>{t('common.code')}</span>,
      dataIndex: 'sku',
      align: 'center',
      width: '15%',
      render: (sku) => <div>{sku}</div>,
    },
    {
      title: <span>{t('modals.warehouseStockProductName')}</span>,
      dataIndex: 'productName',
      width: '50%',
      render: (productName) => <div>{productName}</div>,
    },
  ];

  return (
    <Modal
      width="calc(80% - 195px)"
      className={styles.modal}
      style={{ left: '119px' }}
      footer={null}
      open={visible}
      onCancel={closeModal}
      destroyOnClose
      title={''}
    >
      <div className={styles.wrapper}>
        <div className={styles.searchGroup}>
          <Input
            addonBefore={t('common.warehouseStockName')}
            type="text"
            size="large"
            value={warehouseInputName}
            onChange={(e) => setWarehouseInputName(e.target.value)}
          />
          <Input
            type="text"
            addonBefore={t('common.code')}
            size="large"
            value={inputSku}
            onChange={(e) => setInputSku(e.target.value)}
          />
          <Button
            className={styles.searchButton}
            type="primary"
            size="large"
            icon={<SearchOutlined />}
            loading={isLoading}
            onClick={handleSearch}
          />
        </div>
        <div className={styles.switchButtons}>
          <Switch label={String(t('common.hideOutOfStock'))} checked={hideOutOfStock} onChange={handleHideOutOfStock} />
          <Switch
            label={String(t('common.rememberWarehouse'))}
            checked={rememberWarehouseCheck}
            onChange={handleRememberWarehouse}
          />
        </div>
      </div>
      <Text type={'title-gray3'}>{t('modals.warehouseStockTitle')}</Text>
      <Table
        columns={TABLE_COLUMNS}
        size={'small'}
        loading={isLoading}
        dataSource={warehouseStockDataItems}
        scroll={{ y: 400 }}
        rowKey={(record, index) => `${record.warehouseId}_${record.variantId}_${index?.toString()}`}
        pagination={false}
      ></Table>
    </Modal>
  );
};

export default WarehouseStockModal;
