import { getDeliveryAddress } from 'utils';
import { useAppDispatch } from 'store/store';
import {
  selectPointListByShippingMethodId,
  selectShippingAddress,
  updateShippingDetailsShippingItemsDeliveryEndDate,
  updateShippingDetailsShippingItemsDeliveryStartDate,
} from 'store/delivery';
import { selectProductsByOfferIds, updateOrderDeliveryOverride, updateProductsFieldByOffers } from 'store/cart';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { PictoSiloIcon, PictoFermeIcon } from 'icons';
import { useDateFormat } from 'hooks/useDateFormat';
import dayjs, { Dayjs } from 'dayjs';
import env from 'config';
import { DateRenderOverride } from 'components/shared/DateRenderOverride/DateRenderOverride';
import { Text } from 'components/shared';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { DatePicker } from 'antd';
import ValidationProduct from '../ValidationProduct';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import { IValidationContainerProps } from './ValidationContainer.types';
import styles from './ValidationContainer.module.scss';

const { RangePicker } = DatePicker;

const ValidationContainer: FC<IValidationContainerProps> = (props: IValidationContainerProps) => {
  const { order, truckId, customerIdentifier } = props;
  const { id: orderId, isTruck } = order;
  const { orderItems, shippingMethodId } = order;
  const offerIds = useMemo(() => orderItems.map((orderItem) => parseInt(orderItem.offerId)), [orderItems]);
  const products = useSelector(selectProductsByOfferIds(orderItems.map((orderItem) => parseInt(orderItem.offerId))));
  const shippingAddress = useSelector(selectShippingAddress);
  const pointListItem = useSelector(selectPointListByShippingMethodId(parseInt(shippingMethodId)));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dateFormatShort = useDateFormat('short');

  const handleChangeStartDate = (date: string | null) => {
    dispatch(
      updateOrderDeliveryOverride({
        orderId,
        values: {
          startDate: date,
        },
      }),
    );

    dispatch(updateShippingDetailsShippingItemsDeliveryStartDate({ orderId, deliveryStartDate: date }));

    dispatch(
      updateProductsFieldByOffers({
        field: 'overriddenDeliveryStartDate',
        offerIds,
        value: date,
      }),
    );

    dispatch(
      updateProductsFieldByOffers({
        field: 'deliveryOverridden',
        offerIds,
        value: !!date,
      }),
    );
  };

  const handleChangeEndDate = (date: string | null) => {
    dispatch(
      updateOrderDeliveryOverride({
        orderId,
        values: {
          endDate: date,
        },
      }),
    );

    dispatch(updateShippingDetailsShippingItemsDeliveryEndDate({ orderId, deliveryEndDate: date }));

    dispatch(
      updateProductsFieldByOffers({
        field: 'overriddenDeliveryEndDate',
        offerIds,
        value: date,
      }),
    );

    dispatch(
      updateProductsFieldByOffers({
        field: 'deliveryOverridden',
        offerIds,
        value: !!date,
      }),
    );
  };

  const startDate = useMemo(() => {
    const product = [...products].shift();
    if (product?.overriddenDeliveryStartDate && product?.deliveryOverridden) {
      return dayjs(product.overriddenDeliveryStartDate);
    }

    if (order.deliveryOverride.startDate) {
      return dayjs(order.deliveryOverride.startDate);
    }

    return null;
  }, [order.deliveryOverride.startDate, products]);

  const endDate = useMemo(() => {
    const product = [...products].shift();
    if (product?.overriddenDeliveryEndDate && product?.deliveryOverridden) {
      return dayjs(product.overriddenDeliveryEndDate);
    }

    if (order.deliveryOverride.endDate) {
      return dayjs(order.deliveryOverride.endDate);
    }

    return null;
  }, [order.deliveryOverride.endDate, products]);

  const disabledDate = (current: Dayjs) => {
    return current.format(CUSTOM_DATE_FORMAT.timestamp) < dayjs().format(CUSTOM_DATE_FORMAT.timestamp);
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles.left}>
            {pointListItem ? <PictoSiloIcon className={styles.icon} /> : <PictoFermeIcon className={styles.icon} />}
          </div>
          <div className={styles.middle}>
            <Text type="title-gray8" className={styles.text}>
              {shippingAddress?.company} {shippingAddress?.firstName} {shippingAddress?.lastName}
            </Text>
            <Text type="subtitle-gray1" className={styles.textSubtitle}>
              {' '}
              {getDeliveryAddress(pointListItem, shippingAddress)}
            </Text>
          </div>
        </div>
        {!!env('enableOverrideDeliveryDate') && !isTruck && (
          <div className={styles.right}>
            <div className={styles.deliveryDate}>
              <Text type="subtitle-gray1" className={styles.text}>
                {t('common.selectDeliveryDate')}
              </Text>
              <RangePicker
                size="middle"
                defaultValue={[null, null]}
                value={[startDate, endDate]}
                disabledDate={disabledDate}
                locale={locale}
                popupClassName={'has-weeknumber'}
                format={dateFormatShort}
                dateRender={DateRenderOverride}
                onChange={(dates: [Dayjs | null, Dayjs | null] | null) => {
                  if (dates) {
                    const [currentStartDate, currentEndDate] = dates;
                    handleChangeStartDate(currentStartDate?.format(CUSTOM_DATE_FORMAT.mysql.short) ?? null);
                    handleChangeEndDate(currentEndDate?.format(CUSTOM_DATE_FORMAT.mysql.short) ?? null);
                  } else {
                    handleChangeStartDate(null);
                    handleChangeEndDate(null);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      {products.length &&
        products.map((product) => {
          if (product) {
            const findQuantityDisplayed = orderItems.find((item) => {
              return Number(item.offerId) === product.offerId;
            });

            const totalPrice =
              findQuantityDisplayed?.quantity === product.quantity
                ? product.totalPrice
                : product.quantityIncrement * product.offerPrice + product.rpd;

            return (
              <ValidationProduct
                key={`${product.offerId}-${product.productId}-${product.variantId}`}
                product={{
                  ...product,
                  quantity: findQuantityDisplayed ? findQuantityDisplayed?.quantity : product.quantity,
                  totalPrice,
                }}
                truckNumber={truckId}
                customerIdentifier={customerIdentifier}
                order={order}
              />
            );
          }
          return null;
        })}
    </div>
  );
};

export default ValidationContainer;
