import { FC } from 'react';
import { usePermissions } from 'hooks';
import SavedCartListTable from '../../components/savedCart';
import styles from './SavedCart.module.scss';

const ALLOWED_PERMISSIONS = ['QUOTE_LIST'];

const SavedCart: FC = () => {
  const { allowed } = usePermissions(ALLOWED_PERMISSIONS, true, true);

  return (
    <>
      {allowed && (
        <div className={styles.savedCart}>
          <SavedCartListTable />
        </div>
      )}
    </>
  );
};

export default SavedCart;
