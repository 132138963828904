import { ErrorType } from 'types';
import { MessageType } from 'store/messages/type';

export type FulfilledPayload<T = unknown> = T & { __show_notification: MessageType };

export const successWithMessage = <T>(message: string, payload: T): FulfilledPayload<T> => {
  return {
    ...payload,
    __show_notification: {
      type: 'success',
      message,
    },
  };
};

export type ErrorMessage = Error | ErrorType;
export type RejectedPayload<T> = T & { __show_notification: boolean };
