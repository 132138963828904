import { selectAddProductModalLoading, selectAddProductModalUnits, selectSuppliers } from 'store/products';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Form, Space, Input, InputNumber, Select, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from '../styles.module.scss';
import { DiscountType } from '../../../shared/Discount/Discount.types';
import { Button } from '../../../shared';
import { generateInteger } from '../../../../utils/global.utils';
import { createObjectId } from '../../../../utils';
import { ICartProductItem } from '../../../../store/cart/types';
import { addProduct } from '../../../../store/cart';

type AddProductFormProps = {
  onFinish: () => void;
};

type CustomProduct = {
  requestedName: string;
  suggestedName?: string;
  sku: string;
  conditioning?: string;
  conditioningQuantity: number;
  price: number;
  unit: number;
  unitPrice: number;
  supplier: number;
  supplierReference: string;
  supplierPrice: number;
  comment?: string;
};

export const AddProductForm = ({ onFinish }: AddProductFormProps): JSX.Element => {
  const [form] = useForm<CustomProduct>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const units = useSelector(selectAddProductModalUnits);
  const suppliers = useSelector(selectSuppliers);
  const loading = useSelector(selectAddProductModalLoading);
  const onSubmit = (values: CustomProduct) => {
    const unit = units.find((u) => u.id === values.unit);

    /*
      TODO: See if there's another way to work around this.
      Product/Variant/Offer IDs are required throughout the entire checkout flow.
      Best option for now is to generate an ID that's large enough not to
      match existing ones in order to keep other functionality enabled.
     */
    const integerId = generateInteger(1500000, 2000000);
    const unitPrice = values.unitPrice * 100;
    const supplierPrice = values.supplierPrice * 100;

    const productToAdd: ICartProductItem = {
      isPlainProduct: true,
      name: values.suggestedName ?? values.requestedName,
      label: values.requestedName,
      sku: values.sku,
      quantity: 1,
      conditioning: values?.conditioning ?? '',
      conditioningQuantity: values.conditioningQuantity,
      unit: {
        singular: unit?.singular ?? '',
        plural: unit?.plural ?? '',
      },
      supplierId: values.supplier,
      supplierReference: values.supplierReference,
      publicComment: values.comment,
      productId: integerId,
      variantId: integerId,
      offerId: integerId,
      cvo: 0,
      rpd: 0,
      weight: 0,
      isTownRequired: false,
      discountType: DiscountType.PERCENTAGE,
      discountValue: 0,
      attachments: [],
      cartProductId: createObjectId(),
      price: {
        price: unitPrice,
        initialPrice: unitPrice,
        isProduct: true,
      },
      offerPrice: unitPrice,
      taxes: {
        cvo: 0,
        rpd: 0,
        vat: 0,
      },
      enabled: true,
      supplierPrice: {
        supplierId: values.supplier,
        price: supplierPrice,
        quantity: 1,
      },
      quantityIncrement: 1,
      priceTotalWithTaxes: unitPrice,
      totalPrice: unitPrice,
      shippingMethods: [],
      minimumOrderable: 1,
      cartRequestItemId: null,
      wiuz: null,
      stock: 999999,
      availableQuantity: 999999,
      availableSelectedQuantity: 1,
      bu: '',
      noStock: false,
      enforcedPrice: true,
    };
    dispatch(addProduct(productToAdd));
    onFinish();
  };

  return (
    <Form
      form={form}
      name="add-product-form"
      layout="horizontal"
      onFinish={onSubmit}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      labelAlign="left"
    >
      <Divider />
      <Form.Item
        name="requestedName"
        required
        label={t('modals.addProductModalRequestedProduct')}
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <Input size="large" className={styles.formItem} />
      </Form.Item>

      <Form.Item name="suggestedName" label={t('modals.addProductModalSuggestedProduct')}>
        <Input size="large" className={styles.formItem} />
      </Form.Item>

      <Form.Item
        name="sku"
        required
        label={t('common.sku')}
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <Input size="large" className={styles.formItem} />
      </Form.Item>

      <Form.Item name="conditioning" label={t('common.conditioning')}>
        <Input size="large" className={styles.formItem} />
      </Form.Item>

      <Form.Item
        name="conditioningQuantity"
        label={t('common.conditioningQuantity')}
        required
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <InputNumber className={styles.formItem} size="large" decimalSeparator="," precision={2} min={0} />
      </Form.Item>

      <Form.Item
        name="unitPrice"
        label={t('common.unitPrice')}
        required
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <InputNumber
          className={styles.formItem}
          addonAfter="€"
          size="large"
          decimalSeparator=","
          precision={2}
          min={0}
        />
      </Form.Item>

      <Form.Item
        name="unit"
        required
        label={t('common.unit')}
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <Select
          className={styles.formItem}
          size="large"
          showSearch
          fieldNames={{
            label: 'singular',
            value: 'id',
          }}
          options={units}
          loading={loading}
        />
      </Form.Item>

      <Form.Item
        name="supplier"
        required
        label={t('common.supplierName')}
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <Select
          className={styles.formItem}
          size="large"
          showSearch
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          options={suppliers}
        />
      </Form.Item>

      <Form.Item
        name="supplierReference"
        required
        label={t('common.supplierReference')}
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <Input size="large" className={styles.formItem} />
      </Form.Item>

      <Form.Item
        name="supplierPrice"
        label={t('common.buyingPrice')}
        required
        rules={[{ required: true, message: t('common.requiredField').toString() }]}
      >
        <InputNumber
          className={styles.formItem}
          addonAfter="€"
          size="large"
          decimalSeparator=","
          precision={2}
          min={0}
        />
      </Form.Item>

      <Form.Item name="comment" label={t('common.comment')}>
        <Input.TextArea rows={4} className={styles.formItem} />
      </Form.Item>
      <Space direction="horizontal">
        <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
          {t('common.confirm')}
        </Button>
        <Button type="dashed" icon={<CloseOutlined />} onClick={onFinish}>
          {t('common.cancel')}
        </Button>
      </Space>
    </Form>
  );
};
