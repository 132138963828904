import { ErrorType } from 'types';

export const setStatusCodeError = (error: number): ErrorType => {
  switch (error) {
    case 400:
      return {
        code: 14,
        name: 'bad-request',
        message: '(400) Mauvaise demande',
      };
    case 401:
      return {
        code: 15,
        name: 'unauthorized',
        message: '(401) Non autorisé',
      };
    case 404:
      return {
        code: 16,
        name: 'not-found',
        message: '(404) Pas trouvé',
      };
    default:
      return {
        code: 17,
        name: 'server-error',
        message: 'Erreur du serveur',
      };
  }
};
