import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import PercentageOutlined from '@ant-design/icons/lib/icons/PercentageOutlined';
import { Popover } from '../index';
import Price from '../Price/Price';
import Button from '../Button';
import { IDiscount } from '../../../types/offer';
import styles from './DiscountTooltip.module.scss';

type UnitType = {
  singular: string;
  plural: string;
};

type DiscountToolTipProps = {
  discounts?: Array<IDiscount>;
  label?: UnitType;
};

const DiscountTooltip: FC<DiscountToolTipProps> = memo((props: DiscountToolTipProps) => {
  const { discounts } = props;
  const { t } = useTranslation();
  const columns: Array<TableColumnDefinition<IDiscount>> = useMemo(
    () => [
      {
        title: t('modals.discountPopoverStartDate'),
        dataIndex: 'StartDate',
        key: 'StartDate',
        render: (StartDate: string | number | Date) =>
          StartDate ? new Date(StartDate).toLocaleDateString() : t('modals.discountPopoverUndefined'),
      },
      {
        title: t('modals.discountPopoverEndDate'),
        dataIndex: 'EndDate',
        key: 'EndDate',
        render: (EndDate: string | number | Date) =>
          EndDate ? new Date(EndDate).toLocaleDateString() : t('modals.discountPopoverUndefined'),
      },
      {
        title: t('modals.discountPopoverDiscount'),
        dataIndex: undefined,
        key: 'Value',
        align: 'center',
        render: (record: IDiscount) => {
          if (record.Type > 0) {
            return <Price unitPrice={record.Value} />;
          } else {
            return <Price unitPrice={record.Value} type={'PERCENTAGE'} />;
          }
        },
      },
      {
        title: t('modals.discountPopoverMinimumOrder'),
        dataIndex: 'MinimumQuantity',
        key: 'MinimumQuantity',
        align: 'center',
        render: (MinimumQuantity: number) => {
          return (
            <span>
              {MinimumQuantity} {t('uvc')}
            </span>
          );
        },
      },
    ],
    [t],
  );
  const content = useMemo(
    () => <Table columns={columns as ColumnsType<IDiscount>} dataSource={discounts} pagination={false} />,
    [columns, discounts],
  );
  const title = useMemo(() => <b>{t('modals.discountPopoverTitle')}</b>, [t]);
  return (
    <Popover content={content} title={title} placement={'left'}>
      <div>
        <Button
          type={'dashed'}
          shape={'circle'}
          icon={<PercentageOutlined />}
          size={'small'}
          className={styles.button}
        />
      </div>
    </Popover>
  );
});

export default DiscountTooltip;
