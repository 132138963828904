import React from 'react';
import env from 'config';
import { UserGuideProps } from './UserGuide.types';
import styles from './UserGuide.module.scss';

const UserGuide: React.FC<UserGuideProps> = () => {
  return (
    <div className={styles.wrapper}>
      <iframe className={styles.iframe} src={env('userGuide')} />
    </div>
  );
};

export default UserGuide;
