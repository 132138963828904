import { IPointListDailyOpening } from 'types/shippingFees';
import { FC, useState } from 'react';
import moment from 'moment';
import { Text } from 'components/shared';
import { Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { IDatesDropdownProps } from './types';
import styles from './styles.module.scss';

const DatesDropdown: FC<IDatesDropdownProps> = (props: IDatesDropdownProps) => {
  const { closingDays, openingDays } = props;
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const hasOpenDays = !!openingDays?.length;

  const getDaysSchedule = (day: IPointListDailyOpening): string => {
    const { HorairesAsString, periodOpening } = day;
    if (HorairesAsString) {
      return HorairesAsString;
    }
    if (periodOpening) {
      let schedule = '';
      periodOpening.forEach((period) => {
        if (period.start) {
          schedule += period.start + ' ';
        }
        if (period.end) {
          schedule += '-' + period.end + ' ';
        }
      });
      return schedule;
    }
    return '';
  };

  const parsedOpeningDays = Array.from({ length: 7 }, (_, dayIndex) => {
    if (openingDays) {
      const dayNumber = dayIndex + 1;
      const openDay = openingDays.filter((day) => day.Day === dayNumber);
      const label = moment().day(dayNumber).format('ddd');
      return (
        <Text key={dayNumber} type="secondary" className={styles.days}>
          <span className={styles.days__label}>{label}</span>
          {openDay.length && openDay
            ? openDay.map((day, index) => {
                return (
                  <span className={styles.days__day} key={index}>
                    {getDaysSchedule(day)}
                  </span>
                );
              })
            : ' fermé'}
        </Text>
      );
    }
  });

  return (
    <div className={styles.dropdown}>
      <Button
        onClick={() => setIsExpand(hasOpenDays && !isExpand)}
        className={styles.dropdown__button}
        icon={hasOpenDays && <UpOutlined rotate={isExpand ? 180 : 0} />}
      >
        {hasOpenDays ? 'Afficher les horaires' : 'Fermé'}
      </Button>
      {isExpand && (
        <div className={styles.dropdown__content}>
          <div className={styles.dates}>{parsedOpeningDays}</div>
          {closingDays && (
            <div className={styles.closing}>
              <Text type="secondary-thin" className={styles.closing__title}>
                Jours non ouvrables:
              </Text>
              {closingDays.map((date, index) => {
                return (
                  <div className={styles.closing__dates} key={index}>
                    <Text className={styles.period} type="secondary">
                      <span>A partir du: </span>
                      {moment(date.beginning).format('DD MMMM')}
                    </Text>
                    <Text className={styles.period} type="secondary">
                      <span>{`Jusqu'au: `}</span>
                      {moment(date.ending).format('DD MMMM')}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DatesDropdown;
