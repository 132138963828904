import { LocaleDateFormat } from 'types';

const list = {
  'sq-AL': {
    countryCode: 'AL',
    country: 'Albania',
    languageCode: 'sq',
    language: 'Albanian',
    locale: 'sq-AL',
    dateFormat: 'YYYY-MM-DD',
  },
  'ar-AE': {
    countryCode: 'AE',
    country: 'United Arab Emirates',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-AE',
    dateFormat: 'DD/MM/YYYY',
  },
  'es-AR': {
    countryCode: 'AR',
    country: 'Argentina',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-AR',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-AU': {
    countryCode: 'AU',
    country: 'Australia',
    languageCode: 'en',
    language: 'English',
    locale: 'en-AU',
    dateFormat: 'D/MM/YYYY',
  },
  'de-AT': {
    countryCode: 'AT',
    country: 'Austria',
    languageCode: 'de',
    language: 'German',
    locale: 'de-AT',
    dateFormat: 'DD.MM.YYYY',
  },
  'fr-BE': {
    countryCode: 'BE',
    country: 'Belgium',
    languageCode: 'fr',
    language: 'French',
    locale: 'fr-BE',
    dateFormat: 'D/MM/YYYY',
  },
  'nl-BE': {
    countryCode: 'BE',
    country: 'Belgium',
    languageCode: 'nl',
    language: 'Dutch',
    locale: 'nl-BE',
    dateFormat: 'D/MM/YYYY',
  },
  'bg-BG': {
    countryCode: 'BG',
    country: 'Bulgaria',
    languageCode: 'bg',
    language: 'Bulgarian',
    locale: 'bg-BG',
    dateFormat: 'YYYY-M-d',
  },
  'ar-BH': {
    countryCode: 'BH',
    country: 'Bahrain',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-BH',
    dateFormat: 'DD/MM/YYYY',
  },
  'sr-BA': {
    countryCode: 'BA',
    country: 'Bosnia and Herzegovina',
    languageCode: 'sr',
    language: 'Serbian',
    locale: 'sr-BA',
    dateFormat: 'YYYY-MM-DD',
  },
  'be-BY': {
    countryCode: 'BY',
    country: 'Belarus',
    languageCode: 'be',
    language: 'Belarusian',
    locale: 'be-BY',
    dateFormat: 'D.M.YYYY',
  },
  'es-BO': {
    countryCode: 'BO',
    country: 'Bolivia',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-BO',
    dateFormat: 'DD-MM-YYYY',
  },
  'pt-BR': {
    countryCode: 'BR',
    country: 'Brazil',
    languageCode: 'pt',
    language: 'Portuguese',
    locale: 'pt-BR',
    dateFormat: 'DD/MM/YYYY',
  },
  'fr-CA': {
    countryCode: 'CA',
    country: 'Canada',
    languageCode: 'fr',
    language: 'French',
    locale: 'fr-CA',
    dateFormat: 'YYYY-MM-DD',
  },
  'en-CA': {
    countryCode: 'CA',
    country: 'Canada',
    languageCode: 'en',
    language: 'English',
    locale: 'en-CA',
    dateFormat: 'DD/MM/YYYY',
  },
  'de-CH': {
    countryCode: 'CH',
    country: 'Switzerland',
    languageCode: 'de',
    language: 'German',
    locale: 'de-CH',
    dateFormat: 'DD.MM.YYYY',
  },
  'fr-CH': {
    countryCode: 'CH',
    country: 'Switzerland',
    languageCode: 'fr',
    language: 'French',
    locale: 'fr-CH',
    dateFormat: 'DD.MM.YYYY',
  },
  'it-CH': {
    countryCode: 'CH',
    country: 'Switzerland',
    languageCode: 'it',
    language: 'Italian',
    locale: 'it-CH',
    dateFormat: 'DD.MM.YYYY',
  },
  'es-CL': {
    countryCode: 'CL',
    country: 'Chile',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-CL',
    dateFormat: 'DD-MM-YYYY',
  },
  'zh-CN': {
    countryCode: 'CN',
    country: 'China',
    languageCode: 'zh',
    language: 'Chinese',
    locale: 'zh-CN',
    dateFormat: 'YYYY-M-d',
  },
  'es-CO': {
    countryCode: 'CO',
    country: 'Colombia',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-CO',
    dateFormat: 'D/MM/YYYY',
  },
  'es-CR': {
    countryCode: 'CR',
    country: 'Costa Rica',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-CR',
    dateFormat: 'DD/MM/YYYY',
  },
  'el-CY': {
    countryCode: 'CY',
    country: 'Cyprus',
    languageCode: 'el',
    language: 'Greek',
    locale: 'el-CY',
    dateFormat: 'DD/MM/YYYY',
  },
  'cs-CZ': {
    countryCode: 'CZ',
    country: 'Czech Republic',
    languageCode: 'cs',
    language: 'Czech',
    locale: 'cs-CZ',
    dateFormat: 'D.M.YYYY',
  },
  'de-DE': {
    countryCode: 'DE',
    country: 'Germany',
    languageCode: 'de',
    language: 'German',
    locale: 'de-DE',
    dateFormat: 'DD.MM.YYYY',
  },
  'da-DK': {
    countryCode: 'DK',
    country: 'Denmark',
    languageCode: 'da',
    language: 'Danish',
    locale: 'da-DK',
    dateFormat: 'DD-MM-YYYY',
  },
  'es-DO': {
    countryCode: 'DO',
    country: 'Dominican Republic',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-DO',
    dateFormat: 'MM/DD/YYYY',
  },
  'ar-DZ': {
    countryCode: 'DZ',
    country: 'Algeria',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-DZ',
    dateFormat: 'DD/MM/YYYY',
  },
  'es-EC': {
    countryCode: 'EC',
    country: 'Ecuador',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-EC',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-EG': {
    countryCode: 'EG',
    country: 'Egypt',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-EG',
    dateFormat: 'DD/MM/YYYY',
  },
  'es-ES': {
    countryCode: 'ES',
    country: 'Spain',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-ES',
    dateFormat: 'D/MM/YYYY',
  },
  'ca-ES': {
    countryCode: 'ES',
    country: 'Spain',
    languageCode: 'ca',
    language: 'Catalan',
    locale: 'ca-ES',
    dateFormat: 'DD/MM/YYYY',
  },
  'et-EE': {
    countryCode: 'EE',
    country: 'Estonia',
    languageCode: 'et',
    language: 'Estonian',
    locale: 'et-EE',
    dateFormat: 'D.MM.YYYY',
  },
  'fi-FI': {
    countryCode: 'FI',
    country: 'Finland',
    languageCode: 'fi',
    language: 'Finnish',
    locale: 'fi-FI',
    dateFormat: 'D.M.YYYY',
  },
  'fr-FR': {
    countryCode: 'FR',
    country: 'France',
    languageCode: 'fr',
    language: 'French',
    locale: 'fr-FR',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-GB': {
    countryCode: 'GB',
    country: 'United Kingdom',
    languageCode: 'en',
    language: 'English',
    locale: 'en-GB',
    dateFormat: 'DD/MM/YYYY',
  },
  'el-GR': {
    countryCode: 'GR',
    country: 'Greece',
    languageCode: 'el',
    language: 'Greek',
    locale: 'el-GR',
    dateFormat: 'D/M/YYYY',
  },
  'es-GT': {
    countryCode: 'GT',
    country: 'Guatemala',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-GT',
    dateFormat: 'D/MM/YYYY',
  },
  'zh-HK': {
    countryCode: 'HK',
    country: 'Hong Kong',
    languageCode: 'zh',
    language: 'Chinese',
    locale: 'zh-HK',
    dateFormat: 'YYYY年M月d日',
  },
  'es-HN': {
    countryCode: 'HN',
    country: 'Honduras',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-HN',
    dateFormat: 'MM-DD-YYYY',
  },
  'hr-HR': {
    countryCode: 'HR',
    country: 'Croatia',
    languageCode: 'hr',
    language: 'Croatian',
    locale: 'hr-HR',
    dateFormat: 'DD.MM.YYYY.',
  },
  'hu-HU': {
    countryCode: 'HU',
    country: 'Hungary',
    languageCode: 'hu',
    language: 'Hungarian',
    locale: 'hu-HU',
    dateFormat: 'YYYY.MM.DD.',
  },
  'in-ID': {
    countryCode: 'ID',
    country: 'Indonesia',
    languageCode: 'in',
    language: 'Indonesian',
    locale: 'in-ID',
    dateFormat: 'DD/MM/YYYY',
  },
  'hi-IN': {
    countryCode: 'IN',
    country: 'India',
    languageCode: 'hi',
    language: 'Hindi',
    locale: 'hi-IN',
    dateFormat: '३/६/१२',
  },
  'en-IN': {
    countryCode: 'IN',
    country: 'India',
    languageCode: 'en',
    language: 'English',
    locale: 'en-IN',
    dateFormat: 'D/M/YYYY',
  },
  'ga-IE': {
    countryCode: 'IE',
    country: 'Ireland',
    languageCode: 'ga',
    language: 'Irish',
    locale: 'ga-IE',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-IE': {
    countryCode: 'IE',
    country: 'Ireland',
    languageCode: 'en',
    language: 'English',
    locale: 'en-IE',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-IQ': {
    countryCode: 'IQ',
    country: 'Iraq',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-IQ',
    dateFormat: 'DD/MM/YYYY',
  },
  'is-IS': {
    countryCode: 'IS',
    country: 'Iceland',
    languageCode: 'is',
    language: 'Icelandic',
    locale: 'is-IS',
    dateFormat: 'D.M.YYYY',
  },
  'iw-IL': {
    countryCode: 'IL',
    country: 'Israel',
    languageCode: 'iw',
    language: 'Hebrew',
    locale: 'iw-IL',
    dateFormat: 'DD/MM/YYYY',
  },
  'it-IT': {
    countryCode: 'IT',
    country: 'Italy',
    languageCode: 'it',
    language: 'Italian',
    locale: 'it-IT',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-JO': {
    countryCode: 'JO',
    country: 'Jordan',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-JO',
    dateFormat: 'DD/MM/YYYY',
  },
  'ja-JP': {
    countryCode: 'JP',
    country: 'Japan',
    languageCode: 'ja',
    language: 'Japanese',
    locale: 'ja-JP',
    dateFormat: 'YYYY/MM/DD',
  },
  'ko-KR': {
    countryCode: 'KR',
    country: 'South Korea',
    languageCode: 'ko',
    language: 'Korean',
    locale: 'ko-KR',
    dateFormat: 'YYYY. M. d',
  },
  'ar-KW': {
    countryCode: 'KW',
    country: 'Kuwait',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-KW',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-LB': {
    countryCode: 'LB',
    country: 'Lebanon',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-LB',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-LY': {
    countryCode: 'LY',
    country: 'Libya',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-LY',
    dateFormat: 'DD/MM/YYYY',
  },
  'lt-LT': {
    countryCode: 'LT',
    country: 'Lithuania',
    languageCode: 'lt',
    language: 'Lithuanian',
    locale: 'lt-LT',
    dateFormat: 'YYYY.M.d',
  },
  'fr-LU': {
    countryCode: 'LU',
    country: 'Luxembourg',
    languageCode: 'fr',
    language: 'French',
    locale: 'fr-LU',
    dateFormat: 'DD/MM/YYYY',
  },
  'de-LU': {
    countryCode: 'LU',
    country: 'Luxembourg',
    languageCode: 'de',
    language: 'German',
    locale: 'de-LU',
    dateFormat: 'DD.MM.YYYY',
  },
  'lv-LV': {
    countryCode: 'LV',
    country: 'Latvia',
    languageCode: 'lv',
    language: 'Latvian',
    locale: 'lv-LV',
    dateFormat: 'YYYY.D.M',
  },
  'ar-MA': {
    countryCode: 'MA',
    country: 'Morocco',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-MA',
    dateFormat: 'DD/MM/YYYY',
  },
  'es-MX': {
    countryCode: 'MX',
    country: 'Mexico',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-MX',
    dateFormat: 'D/MM/YYYY',
  },
  'mk-MK': {
    countryCode: 'MK',
    country: 'Macedonia',
    languageCode: 'mk',
    language: 'Macedonian',
    locale: 'mk-MK',
    dateFormat: 'D.M.YYYY',
  },
  'en-MT': {
    countryCode: 'MT',
    country: 'Malta',
    languageCode: 'en',
    language: 'English',
    locale: 'en-MT',
    dateFormat: 'DD/MM/YYYY',
  },
  'mt-MT': {
    countryCode: 'MT',
    country: 'Malta',
    languageCode: 'mt',
    language: 'Maltese',
    locale: 'mt-MT',
    dateFormat: 'DD/MM/YYYY',
  },
  'sr-ME': {
    countryCode: 'ME',
    country: 'Montenegro',
    languageCode: 'sr',
    language: 'Serbian',
    locale: 'sr-ME',
    dateFormat: 'D.M.YYYY.',
  },
  'ms-MY': {
    countryCode: 'MY',
    country: 'Malaysia',
    languageCode: 'ms',
    language: 'Malay',
    locale: 'ms-MY',
    dateFormat: 'DD/MM/YYYY',
  },
  'es-NI': {
    countryCode: 'NI',
    country: 'Nicaragua',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-NI',
    dateFormat: 'MM-DD-YYYY',
  },
  'nl-NL': {
    countryCode: 'NL',
    country: 'Netherlands',
    languageCode: 'nl',
    language: 'Dutch',
    locale: 'nl-NL',
    dateFormat: 'd-M-YYYY',
  },
  'no-NO': {
    countryCode: 'NO',
    country: 'Norway',
    languageCode: 'no',
    language: 'Norwegian',
    locale: 'no-NO',
    dateFormat: 'DD.MM.YYYY',
  },
  'en-NZ': {
    countryCode: 'NZ',
    country: 'New Zealand',
    languageCode: 'en',
    language: 'English',
    locale: 'en-NZ',
    dateFormat: 'D/MM/YYYY',
  },
  'ar-OM': {
    countryCode: 'OM',
    country: 'Oman',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-OM',
    dateFormat: 'DD/MM/YYYY',
  },
  'es-PA': {
    countryCode: 'PA',
    country: 'Panama',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-PA',
    dateFormat: 'MM/DD/YYYY',
  },
  'es-PE': {
    countryCode: 'PE',
    country: 'Peru',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-PE',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-PH': {
    countryCode: 'PH',
    country: 'Philippines',
    languageCode: 'en',
    language: 'English',
    locale: 'en-PH',
    dateFormat: 'M/D/YYYY',
  },
  'pl-PL': {
    countryCode: 'PL',
    country: 'Poland',
    languageCode: 'pl',
    language: 'Polish',
    locale: 'pl-PL',
    dateFormat: 'DD.MM.YYYY',
  },
  'es-PR': {
    countryCode: 'PR',
    country: 'Puerto Rico',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-PR',
    dateFormat: 'MM-DD-YYYY',
  },
  'pt-PT': {
    countryCode: 'PT',
    country: 'Portugal',
    languageCode: 'pt',
    language: 'Portuguese',
    locale: 'pt-PT',
    dateFormat: 'DD-MM-YYYY',
  },
  'es-PY': {
    countryCode: 'PY',
    country: 'Paraguay',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-PY',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-QA': {
    countryCode: 'QA',
    country: 'Qatar',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-QA',
    dateFormat: 'DD/MM/YYYY',
  },
  'ro-RO': {
    countryCode: 'RO',
    country: 'Romania',
    languageCode: 'ro',
    language: 'Romanian',
    locale: 'ro-RO',
    dateFormat: 'DD.MM.YYYY',
  },
  'ru-RU': {
    countryCode: 'RU',
    country: 'Russia',
    languageCode: 'ru',
    language: 'Russian',
    locale: 'ru-RU',
    dateFormat: 'DD.MM.YYYY',
  },
  'ar-SA': {
    countryCode: 'SA',
    country: 'Saudi Arabia',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-SA',
    dateFormat: 'DD/MM/YYYY',
  },
  'sr-CS': {
    countryCode: 'CS',
    country: 'Serbia and Montenegro',
    languageCode: 'sr',
    language: 'Serbian',
    locale: 'sr-CS',
    dateFormat: 'D.M.YYYY.',
  },
  'ar-SD': {
    countryCode: 'SD',
    country: 'Sudan',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-SD',
    dateFormat: 'DD/MM/YYYY',
  },
  'zh-SG': {
    countryCode: 'SG',
    country: 'Singapore',
    languageCode: 'zh',
    language: 'Chinese',
    locale: 'zh-SG',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-SG': {
    countryCode: 'SG',
    country: 'Singapore',
    languageCode: 'en',
    language: 'English',
    locale: 'en-SG',
    dateFormat: 'M/D/YYYY',
  },
  'es-SV': {
    countryCode: 'SV',
    country: 'El Salvador',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-SV',
    dateFormat: 'MM-DD-YYYY',
  },
  'sr-RS': {
    countryCode: 'RS',
    country: 'Serbia',
    languageCode: 'sr',
    language: 'Serbian',
    locale: 'sr-RS',
    dateFormat: 'D.M.YYYY.',
  },
  'sk-SK': {
    countryCode: 'SK',
    country: 'Slovakia',
    languageCode: 'sk',
    language: 'Slovak',
    locale: 'sk-SK',
    dateFormat: 'D.M.YYYY',
  },
  'sl-SI': {
    countryCode: 'SI',
    country: 'Slovenia',
    languageCode: 'sl',
    language: 'Slovenian',
    locale: 'sl-SI',
    dateFormat: 'D.M.YYYY',
  },
  'sv-SE': {
    countryCode: 'SE',
    country: 'Sweden',
    languageCode: 'sv',
    language: 'Swedish',
    locale: 'sv-SE',
    dateFormat: 'YYYY-MM-DD',
  },
  'ar-SY': {
    countryCode: 'SY',
    country: 'Syria',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-SY',
    dateFormat: 'DD/MM/YYYY',
  },
  'th-TH': {
    countryCode: 'TH',
    country: 'Thailand',
    languageCode: 'th',
    language: 'Thai',
    locale: 'th-TH',
    dateFormat: 'D/M/2555',
  },
  'ar-TN': {
    countryCode: 'TN',
    country: 'Tunisia',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-TN',
    dateFormat: 'DD/MM/YYYY',
  },
  'tr-TR': {
    countryCode: 'TR',
    country: 'Turkey',
    languageCode: 'tr',
    language: 'Turkish',
    locale: 'tr-TR',
    dateFormat: 'DD.MM.YYYY',
  },
  'zh-TW': {
    countryCode: 'TW',
    country: 'Taiwan',
    languageCode: 'zh',
    language: 'Chinese',
    locale: 'zh-TW',
    dateFormat: 'YYYY/M/d',
  },
  'uk-UA': {
    countryCode: 'UA',
    country: 'Ukraine',
    languageCode: 'uk',
    language: 'Ukrainian',
    locale: 'uk-UA',
    dateFormat: 'DD.MM.YYYY',
  },
  'es-UY': {
    countryCode: 'UY',
    country: 'Uruguay',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-UY',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-US': {
    countryCode: 'US',
    country: 'United States',
    languageCode: 'en',
    language: 'English',
    locale: 'en-US',
    dateFormat: 'M/D/YYYY',
  },
  'es-US': {
    countryCode: 'US',
    country: 'United States',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-US',
    dateFormat: 'M/D/YYYY',
  },
  'es-VE': {
    countryCode: 'VE',
    country: 'Venezuela',
    languageCode: 'es',
    language: 'Spanish',
    locale: 'es-VE',
    dateFormat: 'DD/MM/YYYY',
  },
  'vi-VN': {
    countryCode: 'VN',
    country: 'Vietnam',
    languageCode: 'vi',
    language: 'Vietnamese',
    locale: 'vi-VN',
    dateFormat: 'DD/MM/YYYY',
  },
  'ar-YE': {
    countryCode: 'YE',
    country: 'Yemen',
    languageCode: 'ar',
    language: 'Arabic',
    locale: 'ar-YE',
    dateFormat: 'DD/MM/YYYY',
  },
  'en-ZA': {
    countryCode: 'ZA',
    country: 'South Africa',
    languageCode: 'en',
    language: 'English',
    locale: 'en-ZA',
    dateFormat: 'YYYY/MM/DD',
  },
};

export type LocaleKey = keyof typeof list;

export const LOCALE_ISO = Object.freeze(
  Object.entries(list).reduce<Record<LocaleKey, LocaleDateFormat>>((acc, [key, item]) => {
    const { dateFormat, ...rest } = item;
    acc[key as keyof typeof list] = {
      ...rest,
      dateFormat: {
        short: dateFormat,
        long: `${dateFormat} HH:mm`,
        full: `${dateFormat} HH:mm:ss`,
      },
    };
    return acc;
  }, {} as Record<LocaleKey, LocaleDateFormat>),
);

export const DEFAULT_LOCALE = 'en-GB';
