import './App.scss';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import React, { FC } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import { selectLanguage } from '../store/settings';
import { LoggedRouteMiddleware } from '../routes/helpers';
import { Messages } from '../components/layout/Messages';
import 'moment/locale/fr';
import 'dayjs/locale/fr';

dayjs.extend(weekOfYear);
import MainLayout from '../components/layout/MainLayout';
import { ErrorComponent } from '../components/error';

const App: FC = () => {
  const selectedLanguage = useSelector(selectLanguage);
  const location = useLocation();
  moment.locale(selectedLanguage, {
    week: {
      dow: 1,
    },
  });
  dayjs.locale(selectedLanguage);
  i18n.changeLanguage(selectedLanguage);

  return (
    <MainLayout>
      <ErrorBoundary FallbackComponent={ErrorComponent} key={location.pathname}>
        <LoggedRouteMiddleware>
          <Outlet />
        </LoggedRouteMiddleware>
      </ErrorBoundary>
      <Messages />
    </MainLayout>
  );
};

export default App;
