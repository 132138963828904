import { useAppDispatch } from 'store/store';
import { selectIsClientMode, setClientMode } from 'store/products';
import { selectIsGroup } from 'store/customers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { useSettings } from 'hooks/useSettings';
import env from 'config';
import { Button } from 'components/shared';
import { CustomerInfo } from 'components/product/ProductPageHeader/components/CustomerInfo';
import { PostalCode } from 'components/product/ProductPageHeader/components';
import { Tabs, Tooltip } from 'antd';
import { Row, Col } from 'antd';
import { Space } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import type { ProductTabProps } from 'components/product/ProductPageHeader/components/ProductTab/types';
import { GroupCustomerInfo } from '../GroupCustomerInfo';
import styles from './styles.module.scss';

const ProductTab: FC<ProductTabProps> = ({ tabs, className, activePage }: ProductTabProps) => {
  const [productPage, setProductPage] = useSettings('PRODUCT_PAGE');
  const isClientMode = useSelector(selectIsClientMode);
  const dispatch = useAppDispatch();
  const isGroup = useSelector(selectIsGroup);

  const { t } = useTranslation();

  const handleTabChange = useCallback((pageName) => setProductPage(pageName), [setProductPage]);

  const handleToggleClientMode = () => {
    dispatch(setClientMode(!isClientMode));
  };

  return (
    <div className={styles.container}>
      <Row align="middle">
        <Col flex="1">
          <Tabs
            defaultActiveKey={productPage}
            activeKey={activePage}
            onChange={handleTabChange}
            className={`${styles.tab} ${className}`}
            items={tabs}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <Space direction="horizontal" className={styles.marginWrapper}>
                <div>
                  <PostalCode />
                </div>
                {!isGroup && <CustomerInfo />}
                {isGroup && <GroupCustomerInfo />}
                {env('enablePriceMargin') && (
                  <Tooltip title={t('common.customerMode')}>
                    <Button
                      type="dashed"
                      size={'middle'}
                      className={styles.clientModeToggle}
                      icon={isClientMode ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                      onClick={handleToggleClientMode}
                    />
                  </Tooltip>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProductTab;
