import { getAttachmentName } from 'utils/cart.utils';
import { downloadFile } from 'utils';
import { useAppDispatch } from 'store/store';
import { ICartItemAttachment } from 'store/cart/types';
import { deleteCartAttachment, downloadCartAttachment, removeCartAttachment } from 'store/cart';
import { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { Spin } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { LoadingOutlined, CloseCircleFilled } from '@ant-design/icons';
import styles from './CartAttachment.module.scss';

type CartAttachmentProps = {
  productIndex: number;
  attachment: ICartItemAttachment;
};

const CartAttachment: FC<CartAttachmentProps> = ({ productIndex, attachment }: CartAttachmentProps) => {
  const { id: cartAttachmentId, key, bucket } = attachment;
  const dispatch = useAppDispatch();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleDownloadAttachment = async () => {
    const response = await dispatch(downloadCartAttachment({ key, bucket }));

    if (isFulfilled(response)) {
      const body = response.payload;
      const name = getAttachmentName(key);

      downloadFile({
        body,
        name,
      });
    }
  };

  const handleDeleteAttachment = async () => {
    setIsLoadingDelete(true);
    const response = await dispatch(deleteCartAttachment({ id: cartAttachmentId }));
    if (isFulfilled(response)) {
      dispatch(removeCartAttachment({ identifier: productIndex, cartAttachmentId }));
    }
    setIsLoadingDelete(false);
  };

  const attachmentName = useMemo(() => getAttachmentName(key), [key]);

  const renderDeleteButtonIcon = useCallback(
    (): JSX.Element =>
      isLoadingDelete ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : <CloseCircleFilled />,
    [isLoadingDelete],
  );

  return (
    <div className={styles.wrapper}>
      <Button
        type="link"
        className={classNames(styles.link, { [styles.link__disabled]: isLoadingDelete })}
        onClick={handleDownloadAttachment}
        disabled={isLoadingDelete}
      >
        {attachmentName}
      </Button>
      <Button
        disabled={isLoadingDelete}
        type="text"
        className={styles.delete}
        shape="circle"
        icon={renderDeleteButtonIcon()}
        onClick={handleDeleteAttachment}
      />
    </div>
  );
};

export default CartAttachment;
