/* eslint-disable react/prop-types */
import { unwrap } from 'utils/api.utils';
import { CustomersApiService } from 'services/customersServiceApi';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { RefSelectProps, Select, Spin } from 'antd';
import styles from './SavedCartSearch.module.scss';

interface TcCodeOption {
  id: string;
  code: string;
  fullname: string;
}

interface SavedCartSearchProps {
  onChange: (value?: TcCodeOption | null) => void;
  value?: string | null;
}

export const SavedCartSearch: FunctionComponent<SavedCartSearchProps> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const inputRef = useRef<RefSelectProps>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tcCodes, setTcCodes] = useState<TcCodeOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [debounced] = useDebouncedValue(inputValue, 200);

  const getTcOptions = useCallback(async (search) => {
    if (!search) {
      setIsLoading(false);
      return;
    }

    const data = unwrap(await CustomersApiService.searchForAtcUser(search));
    setTcCodes(data.map((item) => ({ id: item.email, code: item.code, fullname: item.fullname })));
    setIsLoading(false);
  }, []);

  const onSearch = (search: string) => {
    setInputValue(search);
  };

  const onChangeCb = (val: string) => {
    const foundCustomer = tcCodes?.find((item) => item.code === val);
    onChange(foundCustomer);
    setInputValue('');

    if (inputRef?.current) {
      inputRef?.current.blur();
    }
  };

  const searchedOptions = useMemo(() => {
    return tcCodes?.map((item) => ({ value: item.code, label: `${item.fullname} - ${item.code}` }));
  }, [tcCodes]);

  useEffect(() => {
    setIsLoading(true);
    getTcOptions(debounced);
  }, [debounced, getTcOptions]);

  return (
    <>
      <Select
        notFoundContent={isLoading && debounced.length > 3 ? <Spin className={styles.loadingIcon} /> : false}
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        searchValue={inputValue}
        open={!!inputValue}
        onSearch={onSearch}
        onChange={onChangeCb}
        className={styles.input}
        size={'large'}
        value={value}
        suffixIcon={<Spin size="large" />}
        ref={inputRef}
        placeholder={t('common.searchTc')}
      >
        {searchedOptions?.map((item, index) => (
          <Select.Option key={`${index}`} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default SavedCartSearch;
