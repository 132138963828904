import { useRef } from 'react';

export const useLoading = (delay = 300): [number, (cb: () => void) => void, (cb: () => void) => void] => {
  const timeout = useRef<NodeJS.Timeout>();

  const setDelay = (cb: () => void) => {
    timeout.current = setTimeout(() => {
      cb();
    }, delay);
  };

  const clearDelay = (cb: () => void) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    cb();
  };

  return [delay, setDelay, clearDelay];
};
