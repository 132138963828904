import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types';
import { FC } from 'react';
import { Button, Result } from 'antd';
import { errorResultParser } from '../../utils';
import { getOrderPath, OrderRoute } from '../../constants/routes';

export const ErrorComponent: FC<FallbackProps> = ({ error }: FallbackProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const parsedError = errorResultParser(error);

  return (
    <Result
      className="result"
      status={parsedError.status}
      title={t(`errors.${parsedError.group}`)}
      subTitle={t(`errors.${parsedError.code}`)}
      extra={
        <Button onClick={() => navigate(getOrderPath(OrderRoute.CUSTOMER))} type="primary">
          {t('common.createNewOrder')}
        </Button>
      }
    />
  );
};
