import { Supplier } from 'types/suppliers';
import { RestServiceApi } from 'services';
import { getToken } from 'cookies';
import { config } from 'config';
import { ApiResponse } from 'apisauce';

const msAtcBackendApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class SupplierServiceApi {
  public async getSuppliers(): Promise<ApiResponse<Supplier>> {
    return msAtcBackendApi.get(
      `/suppliers`,
      {},
      {
        ...msAtcBackendApi.getAuthenticateHeader(getToken()),
      },
    );
  }
}

export const SupplierSerivce = new SupplierServiceApi();
