export const landing_en = {
  createOrderTitle_one: 'Search for your customer by their first name, last name, company name or customer number.',
  createOrderTitle_other: 'Search for your customer by their first name, last name, company name or customer number.',
  createOrder: 'Create an order',
  orderHistory: 'Order history',
  profile: 'My profile',
  savedCarts: 'Saved carts',
  userGuide: 'User guide',
  cartRequests: 'Customer quote requests',
  abandonedCarts: 'Abandoned carts',
  pageTitle: 'Create a quote',
  searchHere: 'Enter your search here...',
  notAuthorized: 'You are not authorized to create a quote',
};
