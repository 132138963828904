import { Moment } from 'moment/moment';
import moment from 'moment';
import { DisplayOfferGroup } from '../types/offer';

export const addBusinessDays = (date: Moment, days: number): Moment => {
  const d = moment(date)
    .utc(true)
    .add(Math.floor(days / 5) * 7, 'd');
  let remaining = days % 5;
  while (remaining) {
    d.add(1, 'd');
    if (d.day() !== 0 && d.day() !== 6) remaining--;
  }
  return d;
};

export const parseBool = (value: unknown): boolean => {
  if (typeof value === 'string' && ['true', 'TRUE', 'on', 'ON', '1'].includes(value)) {
    return true;
  }

  if (typeof value === 'number' && value === 1) {
    return true;
  }

  if (typeof value === 'boolean') {
    return value;
  }
  return false;
};

export const typed = <T, K>(obj: T, typename: K): T & { __typename: K } => {
  return {
    ...obj,
    __typename: typename,
  };
};

export const parseDisplayOfferGroup = (
  value: string | undefined,
  defaultValue = DisplayOfferGroup.NONE,
): DisplayOfferGroup => {
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  switch (value.toLowerCase()) {
    case 'sku':
      return DisplayOfferGroup.SKU;
    case 'departure':
      return DisplayOfferGroup.DEPARTURE;
    default:
      return defaultValue;
  }
};
export const parseNumberOrDefault = (value: number | string | undefined, defaultValue: number): number => {
  if (typeof value === 'undefined') {
    return defaultValue;
  }

  if (typeof value === 'number') {
    return !isNaN(value) ? value : defaultValue;
  }

  const newValue = parseInt(value ?? String(defaultValue));
  if (isNaN(newValue)) {
    return defaultValue;
  }
  return newValue;
};

export const generateInteger = (min: number, max: number): number => {
  return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min)) + Math.ceil(min));
};
