import {
  IHistoricalPriceDataItemYear,
  IHistoricalPriceGlobalDataItem,
  IHistoricalPricePerSkuItem,
  IHistoricalPricePerSkuResponse,
} from 'store/cart/types';

export const getHistoricalPriceDataNormalizer = (
  payload: IHistoricalPricePerSkuResponse,
): { global: IHistoricalPriceGlobalDataItem; items: IHistoricalPricePerSkuItem[] } => {
  const emptyItem = {
    averageUnitPrice: 0,
    ca: 0,
    totalQuantity: 0,
    atcCode: '',
    atcName: '',
    customerIdentifier: '',
  } as unknown as IHistoricalPriceDataItemYear;
  const historicalPricesGroup: IHistoricalPricePerSkuItem[] = [];
  let previousFiscalLength = 0;
  const global: IHistoricalPriceGlobalDataItem = {
    current_fiscal_year: {
      ca: 0,
      totalQuantity: 0,
      average_unit_price: 0,
    },
    last_fiscal_year: {
      ca: 0,
      totalQuantity: 0,
      average_unit_price: 0,
    },
  };
  payload.current_fiscal_year.forEach((item) => {
    const lastItem = payload.last_fiscal_year.find(
      (priceDataItem) =>
        priceDataItem.atcCode === item.atcCode && priceDataItem.customerIdentifier === item.customerIdentifier,
    );
    historicalPricesGroup.push({
      customerIdentifier: item.customerIdentifier,
      customerName: item.customerName,
      atcCode: item.atcCode,
      atcName: item.atcName,
      current_fiscal_year: item,
      last_fiscal_year: lastItem ?? emptyItem,
    });
    global.current_fiscal_year.ca += parseFloat(item.ca);
    global.current_fiscal_year.totalQuantity += parseFloat(item.totalQuantity);
    global.current_fiscal_year.average_unit_price += parseFloat(item.averageUnitPrice);
    if (lastItem) {
      const index = payload.last_fiscal_year.indexOf(lastItem);
      payload.last_fiscal_year.splice(index, 1);
      global.last_fiscal_year.ca += parseFloat(lastItem.ca);
      global.last_fiscal_year.totalQuantity += parseFloat(lastItem.totalQuantity);
      global.last_fiscal_year.average_unit_price += parseFloat(lastItem.averageUnitPrice);
      previousFiscalLength++;
    }
  });
  payload.last_fiscal_year.forEach((item) => {
    historicalPricesGroup.push({
      customerIdentifier: item.customerIdentifier,
      atcCode: item.atcCode,
      atcName: item.atcName,
      customerName: item.customerName,
      current_fiscal_year: emptyItem,
      last_fiscal_year: item,
    });
    global.last_fiscal_year.ca += parseFloat(item.ca);
    global.last_fiscal_year.totalQuantity += parseFloat(item.totalQuantity);
    global.last_fiscal_year.average_unit_price += parseFloat(item.averageUnitPrice);
    previousFiscalLength++;
  });

  if (payload.current_fiscal_year.length > 0) {
    global.current_fiscal_year.average_unit_price =
      global.current_fiscal_year.average_unit_price / payload.current_fiscal_year.length;
  }
  if (previousFiscalLength > 0) {
    global.last_fiscal_year.average_unit_price = global.last_fiscal_year.average_unit_price / previousFiscalLength;
  }

  return { global, items: historicalPricesGroup.slice(0, 500) };
};
