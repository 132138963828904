import { selectSelectedCustomerObject } from 'store/customers';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import CopyClipboard from 'components/shared/CopyClipboard';
import { OtherInfo, Popover, TagsList } from 'components/shared';
import { Divider } from 'antd';
import styles from './CustomerInfo.module.scss';

const CustomerInfo: React.FC = () => {
  const selectedCustomer = useSelector(selectSelectedCustomerObject);
  const customerPopupContent = useMemo(() => {
    return (
      <div>
        {selectedCustomer?.customerIdentifier}
        <br />
        {selectedCustomer?.street}, {selectedCustomer?.townLabel}, {selectedCustomer?.postcodeLabel}
        <br />
        {selectedCustomer?.phone1 || selectedCustomer?.phone2}
        <br />
        <div>
          {selectedCustomer?.email} <CopyClipboard text={selectedCustomer?.email} />
        </div>
        <Divider />
        <OtherInfo
          className={styles.reducePadding}
          restrictedPrecursor={selectedCustomer?.user?.restrictedPrecursor}
          regulatedPrecursor={selectedCustomer?.user?.regulatedPrecursor}
          certExpiryDate={selectedCustomer?.user?.certiphytoExpiredDate}
        />
        {selectedCustomer?.tags && (
          <>
            <Divider />
            <div className={styles.marginBottom}>
              <TagsList tags={selectedCustomer?.tags} />
            </div>
          </>
        )}
      </div>
    );
  }, [selectedCustomer]);

  return (
    <div className={styles.wrapper}>
      <Popover
        placement={'bottom'}
        content={customerPopupContent}
        title={selectedCustomer?.firstName + ' ' + selectedCustomer?.lastName}
      >
        {selectedCustomer?.firstName} {selectedCustomer?.lastName} ({selectedCustomer?.customerIdentifier})
      </Popover>
    </div>
  );
};

export default CustomerInfo;
