import { isEmpty } from 'utils';
import { fetchAtcUser, setLoggedUser, setUser } from 'store/user';
import { useDispatch } from 'react-redux';
import { FC, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useLogout } from 'hooks';
import { Button, FormInput, Text } from 'components/shared';
import { Form, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { passwordRule } from '../LoginForm/LoginForm.validators';
import { IResponseError } from '../LoginForm/LoginForm.types';
import { INewPasswordProps } from './NewPasswordForm.types';
import styles from './NewPasswordForm.module.scss';

const NewPasswordForm: FC<INewPasswordProps> = (props: INewPasswordProps) => {
  const [loading, setLoading] = useState(false);
  const { ChallengeName, Session, ChallengeParameters } = props.user;
  const { backToLoginPage } = props;
  const dispatch = useDispatch();
  const { loginRedirectAfterLogout } = useLogout();
  const { confirmUser } = useAuth();

  const openErrorNotification = (message: string, description: string) =>
    notification.open({
      message,
      description,
    });

  const onFinish = async ({ password }: { password: string }) => {
    setLoading(true);
    try {
      const response = await confirmUser(password, { ChallengeName, Session, ChallengeParameters });
      if (response?.User && !isEmpty(response?.AuthenticationResult)) {
        dispatch(setLoggedUser(response?.User?.email));
        dispatch(setUser(response.User));
        loginRedirectAfterLogout();
        if (response?.User?.email) {
          dispatch(
            fetchAtcUser({
              token: response?.AuthenticationResult?.IdToken,
            }),
          );
        }
      }
    } catch (error) {
      const { message, name } = error as IResponseError;
      openErrorNotification(name, message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.content}>
      <Text type="title">Nouveau mot de passe requis</Text>
      <Form name="login" className={styles.form} onFinish={onFinish} layout="vertical">
        <FormInput
          name="password"
          rules={passwordRule}
          label="Nouveau mot de passe"
          inputProps={{
            placeholder: 'Renseignez votre nouveau mot de passe',
            prefix: <LockOutlined />,
            color: '#00dea2',
            type: 'password',
          }}
        />
        <div className={styles.actions}>
          <Text type="subtitle-primary" onClick={backToLoginPage} className={styles.cancelButton}>
            {`Retournez à l'étape d'identification`}
          </Text>
          <Button type="primary" htmlType="submit" loading={loading} className={styles.button}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default NewPasswordForm;
