import { useAppSelector } from 'store/store';
import { selectCartProductByIndex } from 'store/cart';
import { FunctionComponent, useState } from 'react';
import CartAttachmentUpload from 'components/product/CartTable/components/CartRowHeader/components/CartAttachmentUpload';
import ADButton from 'antd/lib/button';
import { Badge, Popover } from 'antd';

import { FileAddOutlined, FileOutlined } from '@ant-design/icons';
import CartAttachmentList from './CartAttachmentList';

interface CartAttachmentProps {
  productIndex: number;
}
export const CartAttachment: FunctionComponent<CartAttachmentProps> = ({ productIndex }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const product = useAppSelector(selectCartProductByIndex(productIndex));
  const cartHasAttachments = product.attachments && product.attachments.length > 0;

  if (!cartHasAttachments) {
    return <CartAttachmentUpload productIndex={productIndex} product={product} />;
  }

  return (
    <>
      <Badge count={product?.attachments?.length}>
        <Popover
          content={<CartAttachmentList productIndex={productIndex} />}
          popupVisible={popupVisible}
          trigger={'click'}
          placement="left"
        >
          <ADButton
            icon={cartHasAttachments ? <FileAddOutlined /> : <FileOutlined />}
            type="text"
            shape="circle"
            onClick={() => setPopupVisible(true)}
          />
        </Popover>
      </Badge>
    </>
  );
};
export default CartAttachment;
