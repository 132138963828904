import { FC, useCallback } from 'react';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';
import ADButton from 'antd/lib/button';
import styles from './Button.module.scss';
export type ButtonProps = Omit<AntdButtonProps, 'type'> & {
  type?: AntdButtonProps['type'] | 'secondary';
};

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const { type = 'primary', size = 'middle', className, disabled, children, ...remainingProps } = props;

  const getStylesByType = () => {
    switch (type) {
      case 'primary':
        return styles.button_primary;
      case 'secondary':
        return styles.button_secondary;
      case 'dashed':
        return styles.button_dashed;
      case 'default':
        return styles.button_default;
    }
    return '';
  };

  const getStylesBySize = () => {
    if (size === 'large') return styles.button_large;
    return '';
  };

  const buttonRef = useCallback((node) => {
    if (node !== null) {
      node.classList.remove('ant-btn-default');
    }
  }, []);

  return (
    <ADButton
      ref={buttonRef}
      size={size}
      disabled={disabled}
      {...remainingProps}
      className={`${styles.button} ${getStylesByType()} ${getStylesBySize()} ${
        disabled ? styles.button_disabled : ''
      } ${className}`}
    >
      {children}
    </ADButton>
  );
};

export default Button;
