import { getDeliveryDate, isPriceEditPreventedByOfferType, quantityParser, showDiscount } from 'utils';
import { selectShippingCategoriesById } from 'store/delivery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Price from 'components/shared/Price/Price';
import { InitialPrice, Text } from 'components/shared';
import { IProductInfoProps } from './types';
import styles from './styles.module.scss';

const ProductInfo: FC<IProductInfoProps> = (props: IProductInfoProps) => {
  const {
    product: {
      name,
      price,
      days,
      startDate,
      endDate,
      isTruck,
      conditioning,
      quantityIncrement,
      unit,
      totalPrice,
      cvo = 0,
      quantity,
      productOptions,
      offerPrice,
      offerType,
      discountValue,
      discountType,
    },
    category,
  } = props;
  const { t } = useTranslation();
  const shippingCategory = useSelector(selectShippingCategoriesById(category.shippingMethodId));

  return (
    <div className={styles.info}>
      <Text type="subtitle-third" className={styles.field__name}>
        {name}
      </Text>
      <Text type="subtitle-thin" className={'option'}>
        {productOptions}
      </Text>
      <Text type="subtitle-gray8" className={styles.field__delivery}>
        {getDeliveryDate({
          days,
          startDate,
          endDate,
          delay: { min: shippingCategory.minDelay, max: shippingCategory.maxDelay },
        })}
      </Text>
      {!!isTruck && <Text type="secondary">Camion : {conditioning}</Text>}
      <Text type="subtitle-gray1" className={styles.field}>
        {t('common.unitPrice')} : <InitialPrice {...price} unit={`${unit.singular} HT`} />
      </Text>
      {!isPriceEditPreventedByOfferType(offerType) && (
        <Text type="subtitle-gray1" className={styles.field}>
          {t('common.forcedNetPrice')} :{' '}
          {<Price unitPrice={offerPrice} className={styles.unitPrice} unit={`${unit.singular} HT`} />}{' '}
          {showDiscount(discountValue, discountType)}
        </Text>
      )}
      <Text type="subtitle-gray1" className={styles.field}>
        {t('common.quantity')}: {quantity}
        <br />
        {t('common.volume')}: {quantityParser(quantity, quantityIncrement)}{' '}
        {quantityIncrement * quantity >= 2 ? unit.plural : unit.singular} <br />
        {conditioning && (
          <Text>
            {t('common.conditioning')}: {conditioning}
          </Text>
        )}
      </Text>
      <Text type="subtitle-gray1" className={`${styles.field} ${styles.total}`}>
        {t('common.total')} : <Price unitPrice={totalPrice - cvo} className={styles.totalPrice} />
      </Text>
    </div>
  );
};
export default ProductInfo;
