import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Text } from 'components/shared';
import { usePermissions } from '../../hooks';
import OrdersHistoryTable from '../../components/ordersHistory';
import styles from './OrderHistory.module.scss';

const ALLOWED_PERMISSIONS = ['ORDER_LIST'];
const OrderHistory: FC = () => {
  const { allowed } = usePermissions(ALLOWED_PERMISSIONS, false, false);
  const { t } = useTranslation();

  return (
    <div className={styles.orderHistory}>
      {!allowed && <Text>{t('order_history.notAllowedOrderList')}</Text>}
      {allowed && <OrdersHistoryTable />}
    </div>
  );
};

export default OrderHistory;
