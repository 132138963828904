import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useStepper } from 'hooks';
import { Layout, Steps } from 'antd';
import { getOrderPath } from '../../../constants/routes';
import env from '../../../config';
import { IStep, STEPPER } from 'constants/stepper';
import { IStepperProps } from './Stepper.types';
import styles from './Stepper.module.scss';

const { Step } = Steps;

const Stepper: FC<IStepperProps> = (props: IStepperProps) => {
  const { FIRST, SECOND, THIRD, FOURTH } = STEPPER;
  const { currentStep } = useStepper();
  const { cartId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isFirstStepActive = currentStep?.index !== FIRST.index;
  const isSecondStepActive = (currentStep && [THIRD.index, FOURTH.index].includes(currentStep.index)) || false;
  const isThirdStepActive = currentStep?.index === FOURTH.index;

  const getStepActiveProps = (step: IStep, isActive = false) =>
    isActive
      ? {
          className: styles.step_active,
          onClick: () => navigate(getOrderPath(step.route, step !== FIRST ? cartId : undefined)),
        }
      : {};

  return (
    <Layout.Footer className={`${props.footerClassName} ${styles.footer}`}>
      <Steps className={props.className} current={currentStep?.index}>
        <Step
          {...getStepActiveProps(FIRST, isFirstStepActive)}
          title={t('common.farmer', { count: 2, context: env('theme') })}
        />
        <Step {...getStepActiveProps(SECOND, isSecondStepActive)} title={t('common.catalog')} />
        <Step {...getStepActiveProps(THIRD, isThirdStepActive)} title={t('common.shipping')} />
        <Step title={t('common.validation')} />
      </Steps>
    </Layout.Footer>
  );
};

export default Stepper;
