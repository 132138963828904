import { unwrap, validate } from 'utils/api.utils';
import { IAlgoliaProduct } from 'types/product';
import { IFetchProductsResponse } from 'store/products/types';
import { ProductsService } from 'services/products.service';
import { ShippingApiService } from 'services';
import { uniq } from 'lodash';
import env from 'config';
import { IShippingMethodResponse } from '../../../types/shippingFees';
import { IOfferResponse } from '../../../types/offer';
import { ERRORS } from 'constants/errors';

interface FetchProductAdapterProps {
  search: string;
  page: number;
  filter?: string | false | null;
  townId?: number;
}

export const fetchProductStateAdapter = async ({
  search,
  filter,
  page,
}: FetchProductAdapterProps): Promise<IAlgoliaProduct[]> => {
  const { hits: products } = await ProductsService.search(search, filter, page);
  const productSize = env('algoliaProductsLimit') ?? 15;

  const productIds = new Set(products.map((prod) => prod.productId));
  if (productIds.size <= productSize) {
    return products;
  } else {
    const productIdsLimit = Array.from(productIds.values()).splice(0, productSize);
    return products.filter((prod) => productIdsLimit.includes(prod.productId));
  }
};

interface FetchProductSavedCartAdapterProps {
  productIds: number[];
  variantIds: number[];
  page?: number;
  townId?: number;
}

export const fetchProductsSavedCartAdapter = async ({
  productIds,
  variantIds,
  page,
}: FetchProductSavedCartAdapterProps): Promise<IAlgoliaProduct[]> => {
  // algolia limit for hits per page is 1000
  if (productIds.length === 0) {
    return [];
  }
  const { hits: products } = await ProductsService.filterByProductId(productIds, variantIds, page, 1000);
  return products;
};

interface FetchProductWiuzAdapterProps {
  productIds: number[];
  page?: number;
  townId?: number;
}

export const fetchProductsWiuzAdapter = async ({
  productIds,
  page,
}: FetchProductWiuzAdapterProps): Promise<IAlgoliaProduct[]> => {
  if (productIds.length === 0) {
    return [];
  }
  const { hits: products } = await ProductsService.filterByProductId(productIds, null, page);
  return products;
};

export const fetchProductsAdapter = async (
  productsAdapter: Promise<IAlgoliaProduct[]>,
  postcode?: string,
  tags?: Array<string>,
  allOffers?: boolean,
  clubMemberExpDate?: Date | null,
  excludeNoStockOffers?: boolean,
): Promise<IFetchProductsResponse> => {
  const products = await productsAdapter;
  const searchedVariantsIds = products.map((item) => item.variantId);

  const { eqProductIds, subsProductIds } = products.reduce<{ eqProductIds: number[]; subsProductIds: number[] }>(
    (acc, item) => {
      const eqIds = item.equivalentProducts?.map((curr) => curr.productId) || [];
      acc.eqProductIds = acc.eqProductIds.concat(eqIds);

      const subsIds = item.substitutionProducts?.map((curr) => curr.productId) || [];
      acc.subsProductIds = acc.subsProductIds.concat(subsIds);
      return acc;
    },
    { eqProductIds: [], subsProductIds: [] },
  );

  const fetchedSubstitutesAndEquivalentsProductsIds = [...subsProductIds, ...eqProductIds].filter(
    (v, i, a) => a.indexOf(v) === i,
  );

  let extraProducts: Array<IAlgoliaProduct> = [];
  if (fetchedSubstitutesAndEquivalentsProductsIds.length > 0) {
    ({ hits: extraProducts } = await ProductsService.filterByProductId(fetchedSubstitutesAndEquivalentsProductsIds));
  }

  validate(products, fetchProductsSearchValidator);

  const { noTownIdProducts, withTownIdProducts } = fetchProductsSearchNormalizer([...products, ...extraProducts]);

  const offers = unwrap(
    await ProductsService.getBestOffers({
      products: [
        ...(withTownIdProducts || []).map(({ productId }) => productId),
        ...(noTownIdProducts || []).map(({ productId }) => productId),
      ],
      postcode,
      tags,
      allOffers,
      clubMemberExpDate,
      excludeNoStockOffers,
    }),
  ) as IOfferResponse;
  const offerIds: Array<number> = [];
  offers?.Product?.forEach((p) => {
    p.Variants.forEach((v) => {
      v.OfferPrice.forEach((op) => {
        offerIds.push(op.OfferId);
      });
    });
  });

  const shippingMethods = unwrap(await ShippingApiService.getShippingMethods(uniq<number>(offerIds))) as unknown as
    | Array<IShippingMethodResponse>
    | undefined;

  return {
    lastSearchReturnedNoResult: false,
    products: [...products, ...extraProducts],
    offers,
    searchedVariantsIds,
    shippingMethods,
  };
};

export const fetchRestoredProductsAdapter = async (
  productsAdapter: Promise<IAlgoliaProduct[]>,
  postcode?: string,
  tags?: Array<string>,
  allOffers?: boolean,
  clubMemberExpDate?: Date | null,
): Promise<IFetchProductsResponse> => {
  const products = await productsAdapter;

  const searchedVariantsIds = products.map((item) => item.variantId);

  const { noTownIdProducts, withTownIdProducts } = fetchProductsSearchNormalizer(products);

  const offers = unwrap(
    await ProductsService.getBestOffers({
      products: [
        ...(withTownIdProducts || []).map(({ productId }) => productId),
        ...(noTownIdProducts || []).map(({ productId }) => productId),
      ],
      postcode,
      tags,
      allOffers,
      clubMemberExpDate,
    }),
  ) as IOfferResponse;

  const offerIds: Array<number> = [];
  offers?.Product?.forEach((p) => {
    p.Variants.forEach((v) => {
      v.OfferPrice.forEach((op) => {
        offerIds.push(op.OfferId);
      });
    });
  });

  const shippingMethods = unwrap(await ShippingApiService.getShippingMethods(uniq<number>(offerIds))) as unknown as
    | Array<IShippingMethodResponse>
    | undefined;

  return {
    lastSearchReturnedNoResult: false,
    products: products,
    offers,
    searchedVariantsIds,
    shippingMethods,
  };
};

export const fetchProductReplacementsAdapter = async (
  productsAdapter: Promise<IAlgoliaProduct[]>,
  postcode?: string,
  tags?: Array<string>,
  allOffers?: boolean,
  clubMemberExpDate?: Date | null,
  excludeNoStockOffers = false,
): Promise<IFetchProductsResponse> => {
  const products = await productsAdapter;
  const searchedVariantsIds = Array.from(new Set(products.map((item) => item.variantId)));

  const { eqProductIds, subsProductIds } = products.reduce<{ eqProductIds: number[]; subsProductIds: number[] }>(
    (acc, item) => {
      const eqIds = item.equivalentProducts?.map((curr) => curr.productId) || [];
      acc.eqProductIds = acc.eqProductIds.concat(eqIds);

      const subsIds = item.substitutionProducts?.map((curr) => curr.productId) || [];
      acc.subsProductIds = acc.subsProductIds.concat(subsIds);
      return acc;
    },
    { eqProductIds: [], subsProductIds: [] },
  );

  const fetchedSubstitutesAndEquivalentsProductsIds = [...subsProductIds, ...eqProductIds].filter(
    (v, i, a) => a.indexOf(v) === i,
  );

  let extraProducts: Array<IAlgoliaProduct> = [];
  if (fetchedSubstitutesAndEquivalentsProductsIds.length > 0) {
    ({ hits: extraProducts } = await ProductsService.filterByProductId(
      fetchedSubstitutesAndEquivalentsProductsIds,
      null,
      0,
      1000,
    ));
  }

  validate(products, fetchProductsSearchValidator);

  const { noTownIdProducts, withTownIdProducts } = fetchProductsSearchNormalizer([...products, ...extraProducts]);
  const offers = unwrap(
    await ProductsService.getBestOffers({
      products: [
        ...(withTownIdProducts || []).map(({ productId }) => productId),
        ...(noTownIdProducts || []).map(({ productId }) => productId),
      ],
      postcode,
      tags,
      allOffers,
      clubMemberExpDate,
      excludeNoStockOffers,
    }),
  ) as IOfferResponse;

  const offerIds: Array<number> = [];
  offers?.Product?.forEach((p) => {
    p.Variants.forEach((v) => {
      v.OfferPrice.forEach((op) => {
        offerIds.push(op.OfferId);
      });
    });
  });

  const shippingMethods = unwrap(await ShippingApiService.getShippingMethods(uniq<number>(offerIds))) as unknown as
    | Array<IShippingMethodResponse>
    | undefined;

  return {
    lastSearchReturnedNoResult: false,
    products: [...products, ...extraProducts],
    offers,
    searchedVariantsIds,
    shippingMethods,
  };
};

const fetchProductsSearchValidator = (products: IAlgoliaProduct[]) => {
  if (products.length === 0) {
    return ERRORS.products.noProducts;
  }
};

type FetchProductsNormalizer = {
  noTownIdProducts: Array<IAlgoliaProduct>;
  withTownIdProducts: Array<IAlgoliaProduct>;
};

const fetchProductsSearchNormalizer = (products: IAlgoliaProduct[]): FetchProductsNormalizer => {
  const { noTownIdProducts, withTownIdProducts } = products.reduce<{
    noTownIdProducts: Array<IAlgoliaProduct>;
    withTownIdProducts: Array<IAlgoliaProduct>;
  }>(
    (accumulator, item) => {
      if (!item.isTownRequired) {
        return {
          ...accumulator,
          noTownIdProducts: [...accumulator.noTownIdProducts, item],
        };
      } else {
        return {
          ...accumulator,
          withTownIdProducts: [...accumulator.withTownIdProducts, item],
        };
      }
    },
    { noTownIdProducts: [], withTownIdProducts: [] },
  );
  return { noTownIdProducts, withTownIdProducts };
};
