import { selectUserPermissions } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store/store';
import { sendMessage } from 'store/messages';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';

type UsePermissionsReturnType = {
  allowed: boolean;
  redirect: () => void;
  sendNotice: (message?: string) => void;
};

export const usePermissions = (
  allowedCapabilities: string[],
  redirect = true,
  notice = true,
): UsePermissionsReturnType => {
  const isInitialMount = useRef(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPermissions = useAppSelector(selectUserPermissions);
  const dispatch = useAppDispatch();

  const allowed = useMemo(() => {
    return allowedCapabilities.some((capability) => currentPermissions.includes(capability));
  }, [allowedCapabilities, currentPermissions]);

  const redirectCb = useCallback(() => {
    navigate('/unauthorized');
  }, [navigate]);

  const sendNoticeCb = useCallback(
    (message?: string) => {
      dispatch(sendMessage({ message: message || t('errors.notAuthorized'), type: 'error' }));
    },
    [dispatch, t],
  );

  useEffect(() => {
    if (!isInitialMount.current) {
      return;
    }

    const hasPermission = allowedCapabilities.some((capability) => currentPermissions.includes(capability));

    if (!hasPermission && notice) {
      sendNoticeCb();
    }
    if (!hasPermission && redirect) {
      redirectCb();
    }

    isInitialMount.current = false;
  }, [allowedCapabilities, currentPermissions, notice, redirect, redirectCb, sendNoticeCb]);

  return { allowed, redirect: redirectCb, sendNotice: sendNoticeCb };
};
