export const forms_en = {
  firstname: 'Firstname',
  lastname: 'Lastname',
  createEmail: "Client's email",
  chooseActivity: 'Activity',
  createPassword: 'Create a random password',
  productSearchPlaceholder: 'Name or product ID',
  customerNameOrNumber: 'Customer name or number',
  gender: 'Gender',
  name: 'Name',
  surname: 'Surname',
  companyName: 'Company name',
  address: 'Address',
  postalCode: 'Postal code',
  country: 'Country',
  phoneNumber: 'Phone number',
  postalCodePlaceholder: 'Insert postal code',
  chooseDeliveryMethodPlaceholder: 'Choose delivery method',
};
