/**
 * Returns a string with first letter of the given string capitalized
 * @param text
 */
export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const nullStringToMinus = (text: string | null | undefined): string => {
  return text ? text : '-';
};
