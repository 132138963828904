import { ITown } from 'types';
import { PostcodeApiService } from 'services/postcodeServiceApi';
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';
import { Button, FormInput } from 'components/shared';
import { Form, Input, Radio, Select, Spin } from 'antd';
import { phoneNumberValidator, requiredFieldRule } from 'constants/validators';
import { countries, genderVariants, phonePrefixes } from 'constants/forms/selectVariants';
import { IHomeAddressFrom, IHomeAddressFromProps } from './types';
import styles from './styles.module.scss';

const HomeAddressFrom: FC<IHomeAddressFromProps> = (props: IHomeAddressFromProps) => {
  const { onSubmit, onCloseForm } = props;
  const [postcode, setPostcode] = useState('');
  const [searchedTowns, setSearchedTowns] = useState<Array<ITown>>();
  const [isLoading, setIsLoading] = useState(false);
  const [prefixPhone1, setPrefixPhone1] = useState(phonePrefixes[0].value);
  const [prefixPhone2, setPrefixPhone2] = useState(phonePrefixes[0].value);
  const { t } = useTranslation();

  const debouncedSearch = useDebounce(postcode);

  useEffect(() => {
    const { searchTowns } = PostcodeApiService;
    if (debouncedSearch.length > 3) {
      setIsLoading(true);
      searchTowns(debouncedSearch)
        .then((response) => {
          setSearchedTowns(response.data);
        })
        .catch(() => {
          setSearchedTowns([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [debouncedSearch]);

  const parseSubmitData = (values: IHomeAddressFrom) => {
    values.phone1 = prefixPhone1 + values.phone1;
    if (values.phone2) {
      values.phone2 = prefixPhone2 + values.phone2;
    }
    onSubmit(values);
  };

  const onSearch = (search: string) => {
    setPostcode(search);
  };

  const prefixSelectorPhone = (phone1: boolean) => (
    <Form.Item name={`prefix${phone1}`} noStyle>
      <Select
        defaultValue={phone1 ? prefixPhone1 : prefixPhone2}
        onChange={(prefix) => {
          phone1 ? setPrefixPhone1(prefix) : setPrefixPhone2(prefix);
        }}
      >
        {phonePrefixes.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Form onFinish={parseSubmitData} layout="vertical" className={styles.form} autoComplete="off">
      <Form.Item
        className={styles.input}
        label={`${t('forms.gender')}`}
        name="gender"
        rules={[requiredFieldRule]}
        initialValue={genderVariants[0].id}
      >
        <Radio.Group value={genderVariants[0].id} defaultValue={genderVariants[0].id}>
          {genderVariants.map((gender) => (
            <Radio key={gender.id} value={gender.id} className={styles.radio}>
              {gender.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <FormInput
        className={styles.form__item}
        label={`${t('forms.name')}`}
        name="firstName"
        inputClassName={styles.input}
        rules={[requiredFieldRule]}
      />
      <FormInput
        className={styles.form__item}
        label={`${t('forms.surname')}`}
        name="lastName"
        inputClassName={styles.input}
        rules={[requiredFieldRule]}
      />
      <FormInput
        className={styles.form__item}
        label={t('forms.companyName')}
        name="company"
        inputClassName={styles.input}
      />
      <FormInput
        className={styles.form__item}
        label={`${t('forms.address')}`}
        name="street"
        inputClassName={styles.input}
        rules={[requiredFieldRule]}
      />
      <Form.Item className={styles.input} label={`${t('forms.postalCode')}`} name="town" rules={[requiredFieldRule]}>
        <Select
          placeholder={`${t('forms.postalCodePlaceholder')}`}
          optionFilterProp="children"
          size="large"
          notFoundContent={isLoading ? <Spin className={styles.loadingIcon} /> : false}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={onSearch}
          className={styles.input}
          suffixIcon={<Spin size="large" />}
        >
          {searchedTowns?.map((option) => (
            <Select.Option key={option.id} value={JSON.stringify(option)}>
              {option.town}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.input}
        label={`${t('forms.country')}`}
        name="countryCode"
        rules={[requiredFieldRule]}
        initialValue={'FR'}
      >
        <Select placeholder="Faites votre choix" optionFilterProp="children" size="large" defaultValue={'FR'}>
          {countries.map((option) => (
            <Select.Option key={option.code} value={option.code}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        className={styles.form__item}
        label={`${t('forms.phoneNumber')} 1`}
        name="phone1"
        rules={[requiredFieldRule, phoneNumberValidator]}
      >
        <Input className={styles.input} addonBefore={prefixSelectorPhone(true)} size="large" />
      </Form.Item>
      <Form.Item
        className={styles.form__item}
        label={`${t('forms.phoneNumber')} 2`}
        name="phone2"
        rules={[phoneNumberValidator]}
      >
        <Input className={styles.input} addonBefore={prefixSelectorPhone(false)} size="large" />
      </Form.Item>
      <Form.Item name={'comment'} label={`${t('common.comment')}`}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item>
        <Button className={styles.button} type="primary" htmlType="submit">
          {t('modals.createNewAddress')}
        </Button>
        <Button className={styles.button} type="dashed" onClick={onCloseForm}>
          {t('common.cancel')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default HomeAddressFrom;
