export const date_en = {
  day: 'day',
  days: 'days',
  hour: 'hour',
  hours: 'hours',
  minute: 'minute',
  minutes: 'minutes',
  second: 'second',
  seconds: 'seconds',
  in: 'in',
  ago: 'ago',
  and: 'and',
};
