import { IParsedProductItem } from '../../../../store/products/types';
import ReplacementProductAmount from './ReplacementProductAmount';

const ProductQuantityReplacementColumn = (
  override: TableColumnDeinitionOverride = {},
): TableColumnDefinition<IParsedProductItem> => {
  return {
    dataIndex: 'offerId',
    render: (offerId, product) => <ReplacementProductAmount offerId={offerId} product={product} />,
    ...override,
  };
};

export default ProductQuantityReplacementColumn;
