type TranslationRecord = Record<string, string>;
type Translations = {
  global: TranslationRecord;
  SavedCart: TranslationRecord;
  PostalCodeForm: TranslationRecord;
  Products: TranslationRecord;
  Cart: TranslationRecord;
  ExportPDF: TranslationRecord;
  Format: TranslationRecord;
  Violations: TranslationRecord;
};

const translations: Translations = {
  global: {
    'An error has occurred.': 'Une erreur est survenue',
    'No response data': 'No response data',
    'User not found.': 'Utilisateur introuvable',
  },
  Violations: {
    shippingAddress: 'Adresse de livraison',
  },
  SavedCart: {
    'The cart have been saved.': 'Panier sauvegardé',
    'The offer has expired': "L'offre est périmée",
    'The product cannot be found': 'Le produit est introuvable',
    'The product "%s" does not exists in the abandoned cart.': '"%s" n\'existe pas dans le panier',
    'The product "%s" is out of stock.': '"%s" est en rupture de stock',
    'The customer "%s" does not longer exists.': 'Le client "%s" n\'est plus enregistré',
    'The cart could not be loaded because the products offer are no longer available.':
      'Le panier ne peut être chargé car les offres des produits ne sont plus disponibles.',
    'You must select the postal code in order to save the cart.':
      'Veuillez choisir le code postal afin de sauvegarder le panier',
    'Invalid Action. There are no customers selected.': 'Action invalide. Aucun client séléctionné',
    'Invalid Action. There is no selected group.': 'Action invalide. Aucun groupement séléctionné',
    'You must have at least one product in cart in order to save it.':
      'Veuillez choisir au minimum un produit afin de sauvegarder le panier',
    '"%s": price has changed from %s to %s': '"%s": le prix a changé de %s à %s',
    'The product "%s" doesn\'t have enough stock.': '"%s": stock insuffisant',
    'The customer group no longer exists.': "Le groupe séléctionné n'existe plus",
    'Save cart': 'Sauvegarder le panier',
    Details: 'Details',
    Delete: 'Supprimer',
    Archive: 'Archiver',
    Unarchive: 'Désarchiver',
    Restore: 'Restituer',
    'Product Info': 'Produit info',
    'Delivery before': 'Livraison avant le',
    'Include archived carts': 'Inclure les paniers archivés',
    'Include converted carts': 'Inclure les paniers convertis en commande',
    'Invalid step number.': 'Invalid step number.',
    'Download PDF': 'Download PDF',
  },
  PostalCodeForm: {
    'Available shipping addresses': 'Adresses de livraison disponibles',
    'View more addresses': "Voir plus d'options",
    'View less addresses': "Voir moins d'options",
  },
  Products: {
    Reference: 'Référence',
    'Qty to order': 'Qté à commander',
    Stock: 'Stock',
    Delivery: 'Livraison',
    'Price HT': 'Prix HT',
    'Qty selected': 'Qté sélectionnée',
    'Product Info': 'Produit info',
    'File does not have any associated products.': "Le fichier n'a pas de produits associés.",
    Products: 'Produits',
    'Back to files list': 'Retourner à la liste des préconisations',
    'Uploaded files': 'Fichiers chargés',
    Quantity: 'Quantité',
    'Product Name': 'Nom du produit',
  },
  Cart: {
    Others: 'Autres',
    'Out of stock': 'Rupture de stock',
    Comments: 'Comments',
    Public: 'Public',
    Private: 'Private',
    Comment: 'Commentaire',
    WriteComment: 'Appuyez pour ajouter un commentaire',
  },
  Format: {
    'Delivery : %s day': 'Livraison : %s jour',
    'Delivery : %s days': 'Livraison : %s jours',
    'Delivery : %s - %s': 'Livraison : %s - %s',
    'Delivery : %s - %s days': 'Livraison : %s - %s jours',
    'Delivery : %s': 'Delivery : %s',
    'Delivery between : %s - %s': 'Livraison entre : %s - %s',
    'Delivery before : %s': 'Livraison avant le : %s',
    'Delivery on : %s': 'Livraison le : %s',
    'Delivery between: %s - %s days': 'Livraison entre : %s - %s jours',
    'Delivery before : %s day': 'Livraison avant le : %s jour',
    'Delivery before : %s days': 'Livraison avant le : %s jours',
    '%s day': '%s jour',
    '%s days': '%s jours',
    '%s - %s days': '%s - %s jours',
  },
  ExportPDF: {
    'Export PDF': 'Export PDF',
    'You must have at least one product in cart in order to export it as PDF.':
      'You must have at least one product in cart in order to export it as PDF.',
    'No customer have been selected.': 'No customer have been selected.',
  },
};

export type TranslationDomain = keyof Translations;

export default translations;
