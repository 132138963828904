import { useAppDispatch, useAppSelector } from 'store/store';
import { changePostcode, selectIsLoadingPostalCode, selectPostalCode } from 'store/products';
import { sendMessage } from 'store/messages';
import { selectShippingAddress, selectShippingAddresses } from 'store/delivery';
import { updateProducts } from 'store/cart';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Modal, Popover, Text } from 'components/shared';
import geoLocationFormIcon from 'assets/icons/geoLocationFormIcon.svg';
import { Spin, Tooltip, Button as ADButton } from 'antd';
import { EnvironmentFilled } from '@ant-design/icons';
import PostalCodeForm from '../PostalCodeForm';
import { selectIsGroup } from '../../../../../store/customers';
import { useTheme } from '../../../../../hooks/useTheme';
import styles from './styles.module.scss';

const PostalCode: FC = () => {
  const postalCode = useSelector(selectPostalCode);
  const dispatch = useAppDispatch();
  const themedStyle = useTheme(styles);
  const [isWarningMessageVisible, setIsWarningMessageVisible] = useState(false);
  const isLoadingPostalCode = useAppSelector(selectIsLoadingPostalCode);
  const { t } = useTranslation();
  const customerShippingAddresses = useSelector(selectShippingAddresses);
  const currentShippingAddress = useSelector(selectShippingAddress);
  const currentPostalCode = useSelector(selectPostalCode);
  const [allowPostalCodeOpening, setAllowPostalCodeOpening] = useState(false);
  const [isPostalCodeModalOpen, openPostalCodeModal] = useState(false);
  const isGroup = useSelector(selectIsGroup);

  /**
   * If there is a group, we need to allow it by default to open the model
   * this ensures that the popup will show up when users want to choose an address
   */
  useEffect(() => {
    if (isGroup) {
      setAllowPostalCodeOpening(!isLoadingPostalCode);
    }
  }, [isLoadingPostalCode, isGroup]);

  const onConfirm = useCallback(
    (townId: number, postcode: string) => {
      dispatch(changePostcode({ townId })); //set new postcode to selected user
      dispatch(updateProducts({ postcode })); //update all products from cart
      openPostalCodeModal(false);
    },
    [dispatch],
  );

  useEffect(() => {
    /**
     * if there is no postcode or shippingAddress set we need to pop open the modal
     */
    setAllowPostalCodeOpening(!isLoadingPostalCode);
    if (!currentPostalCode && !currentShippingAddress) {
      openPostalCodeModal(true);
    } else {
      openPostalCodeModal(false);
    }
  }, [customerShippingAddresses, currentShippingAddress, currentPostalCode]);

  const openForm = () => {
    openPostalCodeModal(true);
  };

  const onCancelModal = () => {
    if (postalCode) {
      openPostalCodeModal(false);
    } else {
      dispatch(
        sendMessage({
          message: 'Veuillez sélectionner le code postal pour obtenir le prix des produits',
          type: 'warning',
        }),
      );
    }
  };

  const handleVisibleChange = () => {
    setIsWarningMessageVisible(!isWarningMessageVisible);
  };

  const content = (
    <div className={styles.content}>
      <Button
        className={styles.popoverButton}
        onClick={() => {
          openForm();
          setIsWarningMessageVisible(false);
        }}
      >
        {t('common.continue')}
      </Button>
      <Button
        className={styles.popoverButton}
        type="dashed"
        onClick={() => {
          setIsWarningMessageVisible(false);
        }}
      >
        {t('common.cancel')}
      </Button>
    </div>
  );

  return (
    <>
      <Tooltip title={t('modals.changePostalCode')}>
        <Popover
          className={styles.popover}
          content={content}
          title={t('modals.postalCodeChangeMessage')}
          trigger="click"
          open={isWarningMessageVisible}
          placement="bottomLeft"
          onOpenChange={handleVisibleChange}
        >
          <ADButton className={`${styles.button} ${!postalCode && styles.button_empty}`} type="link">
            {!postalCode && isLoadingPostalCode ? (
              <Spin className={styles.loadingIcon} size="small" />
            ) : (
              <EnvironmentFilled className={styles.button__image} />
            )}
            {postalCode && (
              <Text type="subtitle" className={themedStyle('button__text')}>
                {`( ${postalCode.postcode} ) `} {postalCode.town}
              </Text>
            )}
          </ADButton>
        </Popover>
      </Tooltip>
      <Modal
        className={styles.modal}
        open={isPostalCodeModalOpen && allowPostalCodeOpening}
        onCancel={onCancelModal}
        imageProps={{ src: geoLocationFormIcon, className: styles.modal__img }}
      >
        <div className={styles.modal__form}>
          <PostalCodeForm onConfirm={onConfirm} shippingAddresses={customerShippingAddresses} />
        </div>
      </Modal>
    </>
  );
};

export default PostalCode;
