export const errors_en = {
  invalidStepNumber: 'Invalid step number',
  invalidQuantitySelected: 'Part of the quantity of a selected product has not been allocated',
  minimumFourCharacters: 'Please enter at least {{count}} characters',
  noData: 'No data',
  noCertificateFound: 'No certificate found',
  noAddressProvided: 'You did not provide an address',
  invalidDifferentPostalCode:
    'The postal code of your delivery address is different from the one initially entered on step 2',
  invalidFileUpload: 'An error occurred. Unable to upload file',
  noFilesFound: 'No files found',
  invalidDiscountPercentageValue: 'Percentage has to be between 0% and 100%',
  invalidDiscountAmountValue: 'The amount has to between 0 and the price article',
  cartAttachmentExceededLimit: 'You have reach the upload limit.',
  cartAttachmentUnsupportedFileFormat: 'The file type is not supported.',
  cartAttachmentUnexpectedError: 'Unexpected upload error for ({{count}}) files.',
  discountValueTooHigh: 'The discount is too high, please decrease.',
  offer_without_shipping_fees: 'This delivery method is not available for offers {{ids}}.',
  offer_not_found: 'Offers not found: {{ids}}.',
  notAuthorized: 'You are not authorized to access this page',
  userNotFound: 'User not found',
  missingTcCode: 'You are not a valid TC',
  saveCartBeforeFileUpload: 'Please save your cart before adding any attachment.',
  1: 'Forbidden access',
  2: 'Not found',
  3: 'Validation error',
  4: 'Error',
  5: 'Client error',
  6: 'Server error',
  7: 'Unauthorized',
  9: 'Error',
  201: 'No products found',
  1000: 'Cannot access resource',
  1001: 'You are not logged in',
  1002: 'You have no permissions',
  1003: 'You are not allowed to view this quote',
  2000: 'Saved cart not found',
  2001: 'Cart request not found',
  2002: 'Customer not found',
  2003: 'Town not found',
  2004: 'User not found',
  2005: 'Cart attachment not found',
  2006: 'Wiuz import not found',
  2007: 'Wiuz import data not found',
  4000: 'Cannot update assigned TC of converted cart',
  4001: 'Cannot update assigned TC of converted cart request',
  4002: 'Cart not ready to be sent to marketplace',
  4003: 'Invalid or unsupported file type',
  4004: 'File exceeded maximum size limit',
  4005: 'Invalid file header',
  4006: 'Cart request already exists',
  4007: 'Another TC already quoted this cart request',
  4008: "Cart request doesn't have a cart assigned to it",
  4009: 'Cart cannot be saved because it is already converted',
  5000: 'Empty file or error parsing file',
  5001: 'There was an error retrieving the requested file',
  5002: 'Database error',
  5003: 'There was an error during the PDF creation process',
  5004: 'There was an error processing your request',
};
