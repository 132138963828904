import { filterEmptyObjectKeys } from 'utils/order.utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import 'moment/locale/fr';
import moment from 'moment';
import { useDateFormat } from 'hooks/useDateFormat';
import { Button } from 'components/shared';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Form, DatePicker, Input } from 'antd';
import { IAbandonedCartParams } from '../../../../../types/abandonedCart';
import { fetchAbandonedCarts, selectAbandonedCartsLimit, setFilters } from '../../../../../store/abandonedCarts';
import { CUSTOM_DATE_FORMAT } from 'constants/locale';
import styles from './styles.module.scss';

const AbandonedCartFilters: FC = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const size = useSelector(selectAbandonedCartsLimit);
  const { t } = useTranslation();
  const dateFormatShort = useDateFormat('short');

  const initialValues = useMemo(() => {
    return {
      remember: false,
      dateRange: [moment().startOf('day').subtract(1, 'month'), moment().endOf('day')],
    };
  }, []);

  const onFinish = (values: IAbandonedCartParams) => {
    const abandonedCartFilterParameters = filterEmptyObjectKeys<IAbandonedCartParams>(values);
    if (abandonedCartFilterParameters.dateRange?.length) {
      abandonedCartFilterParameters['createdAt[$gte]'] = moment(abandonedCartFilterParameters.dateRange[0]._d).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
      abandonedCartFilterParameters['createdAt[$lte]'] = moment(abandonedCartFilterParameters.dateRange[1]._d).format(
        CUSTOM_DATE_FORMAT.mysql.short,
      );
    }
    delete abandonedCartFilterParameters.dateRange;
    dispatch(setFilters(abandonedCartFilterParameters));
    dispatch(
      fetchAbandonedCarts({
        filters: abandonedCartFilterParameters,
        size: size,
        page: 1,
      }),
    );
  };

  const clearInputs = () => {
    form.resetFields();
    dispatch(
      fetchAbandonedCarts({
        filters: {},
        size: size,
        page: 1,
      }),
    );
  };

  return (
    <div className={styles.filters}>
      <Form
        form={form}
        layout="horizontal"
        name="filters-form"
        onFinish={onFinish}
        className={styles.form}
        autoComplete="off"
        initialValues={initialValues}
      >
        <div className={styles.filtersContainer}>
          <div className={styles.inputContainer}>
            <Form.Item name="search" className={styles.input}>
              <Input size="large" placeholder={String(t('abandonedCarts.clientInfo'))} allowClear />
            </Form.Item>
          </div>
          <div className={styles.inputContainer}>
            <Form.Item name="dateRange" className={styles.input}>
              <RangePicker size="middle" locale={locale} format={dateFormatShort} className={styles.input} />
            </Form.Item>
          </div>
          <div className={styles.buttonContainer}>
            <Button className={styles.filterButton} type="primary" htmlType="submit">
              {String(t('order_history.filterResults'))}
            </Button>
            <Button className={styles.filterButton} type="dashed" onClick={clearInputs}>
              {String(t('order_history.deleteFilters'))}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AbandonedCartFilters;
