import { getDeliveryDateFormatted, priceParser, quantityParser } from 'utils';
import { useAppSelector } from 'store/store';
import { SavedCartRestoredProductItem } from 'store/savedCart/types';
import { selectCartDetails } from 'store/savedCart';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import moment from 'moment';
import { Text } from 'components/shared';
import { SaveCartDetailsProps } from 'components/savedCart/SavedCartTable/components/SaveCartDetails/types';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import { PRODUCT_DELIVERY_FORMAT } from 'constants/format/delivery';
import styles from './SaveCartDetails.module.scss';

export const SaveCartDetails: FC<SaveCartDetailsProps> = ({ cartId }: SaveCartDetailsProps) => {
  const details = useAppSelector(selectCartDetails(cartId));
  const { t } = useTranslation();

  if (!details) {
    return null;
  }

  const {
    cart: { products },
    updatedAt,
  } = details;

  const isColumnHidden = ({ quantity, noSotck }: { quantity: number; noSotck?: number }): boolean => {
    if (noSotck || quantity === 0) {
      return true;
    }
    return false;
  };

  const TABLE_COLUMNS: Array<TableColumnDefinition<SavedCartRestoredProductItem>> = [
    {
      title: <span className={styles.col}>{t('saved_cart.productInfo')}</span>,
      dataIndex: 'name',
      align: 'left',
      width: '40%',
      render: (name) => (
        <Text type="subtitle-thin" style={{ textAlign: 'left' }}>
          {name}
        </Text>
      ),
    },
    {
      title: <span className={styles.col}>{t('saved_cart.code')}</span>,
      dataIndex: 'sku',
      render: (sku) => <Text type="subtitle-thin">{sku}</Text>,
    },
    {
      title: <span className={styles.col}>{t('saved_cart.qty')}</span>,
      dataIndex: undefined,
      render: (record) => {
        const { unit, conditioning, conditioningQuantity = 1, isTruck, quantity } = record;
        const quantityParsed = Number(quantityParser(quantity, conditioningQuantity));

        if (isColumnHidden(record)) {
          return null;
        }

        return (
          <>
            {!isTruck && (
              <Text type="subtitle-thin">
                {quantity} {conditioning}
              </Text>
            )}
            {!isTruck && conditioning && <br />}
            {conditioning && (
              <Text type="subtitle-thin">
                {quantityParsed} {quantityParsed >= 2 ? unit?.plural : unit?.singular}
              </Text>
            )}
          </>
        );
      },
    },
    {
      title: <span className={styles.col}>{t('cartRequests.deliveryPeriod')}</span>,
      dataIndex: undefined,
      render: (product) => {
        const {
          deliveryStartDate,
          deliveryEndDate,
          deliveryOverridden,
          overriddenDeliveryStartDate,
          overriddenDeliveryEndDate,
        } = product;
        const startDate = deliveryOverridden ? overriddenDeliveryStartDate : deliveryStartDate;
        const endDate = deliveryOverridden ? overriddenDeliveryEndDate : deliveryEndDate;
        const days = deliveryOverridden ? undefined : product.days;

        const formatted = getDeliveryDateFormatted(
          {
            days,
            startDate,
            endDate,
          },
          PRODUCT_DELIVERY_FORMAT.NO_LABEL,
          moment(updatedAt),
        );

        const deliveryDate = typeof formatted === 'string' ? formatted : formatted.date;

        return <Text type="subtitle-thin">{deliveryDate}</Text>;
      },
    },
    {
      title: <span className={styles.col}>{t('saved_cart.up')}</span>,
      dataIndex: undefined,
      render: (record) => {
        const { offerPrice } = record;

        if (isColumnHidden(record)) {
          return null;
        }

        return <Text type="subtitle-thin">{offerPrice ? priceParser(offerPrice) : '-'}</Text>;
      },
    },
    {
      title: <span className={styles.col}>{t('saved_cart.netTotal')}</span>,
      dataIndex: undefined,
      render: (record) => {
        const { totalPrice, cvo = 0 } = record;

        if (isColumnHidden(record)) {
          return null;
        }

        return <Text type="subtitle-thin">{totalPrice ? priceParser(totalPrice - cvo) : '-'}</Text>;
      },
    },
    {
      title: <span className={styles.col}>{t('saved_cart.total')}</span>,
      dataIndex: undefined,
      render: (record) => {
        const { priceTotalWithTaxes } = record;
        if (isColumnHidden(record)) {
          return null;
        }
        return <Text type="subtitle-thin">{priceTotalWithTaxes ? priceParser(priceTotalWithTaxes) : '-'}</Text>;
      },
    },
  ];

  return (
    <div className={styles.container}>
      <Table
        columns={TABLE_COLUMNS as ColumnsType<SavedCartRestoredProductItem>}
        className={styles.table}
        rowClassName={(record) => {
          return record.noStock ? styles.noStock : '';
        }}
        dataSource={products}
        pagination={false}
      />
    </div>
  );
};

export default SaveCartDetails;
