import {
  convertWiuzQuantity,
  createCartProduct,
  getDefaultParsedProdcutItem,
  getProductOfferByQuantity,
  getProductRowKey,
  getWiuzProductRowKey,
  isProductAmountCustomerQuotation,
  percentageParser,
  priceParser,
  quantityParser,
} from 'utils';
import { useAppSelector } from 'store/store';
import { checkPriceByQuantity, selectIsCustomerMode, setWiuzActiveQuantity } from 'store/products';
import { sendMessage } from 'store/messages';
import {
  selectIsGroup,
  selectSelectedCustomerObject,
  selectSelectedGroupCustomers,
  selectSelectedGroupsCustomerIdentifiers,
  selectSelectedGroupsCustomerIds,
} from 'store/customers';
import { ICartProductItem } from 'store/cart/types';
import {
  addNewTruckToProduct,
  addNoStockProduct,
  addProduct,
  changeCustomerProductAvailableQuantity,
  changeProductAvailableQuantity,
  getLastUnitPricesBySku,
  recalculateProductPrices,
  selectCartProducts,
  selectParsedCartCustomerQuotationProduct,
  selectParsedCartMultiProduct,
  selectParsedCartProduct,
  selectParsedCartWiuzProduct,
  setQuantity,
} from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { typeToDiscountMap } from 'components/shared/Discount/Discount.types';
import { Button, Quantity } from 'components/shared';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { selectUserTcCode } from '../../../../../store/user';
import { useTheme } from '../../../../../hooks/useTheme';
import env from '../../../../../config';
import { IProductAmountProps } from './types';
import styles from './styles.module.scss';

const ProductAmount: FC<IProductAmountProps> = memo((props: IProductAmountProps) => {
  const { t } = useTranslation();
  const { offerId, product, productType, parsedWiuzProduct, rowKey } = props;
  const { disabled, quantityIncrement, minimumOrderable, unit, stock, price, ignoreStock } =
    getDefaultParsedProdcutItem(product);
  const minimumOrderQty = minimumOrderable && minimumOrderable > 1 ? minimumOrderable / quantityIncrement : 1;
  const quantity = productType === 'wiuz' ? 0 : minimumOrderQty;
  const [productQuantity, setProductQuantity] = useState(quantity);
  const [_initialPrice] = useState(price);
  const { customerIdentifier, user } = useSelector(selectSelectedCustomerObject) ?? {};
  const customers = useSelector(selectSelectedGroupCustomers);
  const groupLeaderIdentifier = customers.find((c) => c.userId === c.leaderId)?.customerIdentifier;
  const tcCode = useSelector(selectUserTcCode);
  const isCustomerMode = !useSelector(selectIsCustomerMode);
  const isMarginEnabled = env('enablePriceMargin');
  const themeStyle = useTheme(styles);

  const parsedSelector = useMemo(() => {
    if (productType === 'default') {
      return selectParsedCartProduct(offerId, productQuantity, product);
    } else if (productType === 'multi') {
      return selectParsedCartMultiProduct(offerId, productQuantity, product, rowKey!);
    } else if (isProductAmountCustomerQuotation(props)) {
      const { cartRequestItem } = props;
      return selectParsedCartCustomerQuotationProduct(offerId, productQuantity, product, rowKey!, cartRequestItem);
    } else if (productType === 'wiuz') {
      return selectParsedCartWiuzProduct(offerId, productQuantity, product, parsedWiuzProduct!.id);
    }
    throw new Error('Invalid productType selector.');
  }, [offerId, parsedWiuzProduct, product, productQuantity, productType, rowKey, props]);

  const getMargin = useCallback(() => {
    if (!price?.supplierUnitPrice || price?.supplierUnitPrice === 0) {
      return t('common.notAvailable');
    }

    const marginAmount = (price.price - price.supplierUnitPrice) * productQuantity * (quantityIncrement ?? 1);
    const marginPercentage = (marginAmount / (price.price * productQuantity * (quantityIncrement ?? 1))) * 100;

    return `${priceParser(marginAmount)} / ${percentageParser(marginPercentage)}`;
  }, [price, productQuantity]);

  const margin = useMemo(() => {
    if (isCustomerMode || !isMarginEnabled || !price?.supplierUnitPrice || price.supplierUnitPrice === 0) {
      return null;
    }
    return (
      <span className={`${themeStyle('margin_price')}`}>
        {t('common.margin')}: {getMargin()}
      </span>
    );
  }, [isCustomerMode, price, getMargin]);

  const parsedProduct = useAppSelector(parsedSelector);
  const cartProducts = useSelector(selectCartProducts);
  const isGroup = useSelector(selectIsGroup);
  const selectedGroupCustomerIds = useSelector(selectSelectedGroupsCustomerIds);
  const selectedGroupsCustomerIdentifiers = useSelector(selectSelectedGroupsCustomerIdentifiers);

  const dispatch = useDispatch();
  const userHasValidCertificate = useMemo(() => {
    if (isGroup) {
      return true;
    }

    const date = moment(user?.certiphytoExpiredDate);
    if (!date || !date.isValid() || date.isBefore(moment(), 'day')) {
      return false;
    }

    return true;
  }, [isGroup, user?.certiphytoExpiredDate]);

  const allUsersHaveValidCertificate = useMemo(() => {
    if (!isGroup) {
      return true;
    }

    const validity = customers.filter((customer) => {
      const date = moment(customer?.user?.certiphytoExpiredDate);
      return date && date.isValid() && date.isAfter(moment(), 'day');
    });

    return validity.length === customers.length;
  }, [customers, isGroup]);

  useEffect(() => {
    checkProductOffer(productQuantity);

    if (productType === 'wiuz' && parsedWiuzProduct) {
      const { unitRelations, unit: parsedProductUnit } = parsedWiuzProduct;

      const qty = productQuantity * (quantityIncrement || 1);
      const activeQuantity = convertWiuzQuantity(qty, {
        unitRelations,
        unit: parsedProductUnit,
        unitSingular: product.unit.singular,
      });

      dispatch(
        setWiuzActiveQuantity({
          wiuzProductKey: getWiuzProductRowKey(parsedWiuzProduct),
          productKey: getProductRowKey(product),
          quantity: activeQuantity,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productQuantity]);

  const checkProductOffer = (qty: number) => {
    const newDiscount = getProductOfferByQuantity(qty, product);
    if (newDiscount && product.offerId) {
      dispatch(
        checkPriceByQuantity({
          productType,
          wiuzProductId: parsedWiuzProduct?.id,
          offerId: product.offerId,
          price: newDiscount.DiscountedPrice,
          discount: newDiscount.Value,
          type: typeToDiscountMap[newDiscount.Type],
          rowKey: rowKey || 0,
        }),
      );
    }
  };

  const productInCart = useMemo(() => {
    return cartProducts.find((item: ICartProductItem) => item.offerId === offerId);
  }, [cartProducts, offerId]);

  const actualStock = useMemo((): number => {
    if (!offerId || !productInCart) {
      return stock;
    }

    return stock - productInCart.quantity * quantityIncrement;
  }, [offerId, stock, productInCart, quantityIncrement]);

  const onAddProduct = async () => {
    if (!offerId) {
      dispatch(addNoStockProduct(product));
    }

    if (
      ((!isGroup && !userHasValidCertificate) || (isGroup && !allUsersHaveValidCertificate)) &&
      (product?.flags || [])?.includes('certiphyto')
    ) {
      dispatch(
        sendMessage({
          message: t('common.noCertificateMessage'),
          type: 'warning',
        }),
      );
    }

    if (productQuantity >= quantity && offerId && parsedProduct) {
      if (!productInCart) {
        const payload = createCartProduct(
          isGroup,
          selectedGroupCustomerIds,
          selectedGroupsCustomerIdentifiers,
          parsedProduct,
          product,
          productQuantity,
        );

        if (customerIdentifier && tcCode) {
          dispatch(
            getLastUnitPricesBySku({
              sku: product.sku,
              customerIdentifier,
              tcCode,
            }),
          );
        } else if (groupLeaderIdentifier && tcCode) {
          dispatch(
            getLastUnitPricesBySku({
              sku: product.sku,
              customerIdentifier: groupLeaderIdentifier,
              tcCode,
            }),
          );
        }

        payload.match({
          Some: ({ cartProduct }) => {
            dispatch(
              addProduct({
                ...cartProduct,
                offerPrice: price?.type === 1 ? price.initialPrice! : cartProduct.offerPrice,
              }),
            );
            setProductQuantity(quantity);
          },
          Error: ({ message }) => {
            dispatch(
              sendMessage({
                message,
                type: 'warning',
              }),
            );
          },
        });

        return;
      }

      const newQuantity = quantityIncrement * productQuantity;

      if (!ignoreStock && newQuantity > actualStock) return;

      dispatch(
        setQuantity({
          id: productInCart.offerId,
          quantity: productInCart.quantity + productQuantity,
        }),
      );

      // if is group && productInCart==true && truck === false
      if (isGroup && !parsedProduct?.isTruck) {
        if (productInCart.customerProductInfo) {
          Object.entries(productInCart.customerProductInfo).forEach(([_key, value]) =>
            dispatch(
              changeCustomerProductAvailableQuantity({
                productId: offerId,
                customerAvailableQuantity: value.availableCustomerQuantity + quantityIncrement * productQuantity,
                customerIdentifier: value.customerIdentifier,
              }),
            ),
          );
        }
        dispatch(
          changeProductAvailableQuantity({
            offerId: offerId,
            quantity: productInCart.availableSelectedQuantity + quantityIncrement * productQuantity,
          }),
        );
      }

      // if is group && productInCart==true && truck === true
      if (isGroup && parsedProduct?.isTruck && productInCart.truckSplitInfo) {
        const lastAdded = Number(Object.keys(productInCart.truckSplitInfo).slice(-1));
        const lastTruckNumber = Object.values(productInCart.truckSplitInfo).slice(-1)[0];
        const trucksplit: any = {};
        [...Array(parsedProduct.quantity)].forEach((_, idx) => {
          trucksplit[idx + lastAdded + 1] = {
            truckNumber: lastTruckNumber ? lastTruckNumber.truckNumber + 1 : 0,
            totalTruckAvailable: quantityIncrement / product.conditioningQuantity,
            customerProductInfo: selectedGroupsCustomerIdentifiers.reduce(
              (acc, customer) => ({
                ...acc,
                [customer.customerIdentifier]: {
                  selectedQuantity: 0,
                  availableCustomerQuantity: quantityIncrement / product.conditioningQuantity,
                  customerIdentifier: customer.customerIdentifier,
                },
              }),
              {},
            ),
          };
        });
        const newTruckAmount = Object.assign(trucksplit, productInCart.truckSplitInfo);

        dispatch(
          addNewTruckToProduct({
            id: offerId,
            newTruckValue: newTruckAmount,
            availableSelectedQt:
              productInCart.availableSelectedQuantity +
              (quantityIncrement / product.conditioningQuantity) * productQuantity,
          }),
        );
      }

      dispatch(recalculateProductPrices(offerId));
      setProductQuantity(quantity);
    }
  };
  const increaseQuantity = () => {
    setProductQuantity(productQuantity + 1);
  };
  const decreaseQuantity = () => {
    setProductQuantity(productQuantity - 1);
  };
  const renderUnit = useMemo(() => {
    return quantityIncrement * productQuantity >= 2 ? unit.plural : unit.singular;
  }, [productQuantity, quantityIncrement, unit.plural, unit.singular]);

  const addToOrderDisabled = useMemo(() => {
    if (offerId) {
      return false;
    }
    return !env('enableAddProductWithoutOffer');
  }, [offerId]);

  const onChangeProductQuantity = (value: number) => {
    if (ignoreStock) {
      setProductQuantity(value);
    } else {
      if (actualStock / quantityIncrement >= value) {
        setProductQuantity(value);
      }
    }
  };

  const maximum = ignoreStock ? 1e6 : Math.floor(actualStock / quantityIncrement);

  return (
    <div>
      <div className={styles.amount}>
        <Quantity
          value={productQuantity}
          minimum={quantity}
          maximum={maximum}
          subTitle={`${quantityParser(productQuantity, quantityIncrement)} ${renderUnit}`}
          onChange={onChangeProductQuantity}
          onDecrease={decreaseQuantity}
          onIncrease={increaseQuantity}
          disabled={disabled}
          ignoreStock={ignoreStock}
        />
        <Button
          disabled={addToOrderDisabled}
          icon={<ShoppingCartOutlined className={styles.button__icon} />}
          className={styles.button}
          onClick={onAddProduct}
        />
      </div>
      {margin}
    </div>
  );
});

export default ProductAmount;
