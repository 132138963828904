import { users_en } from './users';
import { saved_cart_en } from './saved_cart';
import { order_history_en } from './order_history';
import { my_profile_en } from './my_profile';
import { modals_en } from './modals';
import { mass_action_en } from './mass_action';
import { logs_en } from './logs';
import { login_en } from './login';
import { landing_en } from './landing';
import { forms_en } from './forms';
import { errors_en } from './errors';
import { date_en } from './date';
import { common_en } from './common';
import { cart_requests_en } from './cart_requests';
import { cart_en } from './cart';
import { abandoned_carts_en } from './abandoned_carts';
export const translations_en = {
  common: {
    ...common_en,
  },
  login: {
    ...login_en,
  },
  landing: {
    ...landing_en,
  },
  forms: {
    ...forms_en,
  },
  modals: {
    ...modals_en,
  },
  errors: {
    ...errors_en,
  },
  order_history: {
    ...order_history_en,
  },
  my_profile: {
    ...my_profile_en,
  },
  saved_cart: {
    ...saved_cart_en,
  },
  cart: {
    ...cart_en,
  },
  cartRequests: {
    ...cart_requests_en,
  },
  abandonedCarts: {
    ...abandoned_carts_en,
  },
  date: {
    ...date_en,
  },
  users: {
    ...users_en,
  },
  massAction: {
    ...mass_action_en,
  },
  logs: {
    ...logs_en,
  },
};
