export const users_fr = {
  pageTitle: 'Utilisateurs',
  edit: 'Modifier',
  delete: 'Supprimer',
  confirmDelete: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
  groups: 'Groupes',
  email: 'Email',
  actions: 'Actions',
  nextPage: 'Page suivante',
  prevPage: 'Page précédente',
  newUserPageTitle: 'Nouvel Utilisateur',
  editUserPageTitle: 'Modifier Utilisateur',
  personalInformation: 'Informations Personnelles',
  lastname: 'Nom de famille',
  firstname: 'Prénom',
  username: "Nom d'utilisateur",
  phoneNumber: 'Numéro de téléphone',
  saveChanges: 'Enregistrer',
  newUserAdded: 'Nouvel utilisateur ajouté avec succès',
  userDetailsUpdated: "Détails de l'utilisateur mis à jour avec succès",
  yourGroups: 'Vos Groupes',
  noGroups: 'Aucun groupe',
  addUser: 'Ajouter Utilisateur',
  selectFilter: 'Sélectionner un filtre',
  applyFilter: 'Appliquer le filtre',
  clearFilter: 'Effacer le filtre',
};
