import { ICartProductItem } from 'store/cart/types';
import { SortableContainer } from 'react-sortable-hoc';
import SortableCartProductItem from './SortableCartProductItem';

type SortableCartPorductsListProps = {
  findProductIndex: (product: ICartProductItem) => number;
  products: Array<ICartProductItem>;
  handleHistoricalPriceModal: (sku: string) => void;
};

const SortableCartPorductsList = SortableContainer((props: SortableCartPorductsListProps) => {
  const { findProductIndex, products, handleHistoricalPriceModal } = props;

  return (
    <div>
      {products.map((product) => {
        const productIndex = findProductIndex(product);

        return (
          <SortableCartProductItem
            key={`${product.variantId}-${product.offerId || 0}`}
            index={productIndex}
            productIndex={productIndex}
            handleHistoricalPriceModal={handleHistoricalPriceModal}
            product={product}
          />
        );
      })}
    </div>
  );
});

export default SortableCartPorductsList;
