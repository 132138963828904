import store from 'store/store';
import { SavedCartStatusEnum } from 'store/savedCart/types';
import { changePostcode } from 'store/products';
import { getShippingAddressById, selectShippingAddress } from 'store/delivery';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo } from 'react';
import moment from 'moment';
import { camelCase } from 'lodash';
import { useDateFormat } from 'hooks/useDateFormat';
import { ItemType } from 'antd/es/menu/interface';
import { Menu } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { StepForwardFilled, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { ICartRequest, ICartRequestItem } from '../../../types/cartRequest';
import { ICustomer } from '../../../types';
import { selectUserTcCode } from '../../../store/user';
import { updateSavedCart } from '../../../store/savedCart';
import { fetchCartRequestAndCreateState, patchCartRequests } from '../../../store/cartRequests';
import { clearCartState } from '../../../store/cart';
import { useSetSettings } from '../../../hooks/useSettings';
import { getOrderPath, OrderRoute } from '../../../constants/routes';
import styles from './styles.module.scss';

const Client: FC<ICustomer> = memo((customer: ICustomer) => {
  return (
    <>
      <div>
        {customer.firstName} {customer.lastName}
      </div>
      <div>{customer.company}</div>
      <div>{customer.customerIdentifier}</div>
      <div>{customer.email}</div>
    </>
  );
});

const CartRequestStatus: FC<{
  status: typeof SavedCartStatusEnum[keyof typeof SavedCartStatusEnum];
  displayDate: string;
}> = memo(
  ({
    status,
    displayDate,
  }: {
    status: typeof SavedCartStatusEnum[keyof typeof SavedCartStatusEnum];
    displayDate: string;
  }) => {
    const { t } = useTranslation();
    const dateFormatShort = useDateFormat('short');
    const displayedDate = useMemo(() => {
      const statusText = t(`cartRequests.${camelCase(status)}`);
      return `${statusText} le: ${moment(displayDate).utc(false).format(dateFormatShort)}`;
    }, [displayDate, status, t, dateFormatShort]);
    return (
      <>
        <div className={`${styles[status]} ${styles.status}`}>{t(`cartRequests.${camelCase(status)}`)}</div>
        <>
          {[SavedCartStatusEnum.CONVERTED, SavedCartStatusEnum.REFUSED].includes(status) && displayDate !== null && (
            <span>{displayedDate}</span>
          )}
        </>
      </>
    );
  },
);

const CartRequestItemProgress: FC<{ items: Array<ICartRequestItem> }> = memo(
  ({ items }: { items: Array<ICartRequestItem> }) => {
    const fulfilled = items.filter((item) => item.processed === 1);
    const total = items.length;
    return (
      <>
        {fulfilled.length}/{total}
      </>
    );
  },
);

const CartRequestActionMenu: FC<Partial<ICartRequest>> = memo((cartRequest: Partial<ICartRequest>) => {
  const { id, status, assignedTcCode, atcCheckoutCartId, userId, addressId } = cartRequest;
  const setProductPage = useSetSettings('PRODUCT_PAGE');
  const navigate = useNavigate();
  const menuItems: ItemType[] = [];
  const { t } = useTranslation();
  const tcCode = useSelector(selectUserTcCode);
  const isAssignedToSelf = String(assignedTcCode) === String(tcCode);
  const isAssigned = assignedTcCode === null || String(assignedTcCode).trim().length === 0;
  const dispatch = useDispatch();

  const assign = () => async () => {
    await patchCartRequest({
      assignedTcCode: tcCode,
      atcCheckoutCartId,
    });
  };

  const unassign = () => async () => {
    await patchCartRequest({
      assignedTcCode: null,
      atcCheckoutCartId,
    });
  };

  const patchCartRequest = async (data: Partial<ICartRequest>) => {
    await dispatch(patchCartRequests({ id: id!, cartRequest: data }));
  };

  const handleClickCustomerQuotation = async () => {
    dispatch(clearCartState());

    if (atcCheckoutCartId && status === SavedCartStatusEnum.PENDING) {
      await dispatch(
        updateSavedCart({
          id: atcCheckoutCartId,
          data: {
            status: SavedCartStatusEnum.IN_PROGRESS,
          },
        }),
      );
    }

    const isFullfilled = isFulfilled(await dispatch(fetchCartRequestAndCreateState({ id: id!, isAssigned: true })));

    if (userId && addressId) {
      isFulfilled(await dispatch(getShippingAddressById({ userId, addressId })));
      const state = store.getState();
      const address = selectShippingAddress(state);
      address && isFulfilled(await dispatch(changePostcode({ townId: address.townId })));
    }

    if (isFullfilled) {
      await redirectToCustomerQuotation();
    }
  };

  const redirectToCustomerQuotation = async () => {
    dispatch(setProductPage('customer-quotation'));
    return navigate(getOrderPath(OrderRoute.PRODUCT));
  };

  if (isAssignedToSelf && status !== SavedCartStatusEnum.CONVERTED && atcCheckoutCartId) {
    menuItems.push({
      icon: <UserDeleteOutlined />,
      onClick: unassign(),
      label: t(`cartRequests.unassigned`),
    } as unknown as ItemType);
  }
  if (isAssigned && status !== SavedCartStatusEnum.CONVERTED && atcCheckoutCartId) {
    menuItems.push({
      icon: <UserAddOutlined />,
      onClick: assign(),
      label: t(`cartRequests.assigned`),
    } as unknown as ItemType);
  }
  if (isAssignedToSelf || isAssigned || !atcCheckoutCartId) {
    menuItems.push({
      icon: <StepForwardFilled />,
      onClick: handleClickCustomerQuotation,
      label: t(`cartRequests.actionButton.${camelCase(status)!}`),
    } as unknown as ItemType);
  }
  return <Menu items={menuItems} />;
});

export { Client, CartRequestStatus, CartRequestActionMenu, CartRequestItemProgress };
