import { ITown } from 'types';
import { ApiResponse } from 'apisauce';
import { getToken } from '../cookies';
import { config } from '../config';
import { RestServiceApi } from './api.service';

type ISearchPostcodeParams = { postcode: string; countryCode: string };

const townApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class PostcodeServiceApi {
  async searchTowns(postcode: string): Promise<ApiResponse<Array<ITown>>> {
    const countryCode = 'FR'; // for the moment is only 'France', but in the future this parameter could come from an input
    return townApi.get<ISearchPostcodeParams, Array<ITown>>(
      'towns',
      { postcode, countryCode },
      townApi.getAuthenticateHeader(getToken()),
    );
  }

  async getTownById(townId: number): Promise<ApiResponse<ITown>> {
    return townApi.get<Record<string, never>, ITown>(`towns/${townId}`, {}, townApi.getAuthenticateHeader(getToken()));
  }
}

export const PostcodeApiService = new PostcodeServiceApi();
