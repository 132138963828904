import frFlag from './images/fr-FR.svg';
import enFlag from './images/en-GB.svg';

export const languages = {
  en: 'English',
  fr: 'Français',
};

export type Language = keyof typeof languages;

export const getLanguagesList = (): string[] => Object.keys(languages);

export const flags: Record<Language, string> = {
  en: enFlag,
  fr: frFlag,
};
