import { useIsMobile } from 'utils/styles.utils';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button, FormInput } from 'components/shared';
import { Form, Select, Space } from 'antd';
import { LockOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { emailRule, firstNameRule, lastNameRule, passwordRule } from './validators';
import { ICreateCustomerFormProps } from './types';
import styles from './styles.module.scss';

const CreateCustomerForm: FC<ICreateCustomerFormProps> = (props: ICreateCustomerFormProps) => {
  const { onSubmit } = props;
  const [form] = Form.useForm();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const onFinish = async () => {
    form.resetFields();
    onSubmit();
  };

  const spacerSizes = isMobile ? 0 : 24;

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} name="create-client">
      <Space size={spacerSizes} className={styles.space} align="center">
        <FormInput className={styles.input} label={t('forms.firstname')} name="firstname" rules={firstNameRule} />
        <FormInput className={styles.input} label={t('forms.lastname')} name="lastname" rules={lastNameRule} />
      </Space>
      <Space size={spacerSizes} className={styles.space} align="center">
        <FormInput className={styles.input} label={t('forms.createEmail')} name="username" rules={emailRule} />
        <Form.Item className={styles.input} label={t('forms.chooseActivity')} name="activity">
          <Select placeholder="Faites votre choix" optionFilterProp="children" size="large">
            {['A', 'B', 'C'].map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Space>
      <Space size={spacerSizes} className={styles.space} align="center">
        <FormInput
          label={t('forms.createPassword')}
          className={`${styles.input} ${styles.input_password}`}
          inputClassName={styles.item}
          name="password"
          rules={passwordRule}
          inputProps={{
            size: 'large',
            type: 'password',
            addonBefore: <LockOutlined />,
          }}
        />
        <div className={styles.input} />
      </Space>

      <Button className={styles.button} htmlType="submit" icon={<PlusSquareOutlined />}>
        {t('landing.createAccount')}
      </Button>
    </Form>
  );
};

export default CreateCustomerForm;
