import { createCartProducts, getSavedCartProductsOffers } from 'utils/savedCart.utils';
import { convertCustomerToSearchedCustomer } from 'utils/customer.utils';
import { unwrap } from 'utils/api.utils';
import { getParsedProductsForTable } from 'utils';
import { ParsedProductsForTable } from 'types/product';
import { Log } from 'types/logs';
import { fetchProductsNormalizer } from 'store/products/normalizers';
import {
  fetchProductsSavedCartAdapter,
  fetchProductsAdapter,
  fetchTownAdapter,
  fetchTownCartRequestAdapter,
} from 'store/products';
import { clearDeliveryState } from 'store/delivery';
import { fetchCustomersNormalizer } from 'store/customers/normalizers';
import {
  CartRequestCartNormalizerPayload,
  CartRequestCustomerNormalizerPayload,
  CartRequestNormalizerPayload,
  CartRequestStateNormalizerReturnType,
  CreateCartStateReturnType,
  CreateCustomerStateReturnType,
  CreateProductsStateReturnType,
} from 'store/cartRequests/types';
import { ICartProductItem, ICustomerPostalCode } from 'store/cart/types';
import { clearCartState } from 'store/cart/reducer';
import { CustomersApiService } from 'services/customersServiceApi';

export const cartRequestStateNormalizer = async (
  payload: CartRequestNormalizerPayload,
): Promise<CartRequestStateNormalizerReturnType> => {
  const { cartRequestDetail } = payload;
  const { customerId } = cartRequestDetail;

  const customer = unwrap(await CustomersApiService.getCustomerById(customerId));
  const customerState = await createCustomerState({ ...payload, customer });
  const cart = await createCartState({ ...payload, customer });
  const products = await createProductsState(payload);

  return {
    customer: customerState,
    cart,
    products,
  };
};

const createCustomerState = async (
  payload: CartRequestCustomerNormalizerPayload,
): Promise<CreateCustomerStateReturnType> => {
  const { customer } = payload;

  const selectedSearchedCustomer = convertCustomerToSearchedCustomer(customer);
  const selectedCustomer = customer.id;

  const { customers } = fetchCustomersNormalizer([customer]);

  return {
    selectedSearchedCustomer,
    selectedCustomer,
    customers,
    whitelist: {
      isLoading: false,
      filter: null,
      total: Object.values(customers).length,
      page: 1,
    },
  };
};

type SavedCartState = {
  isSavedCart: boolean;
  cartId: string | null;
  products: Array<ICartProductItem>;
  logs: Log[];
};

const createCartState = async (payload: CartRequestCartNormalizerPayload): Promise<CreateCartStateReturnType> => {
  const { customer, cartRequestDetail, dispatch, tcTags } = payload;
  const { tags = [] } = customer;
  const { id, cart: savedCart, delivery, atcCartNumber } = cartRequestDetail;

  dispatch(clearCartState());
  const savedCartState: SavedCartState = {
    isSavedCart: false,
    cartId: null,
    products: [],
    logs: [],
  };

  if (savedCart) {
    const { id: cartId, cart } = savedCart;
    const { products: savedCartProducts, logs = [] } = cart;
    savedCartState.logs = logs;
    savedCartState.isSavedCart = true;
    savedCartState.cartId = cartId;

    if (savedCartProducts.length === 0) {
      return {
        ...savedCartState,
        cartRequestId: id,
        atcCartNumber,
      };
    }
    const fetchedProducts = await fetchProductsAdapter(
      fetchProductsSavedCartAdapter({
        productIds: savedCartProducts.map((item) => item.productId),
        variantIds: savedCartProducts.map((item) => item.variantId),
      }),
      delivery?.postcodeLabel ?? customer.postcodeLabel,
      tags.concat(tcTags),
      true,
    );

    const { normalizedProducts, normalizedOffers } = fetchProductsNormalizer(fetchedProducts);

    const offers = {
      list: normalizedOffers.offers,
      byProduct: normalizedOffers.products,
    };

    const productsAndOffers: ParsedProductsForTable = {
      products: normalizedProducts,
      offers,
    };
    const parsedProductsForTable = getParsedProductsForTable(productsAndOffers);

    const parsedProducts = getSavedCartProductsOffers({ savedCartProducts, parsedProductsForTable });

    // TODO: check for errors
    const { cartProducts } = createCartProducts({
      parsedProducts,
      products: productsAndOffers,
      productItemOptions: {
        isGroup: false,
        expired: true,
        selectedGroupCustomersIds: null,
      },
    });

    savedCartState.products = cartProducts;
  }

  return {
    ...savedCartState,
    cartRequestId: id,
    atcCartNumber,
  };
};

const createProductsState = async (
  payload: CartRequestNormalizerPayload,
): Promise<CreateProductsStateReturnType | undefined> => {
  const { cartRequestDetail, dispatch } = payload;
  if (!cartRequestDetail.delivery) {
    return;
  }
  const { delivery } = cartRequestDetail;

  const { townId } = delivery;

  dispatch(clearDeliveryState());
  const postalCodes: ICustomerPostalCode = await fetchTownAdapter(fetchTownCartRequestAdapter({ townId }));

  return {
    postalCodes,
  };
};
