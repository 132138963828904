import { FC } from 'react';

import useDebounce from 'hooks/useDebounce';
import { DropDownProps } from 'components/shared/Dropdown/types';
import { Spin } from 'antd';
import { Dropdown as ADDropdown } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';
import styles from './Dropdown.module.scss';

export const Dropdown: FC<DropDownProps> = ({
  children,
  icon,
  type = 'primary',
  lableIcon,
  className,
  loading,
  ...rest
}: DropDownProps) => {
  let delay = 0;
  if (typeof loading === 'object') {
    delay = loading.delay || 0;
  }

  const loadingDebounce = useDebounce(loading, delay);
  const loadingComponent = <LoadingOutlined className={styles.loadingOutlined} />;

  return (
    <ADDropdown.Button
      type={type}
      loading={false}
      className={`${styles[`button_${type}`]} ${className} ${loadingDebounce ? styles.loading : ''}`}
      icon={icon ?? <DownOutlined />}
      {...rest}
    >
      {loadingDebounce ? <Spin className={styles.loadingSpin} indicator={loadingComponent} /> : lableIcon}

      {children}
    </ADDropdown.Button>
  );
};

export default Dropdown;
