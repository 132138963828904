import { AnyAction } from '@reduxjs/toolkit';

const pendingActionTail = 'pending';
const fulfilledActionTail = 'fulfilled';
const rejectedActionTail = 'rejected';

const hasPrefix = (action: AnyAction, prefix: string) => action.type.startsWith(prefix);
const isPending = (action: AnyAction) => action.type.endsWith(`/${pendingActionTail}`);
const isFulfilled = (action: AnyAction) => action.type.endsWith(`/${fulfilledActionTail}`);
const isRejected = (action: AnyAction) => action.type.endsWith(`/${rejectedActionTail}`);

export const isPendingAction =
  (prefix: string) =>
  (action: AnyAction): action is AnyAction => {
    return hasPrefix(action, prefix) && isPending(action);
  };

export const isRejectedAction =
  (prefix: string) =>
  (action: AnyAction): action is AnyAction => {
    return hasPrefix(action, prefix) && isRejected(action);
  };

export const isFulfilledAction =
  (prefix: string) =>
  (action: AnyAction): action is AnyAction => {
    return hasPrefix(action, prefix) && isFulfilled(action);
  };

export const isAction =
  (actionType: string) =>
  (action: AnyAction): action is AnyAction => {
    return actionType === action.type;
  };

export const isOneOfActions =
  (actionsType: string[]) =>
  (action: AnyAction): action is AnyAction => {
    return actionsType.includes(action.type);
  };

export const pendingAction = (actionType: string): string => {
  return `${withSlashIfNeeded(actionType)}${pendingActionTail}`;
};

export const fulfilledAction = (actionType: string): string => {
  return `${withSlashIfNeeded(actionType)}${fulfilledActionTail}`;
};

export const rejectedAction = (actionType: string): string => {
  return `${withSlashIfNeeded(actionType)}${rejectedActionTail}`;
};

const withSlashIfNeeded = (actionType: string): string => {
  let slash = '';

  if (actionType.length > 0) {
    slash = actionType[actionType.length - 1] === '/' ? '' : '/';
  }

  return `${actionType}${slash}`;
};
