import { CookieSetOptions } from 'universal-cookie/cjs';
import { Cookies, useCookies } from 'react-cookie';
import React from 'react';
export const ID_TOKEN = 'idToken';
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRE_DATE = 'tokenExpireDate';

// The token cookie is the same for the whole application,
// no matter what the route

const options: CookieSetOptions = { path: '/', sameSite: 'strict' };

/**
 * Hook to manage token. It returns a tuple containing the value of the
 * token and functions to change and remove it.
 * It can be used like:
 *      const {token, setToken} = useToken()
 */

export const useToken = (): {
  token: string;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  isExpired: () => boolean;
  setToken: (idToken: string, accessToken: string, refreshToken: string, expires: number) => void;
  removeToken: () => void;
} => {
  const [cookies, setCookie, removeCookie] = useCookies([ID_TOKEN, TOKEN, REFRESH_TOKEN, EXPIRE_DATE]);

  const setToken = React.useCallback(
    (idToken: string, accessToken: string, refreshToken: string, expiresIn: number) => {
      setCookie(ID_TOKEN, idToken, options);
      setCookie(TOKEN, accessToken, options);
      setCookie(REFRESH_TOKEN, refreshToken, options);
      /**
       * We deduct 10 minutes to make sure we refresh before expiring
       */
      setCookie(EXPIRE_DATE, Date.now() + (expiresIn - 600) * 1000, options);
    },
    [setCookie],
  );

  const isExpired = () => Date.now() > parseInt(cookies[EXPIRE_DATE]);

  const removeToken = React.useCallback(() => {
    removeCookie(TOKEN, options);
    removeCookie(ID_TOKEN, options);
    removeCookie(REFRESH_TOKEN, options);
    removeCookie(EXPIRE_DATE, options);
  }, [removeCookie]);

  return {
    token: cookies[ID_TOKEN],
    accessToken: cookies[TOKEN],
    idToken: cookies[ID_TOKEN],
    refreshToken: cookies[REFRESH_TOKEN],
    setToken,
    removeToken,
    isExpired,
  };
};

const createCookie = () => {
  const cookieStore = new Cookies();
  return {
    set: (key: string, value: string) => {
      return cookieStore.set(key, value, options);
    },
    get: (key: string) => {
      return cookieStore.get(key);
    },
  };
};

export const cookie = createCookie();

export const getToken = (which: string = ID_TOKEN): string => {
  return cookie.get(which);
};

export const getRefreshToken = (): string => {
  return cookie.get(REFRESH_TOKEN);
};
