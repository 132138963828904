import { DateFormatRecord } from 'types/dateFormat';

export const DATE_FORMAT: DateFormatRecord = Object.freeze({
  fr: {
    short: 'DD/MM/YYYY',
    long: 'DD/MM/YYYY HH:mm',
    full: 'DD/MM/YYYY HH:mm:ss',
  },
  en: {
    short: 'DD/MM/YYYY',
    long: 'DD/MM/YYYY HH:mm',
    full: 'DD/MM/YYYY HH:mm:ss',
  },
  de: {
    short: 'DD.MM.YYYY',
    long: 'DD.MM.YYYY HH:mm',
    full: 'DD.MM.YYYY HH:mm:ss',
  },
});

export const CUSTOM_DATE_FORMAT = Object.freeze({
  fullMonth: 'MMM YYYY',
  fullMonthWithDay: 'DD MMM YYYY',
  timestamp: 'YYYYMMDD',
  mysql: {
    short: 'YYYY-MM-DD',
  },
});
