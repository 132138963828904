import { ICustomerGroup } from 'types';
import { useAppSelector } from 'store/store';
import { selectSelectedGroupCustomers, selectSelectedGroupId, setSelectedGroup } from 'store/customers';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Badge } from 'antd';
import { setShippingAddressForGroup } from '../../../../../store/delivery';
import GroupCustomerRow from './components/GroupCustomerRow';
import { GroupRowProps } from './GroupRow.types';
import styles from './GroupRow.module.scss';

const GroupRow: React.FC<GroupRowProps> = ({ group }: GroupRowProps) => {
  const { name: groupName, leaderId, groupMember } = group;
  const dispatch = useDispatch();
  const selectedSelectedGroupId = useAppSelector(selectSelectedGroupId);
  const selectedGroupCustomers = useAppSelector(selectSelectedGroupCustomers);

  const members = useMemo(() => {
    return groupMember.reduce<Array<ICustomerGroup>>((acc, item) => {
      const { groupId } = item;

      item.groupMemberCustomer.forEach((member) => {
        const { customer } = member;

        acc.push({
          ...customer,
          groupId,
          groupName,
          leaderId,
        });
      });

      return acc;
    }, []);
  }, [groupMember, groupName, leaderId]);

  const hasMembers = members.length > 0;

  const groupLeader = useMemo(() => members.find((member) => member.userId === member.leaderId) || null, [members]);
  const isGroupSelected = useMemo(() => selectedSelectedGroupId === group.id, [group.id, selectedSelectedGroupId]);

  const handleGroupCustomerSelectChange = useCallback(
    (isSelect: boolean) => {
      if (isSelect) {
        dispatch(setSelectedGroup([group]));
        dispatch(setShippingAddressForGroup(group));
      }
    },
    [dispatch, group],
  );

  const renderGroupMembers = useCallback(() => {
    return members.map((groupCustomer) => {
      return (
        <GroupCustomerRow
          key={groupCustomer.id}
          groupCustomer={groupCustomer}
          groupLeader={groupLeader}
          selectedGroupCustomers={selectedGroupCustomers}
          selectedGroupId={selectedSelectedGroupId}
          isGroupSelected={isGroupSelected}
          onSelectChange={handleGroupCustomerSelectChange}
        />
      );
    });
  }, [
    groupLeader,
    handleGroupCustomerSelectChange,
    isGroupSelected,
    members,
    selectedGroupCustomers,
    selectedSelectedGroupId,
  ]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapper__groupSelected]: isGroupSelected,
        [styles.wrapper__hasMembers]: hasMembers,
        [styles.wrapper__noMembers]: !hasMembers,
        [styles.wrapper__noGroupLeaderWithMembers]: !groupLeader && hasMembers,
        [styles.wrapper__noGroupLeader]: !groupLeader,
      })}
    >
      <div className={styles.groupRow}>
        <div className={styles.groupColumn}>
          <div className={styles.name}>{groupName}</div>
        </div>
        <div className={styles.groupColumn}>
          <Badge className={classNames(styles.badge)} count={members.length} showZero />
        </div>
      </div>
      <div>{renderGroupMembers()}</div>
    </div>
  );
};

export default GroupRow;
