import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { Button } from 'components/shared';
import { getUsersPath } from 'constants/routes';
import styles from './Users.module.scss';
import { Table } from './Table';

export const Users: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const addUserCb = () => {
    navigate(`${getUsersPath()}/new`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('users.pageTitle')}</h3>
        <Button onClick={addUserCb} className={styles.link}>
          {t('users.addUser')}
        </Button>
      </div>
      <Table />
    </div>
  );
};

export default Users;
