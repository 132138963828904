import { useIsSmallTablet } from 'utils/styles.utils';
import { selectIsGroup } from 'store/customers';
import { useSelector } from 'react-redux';
import { FC } from 'react';
import SaveCartButton from 'components/shared/SaveCartButton';
import ExportPDFButton from 'components/shared/ExportPDFButton';
import { BackButton, Text } from 'components/shared';
import { CustomerInfo } from 'components/product/ProductPageHeader/components/CustomerInfo';
import { Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { GroupCustomerInfo } from '../../product/ProductPageHeader/components/GroupCustomerInfo';
import styles from './ValidationHeader.module.scss';

const ValidationHeader: FC = () => {
  const isSmallTablet = useIsSmallTablet();
  const isGroup = useSelector(selectIsGroup);

  return (
    <>
      <PageHeader ghost={false} className={styles.header}>
        <div className={styles.content}>
          <Text type="subtitle-gray8">Voici le résumé de votre commande</Text>
          <Space direction="horizontal" align="center" className={styles.content__left}>
            {!isGroup && <CustomerInfo />}
            {isGroup && <GroupCustomerInfo />}
            {!isSmallTablet && <BackButton step="3" />}
            <SaveCartButton />
            <ExportPDFButton />
          </Space>
        </div>
      </PageHeader>
    </>
  );
};

export default ValidationHeader;
