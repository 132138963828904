import { FC } from 'react';
import { ITextKeyValueProps } from './TextKeyValue.types';
import styles from './TextKeyValue.module.scss';

const TextKeyValue: FC<ITextKeyValueProps> = (props: ITextKeyValueProps) => {
  const { size = 'medium', label, value, className = '' } = props;

  const textSizeStyles = () => {
    switch (size) {
      case 'large':
        return styles.text_large;
      case 'medium':
        return styles.text_medium;
    }
  };

  return (
    <p className={`${styles.text} ${textSizeStyles()} ${className}`}>
      <span className={styles.text__label}>{label}</span>
      <span className={styles.text__value}>{value}</span>
    </p>
  );
};

export default TextKeyValue;
