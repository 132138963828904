import { handleCatchError, isFulfilledAction, isPendingAction, isRejectedAction } from 'utils';
import moment from 'moment';
import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ABANDONED_CARTS_REDUCER_NAME } from '../common';
import { unwrap } from '../../utils/api.utils';
import { IAbandonedCart, IAbandonedCartParams } from '../../types/abandonedCart';
import { ErrorType, PaginatedResponse } from '../../types';
import { CartService } from '../../services/cart.service';
import { IAbandonedCartsState } from './types';

export const fetchAbandonedCarts: AsyncThunk<
  PaginatedResponse<IAbandonedCart>,
  { filters: IAbandonedCartParams; page: number; size: number },
  { rejectValue: ErrorType }
> = createAsyncThunk(`${ABANDONED_CARTS_REDUCER_NAME}/fetchCartRequests`, async (data, { rejectWithValue }) => {
  try {
    return unwrap(await CartService.getAbandonedCarts(data.filters, data.page, data.size));
  } catch (err) {
    return handleCatchError(err, rejectWithValue);
  }
});

const initialState: IAbandonedCartsState = {
  isLoading: false,
  page: 1,
  total: 0,
  limit: 30,
  filters: {},
  error: null,
  abandonedCartsList: [],
};

const abandonedCartsSlice = createSlice({
  name: ABANDONED_CARTS_REDUCER_NAME,
  initialState,
  reducers: {
    clearAbandonedCartsState() {
      return { ...initialState };
    },
    clearAbandonedCarts(state) {
      state.abandonedCartsList = [];
    },
    setPagination(state, { payload }) {
      state.page = payload.page ?? 1;
      state.limit = payload.limit;
    },
    resetAbandonedCartParams(state) {
      state.page = 1;
      state.total = 0;
      state.limit = 30;
      state.filters = {};
      state.abandonedCartsList = [];
    },
    setFilters(state, { payload }) {
      state.filters = payload;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbandonedCarts.fulfilled, (state, { payload }) => {
        state.abandonedCartsList = payload.data.filter((abandonedCart) => {
          const abandonedCartCreateDate = moment(abandonedCart.createdAt).utc(false);
          const currentDate = moment().utc(false);
          return currentDate.diff(abandonedCartCreateDate, 'minutes') > 15;
        });
        state.total = payload.total;
      })
      .addMatcher(isPendingAction(`${ABANDONED_CARTS_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${ABANDONED_CARTS_REDUCER_NAME}/`), (state, { payload }) => {
        return {
          ...initialState,
          error: payload,
        };
      })
      .addMatcher(isFulfilledAction(`${ABANDONED_CARTS_REDUCER_NAME}/`), (state) => {
        state.isLoading = false;
        state.error = null;
      });
  },
});
export const { setFilters, resetAbandonedCartParams, setPagination } = abandonedCartsSlice.actions;
export default abandonedCartsSlice.reducer;
