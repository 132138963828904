import { getUnits, selectAddProductModalVisible, setAddProductModalVisible } from 'store/products';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Modal } from '../../shared';
import { selectLanguage } from '../../../store/settings';
import { AddProductForm } from './components/AddProductForm';

const AddProductModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = useSelector(selectLanguage);
  const visible = useSelector(selectAddProductModalVisible);

  const closeModal = () => {
    dispatch(setAddProductModalVisible(false));
  };

  useEffect(() => {
    if (visible) {
      dispatch(getUnits({ locale }));
    }
  }, [dispatch, locale, visible]);

  return (
    <Modal
      centered={false}
      width={600}
      style={{ top: 20 }}
      title={t('modals.addProductModalTitle')}
      open={visible}
      destroyOnClose
      onCancel={closeModal}
      footer={null}
    >
      <AddProductForm onFinish={closeModal} />
    </Modal>
  );
};

export default AddProductModal;
