export const cart_requests_en = {
  pageTitle: 'Customer quote requests',
  cartRequestId: 'Requested ID',
  quoteNumber: 'Quote number',
  client: 'Client',
  delivery: 'Delivery',
  deliveryPeriod: 'Delivery period',
  status: 'Status',
  assignedTo: 'Assigned to',
  createdAt: 'Created at',
  actions: 'Actions',
  draft: 'Draft',
  pending: 'Waiting for quote',
  inProgress: 'Quoting in progress',
  sentToCustomer: 'Sent to customer',
  abandoned: 'Abandoned',
  recovered: 'Recovered',
  converted: 'Converted',
  refused: 'Refused',
  terminated: 'Processed',
  unassigned: 'Unassigned',
  assigned: 'Assign',
  actionButton: {
    pending: 'Quote',
    inProgress: 'Quote',
    sentToCustomer: 'See quote',
    refused: 'See quote',
    converted: 'See quote',
  },
  processStatus: {
    0: 'Not quoted yet',
    1: 'Quoted',
  },
  processedItems: 'Quoted products',
  markAsQuoted: 'Mark as quoted',
  nameSurnameCompanyName: 'Name, Surname, Company Name',
  productInfo: 'Product info',
};
