export const order_history_en = {
  orderHistory: 'Orders',
  orderNumber: 'Order number',
  status: 'Status',
  client: 'Client',
  creationDate: 'Creation date',
  netTotal: 'Amount excl. VAT',
  total: 'Amount incl. VAT',
  display: 'Display',
  name: 'Name',
  surname: 'Surname',
  company: 'Company',
  thirdPartyNumber: 'Third party number',
  tableOrderNumber: 'Order number',
  tableStatus: 'Status',
  deleteFilters: 'Delete filters',
  filterResults: 'Filter results',
  up: 'UP',
  qty: 'Qty',
  sku: 'SKU',
  details: 'Details',
  page: 'Page',
  productInfo: 'Product Info',
  code: 'Code',
  pu: 'PU',
  notAllowedOrderList: 'You are not allowed to view orders.',
};
