import { useIsMobile } from 'utils/styles.utils';
import { DEFAULT_ORDER_FILTER, getOrderStatus } from 'utils/order.utils';
import { IOrdersFilterParams } from 'types/order';
import {
  fetchOrdersHistory,
  selectIsLoadingOrders,
  selectOrderHistoryList,
  selectOrderHistoryParams,
  setPagination,
  clearFilters,
} from 'store/ordersHistory';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Button } from '../../shared';
import { useAppDispatch } from '../../../store/store';
import { IOrderHistoryEntry } from '../../../store/ordersHistory/types';
import { useToken } from '../../../cookies';
import { Client, OrderDate, OrderStatus, TotalPrice } from './components/TableColumns';
import OrdersFilters from './components/OrdersFilters';
import OrderHistoryDetails from './components/OrderHistoryDetails';
import styles from './OrdersHistoryTable.module.scss';

const OrdersHistoryTable: FC = () => {
  const ordersList = useSelector(selectOrderHistoryList);
  const { page, size, total, filters } = useSelector(selectOrderHistoryParams);
  const isLoading = useSelector(selectIsLoadingOrders);

  const [orderDetailsId, setOrderDetailsId] = useState<string | null>(null);
  const { token } = useToken();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(
      setPagination({
        page: 1,
        size: 30,
      }),
    );

    return () => {
      dispatch(clearFilters());
      dispatch(
        setPagination({
          page: 1,
          size: 30,
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchOrdersHistory({
        token,
        filters: {
          status: DEFAULT_ORDER_FILTER,
        },
        page: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRowExpanded = (orderId: string) => {
    return Boolean(orderId && orderId === orderDetailsId);
  };

  const handleClickDetails = (orderId: string) => {
    if (orderId && orderDetailsId === orderId) {
      setOrderDetailsId(null);
    } else {
      setOrderDetailsId(orderId);
    }
  };

  const TABLE_COLUMNS: Array<TableColumnDefinition<IOrderHistoryEntry>> = [
    {
      title: <span className={styles.col}>{t('order_history.tableOrderNumber')}</span>,
      dataIndex: 'number',
      align: 'left',
      render: (number: string) => <div>{number}</div>,
    },
    {
      title: <span className={styles.col}>{t('order_history.client')}</span>,
      align: 'left',
      dataIndex: undefined,
      render: (order) => (
        <Client
          firstName={order.firstName}
          lastName={order.lastName}
          company={order?.company ?? ''}
          customerIdentifier={order.customerIdentifier}
        />
      ),
    },
    {
      title: <span className={styles.col}>{t('order_history.tableStatus')}</span>,
      dataIndex: 'status',
      width: '16%',
      align: 'center',
      render: (status) => <OrderStatus status={getOrderStatus(status)} />,
    },
    {
      title: <span className={styles.col}>{t('order_history.creationDate')}</span>,
      dataIndex: 'createdAt',
      align: 'center',
      render: (createdAt) => <OrderDate date={createdAt} />,
    },
    {
      title: <span className={styles.col}>{t('order_history.netTotal')}</span>,
      dataIndex: 'totalItemsHT',
      align: 'center',
      render: (itemsNetTotal) => <TotalPrice price={itemsNetTotal} />,
    },
    {
      title: <span className={styles.col}>{t('order_history.details')}</span>,
      dataIndex: 'number',
      align: 'center',
      render: (number) => (
        <Button
          icon={<UnorderedListOutlined />}
          className={styles.detailsButton}
          type="secondary"
          onClick={() => handleClickDetails(number)}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('order_history.orderHistory')}</h3>
      <OrdersFilters />
      <Table
        columns={TABLE_COLUMNS}
        size={isMobile ? 'small' : 'large'}
        className={styles.container__table}
        rowClassName={styles.row}
        rowKey={(order) => order.number}
        loading={isLoading}
        dataSource={ordersList}
        pagination={{ style: { display: 'none' }, pageSize: ordersList.length }}
        expandable={{
          defaultExpandAllRows: true,
          indentSize: 0,
          expandedRowClassName: () => styles.expandableRow,
          expandRowByClick: false,
          expandedRowRender: ({ orderItems = [] }: IOrderHistoryEntry) => <OrderHistoryDetails items={orderItems} />,
          rowExpandable: ({ number }) => isRowExpanded(number),
          expandIcon: () => null,
          expandIconColumnIndex: -1,
          expandedRowKeys: ordersList.map((order) => order.number),
        }}
      />
      {ordersList.length > 0 && (
        <Pagination
          className={styles.container__pagination}
          defaultCurrent={1}
          total={total}
          disabled={false}
          current={page}
          pageSize={size}
          defaultPageSize={30}
          pageSizeOptions={['30', '50', '100']}
          onChange={(current, pageSize) => {
            const pageToGo = pageSize === size ? current : 1;
            dispatch(
              setPagination({
                page: pageToGo,
                size: pageSize,
              }),
            );
            dispatch(
              fetchOrdersHistory({
                token,
                filters: filters as unknown as IOrdersFilterParams,
                page: pageToGo,
                size: pageSize,
              }),
            );
          }}
        />
      )}
    </div>
  );
};

export default OrdersHistoryTable;
