import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Modal, Text, Button } from 'components/shared';
import { Col, Row } from 'antd';
import { ConfirmationModalProps } from './types';
import styles from './styles.module.scss';

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  onClose,
  onConfirm,
  title,
  children,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const show = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };

  const handleClose = () => {
    onClose();
    hide();
  };

  const handleConfirm = () => {
    onConfirm();
    hide();
  };

  return (
    <>
      {children(show)}
      <Modal width="640px" className={styles.modal} open={open} onCancel={handleClose}>
        <Row>
          <Col span={22}>
            <Text type="title">{title}</Text>
          </Col>
          <Col span={22}>
            <Row className={styles.actions} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={11}>
                <Button onClick={handleConfirm}>{t('modals.confirmationModalAcceptButton')}</Button>
              </Col>
              <Col span={11}>
                <Button onClick={handleClose} type="dashed">
                  {t('modals.confirmationModalDeclineButton')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
