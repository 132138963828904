import { IGroupAddress, IGroupCustomer } from 'types/groups';
import { ErrorType, ICustomer, ICustomerGroup, ISearchedCustomer, IShippingAddresses } from 'types';
import { ApiOkResponse } from 'apisauce';

export interface ICustomersGroupSelector {
  groupId: number;
  name: string;
  customersList: Array<ICustomerGroup>;
}

export interface INormalizedCustomersList {
  [id: number]: ICustomer;
}

export interface INormalizedGroupCustomersList {
  [id: string]: ICustomerGroup;
}

export type ICustomersFetchState = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

export type Whitelist = {
  isLoading: boolean;
  filter: string | null;
  total: number;
  page: number;
};

export type GroupWhitelist = {
  isLoading: boolean;
  search: string | null;
  total: number;
  page: number;
  data: Array<IGroupCustomer>;
};

export interface CustomersState {
  isLoading: boolean;
  isGroup: boolean;
  error: ErrorType | null;
  customers: INormalizedCustomersList;
  whitelist: Whitelist;
  groupWhitelist: GroupWhitelist;
  selectedCustomer?: number;
  selectedGroupCustomers: INormalizedGroupCustomersList;
  selectedSearchedCustomer?: ISearchedCustomer;
  selectedSearchedGroupCustomer?: ISearchedCustomer;
  selectedGroupId?: number;
  groupAddress: INormalizedGroupAddressList;
  groupTags: INormalizedGroupTagsList;
  shippingAddresses: Array<IShippingAddresses>;
}

export interface INormalizedGroupAddressList {
  [id: string]: Array<IGroupAddress>;
}

export interface INormalizedGroupTagsList {
  [id: string]: Array<string>;
}

export type IFetchCustomers = {
  searchId: number;
  token: string;
  email: string;
};

export type IFetchCustomersWhitelist = {
  filter: string | null;
  skip: number;
  limit: number;
  token: string;
};

export type IFetchGroupCustomersWhitelist = {
  search: string | null;
  skip: number;
  limit: number;
  token: string;
};

export type IFetchGroupsOkResponse = {
  data: Array<IGroupCustomer>;
};

export const areGroupsAvailable = <K>(objectTownResponse: unknown): objectTownResponse is ApiOkResponse<K> =>
  (objectTownResponse as ApiOkResponse<K>).data !== null;
