import { unwrap } from 'utils/api.utils';
import { isTownAvailable } from 'utils';
import { ITown } from 'types';
import { PostcodeApiService } from 'services/postcodeServiceApi';
import { ApiResponse } from 'apisauce';
import { ERRORS } from 'constants/errors';

interface FetchTownsStateAdapterProps {
  townId: number;
}

export const fetchTownStateAdapter = async ({
  townId,
}: FetchTownsStateAdapterProps): Promise<ApiResponse<ITown, ITown>> => {
  return PostcodeApiService.getTownById(townId);
};

export const fetchTownSavedCartAdapter = fetchTownStateAdapter;

export const fetchTownCartRequestAdapter = fetchTownStateAdapter;

export const fetchTownAdapter = async (adapter: Promise<ApiResponse<ITown, ITown>>): Promise<ITown> => {
  return unwrap(await adapter, (response) => {
    if (!isTownAvailable<ITown>(response)) {
      return ERRORS.towns.noTowns;
    }
  });
};
