import { IOrderStatus } from 'types/order';

export const getOrderStatus = (status: number): IOrderStatus => {
  switch (status) {
    case 0:
      return { type: 0, label: 'Attente de Paiement' };
    case 1:
      return { type: 1, label: 'Payée' };
    case 2:
      return { type: 2, label: 'En attente' };
    case 3:
      return { type: 3, label: 'En traitement' };
    case 4:
      return { type: 4, label: 'Expédiée' };
    case 5:
      return { type: 5, label: 'Livrée' };
    case 6:
      return { type: 6, label: 'Annulée' };
    default:
      return { type: -1, label: '' };
  }
};

export const filterEmptyObjectKeys = <T extends Record<string, unknown>>(parameters: T): T => {
  Object.keys(parameters).forEach((key) => {
    if (parameters[key] === undefined || parameters[key] === '' || parameters[key] === null) {
      delete parameters[key];
    }
  });
  return parameters;
};

export const ORDER_STATUS = [
  { label: 'Attente de Paiement', value: 0 },
  { label: 'Payée', value: 1 },
  { label: 'En attente', value: 2 },
  { label: 'En traitement', value: 3 },
  { label: 'Expédiée', value: 4 },
  { label: 'Livrée', value: 5 },
  { label: 'Annulée', value: 6 },
];

export const DEFAULT_ORDER_FILTER = [0, 1, 2, 3];
