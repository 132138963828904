import { priceParser } from 'utils';
import { SavedCartStatusEnum } from 'store/savedCart/types';
import { useTranslation } from 'react-i18next';
import { FC, memo } from 'react';
import moment from 'moment';
import { useLocaleDateFormat } from 'hooks/useDateFormat';
import { SavedCartTable } from '../../../../../types/savedCart';
import styles from './styles.module.scss';

const OrderDate: FC<{ date: string }> = memo(({ date }: { date: string }) => {
  if (date) {
    const datesFormat = 'lll';
    const momentDate = moment(date).utc(true).format(datesFormat);
    return <>{momentDate}</>;
  }
  return null;
});

const TotalPrice: FC<{ price: number }> = memo(({ price }: { price: number }) => {
  return <div>{priceParser(price)} </div>;
});

const Client: FC<{ customerName: string; customerIdentifier: string; company: string; email?: string }> = memo(
  ({
    customerName,
    customerIdentifier,
    company,
    email,
  }: {
    customerName: string;
    customerIdentifier: string;
    company: string;
    email?: string;
  }) => {
    return (
      <div className={styles.client}>
        <div className={styles.customerName}>{customerName}</div>
        <div>{company}</div>
        <div>{customerIdentifier}</div>
        {email && <div>{email}</div>}
      </div>
    );
  },
);

const StepNumber: FC<{ stepNumber: number }> = memo(({ stepNumber }: { stepNumber: number }) => {
  return <>{stepNumber ? <div>{stepNumber}/4</div> : '-'}</>;
});

type CartStatusProps = Pick<
  SavedCartTable,
  'convertedAt' | 'refusedAt' | 'expired' | 'expiredAt' | 'sentToCustomerAt' | 'status'
>;

const CartStatus: FC<CartStatusProps> = memo(
  ({ convertedAt, refusedAt, expired, expiredAt, sentToCustomerAt, status }: CartStatusProps) => {
    const { t } = useTranslation();
    const localeDateFormat = useLocaleDateFormat('short');
    const expireDate = moment(expiredAt).utc(true).format('lll');

    let statusClass = styles.statusInPending;
    let statusLabel = t('cartRequests.pending');
    let dateLabel = (
      <span>
        {t(expired ? 'common.expiredAt' : 'common.expiresOn')}: {expireDate}
      </span>
    );

    switch (status) {
      case SavedCartStatusEnum.IN_PROGRESS: {
        statusClass = styles.statusInProgress;
        statusLabel = t('cartRequests.inProgress');
        dateLabel = (
          <span>
            {t(expired ? 'common.expiredAt' : 'common.expiresOn')}: {expireDate}
          </span>
        );

        break;
      }
      case SavedCartStatusEnum.SENT_TO_CUSTOMER: {
        const sentToCustomerDate = moment(sentToCustomerAt).utc(true).format('lll');
        statusClass = styles.statusSentToCustomer;
        statusLabel = t('cartRequests.sentToCustomer');
        dateLabel = (
          <>
            <span>
              {t('common.sentToCustomerAt')}: {sentToCustomerDate}
            </span>
            <span>
              {t(expired ? 'common.expiredAt' : 'common.expiresOn')}: {expireDate}
            </span>
          </>
        );
        break;
      }
      case SavedCartStatusEnum.REFUSED: {
        const refusedDate = moment(refusedAt).utc(true).format(localeDateFormat);
        statusClass = styles.statusRefused;
        statusLabel = t('common.refusedStatus');
        dateLabel = (
          <span>
            {t('common.refusedAt')}: {refusedDate}
          </span>
        );

        break;
      }

      case SavedCartStatusEnum.CONVERTED: {
        const convertedDate = moment(convertedAt).utc(true).format(localeDateFormat);
        statusClass = styles.statusConverted;
        statusLabel = t('common.convertedStatus');
        dateLabel = (
          <span>
            {t('common.convertedAt')}: {convertedDate}
          </span>
        );

        break;
      }
      default:
        break;
    }

    return (
      <div>
        <div className={`${styles.status} ${statusClass}`}>{statusLabel}</div>
        <div className={`${styles.statusDateLabel}`}>{dateLabel}</div>
      </div>
    );
  },
);

export { OrderDate, StepNumber, TotalPrice, Client, CartStatus };
