import { ICartProductItem } from 'store/cart/types';

export type ICartRowInfoProps = {
  product: ICartProductItem;
  productIndex: number;
  displayHistoricalPriceModal?: (sku: string) => void;
  billingType: BILLING_TYPE;
};

// eslint-disable-next-line no-shadow
export enum BILLING_TYPE {
  SINGLE,
  MULTIPLE,
}
