import { useBlocker } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Col, Row } from 'antd';
import { delay } from '../utils/loading.utils';
import { useAppDispatch, useAppSelector } from '../store/store';
import { SavedCartStatusEnum } from '../store/savedCart/types';
import { saveSavedCart, selectIsLoadingSavedCart } from '../store/savedCart';
import { clearProductsState } from '../store/products';
import { clearDeliveryState } from '../store/delivery';
import { clearCustomersState } from '../store/customers';
import { clearCartState, getIsCartUpdated, selectCartStatus } from '../store/cart';
import { getOrderPath, OrderRoute } from '../constants/routes';
import { Button, Modal, Text } from '../components/shared';
import styles from '../components/savedCart/SavedCartTable/components/DeleteConfirmationModal/styles.module.scss';
import { useStepper } from './stepper';

export const usePageBlocker = () => {
  const { t } = useTranslation();
  const isLoadingSavedCart = useAppSelector(selectIsLoadingSavedCart);
  const savedCartStatus = useSelector(selectCartStatus);
  const isCartUpdated = useAppSelector(getIsCartUpdated);
  const dispatch = useAppDispatch();
  const { currentStep } = useStepper();
  const stepNumber = (currentStep?.index ?? 1) + 1;

  const blockedActionStatus = useMemo(() => {
    if (!savedCartStatus) return false;
    return [SavedCartStatusEnum.CONVERTED, SavedCartStatusEnum.REFUSED].includes(savedCartStatus);
  }, [savedCartStatus]);

  const ALLOWED_PATHS = useMemo(
    () => [
      '/order(?:/[a-zA-Z0-9-]+)?/' + OrderRoute.CUSTOMER,
      '/order(?:/[a-zA-Z0-9-]+)?/' + OrderRoute.PRODUCT,
      '/order(?:/[a-zA-Z0-9-]+)?/' + OrderRoute.VALIDATION,
      '/order(?:/[a-zA-Z0-9-]+)?/' + OrderRoute.DELIVERY,
      '/validated/order',
    ],
    [],
  );

  const blocker = useBlocker(({ nextLocation }) => {
    return !ALLOWED_PATHS.find((path) => nextLocation.pathname.match(path)) && isCartUpdated && !blockedActionStatus;
  });

  const handleClose = () => {
    Promise.all([
      dispatch(clearCartState()),
      dispatch(clearCustomersState()),
      dispatch(clearProductsState()),
      dispatch(clearDeliveryState()),
    ]);
    blocker.proceed?.();
  };

  const handleConfirm = async () => {
    await dispatch(saveSavedCart({ stepNumber, savePdfVariables: true, allowEmptySave: true }));
    handleClose();
  };

  const confirmationModal = (
    <Modal
      width="640px"
      className={styles.modal}
      centered={true}
      closable={true}
      open={blocker.state === 'blocked'}
      onCancel={handleClose}
    >
      <Row>
        <Col span={24}>
          <Text type="title">{t('modals.leavePageConfirmationTitle')}</Text>
        </Col>
        <Col span={24}>
          <Row className={styles.actions} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Button key={'cancel'} type={'dashed'} onClick={handleClose}>
                {t('modals.leavePageConfirmationDeclineButton')}
              </Button>
            </Col>
            <Col span={12}>
              <Button type={'primary'} key={'accept'} loading={delay(isLoadingSavedCart)} onClick={handleConfirm}>
                {t('modals.leavePageConfirmationAcceptButton')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
  return { blocker, confirmationModal };
};
