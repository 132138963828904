import { FC } from 'react';
import { IPopoverProps } from 'components/shared/Popover';
import ADPopover from 'antd/lib/popover';

const Popover: FC<IPopoverProps> = ({ className, ...props }: IPopoverProps) => {
  className = className || '';

  return <ADPopover overlayClassName={`${className}`} {...props} />;
};

export default Popover;
