import { unwrap } from 'utils/api.utils';
import { ICustomer } from 'types';
import { CustomersApiService } from 'services/customersServiceApi';
import { ApiResponse } from 'apisauce';

interface FetchCustomersStateAdapterProps {
  searchId: number;
  token: string;
}

export const fetchCustomersStateAdapter = async ({
  searchId,
  token,
}: FetchCustomersStateAdapterProps): Promise<ApiResponse<Array<ICustomer>>> => {
  return CustomersApiService.getCustomers(searchId, token);
};

export const fetchCustomersSavedCartAdapter = fetchCustomersStateAdapter;

export const fetchCustomersAdapter = async (
  adapter: Promise<ApiResponse<Array<ICustomer>>>,
): Promise<Array<ICustomer>> => {
  return unwrap(await adapter);
};
