import { FC } from 'react';
import { LoginLogoProps } from 'pages/Login/LoginLogo/types';
import rightLogo from 'assets/images/img_logo.png';
import { Image } from 'antd';
import styles from './styles.module.scss';

const LoginLogo: FC<LoginLogoProps> = (_props: LoginLogoProps) => {
  return (
    <>
      <Image preview={false} className={styles.logo} src={rightLogo} alt="logo" />
    </>
  );
};

export default LoginLogo;
