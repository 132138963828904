export const users_en = {
  pageTitle: 'Users',
  edit: 'Edit',
  delete: 'Delete',
  confirmDelete: 'Are you sure you want to delete this user?',
  groups: 'Groups',
  email: 'Email',
  actions: 'Actions',
  nextPage: 'Next page',
  prevPage: 'Previous page',
  newUserPageTitle: 'New User',
  editUserPageTitle: 'Edit User',
  personalInformation: 'Personal Information',
  lastname: 'Last name',
  firstname: 'First name',
  username: 'Username',
  phoneNumber: 'Phone Number',
  saveChanges: 'Save',
  newUserAdded: 'New user added successfully',
  userDetailsUpdated: 'User details updated successfully',
  yourGroups: 'Your Groups',
  noGroups: 'No groups',
  addUser: 'Add User',
  selectFilter: 'Select filter',
  applyFilter: 'Apply filter',
  clearFilter: 'Clear filter',
};
