import { FC } from 'react';
import { useTranslation } from 'hooks';
import { Text } from 'components/shared';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ValidationNoStockProduct from '../ValidationNoStockProduct';
import styles from '../ValidationContainer/ValidationContainer.module.scss';
import { ICartProductItem } from '../../../store/cart/types';

export interface IValidationNoStockContainerProps {
  product: ICartProductItem;
}

const ValidationNoStockContainer: FC<IValidationNoStockContainerProps> = (props: IValidationNoStockContainerProps) => {
  const { product } = props;
  const trans = useTranslation('Cart');

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.left}>
          <ExclamationCircleOutlined color={'red'} />
        </div>
        <div className={styles.right}>
          <Text type="title-gray8" className={styles.text}>
            {trans('Out of stock')}
          </Text>
        </div>
      </div>
      <ValidationNoStockProduct key={`${product.productId}-${product.variantId}`} product={product} />
    </div>
  );
};

export default ValidationNoStockContainer;
