import { RootState } from 'store/rootReducer';
import { IAbandonedCart, IAbandonedCartParams } from '../../types/abandonedCart';

export const selectAbandonedCartsList = (state: RootState): Array<IAbandonedCart> =>
  Object.values(state.abandonedCarts.abandonedCartsList);

export const selectAbandonedCartsListById =
  (abandonedCartId: number) =>
  (state: RootState): IAbandonedCart => {
    return <IAbandonedCart>(
      Object.values(state.abandonedCarts.abandonedCartsList).find(
        (abandonedCart) => abandonedCart.cartId === abandonedCartId,
      )
    );
  };

export const isLoadingAbandonedCarts = (state: RootState): boolean => state.abandonedCarts.isLoading;

export const selectAbandonedCartsLimit = (state: RootState): number => state.abandonedCarts.limit;
export const selectAbandonedCartsTotal = (state: RootState): number => state.abandonedCarts.total;
export const selectAbandonedCartsFilters = (state: RootState): IAbandonedCartParams => state.abandonedCarts.filters;
