import { priceParser } from 'utils';
import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo } from 'react';
import { useTheme } from 'hooks/useTheme';
import { Text } from 'components/shared';
import Price from '../Price/Price';
import DiscountTooltip from '../DiscountTooltip/DiscountTooltip';
import { DiscountType } from '../Discount/Discount.types';
import { IProductPriceProps } from './ProductPrice.types';
import styles from './ProductPrice.module.scss';

const ProductPrice: FC<IProductPriceProps> = memo((props: IProductPriceProps) => {
  const {
    discountOffers,
    initialPrice,
    price,
    className,
    isProduct,
    discountType,
    discountValue,
    conditioning,
    conditioningQuantity,
    unit,
    taxes,
  } = props;

  const themeStyle = useTheme(styles);
  const { t } = useTranslation();

  const symbolType = (dt = DiscountType.PERCENTAGE) => {
    return dt === DiscountType.PERCENTAGE ? '%' : '€';
  };

  /**
   * If the initialPrice and price differs it means there is a discount already applied to the PRICE
   */
  const hasPriceDifference = initialPrice !== undefined && initialPrice !== price;

  /**
   * Check if there are any discount offers
   */
  const hasDiscountOffers = discountOffers && discountOffers.length > 0;

  /**
   * Display the discount value if there is a discount offer or a discount value
   */

  const discountDisplay = useMemo(() => {
    let discount = discountValue ? discountValue / 100 : 0;
    const symbol = symbolType(discountType);
    if (discountValue) {
      discount = discountValue / 100;
    }
    if (discount !== 0) {
      return (
        <span className={`${styles.price} ${styles.price_discount}`}>
          {discount} {symbol}
        </span>
      );
    }
  }, [discountValue, discountType]);

  return (
    <div className={`${styles.block} ${className}`}>
      {initialPrice !== undefined && hasPriceDifference && (
        <span className={`${styles.price} ${styles.price_prev}`}>{initialPrice / 100} €</span>
      )}
      {discountDisplay}
      {price !== undefined && (
        <>
          {!isProduct && (
            <Text type="subtitle-gray8">
              {priceParser(price)}
              {unit && <span className={styles.price_conditioning_label}>{` / ${unit?.singular}`}</span>}
            </Text>
          )}
          {isProduct && (
            <>
              <Price unitPrice={price} unit={unit?.singular} />
              {conditioning && conditioningQuantity && conditioningQuantity !== 1 && (
                <Price
                  unitPrice={conditioningQuantity * price}
                  unit={conditioning}
                  className={styles.price_conditioning}
                />
              )}
            </>
          )}
          {taxes?.rpd && (
            <Text className={themeStyle('tax')} type="subtitle-gray8">{`${t('common.rpdNotIncluded')}: ${priceParser(
              taxes.rpd,
            )}`}</Text>
          )}
          {taxes?.cvo && (
            <Text className={themeStyle('tax')} type="subtitle-gray8">{`CRIV: ${priceParser(taxes.cvo)}`}</Text>
          )}
        </>
      )}
      {hasDiscountOffers && <DiscountTooltip discounts={discountOffers} label={unit} />}
    </div>
  );
});

export default ProductPrice;
