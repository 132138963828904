import { isDeliveryOverriden } from 'utils/delivery.utils';
import { editFloatNumber, getDeliveryDate, isPriceEditPreventedByOfferType, quantityParser, showDiscount } from 'utils';
import { IOrder } from 'types/order';
import { selectCategoriesLabels, selectShippingCategoriesById } from 'store/delivery';
import { selectIsGroup } from 'store/customers';
import { ICartProductItem } from 'store/cart/types';
import { selectOrderByOfferId, selectTruckCustomerInfo, selectTruckInfo } from 'store/cart';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import env from 'config';
import Price from 'components/shared/Price/Price';
import { InitialPrice, TagsList, Text } from 'components/shared';
import styles from './ValidationProduct.module.scss';

type IValidationProductProps = {
  order: IOrder;
  product: ICartProductItem;
  truckNumber?: string;
  customerIdentifier?: string;
};

const ValidationProduct: FC<IValidationProductProps> = (props: IValidationProductProps) => {
  const { product, truckNumber, customerIdentifier, order } = props;
  const {
    name,
    quantity,
    price,
    tags,
    unit,
    quantityIncrement,
    conditioning,
    totalPrice,
    cvo = 0,
    offerId,
    productOptions,
    offerPrice,
    offerType,
    conditioningQuantity,
    discountValue,
    discountType,
  } = product;
  const { t } = useTranslation();
  const categoriesLabels = useSelector(selectCategoriesLabels);
  const getProductGroupQuantity = () => {
    if (!truckNumber && conditioningQuantity && productInfo?.selectedQuantity)
      return productInfo?.selectedQuantity / conditioningQuantity;
    return '';
  };

  const getProductVolume = () => {
    if (productInfo) return editFloatNumber(productInfo.selectedQuantity);
    return '';
  };

  const getProdRpdTaxes = () => {
    if (product.taxes.rpd && !!productVolume) return Number(productVolume) * product.taxes.rpd;
    return 0;
  };

  const getTruckRpdTaxes = () => {
    if (product.taxes.rpd && !!truckVolume) return Number(truckVolume) * product.taxes.rpd;
    return 0;
  };

  const orderItem = useSelector(selectOrderByOfferId(truckNumber ? offerId + '-' + truckNumber : offerId.toString()));
  const productInfo = useSelector(selectTruckCustomerInfo(Number(truckNumber), customerIdentifier, offerId));
  const truckInfo = useSelector(selectTruckInfo(Number(truckNumber), offerId));
  const isGroup = useSelector(selectIsGroup);
  const shippingCategory = useSelector(selectShippingCategoriesById(parseInt(orderItem?.shippingMethodId || '-1')));
  const truckVolume =
    productInfo && conditioningQuantity ? editFloatNumber(productInfo.selectedQuantity * conditioningQuantity) : '';
  const productVolume = getProductVolume();
  const productGroupQuantity = getProductGroupQuantity();
  const prodRpdTaxes = getProdRpdTaxes();
  const truckRpdTaxes = getTruckRpdTaxes();
  const hasDeliveryDateOverride = !order.isTruck && isDeliveryOverriden(product);

  const getPrice = () => {
    if (truckNumber)
      return <Price unitPrice={price.price * Number(truckVolume) + truckRpdTaxes} className={styles.totalPrice} />;
    return <Price unitPrice={price.price * Number(productVolume) + prodRpdTaxes} className={styles.totalPrice} />;
  };

  const getQtyText = () => {
    if (quantityIncrement * quantity >= 2) return unit.plural;
    return unit.singular;
  };

  const getTruckVolume = () => {
    if (truckNumber) return truckVolume;
    return productVolume;
  };

  const getTotal = () => {
    if (isGroup)
      return (
        <Text type="subtitle-gray1" className={styles.total}>
          Total : {getPrice()}
        </Text>
      );
    return (
      <Text type="subtitle-gray1" className={styles.total}>
        Total : <Price unitPrice={totalPrice - cvo} className={styles.totalPrice} />
      </Text>
    );
  };

  const getConditioning = () => {
    if (conditioning)
      return (
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.conditioning')}: {conditioning}
        </Text>
      );
  };

  const getQty = () => {
    if (isGroup)
      return (
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.quantity')}: {!truckNumber ? productGroupQuantity : 1}
        </Text>
      );
    return (
      <Text type="secondary-gray1" className={styles.field}>
        {t('common.quantity')}: {quantity}
      </Text>
    );
  };

  const getVolume = () => {
    if (isGroup)
      return (
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.volume')}: {getTruckVolume()} {getQtyText()}
        </Text>
      );
    return (
      <Text type="secondary-gray1" className={styles.field}>
        {t('common.volume')}: {quantityParser(quantity, quantityIncrement)} {getQtyText()}
      </Text>
    );
  };

  return (
    <div className={styles.product}>
      <div className={styles.delivery}>
        {orderItem && (
          <>
            <Text>{categoriesLabels[orderItem.shippingMethodId as unknown as number]}</Text>
            {!!env('enableOverrideDeliveryDate') && hasDeliveryDateOverride && (
              <>
                <Text type="subtitle-gray8">
                  {getDeliveryDate({
                    startDate: product?.overriddenDeliveryStartDate,
                    endDate: product?.overriddenDeliveryEndDate,
                  })}
                </Text>

                <Text type="subtitle-gray1" className={styles.deliveryOverride}>
                  {getDeliveryDate({
                    days: orderItem.deliveryDay,
                    startDate: orderItem.deliveryStartDate,
                    endDate: orderItem.deliveryEndDate,
                    delay: { min: shippingCategory.minDelay, max: shippingCategory.maxDelay },
                  })}
                </Text>
              </>
            )}
            {(!env('enableOverrideDeliveryDate') || !hasDeliveryDateOverride) && (
              <Text type="subtitle-gray8">
                {getDeliveryDate({
                  days: orderItem.deliveryDay,
                  startDate: orderItem.deliveryStartDate,
                  endDate: orderItem.deliveryEndDate,
                  delay: { min: shippingCategory.minDelay, max: shippingCategory.maxDelay },
                })}
              </Text>
            )}
          </>
        )}
      </div>
      <div className={styles.info}>
        <Text type="subtitle-third">{name}</Text>
        <Text type="secondary-gray3" className={'option'}>
          {productOptions}
        </Text>
        <Text type="secondary-gray1" className={styles.field}>
          {t('common.unitPrice')} : <InitialPrice {...price} unit={`${unit.singular} HT`} />
        </Text>
        {!isPriceEditPreventedByOfferType(offerType) && (
          <Text type="secondary-gray1" className={styles.field}>
            {t('common.forcedNetPrice')}:{' '}
            {<Price unitPrice={offerPrice} className={styles.unitPrice} unit={`${unit.singular} HT`} />}{' '}
            {showDiscount(discountValue, discountType)}
          </Text>
        )}
        {getQty()}
        {getVolume()}
        {getConditioning()}
        <TagsList tags={tags} />
        {truckNumber && (
          <div className={styles.truckNbr}>
            {t('common.truck')} : {truckInfo?.truckNumber}
          </div>
        )}
        <br />

        {getTotal()}
      </div>
    </div>
  );
};

export default ValidationProduct;
