import { priceParser } from 'utils';
import { useAppSelector } from 'store/store';
import { selectSuppliers } from 'store/products';
import { SupplierPriceType } from 'store/cart/types';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { Text } from 'components/shared';
import classNames from 'classnames';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './SupplierPrice.module.scss';

interface SupplierPriceProps {
  supplierUnitPrice: number | undefined;
  supplierPrice: Nullable<SupplierPriceType>;
}

const SupplierPrice: FC<SupplierPriceProps> = ({ supplierPrice, supplierUnitPrice }: SupplierPriceProps) => {
  const hasSupplierPriceOverrideTypeguard = (value: Nullable<SupplierPriceType>): value is SupplierPriceType => !!value;
  const hasSupplierUnitPrice = (value: number | undefined): value is number => !!value;
  const hasSupplierPriceOverride = hasSupplierPriceOverrideTypeguard(supplierPrice);
  const suppliers = useAppSelector(selectSuppliers);
  const { t } = useTranslation();
  const renderSupplierPriceInfo = useCallback(() => {
    if (!supplierPrice) {
      return null;
    }

    const { note, quantity, supplierId } = supplierPrice;
    const supplierName = suppliers.find(({ id }) => id === supplierId)?.name || '-';

    const hasNote = note && note.trim().length > 0;

    return (
      <div className={styles.tooltip__content}>
        <div className={styles.tooltip__content__subtitle}>{t('common.supplierName')}:</div>
        <div>{supplierName}</div>
        <div className={styles.tooltip__content__subtitle}>{t('common.quantity')}:</div>
        <div>{quantity}</div>
        {hasNote && (
          <div className={styles.tooltip__content__comment}>
            <div className={styles.tooltip__content__comment__title}>{t('common.comment')}</div>
            <div>{note}</div>
          </div>
        )}
      </div>
    );
  }, [supplierPrice, suppliers, t]);

  return (
    <div className={styles.wrapper}>
      {hasSupplierPriceOverrideTypeguard(supplierPrice) && (
        <Space direction="horizontal" size="small">
          <Tooltip overlayClassName={styles.tooltip} title={renderSupplierPriceInfo()}>
            <InfoCircleOutlined />
          </Tooltip>
          <Text type="subtitle-gray3">
            {priceParser(typeof supplierPrice === 'number' ? supplierPrice : supplierPrice.price)}
          </Text>
        </Space>
      )}
      {hasSupplierUnitPrice(supplierUnitPrice) && (
        <Text
          className={classNames(styles.supplierUnitPrice, {
            [styles.supplierUnitPrice__lineThrough]: hasSupplierPriceOverride,
          })}
          type="subtitle-gray3"
        >
          {priceParser(supplierUnitPrice)}
        </Text>
      )}
    </div>
  );
};

export default SupplierPrice;
