import { MIMETYPE_TO_EXTENSION } from 'constants/attachments';

export const extensionsToUploadAccept = (list: string[]): string => {
  if (!list.length) {
    return '';
  }

  return `.${list.join(',.')}`;
};

export const mimeTypeToExtensions = (mimeType: string): string[] | null => {
  const mtype = mimeType.toLowerCase();

  if (Object.prototype.hasOwnProperty.call(MIMETYPE_TO_EXTENSION, mtype)) {
    return MIMETYPE_TO_EXTENSION[mtype];
  }

  return null;
};
