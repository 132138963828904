import { FC } from 'react';
import { Form, Input } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { IFormInputProps } from './FormInput.type';
import styles from './FormInput.module.scss';

const FormInput: FC<IFormInputProps> = (props: IFormInputProps) => {
  const { className, inputClassName, inputProps = {}, ...formProps } = props;
  const { type = 'text', ...remainingInputProps } = inputProps;

  const getInput = () => {
    const inputStyles = `${styles.item__input} ${inputClassName || ''}`;

    switch (type) {
      case 'password':
        return (
          <Input.Password
            className={`${inputStyles} ${styles.item__input_password}`}
            {...remainingInputProps}
            iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
          />
        );
      case 'text':
        return <Input className={inputStyles} {...remainingInputProps} />;
    }
  };

  return (
    <Form.Item className={`${styles.item} ${className || ''}`} {...formProps}>
      {getInput()}
    </Form.Item>
  );
};

export default FormInput;
