import { isEmpty } from 'utils/object.utils';
import { selectTcTags, selectUser, selectUsername, setUser } from 'store/user';
import { AppThunkDispatch, useAppSelector } from 'store/store';
import { sendMessage } from 'store/messages';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Button, TagsList, Text } from 'components/shared';
import NotFound from 'components/layout/NotFound';
import { Col, Divider, Form, Input, Layout, Modal, Row } from 'antd';
import { CheckCircleFilled, LockOutlined } from '@ant-design/icons';
import { firstnameRules, lastnameRules, phoneNumberRules, usernameRules } from './Profile.validators';
import { IPasswordFormValues, IUserDetailsFormValues } from './Profile.types';
import styles from './Profile.module.scss';

const modalBodyStyles = {
  borderRadius: '10px',
  background: 'white',
};

const Profile: FC = () => {
  const user = useAppSelector(selectUser);
  const userId = useAppSelector(selectUsername);
  const { t } = useTranslation();
  const { editProfile, changePassword } = useAuth();
  const [userDetails] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const dispatch = useDispatch<AppThunkDispatch>();

  const [userFormValues, setUserFormValues] = useState<IUserDetailsFormValues>({
    firstname: user?.firstName || '',
    lastname: user?.lastName || '',
    username: user?.username,
    phoneNumber: user?.phoneNumber,
  });

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showUserDetailsSubmit, setShowUserDetailsSubmit] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const tcTags = useSelector(selectTcTags);
  const onSubmitUserDetailsForm = async (value: IUserDetailsFormValues) => {
    try {
      await editProfile(value);
      setShowSuccessDialog(true);
      setDialogText('Informations modifiées !');
      setShowUserDetailsSubmit(false);
      setUserFormValues(value);

      const reduxUser = {
        ...value,
        firstName: value.firstname,
        lastName: value.lastname,
        username: userId,
      };
      dispatch(setUser(reduxUser));
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => dispatch(sendMessage({ message: err.message, type: 'error' })));
      }
    }
  };

  const onSubmitPasswordForm = async (value: IPasswordFormValues) => {
    try {
      await changePassword(value.currentPassword, value.newPassword);
      setShowSuccessDialog(true);
      setDialogText('Mot de passe modifié !');
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => dispatch(sendMessage({ message: err.message, type: 'error' })));
      }
    } finally {
      passwordForm.resetFields();
      setShowPasswordForm(false);
    }
  };

  const checkIfFormValuesAreSame = (newValues: IUserDetailsFormValues) =>
    Object.entries(userFormValues).some(([key, value]) => (newValues as never)[key] !== value);
  const onUserDetailsChange = () => setShowUserDetailsSubmit(checkIfFormValuesAreSame(userDetails.getFieldsValue()));

  return (
    <>
      <Layout.Content className={styles.profile}>
        <h3 className={styles.title}>{t('my_profile.pageTitle')}</h3>
        <NotFound showOnRule showContent={!isEmpty(userFormValues)}>
          <Row className={styles.content}>
            <Col className={styles.content__main} span={11}>
              <h3 className={styles.subtitle}>{t('my_profile.yourPersonalInformation')}</h3>
              <Form
                form={userDetails}
                className={styles.form}
                layout="vertical"
                name="profile"
                initialValues={userFormValues}
                onFinish={onSubmitUserDetailsForm}
                onValuesChange={onUserDetailsChange}
              >
                <Form.Item
                  shouldUpdate
                  rules={firstnameRules}
                  className={styles.form__input}
                  label={t('my_profile.lastname')}
                  name="firstname"
                >
                  <Input className={styles.input} />
                </Form.Item>
                <Form.Item
                  rules={lastnameRules}
                  className={styles.form__input}
                  label={t('my_profile.firstname')}
                  name="lastname"
                >
                  <Input className={styles.input} />
                </Form.Item>
                <Form.Item
                  rules={usernameRules}
                  className={styles.form__input}
                  label={t('my_profile.username')}
                  name="username"
                >
                  <Input className={styles.input} disabled />
                </Form.Item>
                <Form.Item
                  rules={phoneNumberRules}
                  className={styles.form__input}
                  label={t('my_profile.phoneNumber')}
                  name="phoneNumber"
                >
                  <Input className={styles.input} />
                </Form.Item>
                <Form.Item className={styles.form__button}>
                  {showUserDetailsSubmit ? (
                    <Button type="primary" className={styles.button} htmlType="submit">
                      {t('my_profile.saveChanges')}
                    </Button>
                  ) : (
                    <div className={styles.buttonReplacer} />
                  )}
                </Form.Item>
              </Form>

              {showPasswordForm ? (
                <Form
                  form={passwordForm}
                  className={styles.form}
                  layout="vertical"
                  name="password"
                  onFinish={onSubmitPasswordForm}
                >
                  <Form.Item
                    className={styles.form__input}
                    label={t('my_profile.currentPassword')}
                    name="currentPassword"
                  >
                    <Input.Password required className={styles.input} />
                  </Form.Item>
                  <Form.Item
                    className={styles.form__input}
                    label={t('my_profile.changeYourPassword')}
                    name="newPassword"
                  >
                    <Input.Password required className={styles.input} />
                  </Form.Item>
                  <Form.Item className={styles.passwordBtnContainer}>
                    <Button className={styles.passwordFormBtn} type="default" htmlType="submit">
                      {t('my_profile.confirm')}
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <div className={styles.passwordBtnContainer}>
                  <Button
                    onClick={() => setShowPasswordForm(true)}
                    className={styles.passwordFormBtn}
                    type="default"
                    htmlType="submit"
                  >
                    <LockOutlined />
                    {t('my_profile.changeYourPasswordButton')}
                  </Button>
                </div>
              )}
            </Col>
            <Col className={styles.content__password} span={11}>
              <h3 className={styles.subtitle}>{t('my_profile.yourSettings')}</h3>
              <div className={styles.tcTags}>
                <TagsList className={styles.tcTagsList} tags={tcTags} />
              </div>
              <Divider />
              <h3 className={styles.subtitle}>{t('my_profile.yourGroups')}</h3>
              {user?.groups?.length === 0 && <Text>{t('my_profile.noGroups')}</Text>}
              {user?.groups?.length && user?.groups?.length > 0 && <Text>{user?.groups?.join(',')}</Text>}
            </Col>
          </Row>
        </NotFound>
        <Modal
          width={'246px'}
          footer={null}
          open={showSuccessDialog}
          bodyStyle={modalBodyStyles}
          onCancel={() => setShowSuccessDialog(false)}
        >
          <div className={styles.modal}>
            <CheckCircleFilled className={styles.modal__icon} />
            <p className={styles.modal__text}>{dialogText}</p>
          </div>
        </Modal>
      </Layout.Content>
    </>
  );
};

export default Profile;
