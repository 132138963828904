export const logs_en = {
  '{FROM_TC_NAME} assigns this cart to {TO_TC_NAME} ({TO_TC_CODE})':
    '{{FROM_TC_NAME}} assigns this cart to {{TO_TC_NAME}} ({{TO_TC_CODE}})',
  'Status change from {FROM_STATUS} to {TO_STATUS}': 'Status change from {{FROM_STATUS}} to {{TO_STATUS}}',
  '{FROM_TC_NAME} add item to cart : {PRODUCT_LABEL} (SKU :{SKU}, QTY : {QUANTITY})':
    '{{FROM_TC_NAME}} added item to cart : {{PRODUCT_LABEL}} (SKU : {{SKU}}, QTY : {{QUANTITY}})',
  '{FROM_TC_NAME} remove item to cart : {PRODUCT_LABEL} (SKU :{SKU})':
    '{{FROM_TC_NAME}} removed item to cart : {{PRODUCT_LABEL}} (SKU : {{SKU}})',
  '{FROM_TC_NAME} change quantity from {FROM_QTY} to {TO_QTY} for item {SKU}':
    '{{FROM_TC_NAME}} changed quantity from {{FROM_QTY}} to {{TO_QTY}} for item {{SKU}}',
  '{FROM_TC_NAME} change forced price from {FROM_PRICE} to {TO_PRICE} for item {SKU}':
    '{{FROM_TC_NAME}} changed forced price from {{FROM_PRICE}} to {{TO_PRICE}} for item {{SKU}}',
};
