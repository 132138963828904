import { useSelector } from 'react-redux';
import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import { selectIsLoadingCartStatusCount } from '../../../store/savedCart';
import styles from './styles.module.scss';
import { SavedCartStatusFilterProps } from './SavedCartStatusFilter.types';

const SavedCartStatusFilter: React.FC<SavedCartStatusFilterProps> = (props: SavedCartStatusFilterProps) => {
  const loading = useSelector(selectIsLoadingCartStatusCount);
  const { statuses, value, onChange } = props;

  const handleClick = (val: string) => {
    onChange?.(val);
  };

  const isActive = (status: string) => status === value;

  return (
    <div className={styles.wrapper}>
      <Spin spinning={loading}>
        <div className={styles.listWrapper}>
          {statuses.map(({ value, label, count }) => (
            <div className={styles.item} key={value}>
              <div className={classNames(styles.label, styles[`label__${value}`])}>{label}</div>
              <div className={styles.countWrapper}>
                <div
                  className={classNames(styles.count, {
                    [styles.active]: isActive(value),
                  })}
                >
                  <div
                    onClick={() => handleClick(value)}
                    className={classNames(styles.countNumber, {
                      [styles.active]: isActive(value),
                    })}
                  >
                    {count}
                  </div>
                </div>
                <div className={styles.line} />
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </div>
  );
};

export default SavedCartStatusFilter;
