import { unwrap } from 'utils/api.utils';
import { isApiOkResponse } from 'utils';
import { IShippingFeeItem, IShippingFeesResponse, IStoreShippingItem } from 'types/shippingFees';
import { IShippingAddresses, ITown, IUser } from 'types';
import { RootState } from 'store/rootReducer';
import { calculateShippingFeesNormalizer } from 'store/delivery/normalizers';
import { ICartState } from 'store/cart/types';
import { ShippingApiService } from 'services';
import { ApiResponse } from 'apisauce';
import type { ICategorizeShippingItemsResponse } from 'store/delivery/types';
import { setAxiosError, setStatusCodeError } from 'constants/errors';

type FetchCategorizeShippingItemsAdapter = {
  shippingItems: IStoreShippingItem[];
  response: Promise<ApiResponse<IShippingFeesResponse, IShippingFeesResponse>>;
};

export const fetchCategorizeShippingItemsStateAdapter = async ({
  shippingItems,
  tags,
  state,
}: {
  shippingItems: IStoreShippingItem[];
  tags: Array<string>;
  state: RootState;
}): Promise<FetchCategorizeShippingItemsAdapter> => {
  const {
    delivery: { shippingAddress },
    products: { postalCodes: town },
    customers: { selectedCustomer: customerId, isGroup },
    cart: { users },
  } = state;

  const { userInfo } = calculateShippingFeesNormalizer({
    shippingAddress,
    town,
    tags,
    customerId,
    isGroup,
    clubMemberExpDate: users?.clubMemberExpDate,
  });
  const response = ShippingApiService.calculateShippingFees(shippingItems, userInfo);

  return {
    shippingItems,
    response: response,
  };
};

export const fetchCategorizeShippingItemsSavedCartAdapter = async ({
  shippingItems,
  shippingAddress,
  town,
  customerId,
  isGroup,
  users,
}: {
  shippingItems: IStoreShippingItem[];
  shippingAddress: IShippingAddresses;
  town: ITown | null;
  customerId: number | undefined;
  isGroup: boolean;
  users: Nullable<IUser>;
}): Promise<FetchCategorizeShippingItemsAdapter> => {
  const { userInfo } = calculateShippingFeesNormalizer({
    shippingAddress,
    tags: undefined,
    town,
    customerId,
    isGroup,
    clubMemberExpDate: users?.clubMemberExpDate,
  });

  return {
    shippingItems,
    response: ShippingApiService.calculateShippingFees(shippingItems, userInfo),
  };
};

export const fetchCategorizeShippingItemsAdapter = async (
  products: ICartState['products'],
  adapter: Promise<FetchCategorizeShippingItemsAdapter>,
): Promise<ICategorizeShippingItemsResponse> => {
  const { shippingItems, response } = await adapter;

  const data = unwrap(await response, (throwableResponse) => {
    if (!isApiOkResponse(throwableResponse)) {
      const statusCode = throwableResponse.originalError.response?.data.code;
      if (statusCode) {
        return setStatusCodeError(statusCode);
      }
      return setAxiosError(throwableResponse.problem);
    }
  });
  const { shippingMethods } = data;

  const shippingMethodIds = shippingMethods?.map((shippingMethod) => shippingMethod.shippingMethodId) ?? [];

  const shippingFees: IShippingFeeItem[] = data.shippingFees || [];

  products.forEach((product) => {
    const hasShippingFee = shippingFees?.find((fee) => fee?.itemList.find((item) => item.offerId === product.offerId));

    if (hasShippingFee) {
      return;
    }

    const productShippingMethodIds = product.shippingMethods
      .map((method) => method.id)
      .filter((id) => shippingMethodIds.includes(id));

    const newShippingFee: IShippingFeeItem = {
      shippingMethodId: productShippingMethodIds[0],
      price: 0,
      itemList: [
        {
          offerId: product.offerId,
          shippingMethodIds: product.shippingMethods
            .map((method) => method.id)
            .filter((id) => shippingMethodIds.includes(id)),
        },
      ],
    };
    shippingFees.push(newShippingFee);
  });

  return {
    ...data,
    shippingFees: shippingFees,
    products,
    shippingItems,
  };
};
